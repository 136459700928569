<template>
	<div class="login-container container">
		<div
			style="width:calc(100% - 10vw);margin: 0 auto;text-align: left;padding: 0 5vw;height: 60px;position: absolute;top: 0;">
			<div style="position: relative;">
				<div class="logo">
					<img src="../assets/logo1.png" />
					<h2>广西学士通教育</h2>
				</div>
				<h5 class="logo_title">始于2009 高度专业 高度敬业 为人师表</h5>
			</div>
		</div>
		<!-- 自动从右向左滚动 -->
		<marquee direction="left" scrollamount="5" behavior="scroll" loop="-1" class="Announcement">{{ systemNote }}
		</marquee>
		<div class="content">
			<!-- <el-link type="primary" :underline="false" class="backSy">
				返回网站首页
			</el-link> -->
			<div class="login-box">
				<div class="text_login">
					<h5>欢迎登录/Login</h5>
				</div>
				<el-form class="login-form" autoComplete="on" :model="loginForm" :rules="loginRules" ref="loginForm"
					label-position="left">
					<el-form-item prop="cardNo">
						<el-input prefix-icon="el-icon-user" name="cardNo" type="text" v-model="loginForm.cardNo" autoComplete="on"
							placeholder="卡号" />
					</el-form-item>
					<el-form-item prop="password">
						<el-input prefix-icon="el-icon-lock" name="password" type="password" v-model="loginForm.password"
							autoComplete="on" placeholder="密码" @keyup.enter.native="login" />
					</el-form-item>
					<el-link type="primary" :underline="false" @click="$router.push('zhuce')"
						style="white-space: nowrap;">卡激活</el-link>
					<el-form-item>
						<el-button type="primary" style="width:100%;background:#4c71fb;font-size: 20px;outline: none;"
							:loading="loading" @click.native.prevent="login">
							登 录
						</el-button>
					</el-form-item>
					<!-- <el-form-item>
						<el-button style="width:100%;font-size: 20px;outline: none;" @click.native.prevent="toEdu">
							返回网站首页
						</el-button>
					</el-form-item> -->
				</el-form>
				<!-- <div class="ewm" style="margin-top: 1.6vw;">
					<img src="../assets/login/erwm.png" style="float: left;width: 25%;height: 100%;margin-top: 0;" />
					<span style="float: left;text-align: left;font-size: 0.8vw;">手机微信扫描二维码关注公众号，点系统入口可同时使用手机版。</span>
				</div> -->
			</div>
			<div class="box">
				<li>
					<div class="box_img">
						<img src="../assets/login/img5.jpg" />
					</div>
					<div class="box_title">
						<h4>
							<p style="white-space: nowrap;">本科提前批</p>
							<p style="white-space: nowrap;">志愿填报查询</p>
							<p style="white-space: nowrap;">分析系统</p>
						</h4>
						<p class="link">
							<img src="../assets/login/turn_in.png" />
							<el-link @click="register('search2')" :underline="false" style="white-space: nowrap;">
								点击进入
							</el-link>
						</p>
					</div>
				</li>
				<li>
					<div class="box_img">
						<img src="../assets/login/img6.jpg" />
					</div>
					<div class="box_title">
						<h4>
							<p style="white-space: nowrap;">本科普通批</p>
							<p style="white-space: nowrap;">志愿填报查询</p>
							<p style="white-space: nowrap;">分析系统</p>
						</h4>
						<p class="link">
							<img src="../assets/login/turn_in.png" />
							<el-link @click="register('search')" :underline="false" style="white-space: nowrap;">
								点击进入
							</el-link>
						</p>
					</div>
				</li>


			</div>
		</div>

		<!--
			<div class="map" style="margin:0 auto;width: 67vw;">
			<p style="float:left">
				<span>联系地址：南宁市青秀区东葛路1号广西锦华大酒店附楼二楼216室</span><br />
				<span>电话：0771—5679916 &nbsp;&nbsp;版权所有©:广西学士通教育投资有限公司 </span>
			</p>
			<p style="float:right">
				<span>网站备案号：桂ICP备16007468号 </span><br />
				<span>电信增值许可证:45010702000580</span>
			</p>
		</div>
		-->
	</div>
</template>

<script>
// import {
// 	getCode
// } from '../api/member.js'
import Cookies from "js-cookie"
import {
	login
} from '../api/login.js'
import {
	setToken,
	getToken
} from '../api/auth.js'
import {
	Message
} from 'element-ui'
//import identify from '@/components/ImgVerify'
import {
	mapActions,
} from "vuex"
import { getList } from '@/api/sysVariables.js'
export default {
	components: {
		//identify
	},
	data() {
		const validatecardNo = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('卡号不能为空'))
			} else {
				callback()
			}
		}
		const validPwd = (rule, value, callback) => {
			console.log('value', value)
			if (!value) {
				callback(new Error('密码不能为空'))
			} else {
				callback()
			}
		}
		return {
			codeTimeout: 60,
			codeBtn: '获取验证码',
			loginForm: {
				cardNo: '',
				password: ''
			},
			loading: false,
			identifyCode: '',
			loginRules: {
				cardNo: [{
					required: true,
					trigger: 'blur',
					validator: validatecardNo
				}],
				password: [{
					required: true,
					trigger: 'blur',
					validator: validPwd
				}]
			},
			windowWidth: document.documentElement.clientWidth,
			systemNote: '',
		};
	},
	created() {
		//console.log(getToken());
		//console.log(getToken());
		this.getWindowWidth();
		this.getSystemNote();
	},
	methods: {
		...mapActions(["verification",]),
		toEdu() {
			window.location.href = 'http://xueshitongedu.cn/';
		},
		getWindowWidth() {
			let that = this;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.documentElement.clientWidth;
				})()

			};
		},
		getSystemNote() {
			getList().then(res => {
				res.data.map(u => {
					if (u.variableName == "SystemNote") {
						this.systemNote = u.variableContent;
					}
				})
			})
		},
		login() {
			console.log(111);
			this.$refs.loginForm.validate(valid => {
				if (valid) {
					Cookies.set('cardNo', this.loginForm.cardNo)
					this.loading = true;
					login(this.loginForm).then(res => {
						//console.log(res);
						if (res.token) {
							//window.localStorage.setItem("cardNo",this.loginForm.cardNo);
							setToken(res.token);
							setTimeout(() => {
								console.log('getToken : ' + getToken());
								this.verification();
							}, 50)
							setTimeout(() => {
								this.loading = false;
								// Message({
								// 	message: '登录成功',
								// 	type: 'success',
								// 	duration: 2 * 1000
								// })
								this.$router.push('home');
							}, 500)

						} else {
							Message({
								message: res.message || res.msg || res.errmsg,
								type: 'error',
								duration: 5 * 1000
							})
						}
					}).catch(() => {
						this.loading = false;
						// setTimeout(() => {
						// 	this.$router.push('login');
						// }, 100)
					})
				}
			})
		},
		register() {
			this.$message.error('您还未登录，请先登录！');
		},
		// getCode() {
		// 	if (this.codeBtn == '获取验证码') {
		// 		if (this.loginForm.cardNo.length == 11) {
		// 			// 进入倒计时
		// 			this.codeBtn = this.codeTimeout + '秒';
		// 			let time = setInterval(() => {
		// 				this.codeTimeout--;
		// 				this.codeBtn = this.codeTimeout + '秒';
		// 				if (this.codeTimeout == 0) {
		// 					this.codeBtn = '获取验证码';
		// 					this.codeTimeout = 60;
		// 					clearInterval(time);
		// 				}
		// 			}, 1000)
		// 			getCode(this.loginForm.cardNo).then(res => {
		// 				console.log(res);
		// 				if (res.state) {
		// 					Message({
		// 						message: '发送成功，请注意查收',
		// 						type: 'success',
		// 						duration: 5 * 1000
		// 					})
		// 				} else {
		// 					Message({
		// 						message: res.errmsg || '发送失败，请联系管理员',
		// 						type: 'error',
		// 						duration: 5 * 1000
		// 					})
		// 				}
		// 			})
		// 		} else {
		// 			Message({
		// 				message: '请输入正确格式的手机号',
		// 				type: 'error',
		// 				duration: 5 * 1000
		// 			})
		// 		}
		// 	}
		// },
	},

}
</script>

<style rel="stylesheet/scss" lang="scss">
/* reset element-ui css */
.login-container {
	li {
		list-style: none;
	}

	.el-input {

		input {
			background: #fff;
			border: 0.15vw #4c71fb solid;
			-webkit-appearance: none;
			border-radius: 0.5vw;
			font-size: 1vw;

			&:-webkit-autofill {
				transition: background-color 5000s ease-in-out 0s;
			}
		}
	}

	.el-form-item {
		margin-bottom: 15px;
		border-radius: 5px;
		color: #454545;

		.el-form-item__content {}

		.imgCode {
			position: absolute;
			top: 0.1vw;
			right: 0.1vw;
		}

		.el-input__inner {
			//padding-left: 2.5vw;
		}

		.el-input__icon {
			color: #4c71fb;
		}
	}
}
</style>
<style lang="scss" scoped>
//@media screen and (max-width: 1150px) {
//.leftImg {
//width: 450px !important;
//}
//}

//@media screen and (max-width: 1010px) {
//.leftImg {
//	width: 380px !important;
//}
//    }

//@media screen and (max-width: 940px) {
//.leftImg {
//display: block;
//width: 260px !important;
//margin: 0 auto !important;
//}
//}

$dark_gray: #D1DFE8;

.login-container {
	width: 100%;
	min-height: 900px;
	background: url(../assets/login/login_bg.jpg) no-repeat;
	background-color: #fff;
	background-position: 0 0;
	background-size: 100% 100%;
	text-align: center;
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.Announcement {
		width: 35%;
		position: absolute;
		top: 100px;
		right: 20%;
		color: red;
	}

	.logo {
		height: 60px;
		line-height: 60px;
		float: left;
		display: flex;

		img {
			height: 70%;
			margin: 0.5vw 0;
		}

		h2 {

			margin-left: 1vw;
			color: #fff;
		}
	}

	.logo_title {
		height: 100%;
		line-height: 60px;
		color: #feca32;
		font-size: 16px;
		position: absolute;
		left: 10px;
		top: 40px;

		.el-link {
			margin: 0 10px;
			color: #fff;
		}
	}

	.content {
		position: absolute;
		top: 20%;
		left: 16.5%;
		width: 70%;
		height: 60vh;
		display: flex;
		vertical-align: middle;

		.login-box {
			margin-right: 200px;
			width: 25%;

			.ewm {
				display: flex;
			}
		}

		.box {
			display: flex;
			flex-wrap: wrap;
			width: 70%;
			align-content: center;

			li {
				display: flex;
				height: 150px;
				width: 33%;
				margin: 15px;
				background: #f0d031;
				border-radius: 5px;
				padding: 5px;

				.box_img {
					width: 50%;
					height: calc(100% - 0.4vw);
					position: relative;

					img:first-child {
						width: 100%;
						height: calc(100% - 0.4vw);
						text-align: left;
					}

					// img:last-child{
					// 	height: 100%;
					// }
				}

				.box_title {
					flex: 1;
					height: 100%;
					position: relative;

					h4 {
						font-style: italic;
						color: #1f484d;
						text-shadow: 0.1vw 0.1vw #fff;
						text-align: left;
					}

					h5 {
						font-size: 18px;
						font-style: italic;
						color: #1f484d;
						text-shadow: 0.1vw 0.1vw #fff;
						position: absolute;
						bottom: 25px;
						right: 20px;
					}

					.link {
						img {
							height: 15px;
							width: unset;
							margin-right: 5px;
							margin-top: 0;
						}

						display: flex;
						position: absolute;
						bottom: 0;
						right: 0;
						align-items: center;
					}
				}
			}

			h4 {
				margin-bottom: 0vw;
				font-size: 1.2vw;
			}
		}

		> img {
			width: 568px;
			margin-right: 150px;
			vertical-align: middle;
		}

		.login-form {
			display: inline-block;
			vertical-align: middle;
			width: 100%;

			.codeItem {
				display: flex;

				.el-input {
					margin-right: 15px;
					font-size: 16px;
				}
			}

			.el-link {
				font-size: 1em;
				margin: 10px 0;
				margin-left: 75%;
			}
		}

		.el-button {
			border-radius: 0.5vw;
		}

		.el-button--primary {
			border: none;
		}
	}

	// @media screen and (max-width: 500px) {
	// 	.content {
	// 		flex-direction: column;
	// 		width: 60%;

	// 		.login-box {
	// 			width: 100%;

	// 			.ewm {
	// 				display: none;
	// 			}

	// 			.login-form {
	// 				.el-link {
	// 					margin-top: -5px;
	// 					color: #fff;
	// 				}
	// 			}
	// 		}

	// 		.box {
	// 			display: none;
	// 		}
	// 	}
	// }

	.svg-container {
		color: $dark_gray;
		vertical-align: middle;
		width: 33px;
		display: inline-block;
		font-size: 22px;

		&_login {
			font-size: 31px;
		}
	}

	.title {
		margin: 10px 0;
		margin-bottom: 40px;
		text-align: left;
		font-size: 26px;
		font-weight: bold;
		background-image: linear-gradient(to right, #fd7f0f, #ff0300);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}

	.text_login h5 {
		font-size: 1.3vw;
		border-bottom: 0.1vw #000 solid;
		margin-bottom: 1.5vw;
		margin-left: 0.5vw;
		text-align: left;
		font-weight: initial;
	}



	.map p {
		text-align: left;
		font-size: 0.8vw;
		margin-top: 0.5vw;
	}

	.el-form-item__content img {
		width: 100%;
	}

	.content img {
		float: left;
		margin-left: 0vw;
		margin-top: 0.3vw;
		height: 1.5vw;
	}

}
</style>
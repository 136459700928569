<template>
	<div id="choose" style="background:#f5f5f5;">
		<!-- <div class="top">
				<h3>新高考选科查询</h3>
				<button @click="back">
					<i>&lt;&nbsp;</i>返回
				</button>
			</div> -->
		<el-page-header class="pageHeader" @back="$router.go(-1)" content="新高考选科查询">
		</el-page-header>
		<div class="content">
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>首选科目</h4>
			</div>

			<el-radio-group v-model="listQuery.fsrShort" size="medium">
				<el-radio-button label="物理"></el-radio-button>
				<el-radio-button label="历史"></el-radio-button>
			</el-radio-group>

		</div>
		<div class="content">
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>再选科目
					<span style="font-size:1vw;font-weight: normal;color: #eaeaea;margin-left: 1vw;">(<i
							style="color:#d30f11;padding-right: 4px;">*</i>四选二,再点取消选择)</span>
				</h4>
			</div>

			<el-checkbox-group v-model="listQuery.ssrShort" :max="2">
				<el-checkbox label="化学"></el-checkbox>
				<el-checkbox label="生物"></el-checkbox>
				<el-checkbox label="地理"></el-checkbox>
				<el-checkbox label="思想政治"></el-checkbox>
			</el-checkbox-group>
		</div>
		<div class="content">
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>批量查询</h4>
			</div>
			<el-radio-group v-model="listQuery.searchType" size="medium">
				<el-radio-button label="1">可报考院校专业</el-radio-button>
				<el-radio-button label="0">不可报考院校专业</el-radio-button>
			</el-radio-group>
			<div class="content-btn">
				<el-button type="primary" @click="submitSearch('many')">批量查询</el-button>
			</div>

		</div>
		<div class="content">
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>单院校查询</h4>
			</div>
			<div class="inputBox">
				<el-select v-model="province" placeholder="请选择省市" @change="selectProvince" class="select"
					size="small">
					<el-option v-for="(item,index) of provinceArr" :key="index" :label="item.address"
						:value="item.code">
					</el-option>
				</el-select>
				<el-select v-model="listQuery.schoolCode" placeholder="请选择院校" class="select" size="small">
					<el-option v-for="(item,index) of schoolArr" :key="index" :label="item.schoolName"
						:value="item.schoolCode">
					</el-option>
				</el-select>
				<!-- <AuthSelect class="select" v-model="city" placeholder="请选择市区" :data-source="cityarr" size="medium"
						@change="selectCity"></AuthSelect>
					<AuthSelect class="select" v-model="county" placeholder="请选择县区" :data-source="countyarr"
						size="medium"></AuthSelect> -->
			</div>
			<div class="content-btn">
				<el-button type="primary" @click="submitSearch('single')">单院校查询</el-button>
			</div>
		</div>

		<!-- <el-dialog title="批量查询" :visible.sync="dialogVisible1" width="30%">

				<el-radio-group v-model="radio2" class="tc">
					<el-radio-button label="可报考院校专业" class="zy"></el-radio-button>
					<el-radio-button label="不可报考院校专业" class="zy"></el-radio-button>
				</el-radio-group>
				<el-button @click.stop="submitSearch" type="primary" class="qx">查询</el-button>
			</el-dialog>

			<el-dialog title="单院校查询" :visible.sync="dialogVisible2" width="30%">

				<el-radio-group v-model="radio2" class="tc">
					<el-radio-button label="可报考院校专业" class="zy"></el-radio-button>
					<el-radio-button label="不可报考院校专业" class="zy"></el-radio-button>
				</el-radio-group>
				<el-button @click.stop="submitSearch" type="primary" class="qx">查询</el-button>
			</el-dialog> -->

	</div>

</template>
<script>
	import * as subjectSelect from '@/api/subjectselectrequires'
	import * as cityData from '@/components/cityData'
	//import AuthSelect from '@/components/AuthSelect.vue'
	export default {
		components: {
			//AuthSelect
		},
		data() {
			return {
				listQuery: {
					page: 1,
					limit: 600,
					schoolCode: '',
					searchType: '',
					fsrShort: '',
					ssrShort: [],
				},
				radio1: [],
				radio2: [],
				checkboxGroup1: [],
				batchQueryGroup: [],
				singleSchoolGroup: [],
				dialogVisible1: false,
				dialogVisible2: false,
				provinceArr: [],
				schoolArr: [],
				province: '',
				school: '',
				windowWidth: document.documentElement.clientWidth
			}
		},
		created() {
			this.provinceArr = cityData.getAreaList('');
			//this.getList();
			this.getWindowWidth();
		},
		methods: {
			// getList() {
			//   if (this.listQuery.ssrShort.length == 2) {
			//     console.log(this.listQuery);
			//     subjectSelect.getMemberList2(this.listQuery).then(res => {
			//       console.log(res);

			//     })
			//   } else {
			//     Message({
			//       message: '请选择两个再选科目',
			//       type: 'error',
			//       duration: 2 * 1000
			//     })
			//   }

			// },
			getSchoolList(code) {
				let listQuery = {
					page: 1,
					limit: 999,
					province: code
				}
				//console.log(this.listQuery);
				subjectSelect.getMemberList2(listQuery).then(res => {
					this.schoolArr = res.data;
					//console.log(res);
				})
			},
			selectProvince(val) {
				this.school = '';
				//this.schoolArr = cityData.getAreaList(val)
				this.getSchoolList(val)
			},
			back() {
				//跳转到上一次浏览的页面
				this.$router.go(-1)
				//通过push进行跳转
				//this.$router.push('/home')
			},
			getWindowWidth() {
				let that = this;
				window.onresize = () => {
					return (() => {
						that.windowWidth = document.documentElement.clientWidth;
					})()

				};
			},
			submitSearch(type) { //提交并跳转至搜索
				// if (this.cityValue == '' || this.provinceValue == '') {
				//   this.$toast('请选择省市与院校')
				//   return false;
				// }
				//console.log(this.listQuery);
				
				for (let i in this.listQuery) {
					if (type == 'single') { //判断是否为单院校
						if (i !== 'searchType') {
							if (!this.listQuery[i]) {
								this.$message({
									message: '请确认是否已选择全部查询条件',
									type: 'error',
									duration: 2 * 1000
								})
								return
							}
						}
						this.$store.commit('setSchoolSearchInfo',{
							type:1,
							schoolCode:this.listQuery.schoolCode,
							schoolName:this.schoolArr.find(u => {return u.schoolCode==this.listQuery.schoolCode}).schoolName,
							subjectInfo:{
								fsrShort:this.listQuery.fsrShort,
								ssrShort:this.listQuery.ssrShort.join(',')
							}
						});
						break;
					} else {
						if (i !== 'schoolCode') {
							if (!this.listQuery[i]) {
								this.$message({
									message: '请确认是否已选择全部查询条件',
									type: 'error',
									duration: 2 * 1000
								})
								return
							}
						}
						this.$store.commit('setSchoolSearchInfo',{
							type:0,
							searchType:this.listQuery.searchType,
							subjectInfo:{
								fsrShort:this.listQuery.fsrShort,
								ssrShort:this.listQuery.ssrShort.join(',')
							}
						});
						break;
					}
				}
				setTimeout(() => {
					this.$router.push('/schoolSearch');
				},100)
				// if (type == 'single') { //单院校查询
				// 	this.$router.push('singleSearch');
				// } else {
				// 	this.$router.push('search');
				// }
			},
		},

	}
</script>

<style lang="scss" scoped>
	#choose {
		min-height: 85vh;

		.pageHeader {
			width: calc(100% - 10vw);
			height: 100px;
			line-height: 100px;
			background-color: #fff;
			padding: 0 5vw;
		}

		.top {
			padding: 0 100px;
			width: calc(100% - 200px);
			display: flex;

			h3 {
				flex: 1;
				text-align: left;
			}

			button {
				flex: 1;
				border: none;
				outline: none;
				background: none;
				text-align: right;
				opacity: .8;
				font-size: 1em;
				cursor: pointer;
			}
		}

		.content {
			margin: 50px auto;
			padding-bottom: 20px;
			width: 80%;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			text-align: left;

			.title {
				margin-bottom: 20px;
				background-color: #4d6ffb;
				border-radius: 10px;

				h4 {
					text-align: left;
					color: #fff;
					font-size: 1.2vw;
					padding: 0.5vw 0vw 0.5vw 2.5vw;
				}

				img {
					float: left;
					margin-left: 1vw;
					margin-top: 0.7vw;
					height: 1.5vw;
				}
			}

			.inputBox {
				.select {
					display: inline-block;
					margin: 0 20px;
				}
			}

			.content-btn {
				text-align: center;

				.el-button {
					margin: 2vw;
				}
			}


			.tips {
				span {
					font-size: 0.8vw;
					font-weight: normal;
					color: #959595;
					margin: 0 2vw;

					i {
						color: #d30f11;
						margin-right: 0.5vw;
					}
				}
			}
		}
	}
</style>
<style rel="stylesheet/scss" lang="scss">
	#choose {
		.content {
			.inputBox {
				.select {
					.el-input__inner {
						background: #FFF;
						border: 0.2vw solid #4d6ffb !important;
						font-weight: bold;
						color: #4d6cfb;
						padding: 0.7vw 3vw;
						font-size: 1.2vw;
						border-radius: 6vw !important;
					}
				}
			}
		}

		.el-radio-group {
			font-size: 0;
			float: left;
		}

		.el-radio-button {
			margin-left: 4vw;

			.el-radio-button:first-child .el-radio-button__inner {
				border-left: 0.2vw solid #4d6ffb;
				border-radius: 6vw;
				box-shadow: none !important;
			}

			.el-radio-button__inner {
				background: #FFF;
				border: 0.2vw solid #4d6ffb !important;
				font-weight: bold;
				color: #4d6cfb;
				padding: 0.7vw 3vw;
				font-size: 1.2vw;
				border-radius: 6vw !important;
			}

		}

		.el-checkbox {
			margin-right: 4vw !important;
		}

		.el-checkbox__inner {
			display: none !important;
		}

		.el-checkbox-group {
			float: left;
			margin-left: 4.5vw;
		}

		.el-checkbox__label {
			padding: 0.5vw 3vw !important;
			font-size: 1.2vw !important;
			color: #4d6cfb;
			font-weight: bold !important;
			border: 0.2vw solid #4d6ffb !important;
			border-radius: 6vw !important;
		}

		.el-button--primary {
			background-color: #4d6ffb !important;
			border-color: #4d6ffb !important;
			font-size: 1.4vw !important;
			font-weight: bold !important;
			border-radius: 5vw !important;
			padding: 1vw 2.5vw !important;
		}

		.el-radio-button__orig-radio:checked+.el-radio-button__inner {
			background-color: #4d6ffb !important;
			color: #FFF;
		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #ffffff !important;
			background: #4d6ffb;
		}

		.el-dialog {
			width: 35vw !important;
			border-radius: 0.5vw;
			margin-top: 17vh !important;
		}

		.el-dialog__body {
			padding: 3.5vw 2vw;
			color: #606266;
			font-size: 14px;
			word-break: break-all;
		}

		.el-dialog__header {
			padding: 1vw 1vw;
			font-size: 2vw;
			background: #4d6ffb;
			border-radius: 0.5vw;
		}

		.el-dialog__title {
			line-height: 24px;
			font-size: 2.1vw;
			font-weight: bold;
			color: #ffffff;
		}

		button.el-button.button.el-button--primary {
			width: 22vw;
			margin: 1vw auto;
			background: #fff !important;
			border: 0.2vw solid;
			color: #4d6ffb;
		}

		button.el-button.qx.el-button--primary {
			padding: 1.2vw 4.8vw !important;
			font-size: 1.8vw !important;
			margin-top: 3vw;
		}

		.tc .el-radio-button {
			margin: 10px auto;
		}

		.zy .el-radio-button__inner {
			margin: 0 auto;
			width: 30vw;
			font-size: 2vw;
			padding: 15px 10px;
		}
	}
</style>

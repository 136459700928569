import request from '@/utils/request'

export function getList(params) {
	return request({
		url: '/eduVideos/load',
		method: 'get',
		params
	})
}

export function loadForRole(roleId) {
	return request({
		url: '/eduVideos/loadForRole',
		method: 'get',
		params: {
			appId: '',
			firstId: roleId
		}
	})
}

export function add(data) {
	return request({
		url: '/eduVideos/add',
		method: 'post',
		data
	})
}

export function hits(id) {
	return request({
		url: '/eduVideos/hits',
		method: 'get',
		params: {
			id
		}
	})
}

export function update(data) {
	return request({
		url: '/eduVideos/update',
		method: 'post',
		data
	})
}

export function del(data) {
	return request({
		url: '/eduVideos/delete',
		method: 'post',
		data
	})
}

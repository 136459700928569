<template>
	<div class="te_content" style="width: calc(100% - 20vw);margin: 4vw auto;min-height: 70vh;padding: 0 10vw;">
		<ul>
			<li class="item" v-for="item in data" :key="item.id" @click="getTemp(item)">
				<el-image class="pic" :src="'https://api.xueshitongedu.cn/UpFiles/'+(item.file_Pic_FacePic? item.file_Pic_FacePic:item.thumbnail)"
					fit="fill"></el-image>
				<div class="content">
					<h3>{{item.videoTitle}}</h3>
					<div class="desc">
						{{item.introduce}}
					</div>
					<div class="footer">
						<span class="teacher">主讲老师：{{item.lecturer}}</span>
						<span class="hits">
							<img src="../assets/look2.png" alt="">
							<span>{{item.hits}}</span>

						</span>
					</div>
				</div>
			</li>
		</ul>
		<el-dialog :title="temp.videoTitle" :visible.sync="dialogvisible">
			<video ref="video" class="video" :src="temp.tencentUrl" controls controlslist="nodownload"></video>
		</el-dialog>
	</div>
</template>
<script>
	import * as eduvideos from '@/api/eduvideos'
	export default {
		data() {
			return {
				dialogvisible: false,
				temp: {},
			}
		},
		props: {
			data: Array
		},
		watch: {
			dialogvisible(nv) {
				if (!nv) {
					this.$refs.video.pause();
				}
			}
		},
		methods: {
			getTemp(item) {
				this.dialogvisible = true;
				this.temp = item;
				eduvideos.hits(item.id).then(res =>{
					if(res.code==200){
						this.temp.hits++;
					}
				})
			}
		}
	}
</script>
<style scoped lang="scss">
	* {
		margin: 0;
		padding: 0;
	}

	.itemWrap {
		text-decoration: none;
	}

	.item {
		cursor: pointer;
		border-radius: 5px;
		margin: 20px;
		height: 15vw;
		display: flex;
	}

	.item .pic {
		flex: 1;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.item .content {
		width: 70%;
		height: calc(100% - 2vw);
		background-color: #698fff;
		//background-color: #9fabfd;
		text-align: left;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		padding: 1vw 2vw;
		color: #fff;
		box-shadow: 3px 3px 10px #cacaca;
		display: flex;
		flex-direction: column;
	}

	.item .content h3 {
		margin-bottom: 2vw;
		font-style: normal;
		font-weight: 600;
		font-size: 1.4vw;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.item .content .desc {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		opacity: .8;
		font-style: normal;
		font-weight: 400;
		font-size: 1vw;
	}

	.item .content .footer {
		display: flex;
	}

	.item .content .teacher {
		min-width: 30%;
		text-align: left;
		font-size: 1vw;
	}

	.item .content .hits {
		height: 100%;
		display: flex;
		text-align: left;
		line-height: 2.4vw;
		position: relative;
		padding-left: 2vw;
	}

	.item .content .hits span {
		opacity: .8;
		min-width: 1vw;
	}

	.item .content .hits img {
		margin: 0px 5px 0 0;
		height: 2vw;
	}

	.video {
		width: 100%;
	}
	
	::v-deep .el-dialog__header{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	// >>>.el-dialog__body {
	// 	padding: 0;
	// 	background: #323232;
	// }
</style>

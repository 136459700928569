<template>
	<div class="collect">
		<el-page-header class="pageHeader" @back="$router.go(-1)" content="收藏夹">
		</el-page-header>
		<el-table :data="collectsList" style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;"
			v-loading="listloading" border :header-cell-style="headerStyle" :cell-style="cellStyle"
			@selection-change="handleSelectionChange" ref="listTable" row-key="id">
			<el-table-column type="selection" align="center" width="55"></el-table-column>
			<el-table-column label="操作" width="70px">
				<template slot-scope="scope">
					<div v-if="scope.row.orders == 0">
						<el-link type="primary" :underline="false" @click="orderTop(scope.row)">置顶</el-link>
					</div>
					<div v-else>
						<el-link type="primary" :underline="false" @click="orderUp(scope.row)">上移</el-link>
						<el-link type="primary" :underline="false" @click="orderDown(scope.row)">下移</el-link>
					</div>
				</template>
			</el-table-column>
			<el-table-column width="50" label="序号">
				<template slot-scope="scope">
					{{ scope.row.index }}
				</template>
			</el-table-column>
			<el-table-column prop="syS_Years" label="年份" show-overflow-tooltip width="70px"></el-table-column>
			<el-table-column prop="scores" label="分数" show-overflow-tooltip width="70px"></el-table-column>
			<el-table-column prop="provinceCity" label="省市" show-overflow-tooltip width="70px"></el-table-column>
			<el-table-column prop="schoolName" label="院校名称" show-overflow-tooltip min-width="150px">
				<template slot-scope="scope">
					<p class="schoolName" @click="showSystem2(scope.row)">{{ scope.row.schoolName }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="schoolCode" label="院校代码" show-overflow-tooltip width="70px">
				<template slot="header">
					<p>院校</p>
					<p>代码</p>
				</template>
			</el-table-column>
			<el-table-column prop="subjectGroup" label="专业组" show-overflow-tooltip width="70px">
				<template slot="header">
					<p>专业组</p>
					<p>代码</p>
				</template>
			</el-table-column>
			<el-table-column prop="ssrShort" label="再选科目" show-overflow-tooltip width="80px">
				<template slot="header">
					<p>再选</p>
					<p>科目</p>
				</template>
			</el-table-column>
			<el-table-column prop="schoolPlans" label="2024计划数" show-overflow-tooltip width="70px">
				<template slot="header">
					<p>专业组</p>
					<p>计划数</p>
				</template>
			</el-table-column>
			<el-table-column prop="schoolChanceStr" label="投档概率" width="70px">
				<template slot="header">
					<p>投档</p>
					<p>概率</p>
				</template>
				<template slot-scope="scope">
					<span>{{ scope.row.schoolChanceStr }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="diC_OldTimes" label="老高考招生批次" width="100px">
				<template slot="header">
					<p>老高考</p>
					<p>招生批次</p>
				</template>
			</el-table-column>
			<el-table-column prop="diC_SchoolClass" label="院校实力评价" width="110px">
				<template slot="header">
					<p>院校</p>
					<p>实力评价</p>
				</template>
				<template slot-scope="scope">
					<p style="color: red;font-weight: bold;">{{ scope.row.schoolRanking }}</p>
					<p v-for="item in scope.row.diC_SchoolClass" :key="item">{{ item }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="subjectName" label="专业" show-overflow-tooltip min-width="150px">
				<template slot="header">
					<p>专业代码</p>
					<p>专业名称</p>
				</template>
				<template slot-scope="scope">
					<p>{{ scope.row.subjectCode ? scope.row.subjectCode : '-' }}</p>
					<p>{{ scope.row.subjectName }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="subjectPlans" label="专业招生" width="70px">
				<template slot="header">
					<p>专业</p>
					<p>计划数</p>
				</template>
			</el-table-column>
			<el-table-column prop="subjectChanceStr" label="录取概率" width="70px">
				<template slot="header">
					<p>录取</p>
					<p>概率</p>
				</template>
				<template slot-scope="scope">
					<span>{{ scope.row.subjectChanceStr }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="diC_SubjectClass" label="专业实力评价" width="100px">
				<template slot="header">
					<p>专业</p>
					<p>实力评价</p>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="70px">
				<template slot-scope="scope">
					<el-link type="primary" :underline="false" @click="delRow(scope.row)">删除</el-link>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align: center;margin: 10px auto;">
			<div style="display: flex;justify-content: center;align-items: center;">
				<!-- <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
					@pagination="handleCurrentChange" /> -->
				<el-button type="primary" @click="save" size="small">保存修改</el-button>
				<ExportExcel :multipleSelection="exportData" :fieldName="exportExcelData.fieldName"
					:fieldValue="exportExcelData.fieldValue" :isDone="processingDataDone" @processingData="processingData"
					style="margin-left: 50px;"></ExportExcel>
			</div>

		</div>
		<div style="margin: 50px 30px;text-align: left;">
			提示：
			<span style="color: red;">
				先点收藏条目前的“上移”或“下移”不松开，可将收藏条目拖动到目标位置。收藏夹最后确定保留的{{times=='本科'?'40':'20'}}个条目（含以下），可以导入模拟填报方案，自动生成模拟填报方案，请进入“{{times=='本科'?'系统四：本科普通批志愿模拟填报':'系统三：本科提前批其他类志愿模拟填报'}}”，点击“收藏夹导入生成模拟填报方案”按扭完成导入。
			</span>
		</div>
		<div style="margin-left: 30px;text-align: left;">
			<el-button type="primary" @click="delList" size="medium">批量删除</el-button>
			<span>（请点列表最左侧的小方框，后勾选或取消要批量删除的收藏条目，最后再点批量删除）</span>
		</div>
	</div>
</template>

<script>
import Sortable from 'sortablejs'
import * as categorys from "@/api/categorys";
import {
	mapState,
} from "vuex"
import ExportExcel from "@/components/ExportExcel.vue";
//import Pagination from "@/components/Pagination";
import * as subjectcollects from '@/api/subjectcollects';
export default {
	components: {
		//Pagination,
		ExportExcel
	},
	data() {
		return {
			listloading: false,
			cellStyle: {
				'text-align': 'center',
			},
			headerStyle: {
				fontWeight: 'bold',
				color: '#303030',
				'text-align': 'center',
			},
			exportLoading: false,
			collectsList: [],
			exportData: [],
			processingDataDone: false,
			exportExcelData: {
				fieldName: ['序号', '年份', '省市', '院校名称', '院校代码', '专业组代码', '再选科目', '专业组计划数', '投档概率', '老高考招生批次', '院校实力评价',
					'专业名称', '专业代码', '专业计划数', '录取概率', '专业实力评价'
				],
				fieldValue: ['index', 'syS_Years', 'provinceCity', 'schoolName', 'schoolCode', 'subjectGroup',
					'ssrShort', 'schoolPlans', 'schoolChanceStr', 'diC_OldTimes', 'diC_SchoolClass', 'subjectName',
					'subjectCode', 'subjectPlans', 'subjectChanceStr', 'diC_SubjectClass'
				],
			},
			listQuery: {
				page: 1,
				limit: 150,
				cardNo: '', // 会员卡号
				provinceCity: '', // 省市
				schoolCode: '', // 院校代码
				schoolName: '', // 院校名称
				diC_SchoolClass: [], // 院校类型
				schoolRanking: '', // 全国排名
				subjectGroup: '', // 专业组
				subjectName: '', // 专业
				minSchoolPlans: '', // 最小招生
				maxSchoolPlans: '', // 最大招生
				syS_FirstSelectCourse: '', // 首选科目
				scores: '', // 分数
				syS_Times: '', // 批次
				syS_Years: '', // 年份
				diC_SubjectClass: [], // 专业实力评估
				minSubjectPlans: '', // 最小专业招生数
				maxSubjectPlans: '', // 最大专业招生数
				schoolChanceStr: '', // 院校专业组概率
				subjectChanceStr: '', // 专业录取概率
			},
			multipleSelection: [],
			total: 0,
			oldTimes: [],
			subjectClassList: [],
			schoolClassList: [],
			times:'',
		}
	},
	activated() {
		this.getCategorys();
		console.log(this.collectTimes)
		if(this.collectTimes) sessionStorage.setItem('times',this.collectTimes);
		
		this.times = sessionStorage.getItem('times');
	},
	mounted() {
		this.rowDrop();
		// 批次存在浏览器缓存
		
	},
	computed: {
		...mapState(["userInfo","collectTimes"]),
	},
	methods: {
		//行拖拽,排序方法
		rowDrop() {
			const el = this.$refs.listTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
			this.sortable = Sortable.create(el, {
				ghostClass: 'sortable-ghost',
				setData: function (dataTransfer) {
					dataTransfer.setData('Text', '')
				},
				onEnd: evt => {
					const targetRow = this.collectsList.splice(evt.oldIndex, 1)[0];
					this.collectsList.splice(evt.newIndex, 0, targetRow);
				}
			});
		},
		save(){
			console.log(this.collectsList)
			subjectcollects.memberSaveList(this.collectsList.map(u => u.id)).then(res => {
				this.$message({
					message: '保存成功',
					type: 'success'
				});
			})
		},
		getCategorys() {
			let listQuery = {
				page: 1,
				limit: 999,
				typeId: 'DIC_SchoolClass'
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.schoolClassList = res.data;
			});
			listQuery.typeId = 'DIC_SubjectClass'
			categorys.getList(listQuery).then((res) => {
				this.subjectClassList = res.data;
			});
			listQuery.typeId = 'diC_OldTimes'
			categorys.getList(listQuery).then((res) => {
				res.data.map(u => {
					if (u.dtValue != 0) {
						this.oldTimes.push(u);
					}
				})
				this.getCollectsList();
			});
		},
		showSystem2(row) {
			let temp = {
				syS_Years: row.syS_Years,
				syS_Times: row.syS_Times == '-' ? '本科' : row.syS_Times,
				province: row.province,
				schoolCode: row.schoolCode,
				subjectGroup: row.subjectGroup
			}
			this.$store.commit('setAnalysisData2', temp)
			this.$store.commit('setSearchStatus', true)
			this.colletsDialogVisible = false;
			this.$router.push('/analysisSystem2')
		},
		processingData() {
			this.exportData = [];
			let listQuery = {
				page: 1,
				limit: 9999,
				syS_Times:this.times
			}
			subjectcollects.memberLoad(listQuery).then(res => {
				//console.log(res);
				res.data.map((o, index) => {
					o.index = (this.listQuery.page - 1) * this.listQuery.limit + (index + 1);
					if (o.diC_OldTimes) {
						let temp = o.diC_OldTimes.split(',');
						for (let i in temp) {
							temp[i] = this.oldTimes.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								temp[i] = temp[i].name
							}
						}
						o.diC_OldTimes = temp.join(',');
					}

					if (o.diC_SchoolClass) {
						let temp = o.diC_SchoolClass.split(',');
						let tempName = [];
						for (let i in temp) {
							temp[i] = this.schoolClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								if (temp[i].name == '211工程大学' || temp[i].name == '985工程大学' || temp[i]
									.name == '一流学科大学' || temp[i].name == '一流大学A' || temp[i].name ==
									'一流大学B' || temp[i].name == '双非大学' || temp[i].name == '民办院校'
								) {

									tempName.push(temp[i].name)
								}
							}
						}
						if (tempName.length == 0) {
							tempName = '-';
						}
						o.diC_SchoolClass = tempName;
					}
					if (o.diC_SubjectClass) {
						let temp = o.diC_SubjectClass.split(',');
						for (let i in temp) {
							temp[i] = this.subjectClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								temp[i] = temp[i].name
							}
						}
						o.diC_SubjectClass = temp.join(',');
					}
					for (let i in o) {
						if (o[i] === '' || o[i] === null || o[i] === undefined) {
							o[i] = '-'
						}
					}
					this.exportData.push(o);
				})
				this.processingDataDone = true;
			})
			//this.exportData = this.collectsList;
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		orderUp(row) {
			if (row.index > 1) {
				subjectcollects.memberOrdersUp({
					id: row.id
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '操作成功',
							type: 'success'
						})
						if (row.index == this.listQuery.page + this.listQuery.limit - 1) {
							if (this.listQuery.page > 1) {
								this.listQuery.page--;
							}
						}
						setTimeout(() => {
							this.getCollectsList();
						}, 50)
					} else {
						this.$message({
							message: res.msg || res.error,
							type: 'error'
						})
					}
				})
			}
		},
		orderDown(row) {
			//console.log(row)
			if (row.index < this.total) {
				subjectcollects.memberOrdersDown({
					id: row.id
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '操作成功',
							type: 'success'
						})
						if (row.index == this.listQuery.page * this.listQuery.limit) {
							this.listQuery.page++;
						}
						setTimeout(() => {
							this.getCollectsList();
						}, 50)

					} else {
						this.$message({
							message: res.msg || res.error,
							type: 'error'
						})
					}
				})
			}
		},
		orderTop(row) {
			subjectcollects.memberSetTop({
				id: row.id
			}).then(res => {
				if (res.code == 200) {
					this.$message({
						message: '操作成功',
						type: 'success'
					})
					this.getCollectsList();
				}
			})
		},
		delList() {
			this.del(this.multipleSelection)
		},
		delRow(row) {
			let list = [];
			list.push(row);
			this.del(list);
		},
		del(rows) {
			subjectcollects.memberDelete(rows.map(u => u.id)).then(res => {
				if (res.code == 200) {
					this.$message({
						message: '删除成功',
						type: 'success'
					})
					this.getCollectsList();
				}
			})
		},

		handleCurrentChange(val) {
			//console.log(val);
			this.listQuery.page = val.page;
			this.listQuery.limit = val.limit;
			this.getCollectsList();
		},
		getCollectsList() {
			this.listloading = true;
			this.collectsList = [];
			this.listQuery.cardNo = this.userInfo.cardNo;
			this.listQuery.syS_Times = this.times;
			console.log(this.listQuery);
			subjectcollects.memberLoad(this.listQuery).then(res => {
				console.log(res);
				this.total = res.count;
				res.data.map((o, index) => {
					o.index = this.total - index;
					if (o.diC_OldTimes) {
						let temp = o.diC_OldTimes.split(',');
						for (let i in temp) {
							temp[i] = this.oldTimes.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								temp[i] = temp[i].name
							}
						}
						o.diC_OldTimes = temp.join(',');
					}

					if (o.diC_SchoolClass) {
						let temp = o.diC_SchoolClass.split(',');
						let tempName = [];
						for (let i in temp) {
							temp[i] = this.schoolClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								if (temp[i].name == '211工程大学' || temp[i].name == '985工程大学' || temp[i]
									.name == '一流学科大学' || temp[i].name == '一流大学A' || temp[i].name ==
									'一流大学B' || temp[i].name == '双非大学' || temp[i].name == '民办院校'
								) {

									tempName.push(temp[i].name)
								}
							}
						}
						if (tempName.length == 0) {
							tempName = '-';
						}
						o.diC_SchoolClass = tempName;
					}
					if (o.diC_SubjectClass) {
						let temp = o.diC_SubjectClass.split(',');
						for (let i in temp) {
							temp[i] = this.subjectClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								temp[i] = temp[i].name
							}
						}
						o.diC_SubjectClass = temp.join(',');
					}
					for (let i in o) {
						if (o[i] === '' || o[i] === null || o[i] === undefined) {
							o[i] = '-'
						}
					}
					this.collectsList.push(o);
				})
				this.listloading = false;
			})
		},
	}
}
</script>

<style lang="scss">
.collect {

	.el-dialog__header {
		padding: 1vw 1vw;
		font-size: 1.2em;
		background: #4d6ffb;
		border-radius: 0.5vw;
	}

	.el-dialog__title {
		line-height: 24px;
		font-size: 1em;
		font-weight: bold;
		color: #ffffff;
	}

	.el-dialog__body {
		padding: 20px;
		color: #606266;
		font-size: 1.2em;
		word-break: break-all;
		text-align: left;
	}

	.el-checkbox__inner {
		border: 1px solid #a9a9a9;
	}
}
</style>

<style scoped lang="scss">
.collect {
	min-height: 85vh;
	background-color: #fff;
	padding-bottom: 50px;

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	span {
		color: red;
	}

	.schoolName {
		cursor: pointer;
	}

	.dialogInput {
		width: 80%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			color: #000;
			width: 100px;
			white-space: nowrap;
		}
	}
}
</style>
import request from '@/utils/request'

export function getTestItems(params) {
  return request({
    url: '/subjectTestItems/Memberload',
    method: 'get',
    params
  })
}

export function subjectNextAdd(data) {
  return request({
    url: '/subjectTestResults/memberAdd',
    method: 'post',
    data
  })
}

export function getTestList(params) {
  return request({
    url: '/subjectTestResults/memberLoad',
    method: 'get',
    params
  })
}

export function getMbtiItems(params) {
  return request({
    url: '/mbtiItemss/Memberload',
    method: 'get',
    params
  })
}

export function mbtiNextAdd(data) {
  return request({
    url: '/mbtiTestResults/memberAdd',
    method: 'post',
    data
  })
}

export function getMbtiList(params) {
  return request({
    url: '/mbtiTestResults/Memberload',
    method: 'get',
    params
  })
}

export function saveReport(data) {
  return request({
    url: '/subjectTests/memberSaveReport',
    method: 'post',
    data
  })
}

export function getMemberInfo(params) {
  return request({
    url: '/SubjectTests/MemberGetByName',
    method: 'get',
    params
  })
}

export function saveMemberInfo(data) {
  return request({
    url: '/SubjectTests/MemberSave',
    method: 'post',
    data
  })
}

export function getTopInfo(params) {
  return request({
    url: '/subjectTestAnswerTypes/MemberLoad',
    method: 'get',
    params
  })
}

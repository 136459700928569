<template>
	<div class="comprehensiveEvaluation">
		<el-page-header class="pageHeader" @back="$router.go(-1)" content="综合评定"></el-page-header>
		<div class="content">
			<div class="title">
				<h4>12种选科组合可报条目数占总条目数比例</h4>
			</div>
			<el-table :data="list" style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
				:loading="listLoading" :header-row-style="headerStyle">
				<el-table-column prop="col1" label="选科组合" min-width="150"></el-table-column>
				<el-table-column prop="col2" label="占比"></el-table-column>
				<el-table-column prop="col3" label="选科组合" min-width="150"></el-table-column>
				<el-table-column prop="col4" label="占比"></el-table-column>
				<el-table-column prop="col5" label="选科组合" min-width="150"></el-table-column>
				<el-table-column prop="col6" label="占比"></el-table-column>
			</el-table>
		</div>
		<div class="content">
			<div class="title">
				<h4><em>{{ userInfo.cardNo }}</em>用户新高考选科综合评定报告</h4>
				<p><em>提示：</em>请自助找出各专业选科组合的交集点，结合“12种选科组合可报条目数占总条目数比例”，以及自己各学科优势情况、学校提供的选科组合，确定自己的选科方案。
				</p>
			</div>
			<h2 style="text-align: center;margin-bottom: 20px;">广西学士通教育高考选科报告</h2>
			<div style="margin: 30px auto;width: 185px;text-align: center;" v-if="reportList.length > 0">
				<el-button type="primary" @click="clearAll">清空所有
				</el-button>
			</div>
			<ul class="reportList">
				<li v-for="(item, index) in reportList" :key="item.id">
					招生专业“<em>{{ item.subjectName }}</em>”
					首选科目<em>{{ item.firstSelect }}</em>，
					再选科目<em>{{ item.ssrShort ? item.ssrShort : '不提科目要求' }}</em>
					的学校共<em>{{ item.recordCount }}</em>所
					<el-link type="primary" :underline="false" @click="viewDetails(item, index)">查看明细</el-link>
					<el-link type="primary" :underline="false" @click="delDetails(item, index)"
						style="margin-left: 15px;">删除</el-link>
				</li>
				<pagination v-show="reportTotal > 0" :total="reportTotal" :page.sync="listQuery.page"
					:limit.sync="listQuery.limit" @pagination="handleCurrentChange" :autoScroll="false" />
			</ul>
			<div class="contentBox">
				<h3 style="text-align: center;margin-bottom: 20px;">6门学科强弱</h3>
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item class="mz">
						<span class="demonstration">物理：</span>
						<el-select v-model="form.scorePhysics" placeholder="" style="width: 50%;" clearable>
							<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mz">
						<span class="demonstration">历史：</span>
						<el-select v-model="form.scoreHistory" placeholder="" style="width: 50%;" clearable>
							<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mz">
						<span class="demonstration">化学：</span>
						<el-select v-model="form.scoreChemistry" placeholder="" style="width: 50%;" clearable>
							<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mz">
						<span class="demonstration">地理：</span>
						<el-select v-model="form.scoreGeography" placeholder="" style="width: 50%;" clearable>
							<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mz">
						<span class="demonstration ">生物：</span>
						<el-select v-model="form.scoreBiology" placeholder="" style="width: 50%;" clearable>
							<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mz">
						<span class="demonstration">政治：</span>
						<el-select v-model="form.scorePolitics" placeholder="" style="width: 50%;" clearable>
							<el-option v-for="item in grade" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div class="contentBox">
				<h3 style="text-align: center;margin-bottom: 20px;">所在中学提供的选科组合（请勾选不用走班的组合）</h3>
				<div class="check">
					<el-checkbox-group v-model="checkList">
						<el-checkbox v-for="item in checkBoxList" :key="item.value" :label="item.value">
							{{ item.label }}
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			<div class="contentBox">
				<h3 style="text-align: center;margin-bottom: 20px;">综合评定选科组合</h3>
				<div class="inputWrap">
					<div class="input">
						<span class="demonstration">首选科目：</span>
						<el-select v-model="form.firstSelect" placeholder="" clearable>
							<el-option v-for="item in firstSelect" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="input">
						<span class="demonstration">再选科目1：</span>
						<el-select v-model="form.secondSelect" placeholder="" clearable>
							<el-option v-for="item in secondSelect" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="input">
						<span class="demonstration">再选科目2：</span>
						<el-select v-model="form.thirdSelect" placeholder="" clearable>
							<el-option v-for="item in thirdSelect" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div style="margin: 30px auto;width: 185px;text-align: center;">
				<el-button type="primary" class="qx" @click="handleExport">导出
				</el-button>
			</div>
		</div>
		<!-- <div class="content">
			<div class="title">
				<h4>综合报告选科方案</h4>
			</div>
			<el-descriptions border class="descriptions" :title="'方案'+item.orders" v-for="item in majorList" :key="item.id">
				<el-descriptions-item label="首选科目">{{item.fisrtSelect}}</el-descriptions-item>
				<el-descriptions-item label="再选科目">{{item.secondSelect1}}</el-descriptions-item>
				<el-descriptions-item label="再选科目2">{{item.secondSelect2}}</el-descriptions-item>
			</el-descriptions>
			<el-table :data="majorList" style="width: calc(100% - 40px);margin: 0 20px;" border id="exportTable"
				@cell-mouse-enter="handleCellEnter" @cell-mouse-leave="handleCellLeave">
				<el-table-column label="选科方案">
					<template slot-scope="scope">
						<span>方案{{scope.row.orders}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="fisrtSelect" label="首选科目">
					<div class="item" slot-scope="scope">
						<el-input class="item__input" v-model="scope.row.fisrtSelect" placeholder="请输入内容" size='mini'></el-input>
						<div class="item__txt">{{scope.row.fisrtSelect}}</div>
					</div>
				</el-table-column>
				<el-table-column prop="secondSelect1" label="再选科目1">
					<div class="item" slot-scope="scope">
						<el-input class="item__input" v-model="scope.row.secondSelect1" placeholder="请输入内容" size='mini'></el-input>
						<div class="item__txt">{{scope.row.secondSelect1}}</div>
					</div>
				</el-table-column>
				<el-table-column prop="secondSelect2" label="再选科目2">
					<div class="item" slot-scope="scope">
						<el-input class="item__input" v-model="scope.row.secondSelect2" placeholder="请输入内容" size='mini'></el-input>
						<div class="item__txt">{{scope.row.secondSelect2}}</div>
					</div>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top: 20px;">
				<el-button type="primary" style="width: 8%;font-size: 16px;font-weight: bold;"
					@click="exportExcel('exportTable')">导出excel</el-button>
			</div>
		</div> -->
	</div>
</template>

<script>
import { exportWord } from "@/utils/exportWord";
import * as subjectSystemApi from '@/api/subjectSystemApi'
import * as subjectselectRecommends from '@/api/subjectselectrecommends'
import * as searchhistorys from '@/api/subjectselectsearchhistorys'
import * as numpercents from '@/api/subjectselectnumpercents'
import Pagination from '@/components/Pagination'
import excelExportUtil from '@/components/exportExcel'

import {
	mapState,
} from "vuex"
export default {
	components: {
		Pagination,
	},
	data() {
		return {
			exportListQuery: {
				page: 1,
				limit: 9999,
				ssrShort:'',
			},
			exportReportList: [],
			firstSelect: [
				{
					value: '物理',
					label: '物理'
				},
				{
					value: '历史',
					label: '历史'
				},
			],
			secondSelect: [
				{
					value: '化学',
					label: '化学'
				},
				{
					value: '生物',
					label: '生物'
				},
				{
					value: '地理',
					label: '地理'
				},
				{
					value: '思想政治',
					label: '思想政治'
				},
			],
			thirdSelect: [
				{
					value: '化学',
					label: '化学'
				},
				{
					value: '生物',
					label: '生物'
				},
				{
					value: '地理',
					label: '地理'
				},
				{
					value: '思想政治',
					label: '思想政治'
				},
			],
			checkList: [],
			checkBoxList: [
				{
					value: '物理+化学+思想政治',
					label: '物理+化学+思想政治'
				},
				{
					value: '物理+化学+地理',
					label: '物理+化学+地理'
				},
				{
					value: '物理+化学+生物',
					label: '物理+化学+生物'
				},
				{
					value: '物理+生物+思想政治',
					label: '物理+生物+思想政治'
				},
				{
					value: '物理+生物+地理',
					label: '物理+生物+地理'
				},
				{
					value: '物理+地理+思想政治',
					label: '物理+地理+思想政治'
				},
				{
					value: '历史+思想政治+地理',
					label: '历史+思想政治+地理'
				},
				{
					value: '历史+生物+思想政治',
					label: '历史+生物+思想政治'
				},
				{
					value: '历史+化学+思想政治',
					label: '历史+化学+思想政治'
				},
				{
					value: '历史+生物+地理',
					label: '历史+生物+地理'
				},
				{
					value: '历史+化学+地理',
					label: '历史+化学+地理'
				},
				{
					value: '历史+化学+生物',
					label: '历史+化学+生物'
				},
			],
			form: {
				scorePhysics: '', //物理
				scoreHistory: '', //历史
				scoreChemistry: '', //化学
				scoreBiology: '', //生物
				scoreGeography: '', //地理
				scorePolitics: '', //思想政治
			},
			editProp: ['fisrtSelect', 'secondSelect1', 'secondSelect2'],
			subjectId: '',
			majorList: [],
			headerStyle: {
				fontWeight: 'bold',
				color: '#303030',
			},
			windowWidth: document.documentElement.clientWidth,
			reportList: [],
			reportTotal: 0,
			list: [],
			listQuery: {
				limit: 20,
				page: 1,
				ssrShort:'',
			},
			listLoading: false,
			grade: [{
				value: '强',
				label: '强'
			}, {
				value: '中上',
				label: '中上'
			}, {
				value: '中等',
				label: '中等'
			}, {
				value: '弱但不排斥',
				label: '弱但不排斥'
			}, {
				value: '弱且排斥',
				label: '弱且排斥'
			},],
		}
	},
	computed: {
		...mapState(["userInfo"]),
	},
	created() {
		this.getReportList();
		this.getList()
		//this.getMajorList()
	},
	methods: {
		/** 鼠标移入cell */
		handleCellEnter(row, column, cell) {
			const property = column.property
			if (this.editProp.includes(property)) {
				cell.querySelector('.item__input').style.display = 'block'
				cell.querySelector('.item__txt').style.display = 'none'
			}
		},
		/** 鼠标移出cell */
		handleCellLeave(row, column, cell) {
			const property = column.property
			if (this.editProp.includes(property)) {
				cell.querySelector('.item__input').style.display = 'none'
				cell.querySelector('.item__txt').style.display = 'block'

			}
		},
		clearAll() {
			this.$confirm('确认清空吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				//this.listQuery.subjectTestId = this.subjectData.id;
				this.listQuery.cardNo = this.userInfo.cardNo;
				searchhistorys.memberClear(this.listQuery).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '清空成功',
							type: 'success'
						});
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
					this.getReportList();
				})
			})

		},
		handleChange() {
			if (this.form.firstSelect) {
				this.exportListQuery.firstSelect = this.form.firstSelect
				this.listQuery.firstSelect = this.form.firstSelect
			} else {
				this.exportListQuery.firstSelect = '';
				this.listQuery.firstSelect = '';
			}
			if (this.form.secondSelect || this.form.thirdSelect) {
				let temp = [];
				if (this.form.secondSelect) {
					temp.push(this.form.secondSelect)
				}
				if (this.form.thirdSelect) {
					temp.push(this.form.thirdSelect)
				}
				temp = temp.join(',')
				this.exportListQuery.ssrShort = temp;
				this.listQuery.ssrShort = temp;
			} else {
				this.exportListQuery.ssrShort = '';
				this.listQuery.ssrShort = '';
			}
			this.getReportList()
		},
		// 导出word
		handleExport() {
			let data = {
				list: this.list,
				reportList: this.exportReportList,
				scorePhysics: this.form.scorePhysics,
				scoreHistory: this.form.scoreHistory,
				scoreChemistry: this.form.scoreChemistry,
				scoreBiology: this.form.scoreBiology,
				scoreGeography: this.form.scoreGeography,
				scorePolitics: this.form.scorePolitics,
				checkList: this.checkList,
				firstSelect: this.form.firstSelect || '',
				secondSelect: this.form.secondSelect || '',
				thirdSelect: this.form.thirdSelect || '',
			}
			exportWord("template.docx", data, "广西学士通教育高考选科报告.docx")
		},
		exportExcel(tableId) {
			//let titles = ['选科方案', '首选科目', '再选科目1', '再选科目2'];
			excelExportUtil.exportExcel(tableId, '', "选科方案", ".xls", true);
		},
		delDetails(row, index) {
			this.$confirm('确认删除招生专业“' + row.subjectName + '” 首选科目' + row.firstSelect + '， 再选科目' + (row.ssrShort ? row
				.ssrShort : '不提科目要求') + '的学校吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.reportList.splice(index, 1)
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			})
		},
		// getMajorList() {
		// 	this.majorList = [];
		// 	//获取subjectid
		// 	subjectSystemApi.getMemberInfo(
		// 		this.subjectData
		// 	).then(res => {
		// 		console.log(res);
		// 		this.subjectId = res.result.id;
		// 		//获取选科结果
		// 		subjectselectRecommends.getList({
		// 			subjectId: this.subjectId
		// 		}).then(res => {
		// 			//console.log(this.userInfo);
		// 			console.log(res);
		// 			this.majorList = res.data;
		// 			this.majorList.sort((a, b) => {
		// 				return a.orders - b.orders
		// 			});
		// 			//console.log(this.majorList);
		// 		})
		// 	})
		// },
		viewDetails(row, index) {
			this.$store.commit('setSchoolSearchInfo', {
				type: 2,
				subjectInfo: row,
				subjectIndex: index,
			});
			setTimeout(() => {
				this.$router.push('/schoolSearch');
			}, 100)
		},
		handleCurrentChange(val) {
			this.listQuery.page = val.page
			this.listQuery.limit = val.limit
			this.getReportList()
		},
		getWindowWidth() {
			let that = this;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.documentElement.clientWidth;
				})()

			};
		},
		getReportList() {
			this.reportList = [];
			//this.listQuery.subjectTestId = this.subjectData.id;
			this.listQuery.cardNo = this.userInfo.cardNo;
			searchhistorys.getList(this.listQuery).then(res => {
				console.log(res);
				this.reportTotal = res.count;
				this.reportList = res.data;
			})

			this.exportReportList = [];
			//this.exportListQuery.subjectTestId = this.subjectData.id;
			this.exportListQuery.cardNo = this.userInfo.cardNo;
			searchhistorys.getList(this.exportListQuery).then(res => {
				//console.log(res);
				this.exportReportList = res.data;
			})
		},
		getList() {
			this.list = [];
			this.listLoading = true;
			numpercents.getList(this.listQuery).then(res => {
				//console.log(res);
				//处理比例表格
				let col1 = [],
					col2 = [],
					col3 = [],
					col4 = [],
					col5 = [],
					col6 = [];
				res.data.map((item, index) => {
					if (index < 4) {
						col1.push(item.groupName);
						col2.push(item.numPercent + '%');
					} else if (index > 3 && index < 8) {
						col3.push(item.groupName);
						col4.push(item.numPercent + '%');
					} else if (index > 7) {
						col5.push(item.groupName);
						col6.push(item.numPercent + '%');
					}
				})
				for (let i in col1) {
					this.list.push({
						col1: col1[i],
						col2: col2[i],
						col3: col3[i],
						col4: col4[i],
						col5: col5[i],
						col6: col6[i],
					})
				}
				this.listLoading = false;
			}).catch(() => {
				this.listLoading = false;
			})
		},
	}
}
</script>
<style lang="scss">
.comprehensiveEvaluation {
	.reportList {
		li {
			.el-link {
				font-size: 16px;
				vertical-align: 0;
			}
		}
	}

	.el-checkbox__inner {
		border: 1px solid #000000 !important;
	}

	.el-input__inner {
		border-radius: 5px;
		border: 1px solid #000;
		font-weight: bold;
		color: #000;
	}

	//表格边框样式
	.el-table--border,
	.el-table--group {
		border: 1px solid #969696 !important;
	}

	.el-table td.el-table__cell,
	.el-table th.el-table__cell.is-leaf {
		border-bottom: 1px solid #969696 !important;
	}

	.el-table--border .el-table__cell,
	.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
		border-right: 1px solid #969696 !important;
	}

	.el-table--border::after,
	.el-table--group::after,
	.el-table::before {
		background-color: #c8c8c8 !important;
	}

	.el-table__expand-icon {
		display: none !important;
	}

	.el-checkbox__label {
		font-size: 18px;
	}
}
</style>


<style scoped lang="scss">
.comprehensiveEvaluation {
	min-height: 85vh;

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.content {
		margin: 50px auto;
		padding-bottom: 20px;
		width: 80%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		text-align: left;

		.contentBox {
			margin-top: 25px;

			.el-form {
				padding: 15px 50px;
				margin-bottom: 35px;
				background: #fff;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				.demonstration {
					font-size: 20px;
				}
			}

			.inputWrap {
				margin: 0 auto;
				padding: 0 20px;
				display: flex;
				flex-wrap: wrap;

				.input {
					margin: 0 10px;
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					white-space: nowrap;

					.demonstration {
						font-weight: bold;
					}
				}

			}
		}

		.el-checkbox {
			width: 28%;
			height: 55px;
			text-align: left;
			color: #000000;
		}


		.check {
			height: auto;
			padding: 20px;

			.el-checkbox-group {
				text-align: left;
				padding: 20px;
				margin: 0 auto;
				width: 80%;
			}

		}



		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;
			text-align: center;
			color: #fff;
			font-size: 1vw;
			;
			padding: 10px;

			p {
				font-size: 0.9vw;
				margin-top: 10px;
			}
		}

		.item__input {
			display: none;
		}

		.inputBox {
			.select {
				display: inline-block;
				margin: 0 20px;
			}
		}

		.content-btn {
			text-align: center;

			.el-button {
				margin: 2vw;
			}
		}

		.reportList {
			padding: 0 20px;

			li {
				margin: 5px 0;
				font-size: 16px;
			}

			.pagination-container {
				text-align: center;
				margin-top: 20px;
			}
		}

		em {
			color: red;
			font-style: normal;
		}

		.descriptions {
			padding: 20px;
		}
	}

}
</style>
import request from '@/utils/request'

export function memberAdd(data) {
  return request({
    url: 'SubjectCollects/MemberAdd',
    method: 'post',
    data
  })
}

export function memberLoad(params) {
  return request({
    url: 'SubjectCollects/MemberLoad',
    method: 'get',
    params
  })
}

export function memberDelete(data) {
  return request({
    url: 'SubjectCollects/MemberDelete',
    method: 'post',
    data
  })
}

export function memberSetTop(params) {
  return request({
    url: 'SubjectCollects/MemberSetTop',
    method: 'get',
    params
  })
}

export function memberOrdersUp(params) {
  return request({
    url: 'SubjectCollects/MemberOrdersUp',
    method: 'get',
    params
  })
}

export function memberOrdersDown(params) {
  return request({
    url: 'SubjectCollects/MemberOrdersDown',
    method: 'get',
    params
  })
}

export function loadIntroduces(params) {
  return request({
    url: 'SubjectIntroduces/load',
    method: 'get',
    params
  })
}

export function loadSubjectRankings(params) {
  return request({
    url: 'SchoolSubjectRankings/load',
    method: 'get',
    params
  })
}

export function memberSaveList(data) {
  return request({
    url: 'SubjectCollects/MemberSaveList',
    method: 'post',
    data
  })
}

<template>
	<div id="app">
		<Frame v-if="$route.meta.frameShow"></Frame>
		<app-header v-show="$route.meta.header_show"></app-header>
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>

		<router-view v-if="!$route.meta.keepAlive"></router-view>
		<app-footer v-show="$route.meta.footer_show"></app-footer>
	</div>
</template>

<script>
	import appHeader from './components/header.vue'
	import appFooter from './components/footer.vue'
	import Frame from './components/frame.vue';
	import {
		mapActions,
		mapState,
	} from "vuex"
	export default {
		data() {
			return {
				//header_show: true,
				//footer_show: true,
			}
		},
		components: {
			Frame,
			appHeader,
			appFooter,
		},
		created() {
			this.ver();
		},
		computed: {
			...mapState(["isLogin", ]),
		},
		methods: {
			...mapActions(["verification", ]),
			ver() {
				if(this.$route.meta.notVer){
					return;
				}
				this.verification();
				//console.log(this.verification());
				setTimeout(() => {
					console.log('登录状态', this.isLogin, this.$route.path);
					if (this.$route.path !== '/login' && this.$route.path !== '/zhuce') {
						if (!this.isLogin) {
							this.$message.error('未登录或登录过期，请重新登录！');
							this.$router.push('/login');
						}
					}
				}, 1000)
			},
			//是否显示头部
			//header: function (bool) {
			//this.header_show = bool;
			//},
			//是否显示底部
			//footer: function (bool) {
			//this.footer_show = bool;
			//}

		}
	}
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		background-color: #f5f5f5;
		width: 100%;
		min-height: 900px;
	}

	input::-webkit-input-placeholder {
		/*webkit内核浏览器*/
		color: #000;
	}

	* {
		margin: 0;
		padding: 0;
	}
</style>
<template>
  <div id="analysisSystem1" style="background: #f5f5f5">
    <!-- <div class="top">
				<h3>新高考选科查询</h3>
				<button @click="back">
					<i>&lt;&nbsp;</i>返回
				</button>
			</div> -->
    <el-page-header
      class="pageHeader"
      @back="dialogVisible ? (dialogVisible = false) : $router.go(-1)"
    >
    </el-page-header>
    <componentsSystem
      :gategoryList="gategoryList"
      :subjectClassList="subjectClassList"
      :times="'本科'"
    ></componentsSystem>

    <div class="warpper" v-if="!dialogVisible">
      <p style="margin-top: 50px; text-align: center">
        查询方法说明：<span style="color: red"
          >可单选一种或同选多种方法查询，系统会给出相应的分析报告</span
        >
      </p>
      <div class="content">
        <div class="title">
          <img src="../../assets/title-line.jpg" />
          <h4>按省市查询</h4>
        </div>

        <el-radio-group v-model="listQuery.province" size="medium">
          <el-radio-button
            v-for="item in provincearr"
            :key="item.code"
            :label="item.code"
            >{{ item.address }}</el-radio-button
          >
          <el-radio-button label="" style="display: block"
            >取消此查询操作</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="content">
        <div class="title">
          <img src="../../assets/title-line.jpg" />
          <h4>按老高考招生批次查询</h4>
        </div>

        <el-radio-group v-model="listQuery.diC_OldTimes" size="medium">
          <el-radio-button
            v-for="item in oldTimes"
            :key="item.id"
            :label="item.dtValue"
            >{{ item.name }}</el-radio-button
          >
          <el-radio-button label="" style="display: block"
            >取消此查询操作</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="content">
        <div class="title">
          <img src="../../assets/title-line.jpg" />
          <h4>按类别查询</h4>
        </div>

        <el-radio-group v-model="listQuery.diC_SchoolClass" size="medium">
          <el-radio-button
            v-for="item in schoolClassList"
            :key="item.id"
            :label="item.dtValue"
            >{{ item.name }}</el-radio-button
          >
          <el-radio-button label="" style="display: block"
            >取消此查询操作</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="content">
        <div class="title">
          <img src="../../assets/title-line.jpg" />
          <h4>按专业组投档概率查询</h4>
        </div>
        <el-radio-group
          v-model="listQuery.subject"
          size="medium"
          @change="selectRadio"
        >
          <el-radio-button label="0-70">70%以下</el-radio-button>
          <el-radio-button label="75-85">75%-85%</el-radio-button>
          <el-radio-button label="90-95">90%-95%</el-radio-button>
          <el-radio-button label="40">40%</el-radio-button>
          <el-radio-button label="45">45%</el-radio-button>
          <el-radio-button label="50">50%</el-radio-button>
          <el-radio-button label="55">55%</el-radio-button>
          <el-radio-button label="60">60%</el-radio-button>
          <el-radio-button label="62">62%</el-radio-button>
          <el-radio-button label="65">65%</el-radio-button>
          <el-radio-button label="70">70%</el-radio-button>
          <el-radio-button label="75">75%</el-radio-button>
          <el-radio-button label="80">80%</el-radio-button>
          <el-radio-button label="85">85%</el-radio-button>
          <el-radio-button label="90">90%</el-radio-button>
          <el-radio-button label="95">95%</el-radio-button>
          <el-radio-button label="" style="display: block"
            >取消此查询操作</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="content">
        <div class="content-btn">
          <el-button
            type="primary"
            @click="search"
            :loading="listLoading"
            :disabled="!analysisData.syS_Years || !analysisData.syS_Times"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="report" v-else>
      <div class="title">
        <img src="../../assets/title-line.jpg" />
        <h4>分析报告</h4>
      </div>
      <div class="reportText">
        <div class="textTitle">
          您是<span>{{ analysisData.syS_Years }}</span
          >年<span>{{ userInfo.syS_CourseGroup }}</span
          >科目考生，{{ getGategoryName(userInfo.syS_Nation, "SYS_Nation") }}，
          区外高校专业组投档总分<span>{{
            Number(userInfo.score) + Number(userInfo.scoreAdd)
          }}</span>
          ，区内高校专业组投档总分<span
            >{{ Number(userInfo.score) + Number(userInfo.scoreAddGX) }}
          </span>
          ，现将您的数据与<span
            >{{ analysisData.schoolName
            }}{{ analysisData.syS_Times }}普通批</span
          >您选定的所有院校所有可报专业组前3年录取数据进行对比分析，分别给出投档概率，供您参考。
          <p style="text-align: center; margin: 10px 0">
            <span>
              提示：如学校数据不足3年，系统给出的投档概率会虚高，请自助分析或向经验丰富的老师咨询。
            </span>
          </p>
        </div>
      </div>
      <el-table
        :data="list"
        style="
          width: calc(100% - 40px);
          margin: 10px 20px;
          font-weight: bold;
          color: #303030;
        "
        border
        v-loading="listLoading"
        :header-cell-style="headerStyle"
        :span-method="
          (param) => objectSpanMethod(param, [0, 1, 2, 3, 4, 12, 13, 14, 15])
        "
        :cell-style="cellStyle"
      >
        <el-table-column
          prop="provinceCity"
          label="省市"
          show-overflow-tooltip
          min-width="70px"
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          label="院校名称"
          show-overflow-tooltip
          min-width="110px"
        ></el-table-column>
        <el-table-column
          prop="subjectGroup"
          label="专业组代码"
          show-overflow-tooltip
          min-width="65px"
        >
          <template slot="header">
            <p>专业组</p>
            <p>代码</p>
          </template>
          <template slot-scope="scope">
            <span style="color: red">{{ scope.row.subjectGroup }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ssrShort"
          label="再选科目"
          show-overflow-tooltip
          min-width="60px"
        >
          <template slot="header">
            <p>再选</p>
            <p>科目</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="plans"
          label="2024计划数"
          show-overflow-tooltip
          min-width="65px"
        >
          <template slot="header">
            <p>{{ analysisData.syS_Years }}</p>
            <p>计划数</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="syS_Years"
          label="年份"
          show-overflow-tooltip
          min-width="60px"
        ></el-table-column>
        <el-table-column
          prop="scoreline"
          label="分数线"
          show-overflow-tooltip
          min-width="70px"
        ></el-table-column>
        <el-table-column
          prop="minScore"
          label="录取最低分"
          show-overflow-tooltip
          min-width="70px"
        >
          <template slot="header">
            <p>录取</p>
            <p>最低分</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="minPlaceDif"
          label="最低分名次/线差"
          show-overflow-tooltip
          min-width="85px"
        >
          <template slot="header">
            <p>最低分</p>
            <p>名次/线差</p>
          </template>
          <template slot-scope="scope">
            <span style="color: red">{{ scope.row.minPlaceDif }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="-"
          label="您的名次/线差"
          show-overflow-tooltip
          min-width="85px"
        >
          <template slot="header">
            <p>您的</p>
            <p>名次/线差</p>
          </template>
          <template slot-scope="scope">
            <span style="color: red">{{
              scope.row.myPlaceDif == 0 ? "-" : scope.row.myPlaceDif
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="avePlaceDif"
          label="平均分名次/线差"
          show-overflow-tooltip
          min-width="85px"
        >
          <template slot="header">
            <p>平均分</p>
            <p>名次/线差</p>
          </template>
          <template slot-scope="scope">
            <span style="color: red">{{ scope.row.avePlaceDif }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="aveScore" label="录取平均分">
          <template slot="header">
            <p>录取</p>
            <p>平均分</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="chance"
          label="投档概率"
          show-overflow-tooltip
          min-width="60px"
        >
          <template slot="header">
            <p>投档</p>
            <p>概率</p>
          </template>
          <template slot-scope="scope">
            <span style="color: red">{{
              scope.row.chance == "30%" ? "建议等待征集" : scope.row.chance
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="diC_OldTimes"
          label="老高考招生批次"
          show-overflow-tooltip
          min-width="95px"
        >
          <template slot="header">
            <p>老高考</p>
            <p>招生批次</p>
          </template>
          <!-- <template slot-scope="scope">
						<p>{{scope.row.diC_OldTimes?scope.row.diC_OldTimes:'-'}}</p>
					</template> -->
        </el-table-column>
        <el-table-column
          prop="diC_SchoolClass"
          label="院校实力评价"
          show-overflow-tooltip
          min-width="100px"
        >
          <template slot="header">
            <p>院校</p>
            <p>实力评价</p>
          </template>
          <template slot-scope="scope">
            <p style="color: red; font-weight: bold">
              {{ scope.row.schoolRanking }}
            </p>
            <p v-for="item in scope.row.diC_SchoolClass" :key="item">
              {{ item }}
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="查看专业录取概率">
					<template slot="header">
						<p>专业</p>
						<p>录取</p>
					</template>
					<template slot-scope="scope">
						<el-link type="primary" @click="showSystem2(scope.row)">查看</el-link>
					</template>
				</el-table-column> -->
      </el-table>
      <div style="text-align: center; margin: 0 auto">
        <pagination
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import componentsSystem from "@/components/componentsSystem";
//import * as schoolsubjects from '@/api/schoolsubjects'
import Pagination from "@/components/Pagination";
import * as schoolGroups from "@/api/schoolsubjectgroups";
import { mapState } from "vuex";
import * as categorys from "@/api/categorys";
import * as cityData from "@/components/cityData";
//import * as MergeCol from '@/components/mergeCol'
//import AuthSelect from '@/components/AuthSelect.vue'
export default {
  components: {
    //AuthSelect,
    Pagination,
    componentsSystem,
  },
  data() {
    return {
      oldTimes: [],
      subjectClassList: [],
      noPlanDialogVisible: false,
      detailsDialogVisible: false,
      subjectName: "",
      noPlanList: [],
      detailList: [],
      searchState: false,
      analysisData: {},
      subjectDialogVisible: false,
      total: 0,
      cellStyle: {
        "text-align": "center",
      },
      listLoading: false,
      headerStyle: {
        fontWeight: "bold",
        color: "#303030",
        "text-align": "center",
      },
      list: [],
      list2: [],
      dialogVisible: false,
      provincearr: [],
      // subjectList: [
      // 	{
      // 		value:'70%以下',
      // 		value:'70%以下',
      // 	},

      // ],
      schoolClassList: [],
      gategoryList: [],
      listQuery: {
        page: 1,
        limit: 3,
      },
    };
  },
  computed: {
    ...mapState(["userInfo", "systemData1"]),
  },
  beforeDestroy() {
    sessionStorage.setItem("analysisData1", JSON.stringify(this.analysisData));
  },
  activated() {
    console.log("analysisData1", this.systemData1);
    this.analysisData = this.systemData1;
    if (Object.keys(this.analysisData) == 0) {
      this.analysisData = JSON.parse(sessionStorage.getItem("analysisData1"));
      this.analysisData.dialogVisible = false;
    } else {
      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem(
          "analysisData1",
          JSON.stringify(this.analysisData),
        );
      });
    }
    this.dialogVisible = this.analysisData.dialogVisible ? true : false;
    console.log(this.analysisData);
    this.provincearr = cityData.getAreaList();
    this.getGategorys();
    this.getSchoolClassList();
  },
  methods: {
    //合并单元格
    objectSpanMethod({ rowIndex, columnIndex }, array) {
      for (let i = 0; i < array.length; i++) {
        if (columnIndex === array[i]) {
          if (rowIndex % 3 === 0) {
            return {
              rowspan: 3,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }

      //this.listLoading = false;
    },
    showSystem2(row) {
      let temp = {
        syS_Years: this.analysisData.syS_Years,
        syS_Times: row.syS_Times == "-" ? "本科" : row.syS_Times,
        province: row.province,
        schoolCode: row.schoolCode,
        subjectGroup: row.subjectGroup,
      };
      this.$store.commit("setAnalysisData2", temp);
      this.$store.commit("setSearchStatus", true);
      this.$router.push("/analysisSystem2");
    },

    setInfo() {
      this.analysisData.nation = this.getGategoryName(
        this.userInfo.syS_Nation,
        "SYS_Nation",
      );
      this.analysisData.syS_CourseGroup = this.userInfo.syS_CourseGroup;
    },
    handleCurrentChange(val) {
      //console.log(val);
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    selectRadio(v) {
      console.log(v);
      if (v.indexOf("-") !== -1) {
        let t = v.split("-");
        this.listQuery.minChance = t[0];
        this.listQuery.maxChance = t[1];
      } else {
        this.listQuery.minChance = v;
        this.listQuery.maxChance = v;
      }
    },
    search() {
      this.listQuery.page = 1;
      this.getList();
      this.dialogVisible = true;
      this.analysisData.dialogVisible = true;
    },
    getList() {
      this.listLoading = true;
      this.list = [];
      //console.log(this.analysisData);
      let listQuery = {
        syS_Years: this.analysisData.syS_Years,
        syS_Times: this.analysisData.syS_Times,
        province: this.listQuery.province ? this.listQuery.province : "",
        diC_OldTimes: this.listQuery.diC_OldTimes
          ? this.listQuery.diC_OldTimes
          : "",
        diC_SchoolClass: this.listQuery.diC_SchoolClass
          ? this.listQuery.diC_SchoolClass
          : "",
        minChance: this.listQuery.minChance ? this.listQuery.minChance : "0",
        maxChance: this.listQuery.maxChance ? this.listQuery.maxChance : "100",
        page: this.listQuery.page,
        limit: this.listQuery.limit,
      };
      //console.log(listQuery);
      schoolGroups.getChance(listQuery).then((res) => {
        console.log(res);
        res.data.map((o) => {
          if (o.diC_OldTimes) {
            let temp = o.diC_OldTimes.split(",");
            let tempName = [];
            for (let i in temp) {
              temp[i] = this.oldTimes.find((u) => {
                return u.dtValue == temp[i];
              });
              if (temp[i]) {
                tempName.push(temp[i].name);
              }
            }
            if (tempName.length == 0) {
              tempName = "-";
            }
            o.diC_OldTimes = tempName;
          }
          if (o.diC_SchoolClass) {
            let temp = o.diC_SchoolClass.split(",");
            let tempName = [];
            for (let i in temp) {
              temp[i] = this.schoolClassList.find((u) => {
                return u.dtValue == temp[i];
              });
              if (temp[i]) {
                if (
                  temp[i].name == "211工程大学" ||
                  temp[i].name == "985工程大学" ||
                  temp[i].name == "一流学科大学" ||
                  temp[i].name == "一流大学A" ||
                  temp[i].name == "一流大学B" ||
                  temp[i].name == "双非大学" ||
                  temp[i].name == "民办院校"
                ) {
                  tempName.push(temp[i].name);
                }
              }
            }
            if (tempName.length == 0) {
              tempName = "-";
            }
            o.diC_SchoolClass = tempName;
          }
          for (let i in o) {
            //console.log(o[i],i);
            if (
              o[i] === "" ||
              o[i] === null ||
              o[i] === undefined ||
              o[i] === 0 ||
              o[i] == "9999999"
            ) {
              o[i] = "-";
            }
          }
          // o.diC_SchoolClass.map(c => {
          // 	return this.schoolClassList.find(u => {return u.dtValue==c});
          // })
          this.list.push({
            syS_Years: this.analysisData.syS_Years - 1,
            provinceCity: o.provinceCity,
            schoolCode: o.schoolCode,
            schoolName: o.schoolName,
            subjectGroup: o.subjectGroup,
            syS_Times: o.syS_Times,
            scoreline: o.scoreline1,
            minPlaceDif: o.minPlaceDif1,
            avePlaceDif: o.avePlaceDif1,
            diC_OldTimes: o.diC_OldTimes,
            diC_SchoolClass: o.diC_SchoolClass,
            chance: o.chance + "%",
            myScore: this.userInfo.scores1,
            plans: o.plans,
            minScore: o.minscore1,
            aveScore: o.avescore1,
            ssrShort: o.ssrShort,
            myPlaceDif: o.myPlaceDif,
            schoolRanking: o.schoolRanking,
          });

          this.list.push({
            syS_Years: this.analysisData.syS_Years - 2,
            provinceCity: o.provinceCity,
            schoolCode: o.schoolCode,
            schoolName: o.schoolName,
            subjectGroup: o.subjectGroup,
            syS_Times: o.syS_Times,
            scoreline: o.scoreline2,
            minPlaceDif: o.minPlaceDif2,
            avePlaceDif: o.avePlaceDif2,
            //MyScore:o.MyScore1,
            diC_OldTimes: o.diC_OldTimes,
            diC_SchoolClass: o.diC_SchoolClass,
            chance: o.chance + "%",
            myScore: this.userInfo.scores2,
            plans: o.plans,
            minScore: o.minscore2,
            aveScore: o.avescore2,
            ssrShort: o.ssrShort,
            myPlaceDif: o.myPlaceDif,
            schoolRanking: o.schoolRanking,
          });
          this.list.push({
            syS_Years: this.analysisData.syS_Years - 3,
            provinceCity: o.provinceCity,
            schoolCode: o.schoolCode,
            schoolName: o.schoolName,
            subjectGroup: o.subjectGroup,
            syS_Times: o.syS_Times,
            scoreline: o.scoreline3,
            minPlaceDif: o.minPlaceDif3,
            avePlaceDif: o.avePlaceDif3,
            //MyScore:o.MyScore1,
            diC_OldTimes: o.diC_OldTimes,
            diC_SchoolClass: o.diC_SchoolClass,
            chance: o.chance + "%",
            myScore: this.userInfo.scores3,
            plans: o.plans,
            minScore: o.minscore3,
            aveScore: o.avescore3,
            ssrShort: o.ssrShort,
            myPlaceDif: o.myPlaceDif,
            schoolRanking: o.schoolRanking,
          });
        });

        console.log(this.list);
        this.listLoading = false;
        this.total = res.count;
      });
    },
    getSchoolClassList() {
      let listQuery = {
        page: 1,
        limit: 999,
        typeId: "DIC_SchoolClass",
      };
      //console.log('getcategorys')
      categorys.getList(listQuery).then((res) => {
        this.schoolClassList = res.data;
        //console.log(this.schoolClassList);
      });
      listQuery.typeId = "DIC_SubjectClass";
      categorys.getList(listQuery).then((res) => {
        this.subjectClassList = res.data;
        //console.log(this.schoolClassList);
      });
      listQuery.typeId = "diC_OldTimes";
      this.oldTimes = [];
      categorys.getList(listQuery).then((res) => {
        res.data.map((u) => {
          if (u.dtValue != "05") {
            this.oldTimes.push(u);
          }
        });
        //console.log(this.schoolClassList);
      });
    },
    getGategorys() {
      let listQuery = {
        page: 1,
        limit: 9999,
      };
      //console.log('getcategorys')
      categorys.getList(listQuery).then((res) => {
        this.gategoryList = res.data;
        this.setInfo();
        //console.log(this.gategoryList);
        //for(let key in this.gategoryList){
        //    console.log(this.gategoryList[key])
        //}
        //console.log('getcategorys3')
      });
    },
    getGategoryName(val, columnName) {
      var dicobject = this.gategoryList.find(
        (t) =>
          t.typeId.toUpperCase() == columnName.toUpperCase() &&
          t.dtValue == val,
      );
      //var dicobject = this.gategoryList.find(t => {
      //  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
      //    return t
      //  }
      //});

      if (!dicobject) {
        return "";
      } else {
        return dicobject.name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#analysisSystem1 {
  min-height: 85vh;

  ::-webkit-input-placeholder {
    font-weight: bold;
    color: #646464;
  }

  :-moz-placeholder {
    font-weight: bold;
    color: #646464;
  }

  ::-moz-placeholder {
    font-weight: bold;
    color: #646464;
  }

  :-ms-input-placeholder {
    font-weight: bold;
    color: #646464;
  }

  .pageHeader {
    width: calc(100% - 10vw);
    height: 100px;
    line-height: 100px;
    background-color: #fff;
    padding: 0 5vw;
  }

  .top {
    padding: 0 100px;
    width: calc(100% - 200px);
    display: flex;

    h3 {
      flex: 1;
      text-align: left;
    }

    button {
      flex: 1;
      border: none;
      outline: none;
      background: none;
      text-align: right;
      opacity: 0.8;
      font-size: 1em;
      cursor: pointer;
    }
  }

  .inputWrap {
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    .input {
      margin: 0 10px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      .demonstration {
        font-weight: bold;
      }
    }
  }

  .content {
    margin: 50px auto;
    padding-bottom: 20px;
    width: 80%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    text-align: left;

    .title {
      margin-bottom: 20px;
      background-color: #4d6ffb;
      border-radius: 10px;

      h4 {
        text-align: left;
        color: #fff;
        font-size: 1.2vw;
        padding: 0.5vw 0vw 0.5vw 2.5vw;
      }

      img {
        float: left;
        margin-left: 1vw;
        margin-top: 0.7vw;
        height: 1.5vw;
      }
    }

    .inputBox {
      .select {
        display: inline-block;
        margin: 0 20px;
      }
    }

    .content-btn {
      text-align: center;

      .el-button {
        margin: 2vw;
      }
    }

    .tips {
      span {
        font-size: 0.8vw;
        font-weight: normal;
        color: #959595;
        margin: 0 2vw;

        i {
          color: #d30f11;
          margin-right: 0.5vw;
        }
      }
    }
  }

  .report {
    background-color: #fff;
    margin: 50px auto;
    width: 90%;
    padding: 10px;

    .title {
      margin-bottom: 20px;
      background-color: #4d6ffb;
      border-radius: 10px;

      h4 {
        text-align: left;
        color: #fff;
        font-size: 1.2vw;
        padding: 0.5vw 0vw 0.5vw 2.5vw;
      }

      img {
        float: left;
        margin-left: 1vw;
        margin-top: 0.7vw;
        height: 1.5vw;
      }
    }
  }

  .reportText {
    width: 95%;
    margin: 0 auto;

    .textTitle {
      font-size: 1.2em;
      margin: 5px 0;
      text-align: left;
    }

    span {
      color: red;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss">
#analysisSystem1 {
  .content {
    .inputBox {
      .select {
        .el-input__inner {
          background: #fff;
          border: 0.2vw solid #4d6ffb !important;
          font-weight: bold;
          color: #4d6cfb;
          padding: 0.7vw 3vw;
          font-size: 1.2vw;
          border-radius: 6vw !important;
        }
      }
    }
  }

  .el-radio-group {
    font-size: 0;
    float: none;
    width: 95%;
    margin: 0 auto;
  }

  .el-radio-button {
    margin: 1vw 0.5vw;

    .el-radio-button:first-child .el-radio-button__inner {
      border-left: 0.2vw solid #4d6ffb;
      border-radius: 6vw;
      box-shadow: none !important;
    }

    .el-radio-button__inner {
      background: #fff;
      border: 0.1vw solid #4d6ffb !important;
      font-weight: normal;
      color: #4d6cfb;
      padding: 0.2vw 1vw;
      font-size: 1vw;
      border-radius: 6vw !important;
    }
  }

  .el-checkbox-group {
    float: left;
    width: 95%;
    margin: 0 auto;
  }

  .el-checkbox__label {
    padding: 0.2vw 1vw !important;
    font-size: 1vw !important;
    color: #4d6cfb;
    font-weight: normal !important;
    border: 0.1vw solid #4d6ffb !important;
    border-radius: 6vw !important;
  }

  .el-button--primary {
    background-color: #4d6ffb !important;
    border-color: #4d6ffb !important;
    font-size: 1.2em !important;
    font-weight: bold !important;
    border-radius: 2.5vw !important;
    padding: 10px 25px !important;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #4d6ffb !important;
    color: #fff;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #ffffff !important;
    background: #4d6ffb;
  }

  .el-dialog {
    border-radius: 0.5vw;
  }

  .el-dialog__body {
    padding: 2vw;
    color: #606266;
    font-size: 1.2em;
    word-break: break-all;
    text-align: left;
  }

  .el-dialog__header {
    padding: 1vw 1vw;
    font-size: 1.4em;
    background: #4d6ffb;
    border-radius: 0.5vw;
  }

  .el-dialog__title {
    line-height: 24px;
    font-size: 1em;
    font-weight: bold;
    color: #ffffff;
  }

  .el-dialog__headerbtn {
    font-size: 1.2em;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }

  button.el-button.button.el-button--primary {
    width: 22vw;
    margin: 1vw auto;
    background: #fff !important;
    border: 0.2vw solid;
    color: #4d6ffb;
  }

  button.el-button.qx.el-button--primary {
    padding: 12px 30px !important;
    font-size: 1.2em !important;
    margin-top: 30px;
  }

  .tc .el-radio-button {
    margin: 10px auto;
  }

  .zy .el-radio-button__inner {
    margin: 0 auto;
    width: 30vw;
    font-size: 2vw;
    padding: 15px 10px;
  }
}
</style>

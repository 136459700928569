//import request from '@/utils/request'
import request from '@/utils/request'
export function getList(params) {
  return request({
    url: '/schoolSubjects/load',
    method: 'get',
    params
  })
}

export function loadForRole(roleId) {
  return request({
    url: '/schoolSubjects/loadForRole',
    method: 'get',
    params: { appId: '', firstId: roleId }
  })
}

export function add(data) {
  return request({
    url: '/schoolSubjects/add',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/schoolSubjects/update',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/schoolSubjects/delete',
    method: 'post',
    data
  })
}

export function loadChanceBySubjectName(params) {
  return request({
    url: '/SchoolSubjects/LoadChanceBySubjectName',
    method: 'get',
    params
  })
}

export function loadChance(params) {
  return request({
    url: '/SchoolSubjects/LoadChance',
    method: 'get',
    params
  })
}

export function getScoreDetails(params) {
  return request({
    url: '/SchoolSubjects/GetScoreDetails',
    method: 'get',
    params
  })
}


export function getSchoolSubjectsNoPlan(params) {
  return request({
    url: '/SchoolSubjects/GetSchoolSubjectsNoPlan',
    method: 'get',
    params
  })
}


export function getChance(params) {
  return request({
    url: '/SchoolSubjects/GetChance',
    method: 'get',
    params
  })
}



import Vue from 'vue'
import Vuex from 'vuex'
import {
	getInfo
} from '@/api/member.js'
import {
	getToken
} from '@/api/auth.js'
import Cookies from 'js-cookie'
import { getList } from '@/api/sysVariables.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		systemData1:{},
		systemData2:{},
		systemData3:{},
		systemData4:{},
		systemDataTqp1:{},
		systemDataTqp2:{},
		systemDataTqp3:{},
		subjectData:{},
		userInfo: {},
		tabsMenu: [],
		isLogin: false,
		token: '',
		schoolSearchInfo: {
			type: 0, //0为系统一批量查询，1为系统一单院校查询，2为系统二按专业查询
			subjectInfo: {},
			subjectIndex: -1,
			schoolCode:'',
			schoolName:'',
			searchType:'',
		},
		majorStatus:'',
		isSearch:false,
		collectTimes:'',
		activityActType:'',
		systemTips:'',
		systemNote:'',
		openYear:'',
	},
	mutations: {
		setuserInfo(state, info) {
			state.userInfo = info;
		},
		layoutAddTab(state, tab) {
			state.tabsMenu.push(tab);
		},
		layoutRemoveTab(state, tabName) {
			let tabs = state.tabsMenu;
			state.tabsMenu = tabs.filter(tab => tab.name !== tabName);
		},
		isLogin(state, isLogin) {
			state.isLogin = isLogin;
		},
		setToken(state, token) {
			state.token = token;
		},
		setSchoolSearchInfo(state, info) {
			state.schoolSearchInfo = info;
		},
		setMajorStatus(state, status){
			sessionStorage.setItem('majorStatus', status);
			state.majorStatus = status;
		},
		setAnalysisData1(state,data){
			state.systemData1 = data;
		},
		setAnalysisData2(state,data){
			state.systemData2 = data;
		},
		setAnalysisData3(state,data){
			state.systemData3 = data;
		},
		setAnalysisData4(state,data){
			state.systemData4 = data;
		},
		setAnalysisDataTqp1(state,data){
			state.systemDataTqp1 = data;
		},
		setAnalysisDataTqp2(state,data){
			state.systemDataTqp2 = data;
		},
		setAnalysisDataTqp3(state,data){
			state.systemDataTqp3 = data;
		},
		setSearchStatus(state,status){
			state.isSearch = status;
		},
		setSubjectData(state,data){
			sessionStorage.setItem('subjectData', JSON.stringify(data));
			state.subjectData = data;
		},
		setCollectTimes(state,times){
			sessionStorage.setItem('collectTimes', times);
			state.collectTimes = times;
		},
		setActivityActType(state,type){
			sessionStorage.setItem('activityActType', type);
			state.activityActType = type;
		},
		setSystemTips(state,tips){
			sessionStorage.setItem('systemTips', tips);
			state.systemTips = tips;
		},
		setSystemNote(state,note){
			sessionStorage.setItem('systemNote', note);
			state.systemNote = note;
		},
		setOpenYear(state,year){
			sessionStorage.setItem('openYear', year);
			state.openYear = year;
		}
	},
	actions: {
		verification({
			commit
		}) {
			console.log('start verification');
			let code = getToken();
			console.log(code);
			if (code == undefined) {
				commit('isLogin', false);
			}
			getInfo(code).then((res) => {
				console.log(res);
				if (res.code == 200) {
					Cookies.set('username', res.result.studentName);
					let courseGroup = res.result.syS_CourseGroup;
					for(let i =0;i<courseGroup.length;i++){
						if(courseGroup[i] == ","){
							courseGroup = courseGroup.replace(',','+');
						}
					}
					getList().then((res) => {
						res.data.map(u=>{
							if(u.variableName =="SystemTips"){
								commit('setSystemTips', u.variableContent);
							}
							if(u.variableName =="OpenYears"){
								commit('setOpenYear', u.variableContent);
							}
						})
					})
					res.result.syS_CourseGroup = courseGroup;
					commit('setuserInfo', res.result);
					commit('isLogin', true);
				} else {
					commit('isLogin', false);
				}
			}).catch(() => {
				commit('isLogin', false);
				//console.log(res);
			});
		},
	},
	modules: {}
})

<template>
	<div class="res-container">
		<div class="content">
			<el-form class="res-form" autoComplete="on" :model="resForm" :rules="resRules" ref="resForm"
				label-position="left" label-width="auto">
				<!-- <el-form-item prop="studentName" label="姓名">
					<div class="inputBox">
						<el-input prefix-icon="el-icon-user" name="studentName" type="text"
							v-model="resForm.studentName" autoComplete="on" placeholder="请输入姓名" />
						<span class="tip">请如实填写</span>
					</div>
				</el-form-item> -->
				<el-form-item prop="cardNo" label="卡号">
					<div class="inputBox">
						<el-input prefix-icon="el-icon-key" name="cardNo" type="text" v-model="resForm.cardNo"
							autoComplete="on" placeholder="请输入卡号" max="8" />
						<span class="tip">卡号为8位数</span>
					</div>

				</el-form-item>
				<el-form-item prop="password" label="密码">
					<div class="inputBox">
						<el-input prefix-icon="el-icon-lock" name="password" type="text" v-model="resForm.password"
							autoComplete="on" placeholder="请输入密码" max="8" />
						<span class="tip">密码为6位数</span>
					</div>

				</el-form-item>
				<el-form-item prop="syS_CourseGroup" label="科目组合">
					<div class="inputBox">
						<AuthSelect class="select" v-model="resForm.syS_CourseGroup" placeholder="请选择科目组合"
							:data-source="'SYS_CourseGroup'" size="medium"></AuthSelect>
						<span class="tip">选定后不可更改</span>
					</div>
				</el-form-item>
				<!-- <el-form-item>
					<p style="color: red;">每年的6月23日——7月2日期间分数只能更改一次，其他时段不限次数</p>
				</el-form-item> -->
				<p style="color: red;margin-bottom: 15px;">每年的6月24日——7月2日期间分数只能更改一次，其他时段不限次数</p>
				<el-form-item prop="score" label="高考分数">
					<div class="inputBox">
						<el-input name="score" type="number" min="0" max="750" v-model="resForm.score"
							placeholder="请输入高考分数" @blur="verifyNum('score')" />
						<span class="tip">高考分数没有小数点</span>
					</div>
				</el-form-item>
				<el-form-item prop="scoreAdd" label="区外高校加分">
					<div class="inputBox">
						<el-input name="scoreAdd" type="number" min="0" max="20" v-model="resForm.scoreAdd"
							placeholder="请输入区外高校加分" @blur="verifyNum('scoreAdd')" />
						<span class="tip">据成绩单填写，不能超过20分</span>
					</div>
				</el-form-item>
				<el-form-item prop="scoreAddGX" label="区内高校加分">
					<div class="inputBox">
						<el-input name="scoreAddGX" type="number" min="0" max="20" v-model="resForm.scoreAddGX"
							placeholder="请输入区内高校加分" @blur="verifyNum('scoreAddGX')" />
						<span class="tip">据成绩单填写，不能超过20分</span>
					</div>
				</el-form-item>
				<el-form-item prop="syS_Nation" label="民族">
					<div class="inputBox">
						<AuthSelect class="select" v-model="resForm.syS_Nation" placeholder="请选择民族"
							data-source="SYS_Nation" size="medium"></AuthSelect>
						<span class="tip">选定后不可更改</span>
					</div>
				</el-form-item>
				<el-form-item class="input" prop="city" label="城市">
					<!-- <AuthSelect class="town" v-model="resForm.city" placeholder="城市" :data-source="cityarr"
						size="medium"></AuthSelect>
						<AuthSelect class="area" v-model="resForm.county" placeholder="县区" :data-source="countyarr"
							size="medium"></AuthSelect> -->
					<div class="inputBox">
						<el-select v-model="resForm.city" placeholder="城市" @change="selectCity" class="town">
							<el-option v-for="(item, index) of cityarr" :key="index" :label="item.city"
								:value="item.code">
							</el-option>
						</el-select>
						<el-select v-model="resForm.county" placeholder="县区" class="area">
							<el-option v-for="(item, index) of countyarr" :key="index" :label="item.county || item.city"
								:value="item.code">
							</el-option>
						</el-select>
					</div>

				</el-form-item>
				<el-form-item prop="syS_Nation" label="国家专项资格">
					<div class="inputBox">
						<AuthSelect class="select" v-model="resForm.nationalSpecial" placeholder="是否有国家专项资格"
							:data-source="nationalSpecialList" size="medium"></AuthSelect>
					</div>
				</el-form-item>
				<el-form-item prop="syS_Nation" label="地方专项资格">
					<div class="inputBox">
						<AuthSelect class="select" v-model="resForm.localSpecial" placeholder="是否有地方专项资格"
							:data-source="localSpecialList" size="medium"></AuthSelect>
					</div>
				</el-form-item>
				<el-form-item prop="syS_Nation" label="精准专项资格">
					<div class="inputBox">
						<AuthSelect class="select" v-model="resForm.preciseSpecial" placeholder="是否有地方专项资格"
							:data-source="preciseSpecialList" size="medium"></AuthSelect>
					</div>
				</el-form-item>				
				<!-- <el-form-item prop="password">
					<el-input prefix-icon="el-icon-lock" name="password" v-model="resForm.password" autoComplete="on"
						placeholder="请输入密码"></el-input>
				</el-form-item> -->
				<!-- <el-form-item prop="syS_Years">
					<AuthSelect class="select" v-model="resForm.syS_Years" placeholder="请选择年级" :data-source="'SYS_Years'"
						size="medium"></AuthSelect>
				</el-form-item> -->
				<el-form-item prop="mobileTel" label="手机号">
					<div class="inputBox">
						<el-input prefix-icon="el-icon-mobile-phone" name="mobileTel" type="text"
							v-model="resForm.mobileTel" autoComplete="on" placeholder="请输入手机号" />
						<span class="tip">请如实填写</span>
					</div>

				</el-form-item>
				<el-form-item prop="verifyCode" label="验证码">
					<div class="codeItem">
						<el-input name="code" @keyup.enter.native="register" v-model="resForm.verifyCode"
							autoComplete="off" placeholder="请输入验证码">
						</el-input>
						<el-button type="primary" style="background:linear-gradient(to right, #008ffe, #4e6ffc);"
							@click="getCode" v-if="codeBtn == '获取验证码'">{{ codeBtn }}</el-button>
						<el-button type="info" round disabled v-else>{{ codeBtn }}</el-button>
					</div>
				</el-form-item>
				<el-button type="primary"
					style="width:100%;background:linear-gradient(to right, #008ffe, #4e6ffc);font-size: 20px;height: 50px;"
					:loading="loading" @click.native.prevent="register">
					卡 激 活
				</el-button>

				<el-link style="font-size: 20px;float: right;" type="primary" :underline="false"
					@click="$router.push('login')"><i class="el-icon-arrow-left"></i>返回登录</el-link>
			</el-form>
		</div>
	</div>
</template>
<script>
import * as cityData from '@/components/cityData'
import {
	register,
	getCode
} from '../api/member.js'
import {
	login
} from '../api/login.js'
import {
	setToken
} from '../api/auth.js'
import {
	Message
} from 'element-ui'
import AuthSelect from '../components/AuthSelect.vue'
export default {
	components: {
		AuthSelect
	},
	data() {
		const validateUsername = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('姓名不能为空'))
			} else {
				callback()
			}
		}
		const validateTel = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('手机号不能为空'))
			} else {
				callback()
			}
		}
		const validateCardNo = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('卡号不能为空'))
			} else {
				callback()
			}
		}
		const validatePwd = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('密码不能为空'))
			} else {
				callback()
			}
		}
		const validateprovinceValue = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('城市不能为空'))
			} else {
				callback()
			}
		}
		const validatecityValue = (rule, value, callback) => {
			console.log(value)
			if (value.length <= 0) {
				callback(new Error('县区不能为空'))
			} else {
				callback()
			}
		}
		const validatensValue = (rule, value, callback) => {
			console.log(value)
			if (value.length <= 0) {
				callback(new Error('国家专项资格不能为空'))
			} else {
				callback()
			}
		}
		const validatelsValue = (rule, value, callback) => {
			console.log(value)
			if (value.length <= 0) {
				callback(new Error('地方专项资格不能为空'))
			} else {
				callback()
			}
		}
		// const validateYear = (rule, value, callback) => {
		// 	if (value.length <= 0) {
		// 		callback(new Error('年份不能为空'))
		// 	} else {
		// 		callback()
		// 	}
		// }
		const validateScore = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('高考分数不能为空'))
			} else {
				callback()
			}
		}
		// const validateScoreAdd = (rule, value, callback) => {
		// 	if (value.length <= 0) {
		// 		callback(new Error('区外加分不能为空'))
		// 	} else {
		// 		callback()
		// 	}
		// }
		// const validateScoreAddGX = (rule, value, callback) => {
		// 	if (value.length <= 0) {
		// 		callback(new Error('区内加分不能为空'))
		// 	} else {
		// 		callback()
		// 	}
		// }
		const validateCourse = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('科目组合不能为空'))
			} else {
				callback()
			}
		}
		const validateNation = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('民族不能为空'))
			} else {
				callback()
			}
		}
		const validcode = (rule, value, callback) => {
			//console.log('value', value)
			if (!value) {
				callback(new Error('验证码不能为空'))
			} else {
				callback()
			}
		}
		return {
			nationalSpecialList: [
				{
					value: true,
					label: '有'
				},
				{
					value: false,
					label: '无'
				}
			],
			localSpecialList: [
				{
					value: true,
					label: '有'
				},
				{
					value: false,
					label: '无'
				}
			],
			preciseSpecialList: [
				{
					value: true,
					label: '有'
				},
				{
					value: false,
					label: '无'
				}
			],

			cityarr: [],
			countyarr: [],
			countyValue: [],
			regionValue: '',
			cityValue: '',
			resForm: {
				studentName: '',
				mobileTel: '',
				cardNo: '',
				password: '',
				city: '',
				county: '',
				//syS_Years: '',
				verifyCode: '',
				score: '',
				scoreAdd: 0,
				scoreAddGX: 0,
				syS_CourseGroup: '',
				syS_Nation: '',
			},
			codeTimeout: 60,
			codeBtn: '获取验证码',
			loading: false,
			resRules: {
				studentName: [{
					required: true,
					trigger: 'blur',
					validator: validateUsername
				}],
				mobileTel: [{
					required: true,
					trigger: 'blur',
					validator: validateTel
				}],
				cardNo: [{
					required: true,
					trigger: 'blur',
					validator: validateCardNo
				}],
				password: [{
					required: true,
					trigger: 'blur',
					validator: validatePwd
				}],
				city: [{
					required: true,
					trigger: 'blur',
					validator: validateprovinceValue
				}],
				county: [{
					required: true,
					trigger: 'blur',
					validator: validatecityValue
				}],
				// syS_Years: [{
				// 	required: true,
				// 	trigger: 'blur',
				// 	validator: validateYear
				// }],
				verifyCode: [{
					required: true,
					trigger: 'blur',
					validator: validcode
				}],
				score: [{
					required: true,
					trigger: 'blur',
					validator: validateScore
				}],
				syS_CourseGroup: [{
					required: true,
					trigger: 'blur',
					validator: validateCourse
				}],
				syS_Nation: [{
					required: true,
					trigger: 'blur',
					validator: validateNation
				}],
				nationalSpecial: [{
					required: true,
					trigger: 'blur',
					validator: validatensValue
				}],
				localSpecial: [{
					required: true,
					trigger: 'blur',
					validator: validatelsValue
				}],

				preciseSpecial: [{
					required: true,
					trigger: 'blur',
					validator: validatelsValue
				}],

			},
		};
	},
	created() {
		this.cityarr = cityData.getAreaList('450000');
	},
	methods: {
		verifyNum(type) {
			if (type == "score") {

				if (this.resForm.score > 750 || this.resForm.score < 0) {
					Message({
						message: '请填写0 ~ 750 内的高考分数',
						type: 'warning',
						duration: 3000
					})
					this.resForm.score = 0;
				}
			} else if (type == "scoreAdd") {

				if (this.resForm.scoreAdd > 20 || this.resForm.scoreAdd < 0) {
					Message({
						message: '请填写0 ~ 20 的区内加分分数',
						type: 'warning',
						duration: 3000
					})
					this.resForm.scoreAdd = 0;
				}
			} else if (type == "scoreAddGX") {

				if (this.resForm.scoreAddGX > 20 || this.resForm.scoreAddGX < 0) {
					Message({
						message: '请填写0 ~ 20 的区外加分分数',
						type: 'warning',
						duration: 3000
					})
					this.resForm.scoreAddGX = 0;
				}
			}
		},
		selectCity(val) {
			this.countyValue = '';
			this.countyarr = cityData.getAreaList(val)
			console.log(this.countyarr)
		},
		register() {
			//console.log(this.resForm);
			this.$refs.resForm.validate(valid => {
				if (valid) {
					this.loading = true;
					register(this.resForm).then(res => {
						//console.log(res)
						this.loading = false;
						if (res.code == 200) {
							Message({
								message: '卡激活成功',
								type: 'success',
								duration: 5 * 1000
							})
							this.login();
						} else {
							Message({
								message: res.errmsg,
								type: 'error',
								duration: 5 * 1000
							})
						}
					}).catch(() => {
						this.loading = false;
					})
				}
			})
		},
		login() {
			console.log('code=' + this.resForm.code)
			login(this.resForm.cardNo, this.resForm.password).then(res => {
				if (res.token) {
					Message({
						message: '登录成功',
						type: 'success',
						duration: 5 * 1000
					})
					setToken(res.token);
					this.$router.push('home');
				} else {
					Message({
						message: res.message || res.msg || res.errmsg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		getCode() {
			if (this.codeBtn == '获取验证码') {
				if (this.resForm.mobileTel.length == 11) {
					// 进入倒计时
					this.codeBtn = this.codeTimeout + '秒';
					let time = setInterval(() => {
						this.codeTimeout--;
						this.codeBtn = this.codeTimeout + '秒';
						if (this.codeTimeout == 0) {
							this.codeBtn = '获取验证码';
							this.codeTimeout = 60;
							clearInterval(time);
						}
					}, 1000)
					getCode(this.resForm.mobileTel).then(res => {
						console.log(res);
						if (res.code == 200) {
							Message({
								message: '发送成功，请注意查收',
								type: 'success',
								duration: 5 * 1000
							})
						} else {
							Message({
								message: res.errmsg || '发送失败，请联系管理员',
								type: 'error',
								duration: 5 * 1000
							})
						}
					})
				} else {
					Message({
						message: '请输入正确格式的手机号',
						type: 'error',
						duration: 5 * 1000
					})
				}
			}
		},
	}
};
</script>
<style rel="stylesheet/scss" lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}

input[type='number'] {
	-moz-appearance: textfield;
}

// .res-container {
// 	.select {
// 		.el-input {
// 			input {
// 				//width: 600px;
// 				height: 50px;
// 				font-size: 16px
// 			}

// 		}
// 	}

// 	.el-input--prefix .el-input__inner {
// 		height: 50px;
// 		font-size: 16px
// 	}

// 	.el-input__inner {
// 		height: 50px;
// 	}
// }</style>
<style lang="scss" scoped>
$dark_gray: #D1DFE8;

.res-container {
	height: 100%;
	background: url(../assets/zhuce-bg.jpg) no-repeat;
	background-color: #EBEBEA;
	background-position: 0 0;
	background-size: 100% 100%;
	text-align: center;
	margin-top: 5px;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.logo {
		height: 70px;
		position: absolute;
		left: 30px;
		top: 30px;
	}

	.content {
		width: calc(100% - 10vw);
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding: 35px 5vw;

		>img {
			width: 568px;
			margin-right: 150px;
			vertical-align: middle;
		}

		.res-form {
			display: inline-block;
			vertical-align: middle;
			padding: 20px;
			max-width: 600px;
			border-radius: 5px;
			box-shadow: 3px 3px 10px #323232;
			background-color: #fff;

			.inputBox {
				display: flex;

				.el-input {
					max-width: 70%;
				}

				.tip {
					flex: 1;
					color: #ff0300;
					margin-left: 5px;
					white-space: nowrap;
				}
			}

			.codeItem {
				display: flex;

				.el-input {
					margin-right: 15px;
					font-size: 16px;
				}
			}

			// .el-from-item{
			// 	.el-input{
			// 		width: auto;
			// 	}
			// }
			.input {
				height: 50px;

				.town {
					position: absolute;
					left: 0;
					width: 45%;
				}

				.area {
					position: absolute;
					right: 0;
					width: 45%;
				}
			}
		}

		.el-button--primary {
			border: none;
		}

		.svg-container {
			color: $dark_gray;
			vertical-align: middle;
			width: 33px;
			display: inline-block;
			font-size: 22px;

			&_res {
				font-size: 31px;
			}

			.title {
				margin: 10px 0;
				margin-bottom: 40px;
				text-align: left;
				font-size: 26px;
				font-weight: bold;
				background-image: linear-gradient(to right, #fd7f0f, #ff0300);
				background-clip: text;
				-webkit-background-clip: text;
				color: transparent;
			}

			.tips {
				margin: 0;
				font-size: 20px;
				font-weight: bold;
				background-image: linear-gradient(to right, #fd7f0f, #ff0300);
				background-clip: text;
				-webkit-background-clip: text;
				color: transparent;
				text-align: left;
			}

			.show-pwd {
				position: absolute;
				right: 10px;
				top: 7px;
				font-size: 16px;
				color: $dark_gray;
				cursor: pointer;
				user-select: none;
				font-size: 24px;
			}
		}
	}
}
</style>

import request from "@/utils/request";

export function getList(params) {
  return request({
    url: "/SaleActivitys/MemberLoad",
    method: "get",
    params,
  });
}

export function apply(data) {
  return request({
    url: "/ActivityApplys/apply",
    method: "post",
    data,
  });
}
export function memberGet(params) {
  return request({
    url: "/ActivityApplys/MemberGet",
    method: "get",
    params,
  });
}
export function memberUpdate(data) {
  return request({
    url: "/ActivityApplys/MemberUpdate",
    method: "post",
    data,
  });
}

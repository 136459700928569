<template>
	<el-dialog title="修改高考总分" :visible.sync="changeScoreVisible" width="400px">
		<el-form class="res-form" autoComplete="on" :model="resForm" ref="resForm" label-position="left" label-width="auto">
			<el-form-item prop="score" label="高考分数">
				<div class="inputBox">
					<el-input name="score" type="number" min="0" max="750" v-model="resForm.score" placeholder="请输入高考分数"
						@blur="verifyNum('score')" />
					<span class="tip">（无小数点）</span>
				</div>
			</el-form-item>
			<el-form-item prop="scoreAdd" label="区外高校加分">
				<div class="inputBox">
					<el-input name="scoreAdd" type="number" min="0" max="20" v-model="resForm.scoreAdd"
						placeholder="请输入区外高校加分" @blur="verifyNum('scoreAdd')" />
					<span class="tip">（不超20）</span>
				</div>
			</el-form-item>
			<el-form-item prop="scoreAddGX" label="区内高校加分">
				<div class="inputBox">
					<el-input name="scoreAddGX" type="number" min="0" max="20" v-model="resForm.scoreAddGX"
						placeholder="请输入区内高校加分" @blur="verifyNum('scoreAddGX')" />
					<span class="tip">（不超20）</span>
				</div>
			</el-form-item>
			<el-button type="primary"
				style="width:100%;background:linear-gradient(to right, #008ffe, #4e6ffc);font-size: 20px;height: 50px;"
				@click.native.prevent="save_Score">
				保存修改分数
			</el-button>
		</el-form>
	</el-dialog>
</template>

<script>
import {
	saveScore
} from '@/api/member'
import {
	mapActions
} from "vuex"
	import {
		removeToken
	} from '@/api/auth.js'
export default {
	data() {
		return {
			resForm: {
				studentName: '',
				mobileTel: '',
				cardNo: '',
				password: '',
				city: '',
				county: '',
				//syS_Years: '',
				verifyCode: '',
				score: 0,
				scoreAdd: 0,
				scoreAddGX: 0,
				syS_CourseGroup: '',
				syS_Nation: '',
			},
			gategoryList: [],
			changeScoreVisible: false
		}
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		userInfo: Object
	},
	watch: {
		visible(nv) {
			//console.log(nv);
			this.changeScoreVisible = nv;
			//this.$emit('visible',nv);
		},
		changeScoreVisible(nv) {
			if (!nv) {
				this.$emit('visible', nv);
			}
		},
		userInfo(nv) {
			this.resForm = Object.assign({}, nv);
		}
	},
	methods: {
		...mapActions(["verification",]),
		verifyNum(type) {
			if (type == "score") {

				if (this.resForm.score > 750 || this.resForm.score < 0) {
					this.$message({
						message: '请填写0 ~ 750 内的高考分数',
						type: 'warning',
						duration: 3000
					})
					this.resForm.score = 0;
				}
			} else if (type == "scoreAdd") {

				if (this.resForm.scoreAdd > 20 || this.resForm.scoreAdd < 0) {
					this.$message({
						message: '请填写0 ~ 20 的区内加分分数',
						type: 'warning',
						duration: 3000
					})
					this.resForm.scoreAdd = 0;
				}
			} else if (type == "scoreAddGX") {

				if (this.resForm.scoreAddGX > 20 || this.resForm.scoreAddGX < 0) {
					this.$message({
						message: '请填写0 ~ 20 的区外加分分数',
						type: 'warning',
						duration: 3000
					})
					this.resForm.scoreAddGX = 0;
				}
			}
		},
		save_Score() {
			//console.log(this.resForm);
			this.resForm.score = Number(this.resForm.score);
			this.resForm.scoreAdd = Number(this.resForm.scoreAdd);
			this.resForm.scoreAddGX = Number(this.resForm.scoreAddGX);
			saveScore(this.resForm).then(res => {
				//console.log(res);
				if (res.code == 200) {
					this.verification();
					this.$message({
						message: '修改成功，请重新登录',
						type: 'success',
						duration: 2000
					})
					setTimeout(() => {
						removeToken();
						localStorage.clear()
						window.sessionStorage.clear()
						this.$router.go(0);

					}, 500);
				} else {
					this.$message({
						message: '修改失败，请重试',
						type: 'error',
						duration: 2000
					})
				}
				this.changeScoreVisible = false;

			})
			// this.$store.commit('setuserInfo', this.resForm);

		},
	}
}
</script>

<style scoped lang="scss">
.res-form {
	display: inline-block;
	vertical-align: middle;
	width: 100%;

	.inputBox {
		display: flex;
		width: 100%;

		.el-input {
			flex: 1;
		}

		.tip {
			width: 35%;
			color: #ff0300;
			margin-left: 5px;
			white-space: nowrap;
		}
	}

	// .el-from-item{
	// 	.el-input{
	// 		width: auto;
	// 	}
	// }
}
</style>

<template>
	<div class="search1">
		<el-page-header class="pageHeader" @back="$router.go(-1)">
		</el-page-header>
		<div class="content">
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>系统四选填</h4>
			</div>
			<div class="inputWrap">
				<div class="input">
					<span class="demonstration">考生年份：</span>
					<AuthSelect :placeholder="'请选择考生年份'" :isEdit="true" :data-source="'SYS_Years'"
						v-model="analysisData.syS_Years" size="medium">
					</AuthSelect>
				</div>
				<!-- <div class="input">
					<span class="demonstration">选科组合：</span>
					<el-input v-model="analysisData.syS_CourseGroup" placeholder="选科组合" disabled
						size="medium"></el-input>
				</div>
				<div class="input">
					<span class="demonstration">民族：</span>
					<el-input v-model="analysisData.nation" placeholder="民族" disabled size="medium"></el-input>
				</div> -->
				<div class="input">
					<span class="demonstration">考生姓名：</span>
					<el-input v-model="analysisData.studentName" placeholder="姓名" size="medium"></el-input>
				</div>
				<div class="input">
					<span class="demonstration">联系手机：</span>
					<el-input v-model="analysisData.mobileTel" placeholder="手机号码" size="medium"></el-input>
				</div>
				<!-- <div class="input">
					<span class="demonstration">辅导类型：</span>
					<span style="font-weight: bold;">{{ userInfo.syS_CoachType ? getGategoryName(userInfo.syS_CoachType, 'syS_CoachType') : '无' }}</span>
				</div> -->
			</div>
			<div style="margin: 20px auto;width: 185px;text-align: center;">
				<el-button type="primary" class="qx" @click="jumpSystem"
					:disabled="!analysisData.syS_Years || !analysisData.mobileTel || !analysisData.studentName">进入模拟填报
				</el-button>
			</div>
			<p style="text-align: center;color: red;">{{ systemTips }}</p>
		</div>

	</div>
</template>

<script>

// import {
// 	mapState,
// } from "vuex"
import AuthSelect from '@/components/AuthSelect.vue'
import * as volunteers from '@/api/volunteers'
export default {
	components: {
		AuthSelect,
	},
	data() {
		return {
			analysisData: {},
			gategoryList: [],
		}
	},
	computed: {
		systemTips() {
			return this.$store.state.systemTips;
		}
	},
	methods: {
		jumpSystem() {
			let listQuery = {
				syS_Times: '本科',
				syS_Years: this.analysisData.syS_Years,
				studentName: this.analysisData.studentName,
				mobileTel: this.analysisData.mobileTel,
			}

			volunteers.memberGet(listQuery).then(res => {
				//console.log(res);
				if (res.code == 200) {
					//console.log(res);
					this.analysisData.volunteerId = res.result.id;
					this.analysisData.syS_CoachType = res.result.syS_CoachType;
					this.analysisData.syS_VolunteerStatus = res.result.syS_VolunteerStatus;
					this.analysisData.textarea = res.result.notes;
					//console.log()
					this.$store.commit('setAnalysisData4', this.analysisData);
					this.$router.push('/analysisSystem4');
				}

			})
		},
	},

}
</script>

<style lang="scss">
.search1 {
	.el-input__inner {
		font-weight: bold;
		color: #000;
	}

}
</style>
<style lang="scss" scoped>
.search1 {
	::-webkit-input-placeholder {
		font-weight: bold;
		color: #000;
	}

	:-moz-placeholder {
		font-weight: bold;
		color: #000;
	}

	::-moz-placeholder {
		font-weight: bold;
		color: #000;
	}

	:-ms-input-placeholder {
		font-weight: bold;
		color: #000;
	}

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.inputWrap {
		margin: 0 auto;
		padding: 0 20px;
		display: flex;
		flex-wrap: wrap;

		.input {
			margin: 0 10px;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			white-space: nowrap;

			.demonstration {
				font-weight: bold;
			}
		}

	}

	.content {
		margin: 50px auto;
		padding-bottom: 20px;
		width: 80%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		text-align: left;

		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;

			h4 {
				text-align: left;
				color: #fff;
				font-size: 1.2vw;
				padding: 0.5vw 0vw 0.5vw 2.5vw;
			}

			img {
				float: left;
				margin-left: 1vw;
				margin-top: 0.7vw;
				height: 1.5vw;
			}
		}

		.inputBox {
			.select {
				display: inline-block;
				margin: 0 20px;
			}
		}

		.content-btn {
			text-align: center;

			.el-button {
				margin: 20px;
			}
		}


		.tips {
			span {
				font-size: 0.8vw;
				font-weight: normal;
				color: #959595;
				margin: 0 2vw;

				i {
					color: #d30f11;
					margin-right: 0.5vw;
				}
			}
		}
	}
}
</style>
<template>
	<div class="search1">
		<el-page-header class="pageHeader" @back="$router.go(-1)">
		</el-page-header>
		<div class="content">
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>系统二选填</h4>
			</div>
			<div class="inputWrap">
				<div class="input">
					<span class="demonstration">年份：</span>
					<!-- <el-date-picker v-model="value1" type="syS_Years" placeholder="2022">
					</el-date-picker> -->
					<AuthSelect :placeholder="'请选择考生年份'" :isEdit="true" :data-source="'SYS_Years'"
						v-model="analysisData.syS_Years" size="medium">
					</AuthSelect>
				</div>
				<!-- <div class="input">
					<span class="demonstration">选科组合：</span>
					<el-input v-model="analysisData.syS_CourseGroup" placeholder="选科组合" disabled
						size="medium"></el-input>
				</div>
				<div class="input">
					<span class="demonstration">民族：</span>
					<el-input v-model="analysisData.nation" placeholder="民族" disabled size="medium"></el-input>
				</div> -->
				<div class="input">
					<span class="demonstration">批次：</span>
					<!-- <AuthSelect :placeholder="'请选择类别'" :isEdit="true" :data-source="'DIC_AheadClass'"
						v-model="analysisData.diC_AheadClass" size="medium">
					</AuthSelect> -->
					<el-select v-model="analysisData.times" placeholder="请选择考生批次" size="medium">
						<el-option v-for="(item, index) of times" :key="index" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="input">
					<span class="demonstration">类别：</span>
					<AuthSelect :placeholder="'请选择类别'" :isEdit="true" :data-source="'DIC_AheadClass'"
						@change="getMemberList" v-model="analysisData.diC_AheadClass" size="medium">
					</AuthSelect>

					<!-- <el-select v-model="analysisData.diC_AheadClass" placeholder="请选择省市" @change="getMemberList" size="medium"
						:disabled="!analysisData.syS_Years || !analysisData.syS_Times">
						<el-option v-for="(item,index) of provincearr" :key="index" :label="item.address"
							:value="item.code">
						</el-option>
					</el-select> -->
				</div>
				<div class="input">
					<span class="demonstration">院校：</span>
					<el-select v-model="analysisData.schoolCode" placeholder="请选择院校" size="medium"
						:disabled="!analysisData.diC_AheadClass" :isEdit="true" @change="getSubjectGroups">
						<el-option v-for="(item, index) of schoolList" :key="index" :label="item.schoolName"
							:value="item.schoolCode">
						</el-option>
					</el-select>
				</div>
				<div class="input">
					<span class="demonstration">专业组：</span>
					<el-select v-model="analysisData.subjectGroup" placeholder="请选择专业组" size="medium"
						:disabled="!analysisData.schoolCode" :isEdit="true">
						<el-option v-for="item of subjectGroupList" :key="item.id" :label="item.subjectGroup"
							:value="item.subjectGroup">
						</el-option>
					</el-select>
				</div>
			</div>

			<div style="margin: 20px auto;width: 185px;text-align: center;">
				<el-button type="primary" class="qx" @click="jumpSystem"
					:disabled="!analysisData.syS_Years || !analysisData.syS_Times || !analysisData.diC_AheadClass || !analysisData.schoolCode || !analysisData.subjectGroup">进入</el-button>
			</div>
			<p style="text-align: center;color: red;">{{ systemTips }}</p>
		</div>

	</div>
</template>

<script>
import * as schoolGroups from '@/api/schoolsubjectgroups'
import * as cityData from '@/components/cityData';
import AuthSelect from '@/components/AuthSelect.vue'
export default {
	components: {
		AuthSelect,
	},
	data() {
		return {
			analysisData: {
				syS_Times: '提前批',
				subjectGroup: ''
			},
			provincearr: [],
			schoolList: [],
			subjectGroupList: [],
			times: [
				{
					label: '其他一类',
					value: '001'
				}
			]
		}
	},
	created() {
		this.provincearr = cityData.getAreaList('');
	},
	computed: {
		systemTips() {
			return this.$store.state.systemTips;
		}
	},
	methods: {
		jumpSystem() {
			this.$store.commit('setAnalysisDataTqp2', this.analysisData);

			this.$router.push('/analysisSystemTqp2');
		},
		// 查询相关
		getMemberList() {
			this.analysisData.schoolCode = '';
			//this.analysisData.subjectGroup = '';
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				diC_AheadClass: this.analysisData.diC_AheadClass,
				syS_SubTimes: '001',
				limit: 999
			}
			//console.log(listQuery);
			schoolGroups.getMembelList(listQuery).then(res => {
				this.schoolList = res.data;
				//console.log(res);
			})
		},
		getSubjectGroups() {
			this.analysisData.subjectGroup = '';
			//console.log(this.analysisData.schoolCode, this.schoolList)

			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				diC_AheadClass: this.analysisData.diC_AheadClass,
				schoolCode: this.analysisData.schoolCode,
				syS_SubTimes: '001',
			}
			schoolGroups.memberLoadSubjectGroup(listQuery).then(res => {
				this.subjectGroupList = res.data;
				//console.log(this.schoolList);
				// this.analysisData.schoolName = this.schoolList.find(u => {
				// 	return u.schoolCode == res.data[0].schoolCode
				// }).schoolName;
				//console.log(this.subjectGroupList);
			})
		},
	},

}
</script>

<style lang="scss">
.search1 {
	.el-input__inner {
		font-weight: bold;
		color: #000;
	}

}
</style>
<style lang="scss" scoped>
.search1 {
	::-webkit-input-placeholder {
		font-weight: bold;
		color: #000;
	}

	:-moz-placeholder {
		font-weight: bold;
		color: #000;
	}

	::-moz-placeholder {
		font-weight: bold;
		color: #000;
	}

	:-ms-input-placeholder {
		font-weight: bold;
		color: #000;
	}

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.inputWrap {
		margin: 0 auto;
		padding: 0 20px;
		display: flex;
		flex-wrap: wrap;

		.input {
			margin: 0 10px;
			flex: 1;
			min-width: 15%;
			display: flex;
			align-items: center;
			justify-content: center;
			white-space: nowrap;

			.demonstration {
				font-weight: bold;
			}
		}

	}

	.content {
		margin: 50px auto;
		padding-bottom: 20px;
		width: 80%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		text-align: left;

		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;

			h4 {
				text-align: left;
				color: #fff;
				font-size: 1.2vw;
				padding: 0.5vw 0vw 0.5vw 2.5vw;
			}

			img {
				float: left;
				margin-left: 1vw;
				margin-top: 0.7vw;
				height: 1.5vw;
			}
		}

		.inputBox {
			.select {
				display: inline-block;
				margin: 0 20px;
			}
		}

		.content-btn {
			text-align: center;

			.el-button {
				margin: 20px;
			}
		}


		.tips {
			span {
				font-size: 0.8vw;
				font-weight: normal;
				color: #959595;
				margin: 0 2vw;

				i {
					color: #d30f11;
					margin-right: 0.5vw;
				}
			}
		}
	}
}
</style>
<template>
  <div id="search">
    <!-- <el-page-header class="pageHeader" @back="$router.go(-1)" content="志愿填报查询分析" >
		</el-page-header> -->
    <el-page-header class="pageHeader" @back="$router.go(-1)" content="">
      <template slot="content">
        <div
          style="
            display: flex;
            min-width: 700px;
            justify-content: space-between;
          "
        >
          <span>志愿填报查询分析</span>
          <span style="color: red; font-weight: bold"
            >浏览器缩放设置为80%是最佳浏览模式</span
          >
        </div>
      </template>
    </el-page-header>
    <div class="content">
      <div class="system">
        <h3>系统一：其他一类所有院校专业组投档概率查询</h3>
        <p>考生投档到其他一类所有院校所有专业组概率查询</p>
        <el-button
          class="button"
          type="default"
          @click="$router.push('/search1Tqp')"
          >点击进入</el-button
        >
      </div>
      <!-- <div class="system">
				<h3>系统二：其他一类单个院校专业组投档概率和所有专业录取概率查询</h3>
				<p>考生投档到其他一类单个院校专业组的投档概率及被专业组中各专业录取的概率查询</p>
				<el-button class="button" type="default" @click="$router.push('/search2Tqp')">点击进入</el-button>
			</div> -->
      <!-- <div class="system">
				<h3>系统三：本科提前批其他类志愿模拟填报</h3>
				<p></p>
				<el-button class="button" type="default" @click="$router.push('/search3Tqp')">点击进入</el-button>
			</div> -->
    </div>
  </div>
</template>
<script>
import * as schoolGroups from "@/api/schoolsubjectgroups";
import { mapState } from "vuex";
//import AuthSelect from "@/components/AuthSelect.vue";
import * as categorys from "@/api/categorys";
import * as cityData from "@/components/cityData";
export default {
  components: {
    //AuthSelect,
  },
  data() {
    return {
      defaultProps: {
        label: "subjectGroup",
        value: "subjectGroup",
      },
      nation: "",
      gategoryList: [],
      s: [
        {
          //系统1
          syS_Years: "",
          nation: "",
          syS_CourseGroup: "",
          syS_Times: "",
        },
        {
          //系统2
          syS_Years: "",
          nation: "",
          syS_CourseGroup: "",
          syS_Times: "",
          province: "",
          subjectGroup: "",
          schoolCode: "",
        },
        {
          //系统3
          syS_Years: "",
          nation: "",
          syS_CourseGroup: "",
          syS_Times: "",
          subjectName: "",
        },
        {
          //系统4
          syS_Years: "",
          nation: "",
          syS_CourseGroup: "",
          studentName: "",
          mobileTel: "",
          syS_CoachType: "",
        },
      ],
      provincearr: [],
      schoolList: [],
      subjectGroupList: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.provincearr = cityData.getAreaList("");
    this.getGategorys();
  },
  methods: {
    search(index) {
      //把数据存储到内存
      this.$store.commit("setAnalysisData", this.s[index]);
      this.$router.push("/analysisSystemTqp" + (index + 1));
    },
    setInfo() {
      for (let i in this.s) {
        this.s[i].nation = this.getGategoryName(
          this.userInfo.syS_Nation,
          "SYS_Nation",
        );
        this.s[i].syS_CourseGroup = this.userInfo.syS_CourseGroup;
      }
    },
    getGategorys() {
      //console.log(this.userInfo);
      let listQuery = {
        page: 1,
        limit: 9999,
      };
      categorys.getList(listQuery).then((res) => {
        this.gategoryList = res.data;
        this.setInfo();
      });
    },
    getGategoryName(val, columnName) {
      var dicobject = this.gategoryList.find(
        (t) =>
          t.typeId.toUpperCase() == columnName.toUpperCase() &&
          t.dtValue == val,
      );
      //var dicobject = this.gategoryList.find(t => {
      //  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
      //    return t
      //  }
      //});

      if (!dicobject) {
        return "";
      } else {
        return dicobject.name;
      }
    },
    back() {
      //通过push进行跳转
      this.$router.push("/home");
    },
    getSubjectGroups() {
      let listQuery = {
        syS_Years: this.s[1].syS_Years,
        syS_Times: this.s[1].syS_Times,
        province: this.s[1].province,
        schoolCode: this.s[1].schoolCode,
      };
      schoolGroups.memberLoadSubjectGroup(listQuery).then((res) => {
        this.subjectGroupList = res.data;
        console.log(this.subjectGroupList);
      });
    },
    getList() {
      let listQuery = {
        syS_Years: this.s[1].syS_Years,
        syS_Times: this.s[1].syS_Times,
        province: this.s[1].province,
      };
      console.log(listQuery);
      schoolGroups.getMembelList(listQuery).then((res) => {
        this.schoolList = res.data;
        console.log(res);
      });
    },
    selectcity() {},
  },
};
</script>
<style lang="scss" scoped>
#search {
  min-height: 85vh;
  background-color: #fff;

  min-height: 85vh;

  .pageHeader {
    height: 100px;
    line-height: 100px;
    background-color: #fff;
    padding: 0 5vw;
  }

  .content {
    .system {
      position: relative;
      margin: 20px auto;
      padding: 20px;
      background-color: #4d6ffb;
      border-radius: 10px;
      width: 700px;
      height: 100px;
      color: #fff;

      h3 {
        text-align: left;
        color: yellow;
      }

      p {
        margin-top: 10px;
        text-align: left;
        color: yellow;
      }

      .button {
        position: absolute;
        top: 80px;
        right: 40px;
      }
    }

    @media screen and (max-width: 500px) {
      .system {
        width: 700px;

        .button {
          position: absolute;
          top: 60px;
          right: 40px;
        }
      }
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss">
#search {
  .pageHeader {
    .el-page-header__left {
      width: 15%;
    }
  }

  .content {
    .button {
      .el-button {
        width: 80%;
        font-size: 1.2em;
      }
    }
  }

  .el-input__inner {
    border: 2px solid #4d6ffb !important;
    border-radius: 8px !important;
    font-size: 0.9em !important;
  }

  .el-input--prefix .el-input__inner {
    padding-left: 15px !important;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 145px !important;
  }

  .el-input__icon {
  }

  i.el-input__icon.el-icon-date {
    color: #c0c4cc;
    font-size: 0.8em !important;
    transition: transform 0.3s !important;
    transform: rotateZ(180deg) !important;
    cursor: pointer !important;
  }

  .el-input__icon {
    height: 100% !important;
    width: 25px !important;
    text-align: center !important;
    transition: all 0.3s !important;
    line-height: 40px !important;
  }

  .el-icon-date:before {
    content: "\e6e1" !important;
    position: absolute;
    top: 0px;
    right: 116px;
  }

  .el-icon-date:after {
    content: "";
    height: 100%;
    width: 0;
    display: inline-input;
    vertical-align: middle;
  }

  span.demonstration {
    font-size: 1em;
  }

  // .el-button {
  // 	border: 1px solid #4d6ffb !important;
  // 	margin: 35px auto !important;
  // 	padding: 15px 70px !important;
  // 	font-size: 1em !important;
  // 	font-weight: bold !important;
  // 	border-radius: 35px !important;
  // }

  // .el-button--primary {
  // 	background-color: #4d6ffb !important;
  // 	border-color: #4d6ffb !important;
  // }
}
</style>

<template>
  <div class="activity-modify">
    <h2>{{ getGategoryName(syS_ActType, "SYS_ActType") }}报名</h2>
    <div class="content">
      <el-form
        class="res-form"
        autoComplete="on"
        :model="resForm"
        :rules="resRules"
        ref="resForm"
        label-position="left"
        label-width="auto"
      >
        <p style="font-weight: bold; padding-bottom: 15px; text-align: left">
          填写报名信息
        </p>
        <el-form-item class="input" prop="city" label="选择城市">
          <AuthSelect
            :data-source="'SYS_City'"
            v-model="resForm.city"
            size="medium"
            @change="getActId"
            class="inputBox"
          >
          </AuthSelect>
        </el-form-item>

        <el-form-item prop="actId" label="选择场次">
          <div class="inputBox">
            <el-select v-model="resForm.actId" style="width: 100%">
              <el-option
                v-for="(item, index) of actIdList"
                :key="index"
                :label="item.actAddr + ' ' + item.actDate"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item prop="school" label="孩子学校">
          <div class="inputBox">
            <el-input name="school" type="text" v-model="resForm.school" />
          </div>
        </el-form-item>
        <el-form-item prop="name" label="家长昵称">
          <div class="inputBox">
            <el-input name="name" type="text" v-model="resForm.name" />
          </div>
        </el-form-item>
        <el-form-item prop="phone" label="联系方式">
          <div class="inputBox">
            <el-input name="phone" type="text" v-model="resForm.phone" />
          </div>
        </el-form-item>

        <el-button
          type="primary"
          style="
            width: 100%;
            background: #0683a2;
            font-size: 20px;
            height: 50px;
          "
          :loading="loading"
          @click.native.prevent="submit"
        >
          提交
        </el-button>
        <p
          style="
            font-weight: bold;
            color: red;
            text-align: center;
            margin-top: 20px;
          "
        >
          点“请选择场次”没反应？说明报名已结束。
        </p>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as categorys from "@/api/categorys";
import AuthSelect from "@/components/AuthSelect";
import * as avtivity from "@/api/activity";
import { mapState } from "vuex";
export default {
  name: "activity-modify",
  components: {
    AuthSelect,
  },
  computed: {
    ...mapState(["activityActType"]),
  },
  data() {
    const validateCity = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择城市"));
      } else {
        callback();
      }
    };
    const validateActId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择场次"));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入家长昵称"));
      } else {
        callback();
      }
    };
    const validateSchool = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入孩子学校"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    return {
      resForm: {
        school: "", // 学校
        name: "", // 昵称
        actId: "", // 场次
        phone: "", // 电话
      },
      resRules: {
        city: [
          {
            required: true,
            trigger: "blur",
            validator: validateCity,
          },
        ],
        actId: [
          {
            required: true,
            trigger: "blur",
            validator: validateActId,
          },
        ],
        school: [
          {
            required: true,
            trigger: "blur",
            validator: validateSchool,
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            validator: validateName,
          },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            validator: validatePhone,
          },
        ],
      },
      cityValue: "",
      codeTimeout: 60,
      loading: false,
      codeBtn: "获取验证码",
      duration: 5 * 1000,
      message: "请输入正确格式的手机号",
      cityarr: [],
      actIdList: [],
      gategoryList: [],
      syS_ActType: "",
    };
  },
  mounted() {
    if (!this.activityActType) {
      this.syS_ActType = sessionStorage.getItem("activityActType");
    } else {
      this.syS_ActType = this.activityActType;
    }
  },
  created() {
    this.getGategorys();
  },
  methods: {
    submit() {
      this.$refs["resForm"].validate((valid) => {
        if (valid) {
          let formData = Object.assign(this.resForm,{})
          console.log(formData);
          formData.syS_ActType = this.syS_ActType
          avtivity.apply(formData).then((res) => {
            if (res.code == 200) {
              this.$notify({
                title: "成功",
                message: "报名成功",
                type: "success",
                duration: 2000,
              });
            }
          });
        }
      });
    },
    getActId() {
      let listQuery = {
        syS_City: this.resForm.city,
        syS_actType: this.activityActType,
      };
      console.log(listQuery);
      avtivity.getList(listQuery).then((res) => {
        this.actIdList = res.data;
      });
    },
    getGategorys() {
      let listQuery = {
        page: 1,
        limit: 9999,
      };
      //console.log('getcategorys')
      categorys.getList(listQuery).then((res) => {
        this.gategoryList = res.data;
        //console.log(this.gategoryList);
        //for(let key in this.gategoryList){
        //    console.log(this.gategoryList[key])
        //}
        //console.log('getcategorys3')
      });
    },
    getGategoryName(val, columnName) {
      var dicobject = this.gategoryList.find(
        (t) =>
          t.typeId.toUpperCase() == columnName.toUpperCase() &&
          t.dtValue == val,
      );
      //var dicobject = this.gategoryList.find(t => {
      //  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
      //    return t
      //  }
      //});

      if (!dicobject) {
        return "";
      } else {
        return dicobject.name;
      }
    },
  },
};
</script>

<style lang="scss">
.activity-modify {
  .inputBox {
    .el-select {
      width: 100%;
    }
  }
}
</style>

<style scoped lang="scss">
.activity-modify {
  h2 {
    padding-bottom: 4vw;
    padding-top: 4vw;
    background-color: #0683a2;
    font-size: 5vw;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  .content {
    padding-top: 30vh;

    .res-form {
      display: inline-block;
      vertical-align: middle;
      padding: 20px;
      width: calc(100vw - 40px);
      border-radius: 5px;
      background-color: #fff;

      .inputBox {
        display: flex;

        .el-input {
          max-width: 100%;
        }

        .tip {
          flex: 1;
          color: #ff0300;
          margin-left: 5px;
          white-space: nowrap;
        }
      }

      .codeItem {
        display: flex;

        .el-input {
          margin-right: 15px;
          font-size: 16px;
        }
      }

      // .el-from-item{
      // 	.el-input{
      // 		width: auto;
      // 	}
      // }
      .input {
        height: 50px;
      }
    }
  }

  .abut {
    display: block;
    width: 35vw;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: #0683a2;
    color: #fff;
    text-align: center;
    float: left;
    padding-bottom: 4vw;
    padding-top: 4vw;
    font-size: 5vw;
  }
}
</style>

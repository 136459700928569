<!--ExportExcel-->
<template>
	<div style="display: inline-block">
		<el-popover placement="left" title="文件名" width="200" trigger="click">
			<el-input v-model="filename" placeholder="请输入文件名" clearable size="small"></el-input>
			<el-button @click="export2Excel()" style="margin: 10px 0" size="small">确定</el-button>
			<el-button slot="reference" size="small" type="primary">导出Excel</el-button>
		</el-popover>
	</div>
</template>

<script>
	export default {
		name: "ExportExcel",
		components: {},
		props: {
			multipleSelection: {
				type: Array,
				default: () => [],
				required: true,
			},
			fieldName: {
				type: Array,
				default: () => [],
				required: true,
			},
			fieldValue: {
				type: Array,
				default: () => [],
				required: true,
			},
			isDone:Boolean
		},
		data() {
			return {
				filename: "",
				timer:0,
			};
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
			//导出Excel
			export2Excel() {
				this.$emit("processingData");
				this.timer = setInterval(() => {
					if(this.isDone){
						clearInterval(this.timer);
						if (this.multipleSelection.length == 0) {
							this.$message.warning("请勾选需要导出的记录");
							return;
						}
						try {
							require.ensure([], () => {
								const {
									export_json_to_excel
								} = require("@/excel/Export2Excel");
								const data = this.multipleSelection.map((v) =>
									this.fieldValue.map((j) => v[j])
								);
								export_json_to_excel(this.fieldName, data, this.filename);
								this.filename = "";
								this.$emit("exported");
								this.$message.success("导出成功，正在下载，请等待",3000);
							});
						} catch (error) {
							this.$message.error("导出失败");
							this.filename = "";
							this.$emit("exported");
						}
					}
				},1000)
			},
		},
		watch: {},
	};
</script>

<style lang="scss" scoped>
	.ExportExcel {}
</style>
<!--
 * @description: 通用下拉选择框
 * @author: liyubao | xufuxing
 * @version: 1.1
 * @updateDate:2021-08-25 增加传入value/label数组的支持
 *             2021-08-26 增加动态下拉列表支持
*  @description: 用于通用的下拉框选择，简单用法如下：
*             字典获取： <auth-select :isEdit="isEdit" @change="change" :data-source="'SYS_STATUS'" v-model="val" size="mini"></auth-select>
*             动态列表： <auth-select :isEdit="isEdit" @change="change" :type="'dynamic'" :data-source="'/CategoryTypes/Load'" v-model="val" size="mini"></auth-select>
-->

<template>
	<div>
		<span v-if="!isEdit">{{ labelName }}</span>
		<el-select allow-create clearable v-else :size="size" :disabled="disabled" :value="value" @change="handleChange"
			:placeholder="placeholder" :multiple="multiple" :collapse-tags="collapseTags">
			<el-option v-for="(item, index) in typeDatas" :key="index" :value="item[defaultProps.value]"
				:label="item[defaultProps.label]"></el-option>
		</el-select>
	</div>
</template>

<script>
	import * as categorys from '@/api/categorys'

	export default {
		name: 'authSelect',
		components: {},
		props: {
			/**
			 * 下拉的类型：
			 * static：从数据字典Category按字典类型加载
			 * dynamic：从接口中获取
			 */
			type: {
				type: String,
				default: 'static'
			},

			/**
			 * 搜索关键字
			 * 当style为dynamic时，该值为传入的搜索关键字
			 */
			searchKey: {
				type: Object,
				defalut: {}
			},
			/**
			 * 下拉框数据来源，
			 * 当为字符串时，表示字典类型，下拉框的选项为该字典类型下所有的字典列表
			 * 当为value/label列表时，下拉框的选项为value/label列表
			 * 当sytle为dynamic时，下拉框的选项从dataSource接口地址中获取列表
			 */
			dataSource: [String, Number, Array],
			/**
			 * 绑定值，直接同步v-model
			 */
			value: {
				type: [String, Number, Array],
				default: ''
			},
			/*
			 * 是否禁用
			 */
			disabled: {
				type: Boolean,
				default: false
			},
			/**
			 * 是否为编辑状态
			 */
			isEdit: {
				type: Boolean,
				default: true
			},
			size: {
				type: String,
				default: 'mini'
			},
			placeholder: {
				type: String,
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			collapseTags: {
				type: Boolean,
				default: false,
			},
			defaultProps: {
				type: Object,
				default () {
					return {
						label: 'label',
						value: 'value'
					}
				}
			}
		},
		data() {
			return {
				typeDatas:null,
			}
		},
		computed: {
			labelName() {
				//当前值对应的名称

				var item = {}

				if (Array.isArray(this.dataSource)) {
					//如果直接输入的是key/value对象数组
					item = this.dataSource.find((item) => item[this.defaultProps.value] === this.value)
				} else {
					item = this.typeDatas.find((item) => item[this.defaultProps.value] === this.value)
				}
				//console.log(item,item[this.defaultProps.label]);
				return (item && item[this.defaultProps.label]) || this.value
			},
			// typeDatas(){
			// 	console.log(this.getList());
			// 	return this.getList();
			// }
		},
		watch: {
			typeId() {
				this.getList()
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				if (Array.isArray(this.dataSource)) {
					//如果输入的是key/value对象数组
					this.typeDatas = this.dataSource;
				}else{
					//从字典表里面按照字典类型获取
					const listQuery = {
						page: 1,
						limit: 9999,
						TypeId: this.dataSource
					}
					return categorys.getList(listQuery).then((res) => {
							//typeId: this.dataSource,
							this.typeDatas = res.data.map((item) => {
								var o = {
									label: item.name,
									value: item.dtValue
								}
								return o
							})
					})
				}
			},
			handleChange(val) {
				this.$emit('input', val)
				this.$emit('change', val)
			}
		}
	}
</script>

<style scoped></style>

import request from '@/utils/request'
export function getList(params) {
  return request({
    url: '/VolunteerSchoolSubjectGroups/MemberGet',
    method: 'get',
    params
  })
}
export function memberSave(data) {
  return request({
    url: '/VolunteerSchoolSubjectGroups/MemberSave',
    method: 'post',
    data
  })
}
export function getVolunteerList(params) {
  return request({
    url: '/VolunteerSchoolSubjectGroups/MemberLoad',
    method: 'get',
    params
  })
}
export function getVolunteerList2(params) {
  return request({
    url: '/VolunteerSchoolSubjectGroups/MemberLoad2',
    method: 'get',
    params
  })
}
export function memberDelete(data) {
  return request({
    url: 'VolunteerSchoolSubjectGroups/MemberDelete',
    method: 'post',
    data
  })
}
export function memberOrdersUp(params) {
  return request({
    url: '/VolunteerSchoolSubjectGroups/MemberOrdersUp',
    method: 'get',
    params
  })
}
export function memberOrdersDown(params) {
  return request({
    url: '/VolunteerSchoolSubjectGroups/MemberOrdersDown',
    method: 'get',
    params
  })
}
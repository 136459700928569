<template>
	<div class="xuanke">
		<el-page-header class="pageHeader" @back="$router.go(-1)" content="新高考选科系统">
		</el-page-header>
		<div class="content">
			<div class="system">
				<h4>系统一：根据选科组合查询可报与不可报院校和专业</h4>
				<el-button class="button" type="default" @click="$router.push('/system1')">点击进入</el-button>
			</div>
			<div class="system">
				<h4>系统二：根据专业名称反查选科组合并自助找出交集点</h4>
				<el-button class="button" type="default" @click="$router.push('/system2')">点击进入</el-button>
			</div>
		</div>

	</div>
</template>

<script>
import {
	mapState
} from "vuex"
import * as subjecttests from "@/api/subjecttests";
export default {
	data() {
		return {
			windowWidth: document.documentElement.clientWidth
		}
	},
	computed: {
		...mapState(["subjectData",]),
	},
	mounted() {
		//this.getByName();
	},
	methods: {

		getWindowWidth() {
			let that = this;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.documentElement.clientWidth;
				})()

			};
		},
	}
}
</script>

<style lang="scss">
.pageHeader {
	.el-page-header__left {
		width: 15%;
	}
}

.content {
	.button {
		.el-button {
			width: 80%;
			font-size: 1.2vw;
		}
	}
}
</style>
<style scoped lang="scss">
.xuanke {
	min-height: 85vh;
	background-color: #fff;

	.pageHeader {
		width: 50%;
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.content {
		.system {
			position: relative;
			margin: 20px auto;
			padding: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;
			width: 40%;
			height: 10vh;
			color: #fff;

			h4 {
				text-align: left;
			}

			.button {
				position: absolute;
				top: 60px;
				right: 40px;
			}
		}

		@media screen and (max-width: 500px) {
			.system {
				width: 80%;

				.button {
					position: absolute;
					top: 60px;
					right: 40px;
				}
			}
		}
	}
}</style>
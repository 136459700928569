import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/subjectSelectRequires/load',
    method: 'get',
    params
  })
}

export function getMemberList(params) {
	return request({
		url: "/subjectSelectRequires/MemberLoad",
		method: 'get',
		params
	});
}
export function getMemberList2(params) {
	return request({
		url: "/subjectSelectRequires/MemberLoad2",
		method: 'get',
		params
	});
}
export function getMemberList3(params) {
	return request({
		url: "/subjectSelectRequires/MemberLoad3",
		method: 'get',
		params
	});
}
export function getMemberList4(params) {
	return request({
		url: "/subjectSelectRequires/MemberLoad4",
		method: 'get',
		params
	});
}

export function loadForRole(roleId) {
  return request({
    url: '/subjectSelectRequires/loadForRole',
    method: 'get',
    params: { appId: '', firstId: roleId }
  })
}

export function add(data) {
  return request({
    url: '/subjectSelectRequires/add',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/subjectSelectRequires/update',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/subjectSelectRequires/delete',
    method: 'post',
    data
  })
}

<template>
	<div id="teacher" style="background: #f5f5f5;">
		<div class="head_top">
			<!-- <div style="background: #fff;height:80px;box-shadow: 2.1vw 1vw 2.1vw #e7e2e2;width: 100%;">
				<button @click="back"
					style="float:left;background: none;border: none;color: #989898;font-size: 1.2vw;width: 10vw;line-height: 4.5; text-align: right;cursor: pointer;"><i>
						&lt;&nbsp;</i>返回</button>
				<div style="width:1200px;height:1400px;margin:0 auto;">
					<h3 style="float: left; margin: 19px 0px;font-size: 32px;padding-right: 252px;">专家讲堂</h3>
				</div>
			</div> -->
			<el-page-header class="pageHeader" @back="back" :content="windowWidth < 800 ? '' : '专家讲堂'">
			</el-page-header>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane :name="'item' + (index + 1)" v-for="(item, index) in tabList" :key="item.label"
					:label="item.label">
					<video-vue :data="list" v-if="list.length > 0"></video-vue>
					<div style="width: 100%;margin: 40px auto;height: 80vh;" v-else></div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
			@pagination="handleCurrentChange" />
	</div>
</template>
<script>
import Pagination from '@/components/Pagination'
import videoVue from '../components/video.vue';
import * as eduvideos from "@/api/eduvideos"
import * as categorys from '@/api/categorys'
// import {
// 	mapActions
// } from "vuex"
export default {

	data() {
		return {
			activeName: 'item1',
			list: [],
			tabList: [],
			listQuery: {
				page: 1,
				limit: 10,
				syS_VideoClass: '01'
			},
			total: 0,
			windowWidth: document.documentElement.clientWidth
		}
	},

	components: {
		videoVue,
		Pagination
	},
	created() {
		this.getList()
		this.getCategorys()
		this.getWindowWidth();
		//this.ver();
	},
	methods: {
		getWindowWidth() {
			let that = this;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.documentElement.clientWidth;
				})()

			};
		},
		handleCurrentChange(val) {
			this.listQuery.page = val.page
			this.listQuery.limit = val.limit
			this.getList()
		},
		back() {
			//跳转到上一次浏览的页面
			this.$router.go(-1)
			//通过push进行跳转
			//this.$router.push('/home')
		},
		handleClick(tab) {
			if (tab.index == '0') {
				this.listQuery.syS_VideoClass = '01';
			}
			if (tab.index == '1') {
				this.listQuery.syS_VideoClass = '10';
			}
			if (tab.index == '2') {
				this.listQuery.syS_VideoClass = '20';
			}
			if (tab.index == '3') {
				this.listQuery.syS_VideoClass = '30';
			}
			this.getList();
		},
		getList() {
			this.list = [];
			eduvideos.getList(this.listQuery).then(res => {
				if (res.code == 200) {
					this.total = res.count;
					this.list = res.data;
					console.log(this.list);
				}

			})
		},
		getCategorys() {
			this.tabList = [];
			//从字典表里面按照字典类型获取
			const listQuery = {
				page: 1,
				limit: 9999,
				TypeId: 'SYS_VideoClass'
			}
			categorys.getList(listQuery).then((res) => {
				//typeId: this.dataSource,
				// this.tabList = res.data.map((item) => {
				// 	if (item.dtValue !== '40') {
				// 		var o = {
				// 			label: item.name,
				// 			value: item.dtValue
				// 		}
				// 		return o
				// 	}
				// })
				for (let i in res.data) {
					if (res.data[i].dtValue !== '40') {
						var o = {
							label: res.data[i].name,
							value: res.data[i].dtValue
						}
						this.tabList.push(o)
					}
				}
			})
		}
	}
}
</script>
<style rel="stylesheet/scss" lang="scss">
#teacher {
	.head_top {
		position: relative;

		.pageHeader {
			width: 50%;
			height: 100px;
			line-height: 100px;
			background-color: #fff;
			padding: 0 5vw;
		}

		.el-tabs__header {
			position: absolute;
			top: 30px;
			left: 25%;
			width: 75%;
		}

		.el-tabs__nav-prev,
		.el-tabs__nav-next {
			font-size: 2vw;
		}

		.el-icon-arrow-left,
		.el-icon-arrow-right {
			font-weight: bold;
		}

		.el-tabs__nav-wrap::after {
			display: none;
		}

		.el-tabs__nav {
			z-index: 0;
		}

		.el-tabs__item {
			font-size: 1vw;
			font-weight: bold;
			padding: 0 1vw;
			margin: 0 1vw;
		}

		.el-tabs__item:hover {
			color: #ffffff;
			background: #4c72f0;
			border-radius: 7px;
		}

		.el-tabs__item.is-active {
			color: #ffffff;
			background: #4c72f0;
			border-radius: 7px;
		}

		.el-tabs__active-bar {
			display: none;
		}
	}

	.pagination-container {
		padding: 20px 0;
	}
}
</style>

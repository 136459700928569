<template>
	<div id="intriduce">
		<div class="nav">
			<top-vue></top-vue>
		</div>
		<div class="contant">
			<div class="top_bg"
				style="text-align: left;background: #9fa9fe;border-radius: 5px;height: 70px;padding: 6px 0;">
				<i><img src="../assets/title_logo.png" style="width: 50%;margin: 7px 10px 5px 25px;" /></i>
				<h3 style="font-size: 22px;color: #fff;">
					测评说明
				</h3>
			</div>
			<div class="nr">
				<span>1、测评分为<span style="font-weight: bold;">兴趣能力与职业测试</span>和<span style="font-weight: bold;">性格测试</span>，
					每个部分的测试时间约为1 5分钟，全部完成约需3 0分钟，测试作答情况将直接影响专业评定的准确度，请认真作答。<br />
					2、考生完成测试后是选填<span style="font-weight: bold;">补充信息</span>，补充信息的选填也将直接影响专业评定的准确度。<br />
					3、专家老师会根据初步分析报告和考生补充的信息，人工给出专业评定（包含有10个专业)。<br>
					4、完成测试后，如果长时间还没有收到评定结果，请联系客服人员。<br>
				</span>
				<!-- <div class="btn">
					<el-button type="default" round>下一步</el-button>
				</div> -->

			</div>
			<div style="height: 100px;margin: 0 auto;">
				<el-button @click="nextClick()"
					style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
					下一步</el-button>
			</div>
		</div>
	</div>
</template>
<script>
import * as systemApi from "@/api/subjectSystemApi"
import topVue from '../components/top.vue';
import {
	mapState
} from "vuex"
//import * as systemApi from "@/api/subjectSystemApi"

export default {
	data() {
		return {
		}
	},
	components: {
		topVue
	},
	created() {
		console.log(this.subjectData)
		if (Object.keys(this.subjectData).length == 0) {
			let subjectData = JSON.parse(sessionStorage.getItem('subjectData'));
			console.log(subjectData);
			this.$store.commit('setSubjectData', subjectData);
		}
	},
	computed: {
		...mapState(["subjectData",]),
	},
	methods: {
		nextClick() {
			systemApi.getMemberInfo(this.subjectData).then(res => {
				console.log(res)
				if (res.code == 200) {
					let r = res.result;

					this.$store.commit('setSubjectData', r);
					this.$store.commit('setMajorStatus', r.syS_SubjectTestStatus);
					//console.log(r.syS_SubjectTestStatus);
					if (r.syS_SubjectTestStatus == '1') {
						setTimeout(() => {
							this.$router.push('major');
						}, 100);
					} else if (r.syS_SubjectTestStatus == '2') {
						setTimeout(() => {
							this.$router.push('major');
						}, 100);
					} else if (r.syS_SubjectTestStatus == '3') {
						setTimeout(() => {
							this.$router.push('major');
						}, 100);
					} else if (r.syS_SubjectTestStatus == '4') {
						this.$router.push('character');
					} else if (r.syS_SubjectTestStatus == '5') {
						this.$router.push('preliminary');
					} else if (r.syS_SubjectTestStatus == '6') {
						this.$router.push('supplement');
					} else if (r.syS_SubjectTestStatus == '7') {
						this.$router.push('final');
					}

				} else {

					this.$router.push('mbtiStuInfo');
				}

				//
			})
		},
	}
}
</script>
<style lang="scss">
// .contant {
// 	.nr {
// 		.btn {
// 			margin: 10px 0;
// 			text-align: center;

// 			.el-button {
// 				background: #9eacfd;
// 				color: #fff;
// 			}

// 			.el-button:focus,
// 			.el-button:hover {
// 				background: #7782bf !important;
// 			}
// 		}

// 	}
// }
#intriduce {
	.el-button {
		background: #9eacfd !important;
	}

	.el-button:focus,
	.el-button:hover {
		background: #9eacfd !important;
	}
}
</style>
<style lang="scss" scoped>
.contant {
	width: 1000px;
	background-color: #fff;
	margin: 3vw auto 3vw auto;
	border-radius: 5px;
	box-shadow: 2px 3px 10px #d9d9d9;
	.top_bg{
		display: flex;
		align-items: center
	}
}

.nr {
	text-align: left;
	padding: 35px 25px;
	font-size: 1.2vw;
	color: #343434;
	line-height: 45px;
}
</style>

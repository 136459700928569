<template>
	<div id="analysisSystem2" style="background:#f5f5f5;">
		<!-- <div class="top">
				<h3>新高考选科查询</h3>
				<button @click="back">
					<i>&lt;&nbsp;</i>返回
				</button>
			</div> -->
		<el-page-header class="pageHeader" @back="back">
		</el-page-header>
		<div class="warpper">
			<componentsSystem :gategoryList="gategoryList" :subjectClassList="subjectClassList" :times="'提前批'"></componentsSystem>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>分析报告一</h4>
				</div>

				<div class="reportText">
					<div class="textTitle">
						<p v-if="list.length == 0"></p>
						<p v-else>
							您是<span>{{ analysisData.syS_Years }}</span>年<span>{{ userInfo.syS_CourseGroup }}</span>科目考生，{{
								getGategoryName(userInfo.syS_Nation, 'SYS_Nation') }}，
							区外高校专业组投档总分<span>{{ Number(userInfo.score) + Number(userInfo.scoreAdd) }}</span>
							，区内高校专业组投档总分<span>{{ Number(userInfo.score) + Number(userInfo.scoreAddGX) }}</span>
							，现将您的数据与本科{{ analysisData.syS_Times }}<span>{{ analysisData.schoolName }}{{
								analysisData.subjectGroup }}</span>
							专业组前3年录取数据进行对比分析，给出投档概率，供您参考。
							（ 该校在<span>{{ list[0].provinceCity }}</span> ）
						</p>
						
						<p style="text-align: center;margin: 10px 0;">
							<span>提示：如学校数据不足3年，系统给出的投档概率会虚高，请自助分析或向经验丰富的老师咨询。</span>
						</p>
					</div>
				</div>
				<el-table :data="list" style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;"
					border v-loading="listLoading1" :header-cell-style="headerStyle"
					:span-method="(param) => objectSpanMethod(param, [0, 1, 2, 3, 4, 12, 13, 14, 15])"
					:cell-style="cellStyle">
					<el-table-column prop="provinceCity" label="省市" show-overflow-tooltip
						min-width="70px"></el-table-column>
					<el-table-column prop="schoolName" label="院校名称" show-overflow-tooltip
						min-width="110px"></el-table-column>
					<el-table-column prop="subjectGroup" label="专业组代码" show-overflow-tooltip min-width="65px">
						<template slot="header">
							<p>专业组</p>
							<p>代码</p>
						</template>
					</el-table-column>
					<el-table-column prop="ssrShort" label="再选科目" show-overflow-tooltip min-width="60px">
						<template slot="header">
							<p>再选</p>
							<p>科目</p>
						</template>
					</el-table-column>
					<el-table-column prop="plans" label="2024计划数" show-overflow-tooltip min-width="65px">
						<template slot="header">
							<p>{{ analysisData.syS_Years }}</p>
							<p>计划数</p>
						</template>
					</el-table-column>
					<el-table-column prop="syS_Years" label="年份" show-overflow-tooltip min-width="60px"></el-table-column>
					<el-table-column prop="scoreline" label="分数线" show-overflow-tooltip min-width="70px"></el-table-column>
					<el-table-column prop="minscore" label="录取最低分" show-overflow-tooltip min-width="70px">
						<template slot="header">
							<p>录取</p>
							<p>最低分</p>
						</template>
					</el-table-column>
					<el-table-column prop="minPlaceDif" label="最低分名次/线差" show-overflow-tooltip min-width="85px">
						<template slot="header">
							<p>最低分</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.minPlaceDif }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="-" label="您的名次/线差" show-overflow-tooltip min-width="85px">
						<template slot="header">
							<p>您的</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.myPlaceDif == 0 ? '-' : scope.row.myPlaceDif }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="myScore" label="您的同名次分"></el-table-column> -->
					<el-table-column prop="avePlaceDif" label="平均分名次/线差" show-overflow-tooltip min-width="85px">
						<template slot="header">
							<p>平均分</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.avePlaceDif }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="avescore" label="录取平均分" show-overflow-tooltip>
						<template slot="header">
							<p>录取</p>
							<p>平均分</p>
						</template>
					</el-table-column>
					<el-table-column prop="chance" label="投档概率" show-overflow-tooltip min-width="60px">
						<template slot="header">
							<p>投档</p>
							<p>概率</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.chance== '30%'?'建议等待征集':scope.row.chance }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="diC_OldTimes" label="老高考招生批次" show-overflow-tooltip min-width="95px">
						<template slot="header">
							<p>老高考</p>
							<p>招生批次</p>
						</template>
					</el-table-column>
					<el-table-column prop="diC_SchoolClass" label="院校实力评价" show-overflow-tooltip min-width="95px">
						<template slot="header">
							<p>院校</p>
							<p>实力评价</p>
						</template>
						<template slot-scope="scope">
							<p v-for="item in scope.row.diC_SchoolClass" :key="item">{{ item }}</p>
						</template>
					</el-table-column>
					<!-- <el-table-column label="查看专业录取概率">
						<template slot-scope="scope">
							<el-link type="primary" @click="handleUpdate(scope.row)" :underline="false">查看</el-link>
						</template>
					</el-table-column> -->
				</el-table>

				<div class="reportTextBottom" v-if="list.length > 0">
					<p><span style="font-weight: bold;">填报须知：</span></p>
					<div v-html="list[0].remarks"></div>
					<!-- <p>学校实力评价：{{list[0].diC_SchoolClass}}</p> -->
					<!-- <p>第四轮学科评估结果：</p> -->
				</div>

			</div>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>分析报告二</h4>
				</div>

				<div class="reportText">
					<div class="textTitle">

						<p v-if="list.length == 0"></p>
						<p v-else>
							您是<span>{{ analysisData.syS_Years }}</span>年<span>{{ userInfo.syS_CourseGroup }}</span>科目考生，{{
								getGategoryName(userInfo.syS_Nation, 'SYS_Nation') }}，
							区外高校专业组投档总分<span>{{ Number(userInfo.score) + Number(userInfo.scoreAdd) }}</span>
							，区内高校专业组投档总分<span>{{ Number(userInfo.score) + Number(userInfo.scoreAddGX) }}</span>
							，现将您的数据与本科{{ analysisData.syS_Times }}<span>{{ analysisData.schoolName }}{{
								analysisData.subjectGroup }}</span>
							专业组所有专业前3年录取数据进行对比分析，给出专业录取概率，供您参考。
						</p>
						<!-- v-if="list[0].admissionrule.indexOf('按级差录取') != -1" -->
						<!-- <div v-if="list.length > 0">
							<div style="display: flex;align-items: center;justify-items: center;"
								v-if="list[0].admissionrule.indexOf('级差') != -1">
								<span style="white-space: nowrap;">减级差分：</span>
								<el-input v-model="listQuery.scoreMinus" size="medium" style="width: 100px;"></el-input>
								<el-button type="primary" @click="getList2" style="margin:0 20px;">确定重算</el-button>
								<span>（系统当前给出的专业录取分析只是把招生专业填专业1时的录取分析，如把招生专业填专业2、专业3、专业4、专业5、专业6，请减去相应的级差分，再点确定重算）</span>
							</div>

						</div> -->
						<div v-if="list.length > 0">
							<p style="margin-top:10px">专业录取、大类专业分流、转专业政策：</p>
							<div class="subjectContent" v-if="list[0].policy">
								{{ list[0].policy }}
							</div>
						</div>
						<p style="text-align: center;margin: 10px 0;text-align: left;">
							<span style="display: block;">提示：1.如专业数据不足3年，系统给出的录取概率会虚高，请自助分析或向经验丰富的老师咨询。</span>
							<span style="display: block;">
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								2.学校专业组今年招生专业名称如发生改变，前三年的录取数据为空，请点击查看相近专业数据，查找往年录取数据。
							</span>
						</p>
					</div>
				</div>
				<el-table :data="list2" style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;"
					border v-loading="listLoading2" :header-cell-style="headerStyle"
					:span-method="(param) => objectSpanMethod(param, [0, 1, 11, 12, 13, 14])" :cell-style="cellStyle">
					<el-table-column prop="subjectName" label="专业" show-overflow-tooltip min-width="150px">
						<template slot="header">
							<p>专业代码</p>
							<p>专业名称</p>
						</template>
						<template slot-scope="scope">
							<p>{{ scope.row.subjectCode }}</p>
							<p style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">
								<el-link type="primary" :underline="false" @click="clickSubject(scope.row)">{{
									scope.row.subjectName }}</el-link>
							</p>
							<p style="cursor: pointer;" @click="showNotes(scope.row.notes ? scope.row.notes : '-')">
								招生说明
							</p>
						</template>
					</el-table-column>
					<el-table-column prop="plans" label="2024计划数" show-overflow-tooltip min-width="65px">
						<template slot="header">
							<p>{{ analysisData.syS_Years }}</p>
							<p>计划数</p>
						</template>
					</el-table-column>
					<el-table-column prop="syS_Years" label="年份" show-overflow-tooltip min-width="60px"></el-table-column>
					<el-table-column prop="plans" label="计划数" min-width="65px" show-overflow-tooltip>
						<template slot="header">
							<p>计划数</p>
						</template>
						<template slot-scope="scope">
							<span>{{ scope.row.plansSoli }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="scoreline" label="分数线" show-overflow-tooltip min-width="65px"></el-table-column>
					<el-table-column prop="minscore" label="录取最低分" show-overflow-tooltip min-width="70px">
						<template slot="header">
							<p>录取</p>
							<p>最低分</p>
						</template>
					</el-table-column>
					<el-table-column prop="minPlaceDif" label="最低分名次/线差" show-overflow-tooltip min-width="85px">
						<template slot="header">
							<p>最低分</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.minPlaceDif }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="-" label="您的名次/线差" show-overflow-tooltip min-width="85px">
						<template slot="header">
							<p>您的</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.myPlaceDif == 0 ? '-' : scope.row.myPlaceDif }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="myScore" label="您的同名次分"></el-table-column> -->
					<el-table-column prop="avePlaceDif" label="平均分名次/线差" show-overflow-tooltip min-width="85px">
						<template slot="header">
							<p>平均分</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.avePlaceDif }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="avescore" label="录取平均分" show-overflow-tooltip>
						<template slot="header">
							<p>录取</p>
							<p>平均分</p>
						</template>
					</el-table-column>
					<el-table-column label="查看往年录取详情" min-width="80px">
						<template slot="header">
							<p>查看往年</p>
							<p>录取详情</p>
						</template>
						<template slot-scope="scope">
							<el-link type="primary" :underline="false" @click="clickDetail(scope.row)">查看</el-link>
						</template>
					</el-table-column>
					<el-table-column label="查看相近专业数据" min-width="80px">
						<template slot="header">
							<p>查看相近</p>
							<p>专业数据</p>
						</template>
						<template>
							<el-link type="primary" :underline="false" @click="clickNoPlan">查看</el-link>
						</template>
					</el-table-column>
					<el-table-column prop="chance" label="录取概率" show-overflow-tooltip min-width="60px">
						<template slot="header">
							<p>录取</p>
							<p>概率</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.chance }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="diC_SubjectClass" label="专业实力评价" show-overflow-tooltip min-width="65px">
						<template slot="header">
							<p>专业</p>
							<p>实力</p>
						</template>
					</el-table-column>
					<el-table-column label="添加进收藏夹" min-width="60px">
						<template slot="header">
							<p>添加</p>
							<p>收藏</p>
						</template>
						<template slot-scope="scope">
							<el-link type="primary" :underline="false" @click="addCollect(scope.row)">添加</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<el-dialog :visible.sync="subjectDialogVisible" :title="subjectName">
				<img :src="showSubjectImg" alt="" srcset="" style="width: 100%;" />
			</el-dialog>

			<el-dialog :visible.sync="detailsDialogVisible">
				<template slot="title">
					<h4 style="font-weight: bold;color: #ffffff;font-size: 18px;">
						{{ selectRow.syS_Years }}年
						<span style="color: red;">{{ selectRow.schoolName + subjectName }}</span>录取详情
					</h4>
				</template>
				<el-table :data="detailList"
					style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
					:header-cell-style="headerStyle" :cell-style="cellStyle">
					<el-table-column prop="score" label="分数分布"></el-table-column>
					<el-table-column prop="person" label="录取人数"></el-table-column>
					<el-table-column prop="place" label="对应排名"></el-table-column>
				</el-table>

			</el-dialog>

			<el-dialog :visible.sync="notesDialogVisible" title="招生说明">
				<p>
					{{ notes }}
				</p>

			</el-dialog>

			<el-dialog
				:title="analysisData.schoolName + analysisData.subjectGroup + '专业组' + analysisData.syS_Times + '批相关专业情况'"
				:visible.sync="noPlanDialogVisible" width="70%">
				<el-table :data="noPlanList"
					style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
					:header-cell-style="headerStyle" :span-method="(param) => objectSpanMethod(param, [0, 1, 10, 11, 12])"
					:cell-style="cellStyle">
					<el-table-column prop="subjectName" label="专业" show-overflow-tooltip min-width="150px">
						<template slot="header">
							<p>专业代码</p>
							<p>专业名称</p>
						</template>
						<template slot-scope="scope">
							<p>{{ scope.row.subjectCode }}</p>
							<p>
								<el-link type="primary" :underline="false" @click="clickSubject(scope.row)">{{
									scope.row.subjectName }}</el-link>
							</p>
							<p style="cursor: pointer;" @click="showNotes(scope.row.notes ? scope.row.notes : '-')">
								招生说明
							</p>
						</template>
					</el-table-column>
					<el-table-column prop="plans" label="2024计划数">
						<template slot="header">
							<p>{{ analysisData.syS_Years }}</p>
							<p>计划数</p>
						</template>
						<template slot-scope="scope">
							<span>{{ scope.row.plans }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="syS_Years" label="年份"></el-table-column>
					<el-table-column prop="plans" label="计划数" width="110px">
						<template slot="header">
							<p>计划数</p>
						</template>
						<template slot-scope="scope">
							<span>{{ scope.row.plansSoli }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="scoreline" label="分数线" width="65px"></el-table-column>
					<el-table-column prop="minscore" label="录取最低分">
						<template slot="header">
							<p>录取</p>
							<p>最低分</p>
						</template>
					</el-table-column>
					<el-table-column prop="minPlaceDif" label="最低分名次/线差" width="100px">
						<template slot="header">
							<p>最低分</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.minPlaceDif }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="myPlaceDif" label="您的名次/线差" width="100px">
						<template slot="header">
							<p>您的</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.myPlaceDif ? scope.row.myPlaceDif : '-' }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="myScore" label="您的同名次分"></el-table-column> -->
					<el-table-column prop="avePlaceDif" label="平均分名次/线差" width="100px">
						<template slot="header">
							<p>平均分</p>
							<p>名次/线差</p>
						</template>
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.avePlaceDif }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="avescore" label="录取平均分">
						<template slot="header">
							<p>录取</p>
							<p>平均分</p>
						</template>
					</el-table-column>
					<el-table-column prop="chance" label="录取概率">
						<template slot-scope="scope">
							<span style="color: red;">{{ scope.row.chance=='30%'?'建议等待征集':scope.row.chance }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="diC_SubjectClass" label="专业实力评价">
						<template slot="header">
							<p>专业</p>
							<p>实力评价</p>
						</template>
					</el-table-column>
					<el-table-column label="添加进收藏夹">
						<template slot="header">
							<p>添加进</p>
							<p>收藏夹</p>
						</template>
						<template slot-scope="scope">
							<el-link type="primary" :underline="false" @click="addCollect(scope.row)">添加</el-link>
						</template>
					</el-table-column>
				</el-table>
				<!-- <div style="text-align: center;margin: 0 auto;">
					<pagination :total="noPlanTotal" :page.sync="noPlanListQuery.page" :limit.sync="noPlanListQuery.limit"
						@pagination="handleNoPlanCurrentChange" v-if="noPlanTotal > 0" />
				</div> -->

			</el-dialog>
		</div>

		<el-dialog title="该校所有专业全国实力评价" :visible.sync="subjectRankingDialogVisible" width="80%">
			<el-table :data="subjectRankinglList"
				style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
				:header-cell-style="headerStyle" :cell-style="cellStyle" v-loading="rankingListloading">
				<el-table-column prop="schoolName" label="院校名称" show-overflow-tooltip></el-table-column>
				<el-table-column prop="subjectName" label="专业名称" show-overflow-tooltip></el-table-column>
				<el-table-column prop="diC_SubjectClass" label="专业实力评价"></el-table-column>
			</el-table>
			<pagination :total="rankingTotal" :page.sync="listRankingQuery.page" :limit.sync="listRankingQuery.limit"
				@pagination="handleCurrentRankingChange" :autoScroll="false" />
		</el-dialog>


	</div>
</template>

<script>
import componentsSystem from "@/components/componentsSystem";
//import AuthSelect from "@/components/AuthSelect.vue";
import Pagination from "@/components/Pagination";
import * as subjectcollects from '@/api/subjectcollects'
import * as schoolsubjects from '@/api/schoolsubjects'
import * as schoolGroups from '@/api/schoolsubjectgroups'
import {
	mapState,
} from "vuex"
import * as categorys from "@/api/categorys";
//import * as MergeCol from '@/components/mergeCol'

export default {
	components: {
		//AuthSelect,
		componentsSystem,
		Pagination,
	},
	data() {
		return {
			listRankingQuery: {
					page: 1,
					limit: 10
			},
			rankingTotal:0,
			rankingListloading:false,
			subjectRankinglList: [],
			subjectRankingDialogVisible:false,
			myPlaceDif: '',
			oldTimes: [],
			selectRow: {},
			notesDialogVisible: false,
			notes: '',
			showSubjectImg: '',
			subjectDialogVisible: false,
			noPlanTotal: 0,
			subjectTotal: 0,
			subjectClassList: [],
			analysisData: {
				syS_Years: '',
				nation: '',
				syS_CourseGroup: '',
				syS_Times: '',
				province: '',
				subjectGroup: '',
				schoolCode: '',
			},
			//searchState: false,
			detailList: [],
			noPlanList: [],
			noPlanPageList: [],
			detailsDialogVisible: false,
			noPlanDialogVisible: false,
			cellStyle: {
				'text-align': 'center',
			},
			listLoading1: false,
			listLoading2: false,
			headerStyle: {
				fontWeight: 'bold',
				color: '#303030',
				'text-align': 'center',
			},
			list: [],
			list2: [],
			dialogVisible: false,
			schoolList: [],
			subjectGroupList: [],
			schoolClassList: [],
			gategoryList: [],
			listQuery: {
				syS_Years: '',
				syS_Times: '',
				schoolCode: '',
				subjectGroup: '',
			},
			subjectListQuery: {
				page: 1,
				limit: 2,
			},
			noPlanListQuery: {
				page: 1,
				limit: 2,
			},
			subjectName: '',
		}
	},
	computed: {
		...mapState(["userInfo", "systemDataTqp2"]),
	},
	// watch: {
	// 	searchState(val) {
	// 		if (!val) {
	// 			this.$store.commit('setSearchStatus', val)
	// 			this.$store.commit('setAnalysisData', {})
	// 		}
	// 	}
	// },
	activated() {
		//this.searchState = this.isSearch;
		this.analysisData = this.systemDataTqp2;
		if (Object.keys(this.analysisData) == 0) {
			this.analysisData = JSON.parse(sessionStorage.getItem('analysisDataTqp2'));
		} else {
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem('analysisDataTqp2', JSON.stringify(this.analysisData));
			})
		}
		console.log(this.analysisData);
		setTimeout(() => {
			this.getMemberList();
			setTimeout(() => {
				this.getSubjectGroups();
			}, 1000)
			//this.search();
		}, 50)
		this.getGategorys();
		this.getSchoolClassList();
	},
	methods: {
		handleCurrentRankingChange(val) {
			//console.log(val);
			this.listRankingQuery.page = val.page;
			this.listRankingQuery.limit = val.limit;
			this.getSubjectRankingList();
		},
		getSubjectRankingList() {
			this.rankingListloading = true;
			this.subjectRankinglList = [];
			//console.log(this.analysisData.schoolCode);
			subjectcollects.loadSubjectRankings({
				minSchoolCode: this.analysisData.schoolCode,
				maxSchoolCode: this.analysisData.schoolCode,
				page:this.listRankingQuery.page,
				limit:this.listRankingQuery.limit
			}).then(res => {
				console.log(res);
				res.data.map(item => {
					let o = item.s1;
					o.schoolName = item.s2.schoolName;
					if (o.diC_SubjectClass) {
						let temp = o.diC_SubjectClass.split(',');
						//console.log(temp)
						for (let i in temp) {
							temp[i] = this.subjectClassList.find(u => {
								//console.log(u, temp[i])
								return u.dtValue == temp[i];
							});
							//console.log(temp[i])
							if (temp[i]) {
								temp[i] = temp[i].name
							}
						}
						o.diC_SubjectClass = temp.join(',');
					}
					this.subjectRankinglList.push(o);
				})
				//console.log(res);
				this.rankingTotal = res.count;
				this.subjectRankingDialogVisible = true;
				this.rankingListloading = false;
			})
		},
		back() {
			if (this.isSearch) {
				this.$store.commit('setSearchStatus', false)
			}
			this.$router.go(-1)
		},
		//合并单元格
		objectSpanMethod({
			rowIndex,
			columnIndex
		}, array) {
			//console.log(row, column, rowIndex, columnIndex);
			for (let i = 0; i < array.length; i++) {
				if (columnIndex === array[i]) {
					if (rowIndex % 3 === 0) {
						return {
							rowspan: 3,
							colspan: 1
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0
						};
					}
				}
			}
		},
		showNotes(text) {
			this.notes = text;
			this.notesDialogVisible = true;
		},
		clickSubject(row) {
			this.showSubjectImg = '';
			this.subjectName = row.subjectName;
			let subjectName = row.subjectName.substring(0, row.subjectName.indexOf('（')==-1?row.subjectName.indexOf('类'):row.subjectName.indexOf('（'))
			subjectcollects.loadIntroduces({
				subjectName: subjectName,
				fullName: row.subjectName
			}).then(res => {
				//console.log(res);
				if (res.data) {

					this.showSubjectImg = 'https://api.xueshitongedu.cn/upfiles/' + res.data[0]
						.filePath
					this.subjectDialogVisible = true;
				}
			})
		},
		handleNoPlanCurrentChange(val) {
			this.noPlanListQuery.page = val.page;
			this.noPlanListQuery.limit = val.limit;
			this.clickNoPlan();
		},
		handleCurrentChange(val) {
			//console.log(val);
			this.subjectListQuery.page = val.page;
			this.subjectListQuery.limit = val.limit;
			this.getList2();
		},
		// search() {
		// 	this.searchState = true;
		// 	this.getSchoolClassList();
		// },
		setInfo() {
			this.analysisData.nation = this.getGategoryName(this.userInfo.syS_Nation, 'SYS_Nation');
			this.analysisData.syS_CourseGroup = this.userInfo.syS_CourseGroup;
		},
		// 查询相关
		getMemberList() {
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				province: this.analysisData.province,
				limit: 999
			}
			//console.log(listQuery);
			schoolGroups.getMembelList(listQuery).then(res => {
				this.schoolList = res.data;
				//console.log(res);
			})
		},
		getSubjectGroups() {
			//console.log(this.analysisData.schoolCode, this.schoolList)

			this.$forceUpdate();
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				province: this.analysisData.province,
				schoolCode: this.analysisData.schoolCode
			}
			schoolGroups.memberLoadSubjectGroup(listQuery).then(res => {
				this.subjectGroupList = res.data;
				//console.log(this.schoolList);
				// this.analysisData.schoolName = this.schoolList.find(u => {
				// 	return u.schoolCode == res.data[0].schoolCode
				// }).schoolName;
				//console.log(this.subjectGroupList);
			})
		},
		//相关专业
		clickNoPlan() {
			this.noPlanList = [];
			let query = {
				syS_Years: this.analysisData.syS_Years,
				schoolCode: this.analysisData.schoolCode,
				syS_FirstSelectCourse: this.userInfo.syS_FirstSelectCourse,
				syS_Times: '',
			}
			this.analysisData.syS_Times == '本科' ? query.syS_Times = '1' : query.syS_Times = '2';
			schoolsubjects.getSchoolSubjectsNoPlan(query).then(res => {
				console.log(res);
				//分页
				// res.data = res.data.slice(
				// 	(this.noPlanListQuery.page - 1) * this.noPlanListQuery.limit,
				// 	this.noPlanListQuery.page * this.noPlanListQuery.limit
				// );
				//console.log(res.data,this.noPlanListQuery.page * this.noPlanListQuery.limit -1);
				res.data.map(o => {
					if (o.diC_SubjectClass) {
						let temp = o.diC_SubjectClass.split(',');
						//console.log(temp);
						for (let i in temp) {
							temp[i] = this.subjectClassList.find(u => {
								return u.dtValue == temp[i];
							});
							console.log(temp[i]);
							if (temp[i]) {
								temp[i] = temp[i].name
							} else {
								temp[i] = '-'
							}
						}
						//console.log(temp);
						o.diC_SubjectClass = temp.join(',');
					}
					for (let i in o) {
						//console.log(o[i],i);
						if (o[i] === '' || o[i] === null || o[i] === undefined || o[i] === 0 || o[
							i] == '9999999') {
							o[i] = '-';
						}
					}
					this.noPlanList.push({
						syS_Years: this.analysisData.syS_Years-1,
						provinceCity: o.provinceCity,
						subjectCode: o.subjectCode,
						subjectName: o.subjectName,
						subjectGroup: o.subjectGroup,
						syS_Times: o.syS_Times,
						scoreline: o.scoreline1,
						minPlaceDif: o.minPlaceDif1,
						avePlaceDif: o.avePlaceDif1,
						//MyScore:o.MyScore1,
						diC_OldTimes: o.diC_OldTimes,
						diC_SubjectClass: o.diC_SubjectClass,
						chance: o.chance,
						myScore: this.userInfo.scores1,
						minscore: o.minScore1,
						avescore: o.aveScore1,
						plans: o.plans,
						schoolName: o.schoolName,
						myPlaceDif: o.myPlaceDif,
						plansSoli: o.plans1,
						solicitation: o.solicitation1,
					})
					this.noPlanList.push({
						syS_Years: this.analysisData.syS_Years-2,
						provinceCity: o.provinceCity,
						subjectCode: o.subjectCode,
						subjectName: o.subjectName,
						subjectGroup: o.subjectGroup,
						syS_Times: o.syS_Times,
						scoreline: o.scoreline2,
						minPlaceDif: o.minPlaceDif2,
						avePlaceDif: o.avePlaceDif2,
						//MyScore:o.MyScore1,
						diC_OldTimes: o.diC_OldTimes,
						diC_SubjectClass: o.diC_SubjectClass,
						chance: o.chance,
						myScore: this.userInfo.scores2,
						minscore: o.minScore2,
						avescore: o.aveScore2,
						plans: o.plans,
						schoolName: o.schoolName,
						myPlaceDif: o.myPlaceDif,
						plansSoli: o.plans2,
						solicitation: o.solicitation2,
					})
					this.noPlanList.push({
						syS_Years: this.analysisData.syS_Years-3,
						provinceCity: o.provinceCity,
						subjectCode: o.subjectCode,
						subjectName: o.subjectName,
						subjectGroup: o.subjectGroup,
						syS_Times: o.syS_Times,
						scoreline: o.scoreline3,
						minPlaceDif: o.minPlaceDif3,
						avePlaceDif: o.avePlaceDif3,
						//MyScore:o.MyScore1,
						diC_OldTimes: o.diC_OldTimes,
						diC_SubjectClass: o.diC_SubjectClass,
						chance: o.chance,
						myScore: this.userInfo.scores3,
						minscore: o.minScore3,
						avescore: o.aveScore3,
						plans: o.plans,
						schoolName: o.schoolName,
						myPlaceDif: o.myPlaceDif,
						plansSoli: o.plans3,
						solicitation: o.solicitation3,
					})
				})

				this.noPlanDialogVisible = true;
				this.noPlanTotal = res.count;
			})
		},
		getNoPlanChance(data1, data2, data3) {
			let query = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				province: this.listQuery.province,
				avePlace1: data1.aveplace,
				avePlace2: data2.aveplace,
				avePlace3: data3.aveplace,
				minPlace1: data1.minplace,
				minPlace2: data2.minplace,
				minPlace3: data3.minplace,
				aveDif1: data1.aveDIf,
				aveDif2: data2.aveDIf,
				aveDif3: data3.aveDIf,
				minDif1: data1.minDif,
				minDif2: data2.minDif,
				minDif3: data3.minDif,
			}
			schoolsubjects.getChance(query).then(res => {
				//console.log(res);
				let o = this.noPlanList.findIndex(u => {
					return u.subjectName == data1.subjectName
				});
				for (let i = 0; i < 3; i++) {
					if (res.result) {
						this.noPlanList[o + i].chance = res.result;
					} else {
						this.noPlanList[o + i].chance = '-';
					}

				}
				this.$forceUpdate();
				//console.log(this.noPlanList);
			})
		},
		//专业详细
		clickDetail(row) {
			this.detailList = [];
			this.subjectName = row.subjectName;
			this.selectRow = row;
			let query = {
				syS_Years: row.syS_Years,
				schoolCode: this.list[0].schoolCode,
				syS_FirstSelectCourse: this.userInfo.syS_FirstSelectCourse,
				syS_Times: this.list[0].syS_Times,
				subjectName: row.subjectName
			}
			//this.list[0].syS_Times == '本科' ? query.syS_Times = '1' : query.syS_Times = '2';
			schoolsubjects.getScoreDetails(query).then(res => {
				//console.log(res);
				this.detailList = res.result;
				this.detailsDialogVisible = true;
			})
		},
		//添加收藏夹
		addCollect(row) {
			let query = {
				subjectName: row.subjectName, // 专业
				schoolCode: this.list[0].schoolCode, // 院校代码
				provinceCity: row.provinceCity, // 省市
				schoolName: this.analysisData.schoolName, // 院校名称
				diC_SchoolClass: [], // 院校类型
				schoolPlans: this.list[0].plans, // 招生
				syS_FirstSelectCourse: this.userInfo.syS_FirstSelectCourse, // 首选科目
				subjectGroup: row.subjectGroup, // 专业组
				scores: this.userInfo.score, // 分数
				syS_Times: '提前批', // 批次
				syS_Years: this.analysisData.syS_Years, // 年份
				diC_SubjectClass: [], // 专业实力评估
				subjectPlans: row.plans == '-' ? 0 : row.plans, // 专业招生
				schoolChanceStr: this.list[0].chance=='30%'?'建议等待征集':this.list[0].chance, // 投档概率
				subjectChanceStr: row.chance=='30%'?'建议等待征集':row.chance, // 录取概率
			}
			if (Array.isArray(this.list[0].diC_SchoolClass)) {
				this.list[0].diC_SchoolClass.map(u => {
					query.diC_SchoolClass.push(u);
				})
			} else {
				query.diC_SchoolClass.push(this.list[0].diC_SchoolClass);
			}
			if (Array.isArray(row.diC_SubjectClass)) {
				row.diC_SubjectClass.map(u => {
					query.diC_SubjectClass.push(u);
				})
			} else {
				query.diC_SubjectClass.push(row.diC_SubjectClass);
			}
			subjectcollects.memberAdd(query).then(res => {
				//console.log(res);
				if (res.code == 200) {
					this.$message({
						message: '添加成功',
						type: 'success'
					})
				}
			})
		},
		getList2() {
			this.listLoading2 = true;
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				schoolCode: this.analysisData.schoolCode,
				subjectGroup: this.analysisData.subjectGroup,
				page: this.subjectListQuery.page,
				limit: 9999,
				scoreMinus: this.listQuery.scoreMinus ? this.listQuery.scoreMinus : 0
			}
			this.list2 = [];
			schoolsubjects.loadChance(listQuery).then(res => {
				console.log(res);
				res.data.map(o => {
					if (o.diC_SubjectClass) {
						let temp = o.diC_SubjectClass.split(',');
						//console.log(temp);
						for (let i in temp) {
							temp[i] = this.subjectClassList.find(u => {
								return u.dtValue == temp[i];
							});
							console.log(temp[i]);
							if (temp[i]) {
								temp[i] = temp[i].name
							} else {
								temp[i] = '-'
							}
						}
						//console.log(temp);
						o.diC_SubjectClass = temp.join(',');
					}
					for (let i in o) {
						//console.log(o[i],i);
						if (o[i] === '' || o[i] === null || o[i] === undefined || o[i] === 0 || o[
							i] == '9999999') {
							o[i] = '-';
						}
					}

					this.list2.push({
						syS_Years: this.analysisData.syS_Years-1,
						provinceCity: o.provinceCity,
						subjectCode: o.subjectCode,
						subjectName: o.subjectName,
						subjectGroup: o.subjectGroup,
						syS_Times: o.syS_Times,
						scoreline: o.scoreline1,
						minPlaceDif: o.minPlaceDif1,
						avePlaceDif: o.avePlaceDif1,
						//MyScore:o.MyScore1,
						diC_OldTimes: o.diC_OldTimes,
						diC_SubjectClass: o.diC_SubjectClass,
						chance: o.chance + '%',
						myScore: this.userInfo.scores1,
						minscore: o.minScore1,
						avescore: o.aveScore1,
						plans: o.plans,
						schoolName: o.schoolName,
						myPlaceDif: o.myPlaceDif,
						plansSoli: o.plans1,
						solicitation: o.solicitation1,
						notes: o.notes,
					})
					this.list2.push({
						syS_Years: this.analysisData.syS_Years-2,
						provinceCity: o.provinceCity,
						subjectCode: o.subjectCode,
						subjectName: o.subjectName,
						subjectGroup: o.subjectGroup,
						syS_Times: o.syS_Times,
						scoreline: o.scoreline2,
						minPlaceDif: o.minPlaceDif2,
						avePlaceDif: o.avePlaceDif2,
						//MyScore:o.MyScore1,
						diC_OldTimes: o.diC_OldTimes,
						diC_SubjectClass: o.diC_SubjectClass,
						chance: o.chance + '%',
						myScore: this.userInfo.scores2,
						minscore: o.minScore2,
						avescore: o.aveScore2,
						plans: o.plans,
						schoolName: o.schoolName,
						myPlaceDif: o.myPlaceDif,
						plansSoli: o.plans2,
						solicitation: o.solicitation1,
						notes: o.notes,
					})
					this.list2.push({
						syS_Years: this.analysisData.syS_Years-3,
						provinceCity: o.provinceCity,
						subjectCode: o.subjectCode,
						subjectName: o.subjectName,
						subjectGroup: o.subjectGroup,
						syS_Times: o.syS_Times,
						scoreline: o.scoreline3,
						minPlaceDif: o.minPlaceDif3,
						avePlaceDif: o.avePlaceDif3,
						//MyScore:o.MyScore1,
						diC_OldTimes: o.diC_OldTimes,
						diC_SubjectClass: o.diC_SubjectClass,
						chance: o.chance + '%',
						myScore: this.userInfo.scores3,
						minscore: o.minScore3,
						avescore: o.aveScore3,
						plans: o.plans,
						schoolName: o.schoolName,
						myPlaceDif: o.myPlaceDif,
						plansSoli: o.plans3,
						solicitation: o.solicitation1,
						notes: o.notes,
					})
				})
				this.subjectTotal = res.count;
				this.listLoading2 = false;
				//console.log(this.list2);
			})
		},
		getSchoolClassList() {
			let listQuery = {
				page: 1,
				limit: 999,
				typeId: 'DIC_SchoolClass'
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res1) => {
				this.schoolClassList = res1.data;
				//console.log(this.schoolClassList);

				listQuery.typeId = 'DIC_SubjectClass'
				categorys.getList(listQuery).then((res2) => {
					this.subjectClassList = res2.data;
					this.getList2();
					//console.log(this.subjectClassList);
				});
				listQuery.typeId = 'diC_OldTimes'
				categorys.getList(listQuery).then((res) => {
					res.data.map(u => {
						if (u.dtValue != 0) {
							this.oldTimes.push(u);
						}
					})
					//console.log(this.schoolClassList);
				});
				this.getList();
				//console.log(this.schoolClassList);
			});
		},
		getList() {
			this.listLoading1 = true;
			this.listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				schoolCode: this.analysisData.schoolCode,
				subjectGroup: this.analysisData.subjectGroup,
			}
			//console.log(listQuery);
			this.list = [];
			schoolGroups.getSubjectGroupChance(this.listQuery).then(res => {
				this.analysisData.schoolName = res.result.schoolName;
				//console.log(res);
				let o = res.result;
				if (o.diC_OldTimes) {
					let temp = o.diC_OldTimes.split(',');
					let tempName = [];
					for (let i in temp) {
						temp[i] = this.oldTimes.find(u => {
							return u.dtValue == temp[i];
						});
						if (temp[i]) {
							tempName.push(temp[i].name)
						}
					}
					if (tempName.length == 0) {
						tempName = '-';
					}
					o.diC_OldTimes = tempName;
				}
				//console.log(o.diC_SchoolClass)
				if (o.diC_SchoolClass) {
					let temp = o.diC_SchoolClass.split(',');
					let tempName = [];
					for (let i in temp) {
						temp[i] = this.schoolClassList.find(u => {
							return u.dtValue == temp[i];
						});
						if (temp[i]) {
							if (temp[i].name == '211工程大学' || temp[i].name == '985工程大学' || temp[i]
								.name == '一流学科大学' || temp[i].name == '一流大学A' || temp[i].name ==
								'一流大学B' || temp[i].name == '双非大学' || temp[i].name == '民办院校'
							) {

								tempName.push(temp[i].name)
							}
						}
					}
					if (tempName.length == 0) {
						tempName = '-';
					}
					o.diC_SchoolClass = tempName;
				}
				for (let i in o) {
					//console.log(o[i],i);
					if (o[i] === '' || o[i] === null || o[i] === undefined || o[i] === 0 || o[i] ==
						'9999999') {
						o[i] = '-';
					}
				}

				//console.log(o);
				this.list.push({
					syS_Years: this.analysisData.syS_Years-1,
					provinceCity: o.provinceCity,
					schoolCode: o.schoolCode,
					schoolName: o.schoolName,
					subjectGroup: o.subjectGroup,
					syS_Times: o.syS_Times,
					scoreline: o.scoreline1,
					minPlaceDif: o.minPlaceDif1,
					avePlaceDif: o.avePlaceDif1,
					//MyScore:o.MyScore1,
					diC_OldTimes: o.diC_OldTimes,
					diC_SchoolClass: o.diC_SchoolClass,
					chance: o.chance,
					myScore: this.userInfo.scores1,
					minscore: o.minscore1,
					avescore: o.avescore1,
					plans: o.plans,
					remarks: o.remarks,
					schoolUrl: o.schoolUrl,
					myPlaceDif: o.myPlaceDif,
					ssrShort: o.ssrShort,
					admissionrule: o.admissionrule,
					schoolRanking: o.schoolRanking,
					policy: o.policy,
				})
				this.list.push({
					syS_Years: this.analysisData.syS_Years-2,
					provinceCity: o.provinceCity,
					schoolCode: o.schoolCode,
					schoolName: o.schoolName,
					subjectGroup: o.subjectGroup,
					syS_Times: o.syS_Times,
					scoreline: o.scoreline2,
					minPlaceDif: o.minPlaceDif2,
					avePlaceDif: o.avePlaceDif2,
					//MyScore:o.MyScore1,
					diC_OldTimes: o.diC_OldTimes,
					diC_SchoolClass: o.diC_SchoolClass,
					chance: o.chance,
					myScore: this.userInfo.scores2,
					minscore: o.minscore2,
					avescore: o.avescore2,
					plans: o.plans,
					remarks: o.remarks,
					schoolUrl: o.schoolUrl,
					myPlaceDif: o.myPlaceDif,
					ssrShort: o.ssrShort,
					admissionrule: o.admissionrule,
					schoolRanking: o.schoolRanking,
					policy: o.policy,
				})
				this.list.push({
					syS_Years: this.analysisData.syS_Years-3,
					provinceCity: o.provinceCity,
					schoolCode: o.schoolCode,
					schoolName: o.schoolName,
					subjectGroup: o.subjectGroup,
					syS_Times: o.syS_Times,
					scoreline: o.scoreline3,
					minPlaceDif: o.minPlaceDif3,
					avePlaceDif: o.avePlaceDif3,
					//MyScore:o.MyScore1,
					diC_OldTimes: o.diC_OldTimes,
					diC_SchoolClass: o.diC_SchoolClass,
					chance: o.chance,
					myScore: this.userInfo.scores3,
					minscore: o.minscore3,
					avescore: o.avescore3,
					plans: o.plans,
					remarks: o.remarks,
					schoolUrl: o.schoolUrl,
					myPlaceDif: o.myPlaceDif,
					ssrShort: o.ssrShort,
					admissionrule: o.admissionrule,
					schoolRanking: o.schoolRanking,
					policy: o.policy,
				})
				this.listLoading1 = false;
				console.log(this.list);
			})
		},
		getGategorys() {
			let listQuery = {
				page: 1,
				limit: 9999,
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.gategoryList = res.data;
				this.setInfo();
				//console.log(this.gategoryList);
				//for(let key in this.gategoryList){
				//    console.log(this.gategoryList[key])
				//}
				//console.log('getcategorys3')
			});
		},
		getGategoryName(val, columnName) {
			var dicobject = this.gategoryList.find(
				(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
			);
			//var dicobject = this.gategoryList.find(t => {
			//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
			//    return t
			//  }
			//});

			if (!dicobject) {
				return "";
			} else {
				return dicobject.name;
			}
		},
	}
}
</script>


<style lang="scss" scoped>
#analysisSystem2 {
	min-height: 85vh;

	::-webkit-input-placeholder {
		font-weight: bold;
		color: #646464;
	}

	:-moz-placeholder {
		font-weight: bold;
		color: #646464;
	}

	::-moz-placeholder {
		font-weight: bold;
		color: #646464;
	}

	:-ms-input-placeholder {
		font-weight: bold;
		color: #646464;
	}

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.top {
		padding: 0 100px;
		width: calc(100% - 200px);
		display: flex;

		h3 {
			flex: 1;
			text-align: left;
		}

		button {
			flex: 1;
			border: none;
			outline: none;
			background: none;
			text-align: right;
			opacity: .8;
			font-size: 1em;
			cursor: pointer;
		}
	}

	.content {
		margin: 50px auto;
		padding-bottom: 20px;
		width: 80%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		text-align: left;

		.inputWrap {
			margin: 0 auto;
			padding: 0 20px;
			display: flex;
			flex-wrap: wrap;

			.input {
				margin: 0 10px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: nowrap;

				.demonstration {
					font-weight: bold;
				}
			}

		}

		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;

			h4 {
				text-align: left;
				color: #fff;
				font-size: 1.2em;
				padding: 0.5vw 0vw 0.5vw 2.5vw;
				font-weight: normal;
			}

			img {
				float: left;
				margin-left: 1vw;
				margin-top: 0.7vw;
				height: 1.5em;
			}
		}

		.inputBox {
			.select {
				display: inline-block;
				margin: 0 20px;
			}
		}

		.content-btn {
			text-align: center;

			.el-button {
				margin: 2vw;
			}
		}


		.tips {
			span {
				font-size: 0.8vw;
				font-weight: normal;
				color: #959595;
				margin: 0 2vw;

				i {
					color: #d30f11;
					margin-right: 0.5vw;
				}
			}
		}
	}

	.reportText {
		width: 95%;
		margin: 0 auto;

		.textTitle {
			font-size: 1.2em;
			margin: 5px 0;
			text-align: left;

			h4 {
				font-weight: normal;
			}
		}

		em {
			color: rgb(0, 127, 0);
			font-style: normal;
		}

		span {
			color: red;
		}
	}

	.reportTextBottom {
		width: 95%;
		margin: 0 auto;

		p {
			font-size: 1.2em;
			margin: 5px 0;
			text-align: left;
		}

		// span {
		// 	font-weight: bold;
		// }
	}
}
</style>
<style rel="stylesheet/scss" lang="scss">
#analysisSystem2 {
	.content {
		.inputBox {
			.select {
				.el-input__inner {
					background: #FFF;
					border: 0.2vw solid #4d6ffb !important;
					font-weight: bold;
					color: #4d6cfb;
					padding: 0.7vw 3vw;
					font-size: 1.2vw;
					border-radius: 6vw !important;
				}
			}
		}
	}

	.el-radio-group {
		font-size: 0;
		float: none;
		width: 95%;
		margin: 0 auto;
	}

	.el-radio-button {
		margin: 1vw 0.5vw;

		.el-radio-button:first-child .el-radio-button__inner {
			border-left: 0.2vw solid #4d6ffb;
			border-radius: 6vw;
			box-shadow: none !important;
		}

		.el-radio-button__inner {
			background: #FFF;
			border: 0.1vw solid #4d6ffb !important;
			font-weight: normal;
			color: #4d6cfb;
			padding: 0.2vw 1vw;
			font-size: 1vw;
			border-radius: 6vw !important;
		}

	}

	.el-checkbox-group {
		float: left;
		width: 95%;
		margin: 0 auto;
	}

	.el-checkbox__label {
		padding: 0.2vw 1vw !important;
		font-size: 1vw !important;
		color: #4d6cfb;
		font-weight: normal !important;
		border: 0.1vw solid #4d6ffb !important;
		border-radius: 6vw !important;
	}

	.el-button--primary {
		background-color: #4d6ffb !important;
		border-color: #4d6ffb !important;
		font-size: 1.2em !important;
		font-weight: bold !important;
		border-radius: 2.5vw !important;
		padding: 10px 25px !important;
	}

	.el-radio-button__orig-radio:checked+.el-radio-button__inner {
		background-color: #4d6ffb !important;
		color: #FFF;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #ffffff !important;
		background: #4d6ffb;
	}

	.el-dialog {
		border-radius: 0.5vw;
	}

	.el-dialog__body {
		padding: 2vw;
		color: #606266;
		font-size: 1.2em;
		word-break: break-all;
	}

	.el-dialog__header {
		padding: 1vw 1vw;
		background: #4d6ffb;
		border-radius: 0.5vw;
	}

	.el-dialog__title {
		line-height: 24px;
		font-weight: bold;
		color: #ffffff;
	}

	.el-dialog__headerbtn {
		font-size: 1.2em;
	}

	.el-dialog__headerbtn .el-dialog__close {
		color: #fff;
	}

	button.el-button.button.el-button--primary {
		width: 22vw;
		margin: 1vw auto;
		background: #fff !important;
		border: 0.2vw solid;
		color: #4d6ffb;
	}

	button.el-button.qx.el-button--primary {
		padding: 12px 30px !important;
		font-size: 1.2em !important;
		margin-top: 30px;
	}

	.tc .el-radio-button {
		margin: 10px auto;
	}

	.zy .el-radio-button__inner {
		margin: 0 auto;
		width: 30vw;
		font-size: 2vw;
		padding: 15px 10px;
	}
}
</style>
<template>
	<div class="schoolSearch">
		<el-page-header class="pageHeader" @back="$router.go(-1)" content="院校专业查询"></el-page-header>
		<div class="content">
			<div class="title">
				<div class="title-content" v-if="schoolSearchInfo.type == 0">
					<h4>
						首选科目<span>{{schoolSearchInfo.subjectInfo.fsrShort}}</span>，
						再选科目<span>{{schoolSearchInfo.subjectInfo.ssrShort}}</span>
						&nbsp;{{schoolSearchInfo.searchType == '1' ? '可报考院校专业' : '不可报考院校专业'}}
					</h4>
					<span
						style="color: #fff;font-size: 15px;font-weight: bold;">查询方式：可单选省市查询或单选类别查询，也可以先省市后类别查询或先类别后省市查询。</span>
				</div>
				<h4 v-if="schoolSearchInfo.type == 1">
					院校“<span>{{schoolSearchInfo.schoolName}}</span>”
					首选科目<span>{{schoolSearchInfo.subjectInfo.fsrShort}}</span>，
					再选科目<span>{{schoolSearchInfo.subjectInfo.ssrShort}}</span>（条目为同一所院校的只显示一所）
				</h4>
				<h4 v-if="schoolSearchInfo.type == 2">
					招生专业“<span>{{schoolSearchInfo.subjectInfo.subjectName}}</span>”
					首选科目<span>{{schoolSearchInfo.subjectInfo.firstSelect}}</span>，
					再选科目<span>{{schoolSearchInfo.subjectInfo.ssrShort}}</span>
					的学校共<span>{{schoolSearchInfo.subjectInfo.recordCount}}</span>所（条目为同一所院校的只显示一所）
				</h4>
			</div>
			<div class="selects" v-if="schoolSearchInfo.type == 0">
				<h4>下拉选择</h4>
				<el-select class="select" v-model="detailsListQuery.province" placeholder="请选择省市" :disabled="detailsTabaleLoading"
					@change="selectProvince" size="medium">
					<el-option v-for="(item,index) of provinceArr" :key="index" :label="item.address"
						:value="item.code">
					</el-option>
				</el-select>
				<AuthSelect class="select" v-model="detailsListQuery.diC_SchoolClass" placeholder="请选择类别" :disabled="detailsTabaleLoading"
					@change="selectClass" data-source="DIC_SchoolClass" size="medium"></AuthSelect>
				<!-- <el-select v-model="schoolClass" placeholder="请选择类别" @change="selectProvince" class="select"
					size="small">
					<el-option v-for="(item,index) of schoolClassList" :key="index" :label="item.address"
						:value="item.code">
					</el-option>
				</el-select> -->
			</div>
			<div class="infinite-scroll" v-infinite-scroll="scrollGetList" :infinite-scroll-disabled="scrollDisabled">
				<el-table ref="list" :data="detailsList" style="width: calc(100% - 40px);margin: 0 20px;"
					v-loading="detailsTabaleLoading" border id="exportTable">
					<el-table-column type="expand" width="1">
						<template slot-scope="props">
							<el-table :data="props.row.subjects" style="width: 100%" id="exportTable2">
								<el-table-column label="专业代码" prop="subjectCode"></el-table-column>
								<el-table-column label="专业名称" prop="subjectName"></el-table-column>
								<el-table-column label="考试科目要求" prop="selectRequire"></el-table-column>
								<el-table-column label="包含专业" prop="includeSubject"></el-table-column>
								<el-table-column label="招考方向" prop="receiveDirection"></el-table-column>
							</el-table>
						</template>
					</el-table-column>
					<el-table-column label="点击查看详情" width="110">
						<template slot-scope="scope">
							<p v-if="!scope.row.isExpand" style="cursor: pointer;" @click="rowExpand(scope.row,true)">
								点击展开</p>
							<p v-else style="cursor: pointer;" @click="rowExpand(scope.row,false)">
								点击收起
							</p>
						</template>
					</el-table-column>
					<el-table-column label="院校代码" prop="schoolCode">
						<template slot-scope="scope">
							<p style="font-weight: bold;">{{scope.row.schoolCode}}</p>
						</template>
					</el-table-column>
					<el-table-column label="院校名称" prop="schoolName">
						<template slot-scope="scope">
							<p style="font-weight: bold;">{{scope.row.schoolName}}</p>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="text-align: center;margin-top: 20px;">
				<ExportExcel :multipleSelection="exportData" :fieldName="exportExcelData.fieldName"
					:fieldValue="exportExcelData.fieldValue" @processingData="processingData()"
					:isDone="processingDataDone"></ExportExcel>
				<!-- <el-button type="primary" size="small" style="width: 8%;font-size: 16px;font-weight: bold;"
					@click="exportExcel('exportTable','exportTable2')">导出excel</el-button> -->
			</div>
			<pagination v-show="windowWidth > 500 && detailsTotal>1" :total="detailsTotal"
				:pageSizes="detailsList.length" :page.sync="detailsListQuery.page" :limit.sync="detailsListQuery.limit"
				@pagination="handleDetailsChange" />
		</div>
		<el-dialog top="20%" title="导出提示" :visible.sync="isProcessingData" width="45%" :show-close="false"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<span><i class="el-icon-loading"></i>正在导出...</span>
		</el-dialog>

	</div>
</template>

<script>
	import ExportExcel from '@/components/ExportExcel.vue'
	//import excelExportUtil from '@/components/exportExcel'
	import AuthSelect from '@/components/AuthSelect.vue'
	import * as cityData from '@/components/cityData'
	//import * as categorys from '@/api/categorys'
	import * as subjectSelect from '@/api/subjectselectrequires'
	import {
		mapState,
	} from "vuex"
	import Pagination from '@/components/Pagination'
	export default {
		components: {
			Pagination,
			AuthSelect,
			ExportExcel
		},
		data() {
			return {
				exportExcelData: {
					fieldName: ["院校代码", '院校名称', '专业'],
					fieldValue: ["schoolCode", 'schoolName', 'subjects']
				},
				exportData: [],
				provinceArr: [],
				schoolClassList: [],
				detailsList: [],
				detailsPageList: [],
				detailsTabaleLoading: true,
				isProcessingData: false,
				processingDataDone: false,
				detailsTotal: 0,
				detailsListQuery: {
					diC_SchoolClass: '',
					province: '',
					subjectName: '',
					schoolCode: '',
					searchType: '',
					fsrShort: '',
					ssrShort: '',
					limit: 20,
					page: 0, //进入页面会自动加1，因此设置为0
				},
				historyIndex: -1,
				windowWidth: document.documentElement.clientWidth,
				scrollDisabled: true,
				getTotal: false,
			}
		},
		computed: {
			...mapState(["schoolSearchInfo", ]),
		},
		mounted() {
			this.provinceArr = cityData.getAreaList('');
			this.provinceArr.push({
				address: '不分省市',
				code: ' '
			});
			this.getWindowWidth();
			if (this.windowWidth > 500) { //判断页面宽度大于500则执行，小于则执行无限滚动
				if (this.schoolSearchInfo.type == 0) { //批量查询
					this.getList1();
				} else if (this.schoolSearchInfo.type == 1) { //单院校查询
					this.getList2();
				} else if (this.schoolSearchInfo.type == 2) { //明细查询
					this.getList3();
				}
			}
		},
		methods: {
			processingData() {
				this.isProcessingData = true;
				this.exportData = [];
				if (this.schoolSearchInfo.type == 0) { //批量查询,只有批量查询为分页处理，因此只有批量查询需要重新获取数据处理
					let listQuery = {
						searchType: this.schoolSearchInfo.searchType,
						fsrShort: this.schoolSearchInfo.subjectInfo.firstSelect || this.schoolSearchInfo.subjectInfo
							.fsrShort,
						ssrShort: this.schoolSearchInfo.subjectInfo.ssrShort,
						limit: 99999,
						page: 1,
						province: this.detailsListQuery.province,
						diC_SchoolClass: this.detailsListQuery.diC_SchoolClass,
					};
					console.log(listQuery);
					subjectSelect.getMemberList2(listQuery).then(res => {
						console.log(res);
						res.data.map(item => {
							//将专业数据转换为文本类型，用于导出
							let subjects = '';
							item.requireList.map(subject => {
								subjects += `专业代码：` + subject.subjectCode + `
								专业名称：` + subject.subjectName +
									`
									考试科目要求：` + subject.selectRequire + `
									包含专业：` + subject.includeSubject +
									`
									招考方向：` + subject.receiveDirection + `
									`
							})
							this.exportData.push({
								schoolCode: item.schoolCode,
								schoolName: item.schoolName,
								subjects: subjects
							})
						})
						//this.setExportData();
						this.isProcessingData = false;
						this.processingDataDone = true;
					})
				} else{
					this.detailsList.map(item => {
						let subjects = '';
						item.subjects.map(subject => {
							subjects += `专业代码：` + subject.subjectCode + `
							专业名称：` + subject.subjectName +
								`
								考试科目要求：` + subject.selectRequire + `
								包含专业：` + subject.includeSubject +
								`
								招考方向：` + subject.receiveDirection + `
								`
						})
						this.exportData.push({
							schoolCode: item.schoolCode,
							schoolName: item.schoolName,
							subjects: subjects
						})
					})
				}
			},
			setExportData() {
				this.detailsList.map(item => {
					let subjects = '';
					item.subjects.map(subject => {
						subjects += `专业代码：` + subject.subjectCode + `
						专业名称：` + subject.subjectName +
							`
							考试科目要求：` + subject.selectRequire + `
							包含专业：` + subject.includeSubject +
							`
							招考方向：` + subject.receiveDirection + `
							`
					})
					this.exportData.push({
						schoolCode: item.schoolCode,
						schoolName: item.schoolName,
						subjects: subjects
					})
				})
				console.log(this.exportData);
			},
			// exportExcel(tableId, tableId2) {
			// 	//let titles = ['选科方案', '首选科目', '再选科目1', '再选科目2'];
			// 	excelExportUtil.exportExcel(tableId, tableId2, "院校明细", ".xls", true);
			// },
			selectProvince() {
				this.detailsList = [];
				this.detailsListQuery.page = 1;
				this.getList1();
			},
			selectClass() {
				this.detailsList = [];
				this.detailsListQuery.page = 1;
				this.getList1();
			},
			// getSchoolClass() {
			// 	let listQuery = {
			// 		page: 1,
			// 		limit: 9999,
			// 		TypeId: 'DIC_SchoolClass'
			// 	}
			// 	categorys.getList(listQuery).then((res) => {
			// 		this.schoolClassList = res.data.map((item) => {
			// 			var o = {
			// 				label: item.name,
			// 				value: item.dtValue
			// 			}
			// 			return o
			// 		})
			// 		this.schoolClassList.push({
			// 			label: '不分类别',
			// 			value: ' '
			// 		});
			// 		//console.log(this.firstSelectCourse);
			// 	})
			// }
			rowExpand(row, expand) {
				this.detailsList.find(u => {
					return u.schoolCode == row.schoolCode
				}).isExpand = expand;
				this.$refs.list.toggleRowExpansion(row, expand);
			},
			getWindowWidth() {
				let that = this;
				window.onresize = () => {
					return (() => {
						that.windowWidth = document.documentElement.clientWidth;
					})()

				};
			},
			scrollGetList() { //无限滚动进入页面会自动执行一次
				if (this.windowWidth > 500) { //页面大于500则不执行无限滚动
					this.scrollDisabled = true;
					return;
				}
				if (this.schoolSearchInfo.type == 0) { //批量查询
					this.detailsListQuery.page++;
					this.getList1();
				} else if (this.schoolSearchInfo.type == 1) { //单院校查询
					this.getList2();
				} else if (this.schoolSearchInfo.type == 2) { //明细查询
					this.detailsListQuery.page++;
					this.getList3();
				}
			},
			handleDetailsChange(val) {
				this.detailsListQuery.page = val.page
				this.detailsListQuery.limit = val.limit
				this.detailsList = [];
				if (this.schoolSearchInfo.type == 0) { //批量查询的需重新获取数据
					//console.log(1);
					this.getList1();
					return;
				}
				//this.getDetailsPageList();
			},
			getDetailsPageList() { //对数据进行分页处理
				//console.log(this.detailsListQuery);
				this.detailsPageList = [];
				let index = this.detailsListQuery.page * this.detailsListQuery.limit;
				console.log(this.detailsList.length);
				if (this.detailsList.length <= this.detailsListQuery.limit) { //小于limit
					for (let i = 0; i < this.detailsList.length; i++) {
						this.detailsPageList.push(this.detailsList[i])
					}
				} else {
					for (let i = index - this.detailsListQuery.limit; i < index; i++) {
						this.detailsPageList.push(this.detailsList[i])
					}
				}

				//console.log(this.detailsPageList);
			},
			getList1() { //批量查询
				//this.detailsList = [];
				this.detailsTabaleLoading = true;
				//console.log(this.schoolSearchInfo);
				let listQuery = {
					searchType: this.schoolSearchInfo.searchType,
					fsrShort: this.schoolSearchInfo.subjectInfo.firstSelect || this.schoolSearchInfo.subjectInfo
						.fsrShort,
					ssrShort: this.schoolSearchInfo.subjectInfo.ssrShort,
					limit: this.detailsListQuery.limit,
					page: this.detailsListQuery.page,
					province: this.detailsListQuery.province,
					diC_SchoolClass: this.detailsListQuery.diC_SchoolClass,
				};
				console.log(listQuery);
				subjectSelect.getMemberList2(listQuery).then(res => {
					console.log(res);
					//此接口数据已处理好，因此直接添加进detailsList
					res.data.map(item => {
						if (this.detailsList.findIndex(u => {
								return item.schoolCode == u.schoolCode
							}) == -1) {
							this.detailsList.push({
								schoolCode: item.schoolCode,
								schoolName: item.schoolName,
								subjects: item.requireList,
								isExpand: false,
							})
							// if (this.isOneSchool) {
							//   this.activeNames = [];
							//   this.activeNames.push(item.schoolCode)
							// }
						} else {
							//因为接口获取问题，可能第一次获取最后一所获取的学校会还有专业未获取，第二次则补进
							let index = this.detailsList.findIndex(u => {
								return item.schoolCode == u.schoolCode
							});
							if (index != -1) {
								this.detailsList[index].subjects.push(item.requireList);
							}
						}
					})
					this.detailsTotal = res.count;
					this.detailsTabaleLoading = false;
					//this.setExportData();
					//this.getDetailsPageList();

					//获取总长度，比较占用资源
					// listQuery.limit = 99999;
					// subjectSelect.getMemberList2(listQuery).then(res => {
					// 	console.log(res);
					// 	this.detailsTabaleLoading = false;
					// 	this.detailsTotal = res.data.length;
						
					// })
				})
			},
			getList2() { //单院校查询
				this.detailsList = [];
				this.detailsTabaleLoading = true;
				//console.log(this.schoolSearchInfo);
				let listQuery = {
					schoolCode: this.schoolSearchInfo.schoolCode,
					fsrShort: this.schoolSearchInfo.subjectInfo.firstSelect,
					ssrShort: this.schoolSearchInfo.subjectInfo.ssrShort,
					limit: this.detailsListQuery.limit * 10,
					page: this.detailsListQuery.page,
				};
				console.log(listQuery);
				subjectSelect.getMemberList2(listQuery).then(res => {
					console.log(res);
					//此接口数据已处理好，因此直接添加进detailsList
					// res.data.map(item => {
					// 	if (this.detailsPageList.findIndex(u => {
					// 			return item.schoolCode == u.schoolCode
					// 		}) == -1) {
					// 		this.detailsPageList.push({
					// 			schoolCode: item.schoolCode,
					// 			schoolName: item.schoolName,
					// 			subjects: item.requireList
					// 		})
					// 		// if (this.isOneSchool) {
					// 		//   this.activeNames = [];
					// 		//   this.activeNames.push(item.schoolCode)
					// 		// }
					// 	}
					// })
					res.data.map(item => {
						this.detailsPageList.push({
							schoolCode: item.schoolCode,
							schoolName: item.schoolName,
							subjects: item.requireList,
							isExpand: false,
						})
						this.detailsList.push({
							schoolCode: item.schoolCode,
							schoolName: item.schoolName,
							subjects: item.requireList,
							isExpand: false,
						})
					})
					//console.log(this.detailsPageList);
					//this.setExportData();
					this.scrollDisabled = true; // 单院校查询只有一条数据，因此不需要无限滚动获取数据
					//console.log(this.detailsList);
					//this.detailsTotal = res.count;
					this.detailsTabaleLoading = false;
				})
			},
			getList3() { //明细查询
				if (this.subjectIndex == this.historyIndex) { //判断是否重复点击同一行明细，如果是则不需要重复获取数据
					return;
				}
				this.historyIndex = this.subjectIndex;
				this.detailsTabaleLoading = true;
				this.detailsList = [];
				let listQuery = {
					subjectName: this.schoolSearchInfo.subjectInfo.subjectName,
					fsrShort: this.schoolSearchInfo.subjectInfo.firstSelect,
					ssrShort: this.schoolSearchInfo.subjectInfo.ssrShort || '',
					limit: 999
				};
				subjectSelect.getMemberList4(listQuery).then(res => {
					//console.log(res);
					res.data.map(item => {
						//遍历将相同学校不同专业添加至subjects数组，最后添加至此学校
						let subjects = [];
						res.data.map(u => { //学校相同则添加进专业数组
							if (u.schoolCode == item.schoolCode) {
								subjects.push(u);
							}
						})
						//判断detailsList.school中是否存在相同学校，如不存在则添加
						if (this.detailsList.findIndex(u => {
								return u.schoolCode == item.schoolCode
							}) == -1) {
							this.detailsList.push({
								schoolCode: item.schoolCode,
								schoolName: item.schoolName,
								subjects: subjects,
								isExpand: false,
							})
						}
					})
					this.getDetailsPageList();
					//this.setExportData();
					//this.detailsList = res.data;
					//console.log(this.detailsList);
					//this.detailsTotal = res.count;
					this.detailsTabaleLoading = false;
				})
			},

		}
	}
</script>
<style lang="scss">
	.schoolSearch {
		.content {
			.selects {
				.select {
					.el-input__inner {
						border-color: #646464;
						font-weight: bold;
						font-size: 0.7vw;
					}

					.el-input__inner::placeholder {
						color: #646464;
					}
				}
			}

		}
	}

	.el-select .el-input .el-select__caret {
		color: #646464;
	}
</style>

<style scoped lang="scss">
	.schoolSearch {
		min-height: 85vh;

		.pageHeader {
			width: calc(100% - 10vw);
			height: 100px;
			line-height: 100px;
			background-color: #fff;
			padding: 0 5vw;
		}

		.content {
			margin: 50px auto;
			padding-bottom: 20px;
			width: 80%;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			text-align: left;

			.title {
				margin-bottom: 10px;
				background-color: #4d6ffb;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				text-align: left;
				color: #fff;
				font-size: 1vw;
				padding: 10px;

				.title-content {
					display: flex;
					justify-content: space-between;
				}

				p {
					font-size: 0.9vw;
					margin-top: 10px;
				}
			}

			.selects {
				display: flex;
				justify-content: flex-end;
				margin-bottom: 20px;
				align-items: center;

				.select {
					margin: 0 10px;
				}
			}

			// .title-content {
			// 	display: flex;
			// 	justify-content: space-between;
			// 	border-radius: 0;
			// 	.selects {
			// 		display: flex;

			// 		.select {
			// 			margin: 0 10px;
			// 		}
			// 	}
			// }

			span {
				color: red;
			}

			.infinite-scroll {
				margin-bottom: 20px;
				max-height: 550px;
				overflow-y: auto;
			}
		}

	}
</style>
<template>
	<div class="system2">
		<el-page-header class="pageHeader" @back="$router.go(-1)" content="新高考选科查询">
		</el-page-header>
		<div class="content">
			<span>专业名称关键字：</span>
			<el-input type="text" v-model="subjectName" placeholder="请输入专业名称" style="margin-right: 20px;"
				@keyup.enter.native="subjectSearch"></el-input>
			<el-button type="primary" @click="subjectSearch">查询</el-button>
			<el-button type="primary" @click="$router.push('/comprehensiveevaluation')">进入综合评定</el-button>
		</div>
		<el-dialog title="新高考普通高校本科招生专业选考科目要求 (3+1+2模式)" :visible.sync="dialogVisible" width="80%" top="6vh">
			<div class="dialog-content-title">
				<h4 style="text-align: left;margin-bottom: 10px;">招生专业“<span
						style="color: red;">{{ subjectName }}</span>”的学校共<span style="color: red;">{{ total }}</span>所</h4>
				<el-button type="default" size="small" @click="addMember" :disabled="btnLoading">
					<i class="el-icon-loading" v-show="btnLoading"></i>结果添加到综合评定报告
				</el-button>
			</div>
			<el-table ref="list" :data="subjectList" style="width: 100%" height="500" v-loading="tableLoading" border>
				<el-table-column type="expand" width="1">
					<template slot-scope="props">
						<el-table :data="props.row.subjects" style="width: 100%">
							<el-table-column label="专业代码" prop="subjectCode"></el-table-column>
							<el-table-column label="专业名称" prop="subjectName"></el-table-column>
							<el-table-column label="考试科目要求" prop="selectRequire"></el-table-column>
							<el-table-column label="包含专业" prop="includeSubject"></el-table-column>
							<el-table-column label="招考方向" prop="receiveDirection"></el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column label="点击查看详情" width="110">
					<template slot-scope="scope">
						<p v-if="!scope.row.isExpand" style="cursor: pointer;" @click="rowExpand(scope.row, true)">点击展开
						</p>
						<p v-else style="cursor: pointer;" @click="rowExpand(scope.row, false)">
							点击收起
						</p>
					</template>
				</el-table-column>
				<el-table-column label="院校代码" prop="schoolCode">
					<template slot-scope="scope">
						<p style="font-weight: bold;">{{ scope.row.schoolCode }}</p>
					</template>
				</el-table-column>
				<el-table-column label="院校名称" prop="schoolName">
					<template slot-scope="scope">
						<p style="font-weight: bold;">{{ scope.row.schoolName }}</p>
					</template>
				</el-table-column>
				<el-table-column label="首选科目要求" prop="schoolName">
					<template slot-scope="scope">
						<p style="font-weight: bold;">{{ scope.row.fsrShort }}</p>
					</template>
				</el-table-column>
				<el-table-column label="再选科目要求" prop="schoolName">
					<template slot-scope="scope">
						<p style="font-weight: bold;">{{ scope.row.ssrShort }}</p>
					</template>
				</el-table-column>
			</el-table>

		</el-dialog>


	</div>
</template>

<script>
import {
	mapState
} from "vuex"
import * as subjectselectrequires from '@/api/subjectselectrequires'
import * as subjectselectsearchhistorys from '@/api/subjectselectsearchhistorys'
export default {
	data() {
		return {
			dialogVisible: false,
			subjectList: [],
			total: 0,
			windowWidth: document.documentElement.clientWidth,
			subjectName: '',
			listQuery: {
				subjectName: '',
				limit: 10,
				page: 1
			},
			btnLoading: false,
			tableLoading: true,
		}
	},
	computed: {
		...mapState(["subjectData",]),
	},
	methods: {
		rowExpand(row, expand) {
			this.subjectList.find(u => {
				return u.schoolCode == row.schoolCode
			}).isExpand = expand;
			this.$refs.list.toggleRowExpansion(row, expand);
		},
		subjectSearch() {
			if (this.subjectName == '') {
				this.$message({
					message: '请先输入需要查询的专业名称',
					type: 'error',
					duration: 3000
				})
				return;
			}
			this.btnLoading = true;
			this.dialogVisible = true;
			this.tableLoading = true;
			this.subjectList = [];
			let listQuery = {
				subjectName: this.subjectName,
				limit: 999
			};
			subjectselectrequires.getMemberList3(listQuery).then(res => {
				console.log(res);
				res.data.map(item => {
					//遍历将相同学校不同专业添加至subjcets数组，最后添加至此学校
					let subjcets = [];
					res.data.map(u => { //学校相同则添加进专业数组
						if (u.schoolCode == item.schoolCode) {
							subjcets.push(u);
						}
					})
					//判断subjectList.school中是否存在相同学校，如不存在则添加
					if (this.subjectList.findIndex(u => {
						return u.schoolCode == item.schoolCode
					}) == -1) {
						this.subjectList.push({
							schoolCode: item.schoolCode,
							schoolName: item.schoolName,
							fsrShort: item.fsrShort,
							ssrShort: item.ssrShort,
							subjects: subjcets,
							isExpand: false
						})
					}
				})
				console.log(this.subjectList);
				this.total = res.count;
				this.tableLoading = false;
				this.btnLoading = false;
			})

		},
		addMember() {
			//let listQuery = {
			//	subjectName:this.subjectName
			//}
			this.btnLoading = true;
			var subjectName = this.subjectName;
			console.log('subjectName=' + subjectName)
			let query = {
				subjectTestId: this.subjectData.id,
				subjectName: subjectName
			}
			subjectselectsearchhistorys.memberAdd(query).then(res => {
				console.log(res);
				if (res.code == 200) {
					this.$message({
						message: '添加成功',
						type: 'success',
						duration: 3000
					})
				}
				this.btnLoading = false;
			})
		},
		getWindowWidth() {
			let that = this;
			window.onresize = () => {
				return (() => {
					that.windowWidth = document.documentElement.clientWidth;
				})()

			};
		},
	}
}
</script>
<!-- 表格边框样式 -->
<style lang="scss">
.el-table--border,
.el-table--group {
	border: 1px solid #969696 !important;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
	border-bottom: 1px solid #969696 !important;
}

.el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
	border-right: 1px solid #969696 !important;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
	background-color: #c8c8c8 !important;
}

.el-table__expand-icon {
	display: none !important;
}
</style>
<style scoped lang="scss">
.system2 {
	min-height: 85vh;

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.content {
		margin: 50px auto;
		padding: 20px;
		width: 50%;
		background-color: #fff;
		display: flex;
		align-items: center;

		span {
			min-width: 8vw;
			font-size: 1vw;
		}

	}

	.dialog-content-title {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	@media screen and (max-width: 500px) {
		.content {
			width: 80%;
		}
	}
}
</style>

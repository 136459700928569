<template>
	<div id="analysisSystem3" style="background:#f5f5f5;">
		<!-- <div class="top">
				<h3>新高考选科查询</h3>
				<button @click="back">
					<i>&lt;&nbsp;</i>返回
				</button>
			</div> -->
		<el-page-header class="pageHeader" @back="dialogVisible ? dialogVisible = false : $router.go(-1)">
		</el-page-header>
		<componentsSystem :gategoryList="gategoryList" :subjectClassList="subjectClassList" :times="'本科'"></componentsSystem>
		<div class="warpper" v-if="!dialogVisible">
			<p style="margin-top: 50px;text-align: center;">
				查询方法说明：<span style="color: red;">可单选一种或同选多种方法查询，系统会给出相应的分析报告</span>
			</p>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>按省市查询</h4>
				</div>

				<el-radio-group v-model="listQuery.province" size="medium">
					<el-radio-button v-for="item in provincearr" :key="item.code"
						:label="item.code">{{ item.address }}</el-radio-button>
					<el-radio-button label="" style="display: block;">取消此查询操作</el-radio-button>
				</el-radio-group>

			</div>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>按老高考招生批次查询</h4>
				</div>

				<el-radio-group v-model="listQuery.diC_OldTimes" size="medium">
					<el-radio-button v-for="item in oldTimes" :key="item.id"
						:label="item.dtValue">{{ item.name }}</el-radio-button>
					<el-radio-button label="" style="display: block;">取消此查询操作</el-radio-button>
				</el-radio-group>

			</div>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>按类别查询</h4>
				</div>

				<el-radio-group v-model="listQuery.diC_SchoolClass" size="medium">
					<el-radio-button v-for="item in schoolClassList" :key="item.id"
						:label="item.dtValue">{{ item.name }}</el-radio-button>
					<el-radio-button label="" style="display: block;">取消此查询操作</el-radio-button>
				</el-radio-group>
			</div>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>按概率和招生计划数组合查询</h4>
				</div>
				<div class="inputWrap">
					<div class="input">
						<span class="demonstration">专业组投档概率</span>
						<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
						</el-date-picker> -->
						<el-select v-model="listQuery.probability" placeholder="投档概率" @change="groupChanceSelect">
							<el-option v-for="item in probabilitys" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="input">
						<span class="demonstration">专业录取概率</span>
						<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
						</el-date-picker> -->
						<el-select v-model="listQuery.admProbability" placeholder="录取概率" @change="chanceSelect">
							<el-option v-for="item in probabilitys" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="input">
						<span class="demonstration">专业招生计划数</span>
						<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
						</el-date-picker> -->
						<el-input v-model="listQuery.minPlans" placeholder="请输入计划数"></el-input>
						<span class="demonstration">以上</span>
					</div>
				</div>

				<!-- <el-radio-group v-model="listQuery.subject" size="medium">
					<el-radio-button label="70%以下"></el-radio-button>
					<el-radio-button label="70%-85%"></el-radio-button>
					<el-radio-button label="85%-95%"></el-radio-button>
					<el-radio-button label="40%"></el-radio-button>
					<el-radio-button label="45%"></el-radio-button>
					<el-radio-button label="50%"></el-radio-button>
					<el-radio-button label="55%"></el-radio-button>
					<el-radio-button label="60%"></el-radio-button>
					<el-radio-button label="65%"></el-radio-button>
					<el-radio-button label="70%"></el-radio-button>
					<el-radio-button label="75%"></el-radio-button>
					<el-radio-button label="80%"></el-radio-button>
					<el-radio-button label="85%"></el-radio-button>
					<el-radio-button label="90%"></el-radio-button>
					<el-radio-button label="95%"></el-radio-button>
				</el-radio-group> -->
				<div class="content-btn">
					<el-button type="primary" @click="clearSelect" size="small">取消此查询操作</el-button>
				</div>

			</div>
			<div class="content">
				<div class="content-btn">
					<el-button type="primary" @click="search()"
						:disabled="!analysisData.syS_Years || !analysisData.subjectName" size="small">查询</el-button>
				</div>
			</div>
		</div>
		<div class="report" v-else>
			<div class="title">
				<img src="../../assets/title-line.jpg" />
				<h4>分析报告</h4>
			</div>
			<div class="reportText">
				<p><span>提示：如果学校专业组是有这个招生专业的，但查询结果中没出现，那是因为您输入专业名称时输入了标准名称，有些学校专业组的招生专业名称与此不同，
						系统就没法调出来了，应尽量使用模糊查询，如“土木工程”，输入“土木”即可。</span></p>
			</div>
			<el-table :data="list" style="width: calc(100% - 40px);margin: 10px 20px;font-weight: bold;color: #303030;"
				border v-loading="listLoading" :header-cell-style="headerStyle" :cell-style="cellStyle">
				<el-table-column prop="provinceCity" label="省市" show-overflow-tooltip min-width="70px"></el-table-column>
				<el-table-column prop="schoolName" label="院校名称" show-overflow-tooltip min-width="110px">
					<template slot-scope="scope">
						<p style="cursor: pointer;" @click="showSystem2(scope.row)">{{scope.row.schoolName}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="schoolCode" label="院校代码" show-overflow-tooltip min-width="65px">
					<template slot="header">
						<p>院校</p>
						<p>代码</p>
					</template>
				</el-table-column>
				<el-table-column prop="subjectGroup" label="专业组" show-overflow-tooltip min-width="70px">
					<template slot="header">
						<p>专业组</p>
						<p>代码</p>
					</template>
					<template slot-scope="scope">
						<span style="color: red;">{{ scope.row.subjectGroup }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="ssrShort" label="再选科目" show-overflow-tooltip min-width="80px">
					<template slot="header">
						<p>再选</p>
						<p>科目</p>
					</template>
				</el-table-column>
				<el-table-column prop="groupPlans" label="院校2024计划数" show-overflow-tooltip min-width="70px">
					<template slot="header">
						<p>{{ analysisData.syS_Years }}</p>
						<p>计划数</p>
					</template>
				</el-table-column>
				<el-table-column prop="groupChance" label="投档概率" min-width="70px">
					<template slot="header">
						<p>投档</p>
						<p>概率</p>
					</template>
					<template slot-scope="scope">
						<span style="color: red;">{{ scope.row.groupChance== '30'?'建议等待征集':scope.row.groupChance }}%</span>
					</template>
				</el-table-column>
				<el-table-column prop="diC_OldTimes" label="老高考招生批次" show-overflow-tooltip width="95px">
					<template slot="header">
						<p>老高考</p>
						<p>招生批次</p>
					</template>
					<!-- <template slot-scope="scope">
						<p>{{scope.row.diC_OldTimes?scope.row.diC_OldTimes:'-'}}</p>
					</template> -->
				</el-table-column>
				<el-table-column prop="diC_SchoolClass" label="院校实力评价" show-overflow-tooltip width="90px">
					<template slot="header">
						<p>院校</p>
						<p>实力评价</p>
					</template>
					<template slot-scope="scope">
						<p style="color: red;font-weight: bold;">{{ scope.row.schoolRanking }}</p>
						<p style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;" v-for="item in scope.row.diC_SchoolClass" :key="item">{{ item }}</p>
					</template>
				</el-table-column>
				<el-table-column prop="subjectName" label="专业" show-overflow-tooltip min-width="150px">
					<template slot="header">
						<p>专业代码</p>
						<p>专业名称</p>
					</template>
					<template slot-scope="scope">
						<p>{{ scope.row.subjectCode }}</p>
						<p style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{ scope.row.subjectName }}</p>
					</template>
				</el-table-column>
				<el-table-column prop="plans" label="专业2024计划数" min-width="70px">
					<template slot="header">
						<p>{{ analysisData.syS_Years }}</p>
						<p>计划数</p>
					</template>
				</el-table-column>
				<el-table-column prop="chance" label="录取概率" min-width="70px">
					<template slot="header">
						<p>录取</p>
						<p>概率</p>
					</template>
					<template slot-scope="scope">
						<span style="color: red;">{{ scope.row.chance=='30%'?'建议等待征集':scope.row.chance }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="diC_SubjectClass" label="专业实力评价" show-overflow-tooltip min-width="60px">
					<template slot="header">
						<p>专业</p>
						<p>实力</p>
					</template>
					<template slot-scope="scope">
						<p style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{ scope.row.diC_SubjectClass ? scope.row.diC_SubjectClass : '-' }}</p>
					</template>
				</el-table-column>
				<el-table-column label="添加进收藏夹" width="60px">
					<template slot="header">
						<p>添加</p>
						<p>收藏</p>
					</template>
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="addCollect(scope.row)">添加</el-link>
					</template>
				</el-table-column>
			</el-table>

			<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
				@pagination="handleCurrentChange" />
		</div>
	</div>
</template>
<script>
import componentsSystem from "@/components/componentsSystem";
import * as subjectcollects from '@/api/subjectcollects'
import Pagination from "@/components/Pagination";
import {
	mapState,
} from "vuex"
import * as schoolsubjects from "@/api/schoolsubjects";
import * as categorys from "@/api/categorys";
import * as cityData from '@/components/cityData';
//import * as MergeCol from '@/components/mergeCol'
//import AuthSelect from '@/components/AuthSelect.vue'
export default {
	components: {
		//AuthSelect,
		Pagination,
		componentsSystem,
	},
	data() {
		return {
			oldTimes: [],
			subjectClassList: [],
			searchState: false,
			analysisData: {},
			listQuery: {
				page: 1,
				limit: 8,
				province: '',
				diC_SchoolClass: '',
			},
			total: 0,
			probabilitys: [{
				label: '70%以下',
				value: '0-70'
			},
			{
				label: '75%-85%',
				value: '75-85'
			},
			{
				label: '90%-95%',
				value: '90-95'
			},
			{
				label: '40%',
				value: '40'
			},
			{
				label: '45%',
				value: '45'
			},
			{
				label: '50%',
				value: '50'
			},
			{
				label: '55%',
				value: '55'
			},
			{
				label: '60%',
				value: '60'
			},
			{
				label: '62%',
				value: '62'
			},
			{
				label: '65%',
				value: '65'
			},
			{
				label: '70%',
				value: '70'
			},
			{
				label: '75%',
				value: '75'
			},
			{
				label: '80%',
				value: '80'
			},
			{
				label: '85%',
				value: '85'
			},
			{
				label: '90%',
				value: '90'
			},
			{
				label: '95%',
				value: '95'
			},
			{
				label: '取消此查询操作',
				value: '0-100'
			},
			],
			cellStyle: {
				'text-align': 'center',
			},
			listLoading: true,
			headerStyle: {
				fontWeight: 'bold',
				color: '#303030',
				'text-align': 'center',
			},
			list: [],
			dialogVisible: false,
			provincearr: [],
			// subjectList: [
			// 	{
			// 		value:'70%以下',
			// 		value:'70%以下',
			// 	},

			// ],
			schoolClassList: [],
			gategoryList: [],
		}
	},
	computed: {
		...mapState(["userInfo", "systemData3"]),
	},
	activated() {
		if (Object.keys(this.systemData3) == 0) {
			this.analysisData = JSON.parse(sessionStorage.getItem('analysisData3'));
			this.analysisData.dialogVisible=false;
		} else {
			this.analysisData = this.systemData3;
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem('analysisData3', JSON.stringify(this.analysisData));
			})
		}
		this.dialogVisible = this.analysisData.dialogVisible?true:false;
		console.log(this.dialogVisible,this.analysisData);
		this.provincearr = cityData.getAreaList();
		this.getGategorys();
		this.getSchoolClassList();
	},
	methods: {
		showSystem2(row) {
			let temp = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times == '-' ? '本科' : this.analysisData.syS_Times,
				province: row.province,
				schoolCode: row.schoolCode,
				subjectGroup: row.subjectGroup
			}
			this.$store.commit('setAnalysisData2', temp)
			this.$store.commit('setSearchStatus', true)
			this.$router.push('/analysisSystem2')
		},
		clearSelect() {
			this.listQuery.probability = '0-100';
			this.listQuery.admProbability = '0-100';
			this.listQuery.minPlans = '';
			this.chanceSelect('0-100');
			this.groupChanceSelect('0-100');
		},
		//添加收藏夹
		addCollect(row) {
			let query = {
				subjectName: row.subjectName, // 专业
				schoolCode: row.schoolCode, // 院校代码
				provinceCity: row.provinceCity, // 省市
				schoolName: row.schoolName, // 院校名称
				diC_SchoolClass: [], // 院校类型
				schoolPlans: row.groupPlans, // 招生
				syS_FirstSelectCourse: this.userInfo.syS_FirstSelectCourse, // 首选科目
				subjectGroup: row.subjectGroup, // 专业组
				scores: this.userInfo.score, // 分数
				syS_Times: this.analysisData.syS_Times, // 批次
				syS_Years: this.analysisData.syS_Years, // 年份
				diC_SubjectClass: [], // 专业实力评估
				subjectPlans: row.plans, // 专业招生
				schoolChanceStr: row.groupChance + '%'=='30%'?'建议等待征集':row.groupChance + '%', // 录取概率
				subjectChanceStr: row.chance=='30%'?'建议等待征集':row.chance, // 投档概率
			}
			if (Array.isArray(row.diC_SchoolClass)) {
				row.diC_SchoolClass.map(u => {
					query.diC_SchoolClass.push(u);
				})
			} else {
				query.diC_SchoolClass.push(row.diC_SchoolClass);
			}
			if (Array.isArray(row.diC_SubjectClass)) {
				row.diC_SubjectClass.map(u => {
					query.diC_SubjectClass.push(u);
				})
			} else {
				query.diC_SubjectClass.push(row.diC_SubjectClass);
			}
			subjectcollects.memberAdd(query).then(res => {
				console.log(res);
				if (res.code == 200) {
					this.$message({
						message: '添加成功',
						type: 'success'
					})
				}
			})
		},
		chanceSelect(v) {
			//console.log(v);
			if (v.indexOf('-') !== -1) {
				let t = v.split('-');
				this.listQuery.minChance = t[0];
				this.listQuery.maxChance = t[1];
			} else {
				this.listQuery.minChance = v;
				this.listQuery.maxChance = v;
			}
		},
		groupChanceSelect(v) {
			if (v.indexOf('-') !== -1) {
				let t = v.split('-');
				this.listQuery.minGroupChance = t[0];
				this.listQuery.maxGroupChance = t[1];
			} else {
				this.listQuery.minGroupChance = v;
				this.listQuery.maxGroupChance = v;
			}
		},
		search(){
			console.log(this.analysisData)
			this.listQuery.page = 1;
			this.listQuery.limit = 8;
			this.getList();
			this.dialogVisible = true;
			this.analysisData.dialogVisible = true;
		},
		getList() {
			this.list = [];
			this.listLoading = true;
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: this.analysisData.syS_Times,
				subjectName: this.analysisData.subjectName,
				province: this.listQuery.province,
				diC_OldTimes: this.listQuery.diC_OldTimes ? this.listQuery.diC_OldTimes : '',
				diC_SchoolClass: this.listQuery.diC_SchoolClass,
				minPlans: this.listQuery.minPlans ? Number(this.listQuery.minPlans) : 0,
				minChance: this.listQuery.minChance ? this.listQuery.minChance : '0',
				maxChance: this.listQuery.maxChance ? this.listQuery.maxChance : '100',
				minGroupChance: this.listQuery.minGroupChance ? this.listQuery.minGroupChance : '0',
				maxGroupChance: this.listQuery.maxGroupChance ? this.listQuery.maxGroupChance : '100',
				page: this.listQuery.page,
				limit: this.listQuery.limit,
			}
			schoolsubjects.loadChanceBySubjectName(listQuery).then(res => {
				console.log(res);
				res.data.map(o => {
					//o.diC_OldTimes = this.getGategoryName(o.diC_OldTimes, 'DiC_OldTimes');
					//o.diC_SchoolClass = this.getGategoryName(o.diC_SchoolClass, 'DiC_SchoolClass');
					if (o.diC_OldTimes) {
						let temp = o.diC_OldTimes.split(',');
						let tempName = [];
						for (let i in temp) {
							temp[i] = this.oldTimes.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								tempName.push(temp[i].name)
							}
						}
						if (tempName.length == 0) {
							tempName = '-';
						}
						o.diC_OldTimes = tempName;
					}
					if (o.diC_SchoolClass) {
						let temp = o.diC_SchoolClass.split(',');
						let tempName = [];
						for (let i in temp) {
							temp[i] = this.schoolClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								if (temp[i].name == '211工程大学' || temp[i].name == '985工程大学' || temp[i]
									.name == '一流学科大学' || temp[i].name == '一流大学A' || temp[i].name ==
									'一流大学B' || temp[i].name == '双非大学' || temp[i].name == '民办院校'
								) {

									tempName.push(temp[i].name)
								}
							}
						}
						if (tempName.length == 0) {
							tempName = '-';
						}
						o.diC_SchoolClass = tempName;
					}
					if (o.diC_SubjectClass) {
						let temp = o.diC_SubjectClass.split(',');
						for (let i in temp) {
							temp[i] = this.subjectClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								temp[i] = temp[i].name
							}
						}
						o.diC_SubjectClass = temp.join(',');
					}
					o.chance = o.chance + '%';
					this.list.push(o);
				})
				//this.list = res.data;
				this.listLoading = false;
				this.total = res.count;
			})
		},
		handleCurrentChange(val) {
			//console.log(val);
			this.listQuery.page = val.page;
			this.listQuery.limit = val.limit;
			this.getList();
		},
		//合并单元格
		objectSpanMethod({
			row,
			column,
			rowIndex,
			columnIndex
		}, array) {
			console.log(row, column, rowIndex, columnIndex);
			for (let i = 0; i < array.length; i++) {
				if (columnIndex === array[i]) {
					if (rowIndex % 3 === 0) {
						return {
							rowspan: 3,
							colspan: 1
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0
						};
					}
				}
			}
		},
		getSchoolClassList() {
			let listQuery = {
				page: 1,
				limit: 999,
				typeId: 'DIC_SchoolClass'
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.schoolClassList = res.data;
				//console.log(this.schoolClassList);
			});
			listQuery.typeId = 'DIC_SubjectClass'
			categorys.getList(listQuery).then((res) => {
				this.subjectClassList = res.data;
				//console.log(this.schoolClassList);
			});
			listQuery.typeId = 'diC_OldTimes'
			this.oldTimes = [];
			categorys.getList(listQuery).then((res) => {
				res.data.map(u => {
					if (u.dtValue != '05') {
						this.oldTimes.push(u);
					}
				})
				//console.log(this.schoolClassList);
			});
		},
		getGategorys() {
			let listQuery = {
				page: 1,
				limit: 9999,
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.gategoryList = res.data;
				//console.log(this.gategoryList);
				//for(let key in this.gategoryList){
				//    console.log(this.gategoryList[key])
				//}
				//console.log('getcategorys3')
			});
		},
		getGategoryName(val, columnName) {
			var dicobject = this.gategoryList.find(
				(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
			);
			//var dicobject = this.gategoryList.find(t => {
			//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
			//    return t
			//  }
			//});

			if (!dicobject) {
				return "";
			} else {
				return dicobject.name;
			}
		},
	},

}
</script>

<style lang="scss" scoped>
#analysisSystem3 {
	min-height: 85vh;


	::-webkit-input-placeholder {
		font-weight: bold;
		color: #646464;
	}

	:-moz-placeholder {
		font-weight: bold;
		color: #646464;
	}

	::-moz-placeholder {
		font-weight: bold;
		color: #646464;
	}

	:-ms-input-placeholder {
		font-weight: bold;
		color: #646464;
	}

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.top {
		padding: 0 100px;
		width: calc(100% - 200px);
		display: flex;

		h3 {
			flex: 1;
			text-align: left;
		}

		button {
			flex: 1;
			border: none;
			outline: none;
			background: none;
			text-align: right;
			opacity: .8;
			font-size: 1em;
			cursor: pointer;
		}
	}

	.content {
		margin: 50px auto;
		padding-bottom: 20px;
		width: 80%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		text-align: left;

		.inputWrap {
			margin: 0 auto;
			padding: 0 20px;
			display: flex;
			flex-wrap: wrap;

			.input {
				margin: 0 10px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: nowrap;
			}

		}

		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;

			h4 {
				text-align: left;
				color: #fff;
				font-size: 1.2vw;
				padding: 0.5vw 0vw 0.5vw 2.5vw;
			}

			img {
				float: left;
				margin-left: 1vw;
				margin-top: 0.7vw;
				height: 1.5vw;
			}
		}

		.inputWrap {
			margin: 0 auto;
			padding: 0 20px;
			display: flex;
			flex-wrap: wrap;

			.input {
				margin: 0 10px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: nowrap;

				.demonstration {
					margin: 0 5px;
					font-weight: bold;
				}
			}

			// .input:nth-child(1),
			// .input:nth-child(3){
			// 	text-align: left;
			// }
			// .input:nth-child(2),
			// .input:nth-child(4){
			// 	text-align: right;
			// }
		}

		.content-btn {
			text-align: center;

			.el-button {
				margin: 2vw;
			}
		}


		.tips {
			span {
				font-size: 0.8vw;
				font-weight: normal;
				color: #959595;
				margin: 0 2vw;

				i {
					color: #d30f11;
					margin-right: 0.5vw;
				}
			}
		}
	}

	.report {
		background-color: #fff;
		margin: 50px auto;
		width: 90%;
		padding: 10px;

		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			border-radius: 10px;

			h4 {
				text-align: left;
				color: #fff;
				font-size: 1.2vw;
				padding: 0.5vw 0vw 0.5vw 2.5vw;
			}

			img {
				float: left;
				margin-left: 1vw;
				margin-top: 0.7vw;
				height: 1.5vw;
			}
		}
	}

	.reportText {
		width: 95%;
		margin: 0 auto;

		p {
			font-size: 1.5em;
			margin: 5px 0;
			text-align: left;
		}

		span {
			color: red;
		}
	}
}
</style>
<style rel="stylesheet/scss" lang="scss">
#analysisSystem3 {
	.el-input__inner {
		border: 2px solid #4d6ffb !important;
		border-radius: 8px !important;
		font-size: 0.9em !important;
	}

	.el-radio-group {
		font-size: 0;
		float: none;
		width: 95%;
		margin: 0 auto;
	}

	.el-radio-button {
		margin: 1vw 0.5vw;

		.el-radio-button:first-child .el-radio-button__inner {
			border-left: 0.2vw solid #4d6ffb;
			border-radius: 6vw;
			box-shadow: none !important;
		}

		.el-radio-button__inner {
			background: #FFF;
			border: 0.1vw solid #4d6ffb !important;
			font-weight: normal;
			color: #4d6cfb;
			padding: 0.2vw 1vw;
			font-size: 1vw;
			border-radius: 6vw !important;
		}

	}

	.el-checkbox-group {
		float: left;
		width: 95%;
		margin: 0 auto;
	}

	.el-checkbox__label {
		padding: 0.2vw 1vw !important;
		font-size: 1vw !important;
		color: #4d6cfb;
		font-weight: normal !important;
		border: 0.1vw solid #4d6ffb !important;
		border-radius: 6vw !important;
	}

	.el-button--primary {
		background-color: #4d6ffb !important;
		border-color: #4d6ffb !important;
		font-size: 1.2em !important;
		font-weight: bold !important;
		border-radius: 25px !important;
		padding: 10px 25px !important;
	}

	.el-radio-button__orig-radio:checked+.el-radio-button__inner {
		background-color: #4d6ffb !important;
		color: #FFF;
	}

	.el-checkbox__input.is-checked+.el-checkbox__label {
		color: #ffffff !important;
		background: #4d6ffb;
	}

	.el-dialog {
		border-radius: 0.5vw;
	}

	.el-dialog__body {
		padding: 2vw;
		color: #606266;
		font-size: 1.2em;
		word-break: break-all;
	}

	.el-dialog__header {
		padding: 1vw 1vw;
		font-size: 1.4em;
		background: #4d6ffb;
		border-radius: 0.5vw;
	}

	.el-dialog__title {
		line-height: 24px;
		font-size: 1em;
		font-weight: bold;
		color: #ffffff;
	}

	.el-dialog__headerbtn {
		font-size: 1.2em;
	}

	.el-dialog__headerbtn .el-dialog__close {
		color: #fff;
	}

	button.el-button.button.el-button--primary {
		width: 22vw;
		margin: 1vw auto;
		background: #fff !important;
		border: 0.2vw solid;
		color: #4d6ffb;
	}

	button.el-button.qx.el-button--primary {
		padding: 12px 30px !important;
		font-size: 1.2em !important;
		margin-top: 30px;
	}

	.tc .el-radio-button {
		margin: 10px auto;
	}

	.zy .el-radio-button__inner {
		margin: 0 auto;
		width: 30vw;
		font-size: 2vw;
		padding: 15px 10px;
	}
}
</style>
<template>
	<div id="header">
		<div class="top_bg">
			<div class="logo">
				<p>
					您好！您是<em>{{ userInfo.syS_CourseGroup }}</em>组合<em>{{ getGategoryName(userInfo.syS_Nation, 'SYS_Nation') }}</em>考生，区外高校投档总分<em>{{ Number(userInfo.score)
						+ Number(userInfo.scoreAdd)}}</em>，
					区内高校投档总分<em>{{ Number(userInfo.score) + Number(userInfo.scoreAddGX) }}</em>
				</p>
			</div>
			<div class="logo_title">
				<el-link :underline="false" @click="changeScoreVisible = true">修改分数</el-link>
				<el-link :underline="false" @click="loginout">退出系统</el-link>
			</div>
		</div>
		<saveScoreDialog :visible="changeScoreVisible" @visible="getVisible" :userInfo="userInfo"></saveScoreDialog>
	</div>
</template>

<script>
import {
	mapState,
} from "vuex"
import * as categorys from '@/api/categorys'
import {
	removeToken
} from '../api/auth.js'
import saveScoreDialog from '@/components/saveScoreDialog'
export default {
	name: 'common-header',
	components: {
		saveScoreDialog
	},
	data() {
		return {
			gategoryList: [],
			changeScoreVisible: false,
		}
	},
	created() {
		this.getGategorys();
	},
	watch: {
		userInfo(nv) {
			this.resForm = Object.assign({}, nv);
		}
	},
	computed: {
		...mapState(["userInfo",]),
	},
	methods: {
		getVisible(val) {
			this.changeScoreVisible = val;
		},
		loginout() {
			//location.reload()
			removeToken();
			localStorage.clear()
			window.sessionStorage.clear()
			this.$message({
				message: '退出成功',
				type: 'success',
				duration: 2000
			})
			this.$router.push('/login')
		},
		getGategorys() {
			let listQuery = {
				page: 1,
				limit: 9999
			};
			console.log('getcategorys')
			categorys.getList(listQuery).then(res => {
				this.gategoryList = res.data
				console.log('getcategorys2')
				//for(let key in this.gategoryList){
				//    console.log(this.gategoryList[key])
				//}
				console.log('getcategorys3')
			})
		},
		getGategoryName(val, columnName) {
			//console.log(val,columnName);
			//console.log(this.gategoryList);
			var dicobject = this.gategoryList.find(t => t.typeId.toUpperCase() == columnName.toUpperCase() && t
				.dtValue == val)
			//var dicobject = this.gategoryList.find(t => { 
			//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) { 
			//    return t
			//  } 
			//});
			//console.log(dicobject);
			if (!dicobject) {
				return ''
			} else {
				return dicobject.name
			}

		},
	},
}
</script>
<style scoped lang="scss">
.top_bg {
	background: url(../assets/logo_bg.jpg) no-repeat;
	padding: 10px 20px;
	width: calc(100% - 40px);
	min-height: 60px;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.logo {
		font-size: 1.35vw;

		p {
			color: #fff;

			em {
				color: #ff0000;
				font-style: normal;
			}
		}

		img {
			height: 80%;
			margin: 0.5vw 0;
		}

		h2 {
			font-size: 2vw;
			margin-left: 1vw;
			color: #fff;
		}
	}

	.logo_title {
		color: #feca32;
		font-size: 1.2vw;
		display: flex;

		.el-link {
			margin: 0 10px;
			color: #fff;
			font-size: 1vw;
			font-weight: bold;
		}

		.el-link:hover {}
	}
}
</style>

<template>
	<div class="head_top">
		<el-page-header class="pageHeader" @back="back" :content="windowWidth<800 ? '' :'专业测评'">
		</el-page-header>

		<el-menu :default-active="this.$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect" router
			background-color="#fff" text-color="#333" active-text-color="#0084ff" style="flex:1">
			<el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
				<template slot="title">
					<span> {{ item.navItem }}</span>
				</template>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				navList: [{
						name: '/introduce',
						navItem: '系统说明'
					},
					{
						name: '/major',
						navItem: '兴趣能力与职业测试 '
					},
					{
						name: '/character',
						navItem: '性格测试'
					},
					{
						name: '/preliminary',
						navItem: ' 查看初步分析报告'
					},
					{
						name: '/supplement',
						navItem: ' 补充信息'
					},
					{
						name: '/final',
						navItem: ' 查看最终分析报告'
					},
				],
				windowWidth: document.documentElement.clientWidth

			}
		},
		created() {
			this.getWindowWidth()
		},
		methods: {
			getWindowWidth() {
				let that = this;
				window.onresize = () => {
					return (() => {
						that.windowWidth = document.documentElement.clientWidth;
					})()

				};
			},
			handleSelect(key, keyPath) {
				console.log(key, keyPath)
			},
			back() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style>
	.el-menu {
		font-weight: bold !important;
		background-color: #fff0 !important;
	}

	.el-menu.el-menu--horizontal {
		border: none !important;
		/* background: url(../assets/ul_bg.png) no-repeat 0 17px; */
	}

	.el-menu-item {
		font-size: 16px !important;
		padding: 0 5px !important;
		background-color: #fff0 !important;
	}

	.el-menu-item:hover {
		background-color: #4c72f0 !important;
		color: #fff !important;
		border-radius: 5px;
	}

	.el-menu-item.is-active {
		color: #ffffff !important;
		border-radius: 5px;
		font-size: 16px !important;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 36px !important;
		line-height: 35px !important;
		margin: 20px 10px !important;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		background: #4c72f0 !important;
	}
</style>
<style lang="scss" scoped>
	.head_top {
		display: flex;
		width: 100%;
		background-color: #fff;
		position: relative;

		.pageHeader {
			width: 50%;
			height: 100px;
			line-height: 100px;
			background-color: #fff;
			padding: 0 5vw;
		}

		.el-menu-demo {
			position: absolute;
			top: 10px;
			left: 25%;
			width: 75%;
		}

		.img {
			background: #ffffff;
			border-bottom: solid 1px #e6e6e6;

			img {
				height: 50px;
				padding: 10px;
			}
		}
	}
</style>

<template>
	<div class="componextsSystem">
		<div class="links" v-if="times == '本科'">
			<el-link :underline="false" @click="dataDialogVisible=true">系统数据相关说明</el-link>
			<el-link :underline="false" @click="chanceDialogVisible=true">录取概率说明</el-link>
			<!-- <el-link :underline="false" @click="placeDialogVisible=true">院校和专业全国排名说明</el-link> -->
			<el-link :underline="false" @click="subjectDialogVisible=true">查看专业详细介绍和全国所有开设院校及专业实力评价</el-link>
			<el-link :underline="false"
				href="https://gaokao.chsi.com.cn/zsgs/zhangcheng/listVerifedZszc--method-index,lb-1.dhtml"
				target="_blank">查看院校招生章程</el-link>
			<el-link :underline="false" @click="showCollects">查看收藏夹</el-link>
			<p style="margin-top: 50px;text-align: center;" v-if="showExplan">
				查询方法说明：<span style="color: red;">可单选一种或同选多种方法查询，系统会给出相应的分析报告</span>
			</p>
		</div>
		<div class="links" v-else>
			<el-link :underline="false" @click="showCollects">查看收藏夹</el-link>
		</div>

		<el-dialog title="系统数据相关说明" :visible.sync="dataDialogVisible" width="50%">
			本系统采集的数据均为院校在广西第一次投档录取时的数据，
			并不包括院校征集补录、定向等情况的录取数据，因此可能会与校方的总结性数据有细小的差异，
			考生填报志愿时应当参考的就是院校在广西第一次投档录取的数据。“-”表示院校或专业当年未在广西招生。
		</el-dialog>
		<el-dialog title="录取概率说明" :visible.sync="chanceDialogVisible" width="50%">
			录取概率分有12个档次，分别为40%、45%、50%、55%、60%、65%、70%、75%、80%、85%、90%、95%
			，当考生分数不上批次线却选择了该批次时，系统会给出“<span>建议等待征集</span>”，这与广西的招生实际是相符的。
			如果概率为<span>40%</span>，说明考生均未达到<span>院校或专业</span>前三年的录取最低分水平，<span>投档录取可能性很小很小</span>；
			如果概率为<span>75%</span>，说明考生均超过<span>院校或专业</span>前三年的录取最低分水平，<span>投档录取可能性很大</span>，
			如果概率为<span>95%</span>，说明考生均超过<span>院校或专业</span>前三年的录取平均分水平，<span>投档录取可能性非常大</span>。
			本系统给出的概率是由科学的算法计算出来的，已具有很高的参考价值，当然，考生如想获得更准确的分析，
			还要考虑<span>院校改名升级、院校征集情况、专业录取规则、专业招生计划数</span>等因素，
			建议考生向经验丰富的指导老师咨询后再确定是否放弃或保留一个目标学校，并确定最终的志愿填报方案。
		</el-dialog>
		<!-- <el-dialog title="院校和专业全国排名说明" :visible.sync="placeDialogVisible" width="50%">
			学校和专业全国排名数据来源于中国管理科学研究院《中国大学评价》课题组，为目前中国最知名、历史最悠久的大学专业排名榜单，
			全国大学排名只排前600名，关于专业全国排名，“第4名A++/454”表示全国开设该专业的学校总数为454个，该校本专业排名为“第4名A++”，
			B属于中等，B以下包括（c+、c、d+、d、e+、e）属实力弱的专业，等级越低越弱，B以上包括（B+、A、A++）属实力中上专业，等级越高，实力越强。”
		</el-dialog> -->
		<el-dialog title="查看专业详细介绍和全国所有开设院校及专业实力评价" :visible.sync="subjectDialogVisible" width="80%">
			<div class="dialogInput">
				<span class="demonstration">专业名称：</span>
				<el-input v-model="subjectName" placeholder="专业名称" size="medium" style="margin-right: 20px;"></el-input>
				<el-button type="primary" @click="getSubjectList" :loading="getSubjectState">查询</el-button>
			</div>

		</el-dialog>
		<el-dialog title="专业列表" :visible.sync="subjectListDialogVisible" width="80%">
			<div class="subjectDetailList">
				<el-table :data="subjectDetailList"
					style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
					:header-cell-style="headerStyle" :cell-style="cellStyle">
					<el-table-column label="序号" type="index" width="60"></el-table-column>
					<el-table-column prop="schoolName" label="专业名称" show-overflow-tooltip>
						<template slot-scope="scope">
							<el-link type="primary" :underline="false"
								@click="clickSubjcet(scope.row)">{{scope.row.subjectName}}</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<el-dialog :title="subjectName?subjectName:'专业详情'" :visible.sync="subjectImgDialogVisible" width="80%">
			<p style="text-align: center;margin: 10px auto;font-size: 20px;">
				<el-link type="danger" :underline="false" @click="getSubjectRankingList"
					style="font-size: 1em;">点击查看本专业全国所有开设院校及专业实力评价</el-link>
			</p>

			<img :src="showSubjectImg" alt="" srcset="" style="width: 100%;" />
		</el-dialog>
		<el-dialog :title="subjectName?subjectName+'专业全国实力评价':'专业全国实力评价'" :visible.sync="subjectRankingDialogVisible"
			width="80%">
			<el-table :data="subjectRankinglList"
				style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
				:header-cell-style="headerStyle" :cell-style="cellStyle" v-loading="rankingListloading">
				<el-table-column prop="province" label="所属省市" show-overflow-tooltip></el-table-column>
				<el-table-column prop="schoolCode" label="院校代码" show-overflow-tooltip></el-table-column>
				<el-table-column prop="schoolName" label="院校名称" show-overflow-tooltip></el-table-column>
				<el-table-column prop="diC_SubjectClass" label="专业实力评价"></el-table-column>
			</el-table>
			<pagination :total="rankingTotal" :page.sync="listRankingQuery.page" :limit.sync="listRankingQuery.limit"
				@pagination="handleCurrentRankingChange" />
		</el-dialog>

	</div>
</template>

<script>
	import pagination from '@/components/Pagination'
	import * as subjectcollects from '@/api/subjectcollects';
	//import * as cityData from '@/components/cityData';
	//import * as schools from "@/api/schools";
	export default {
		components:{
			pagination
		},
		props: {
			gategoryList: {
				Array,
				default: []
			},
			subjectClassList: {
				Array,
				default: []
			},
			showExplan: {
				Boolean,
				default: false
			},
			showCollets: {
				Boolean,
				default: false
			},
			times:{
				String,
				default: ''
			},
		},
		watch: {
			subjectDialogVisible(nv, ov) {
				if (nv != ov) {
					this.subjectDetailList = [];
				}
			}
		},
		data() {
			return {
				rankingListloading: false,
				rankingTotal: 0,
				listRankingQuery: {
					page: 1,
					limit: 10
				},
				subjectRankinglList: [],
				subjectRankingDialogVisible: false,
				subjectListDialogVisible: false,
				showSubjectImg: '',
				subjectImgDialogVisible: false,
				subjectName: '',
				dataDialogVisible: false,
				chanceDialogVisible: false,
				placeDialogVisible: false,
				subjectDialogVisible: false,
				subjectDetailList: [],
				getSubjectState: false,
				cellStyle: {
					'text-align': 'center',
				},
				headerStyle: {
					fontWeight: 'bold',
					color: '#303030',
					'text-align': 'center',
				},
			}
		},
		mounted() {
			
		},
		methods: {
			showCollects() {
				this.$store.commit("setCollectTimes",this.times)
				this.$router.push('/collect')
			},
			getSubjectRankingList() {
				this.rankingListloading = true;
				this.subjectRankinglList = [];
				subjectcollects.loadSubjectRankings({
					page: this.listRankingQuery.page,
					limit: this.listRankingQuery.limit,
					subjectName: this.subjectName
				}).then(res => {
					console.log(res);
					res.data.map(item => {
						let o = item.s1;
						o.schoolName = item.s2.schoolName;
						o.province = item.s2.city;
						if (o.diC_SubjectClass) {
							let temp = o.diC_SubjectClass.split(',');
							for (let i in temp) {
								temp[i] = this.subjectClassList.find(u => {
									return u.dtValue == temp[i];
								});
								if (temp[i]) {
									temp[i] = temp[i].name
								}
							}
							o.diC_SubjectClass = temp.join(',');
						}
						this.subjectRankinglList.push(o);
					})
					//console.log(res);
					this.rankingTotal = res.count;
					this.subjectRankingDialogVisible = true;
					this.rankingListloading = false;
				})
			},
			
			handleCurrentRankingChange(val) {
				//console.log(val);
				this.listRankingQuery.page = val.page;
				this.listRankingQuery.limit = val.limit;
				this.getSubjectRankingList();
			},
			getSubjectList() {
				subjectcollects.loadIntroduces({
					subjectName: this.subjectName
				}).then(res => {
					//console.log(res);
					this.subjectDetailList = res.data;
					this.subjectListDialogVisible = true;
				})
			},
			clickSubjcet(row) {
				//console.log(row);
				this.showSubjectImg = '';
				this.subjectName = row.subjectName;
				setTimeout(() => {
					this.showSubjectImg = 'https://api.xueshitongedu.cn/upfiles/' + row.filePath
					this.subjectImgDialogVisible = true;
				}, 50)
			},
			getGategoryName(val, columnName) {
				var dicobject = this.gategoryList.find(
					(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
				);
				//var dicobject = this.gategoryList.find(t => {
				//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
				//    return t
				//  }
				//});

				if (!dicobject) {
					return "";
				} else {
					return dicobject.name;
				}
			},
		}
	}
</script>

<style lang="scss">
	.componextsSystem {
		.links {
			.el-link {
				margin: 0 10px;
				font-size: 1.2em;
				font-weight: bold;
				color: #000;
			}
		}

		// .el-input {
		// 	width: 80%;
		// 	margin-right: 60px;
		// }

		.el-dialog__header {
			padding: 1vw 1vw;
			font-size: 1.2em;
			background: #4d6ffb;
			border-radius: 0.5vw;
		}

		.el-dialog__title {
			line-height: 24px;
			font-size: 1em;
			font-weight: bold;
			color: #ffffff;
		}

		.el-dialog__body {
			padding: 20px;
			color: #606266;
			font-size: 1.2em;
			word-break: break-all;
			text-align: left;
		}

		.el-checkbox__inner {
			border: 1px solid #a9a9a9;
		}
	}
</style>

<style scoped lang="scss">
	.componextsSystem {
		position: absolute;
		top: 50px;
		left: 150px;
		width: 70%;

		.links {
			padding: 20px;
			margin: 50px auto;
			white-space: nowrap;
		}

		span {
			color: red;
		}

		.schoolName {
			cursor: pointer;
		}

		.dialogInput {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				color: #000;
				width: 100px;
				white-space: nowrap;
			}
		}
	}
</style>
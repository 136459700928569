<template>
	<div class="exportVolunteer" style="background:#f5f5f5;">
		<el-page-header class="pageHeader" @back="$router.go(-1)">
		</el-page-header>
		<div class="exportContent" id="exportContent">
			<div class="content">
				<el-descriptions direction="vertical" :column="6" border style="width: 60%;margin: 0 auto;=">
					<el-descriptions-item label="考生年份">{{analysisData.syS_Years}}</el-descriptions-item>
					<el-descriptions-item label="考生姓名">{{analysisData.studentName}}</el-descriptions-item>
					<el-descriptions-item label="联系手机">{{analysisData.mobileTel}}</el-descriptions-item>
					<el-descriptions-item
						label="辅导类型">{{getGategoryName(analysisData.syS_CoachType,'syS_CoachType')}}</el-descriptions-item>
					<el-descriptions-item
						label="区外高校投档总分">{{(userInfo.score)+(userInfo.scoreAdd)}}</el-descriptions-item>
					<el-descriptions-item label="区外高校投档名次">{{userInfo.place}}</el-descriptions-item>

					<el-descriptions-item
						label="考生民族">{{getGategoryName(userInfo.syS_Nation,'SYS_Nation')}}</el-descriptions-item>
					<el-descriptions-item
						label="考生加分">{{(userInfo.scoreAdd)+(userInfo.scoreAddGX)}}</el-descriptions-item>
					<el-descriptions-item label="考生类别">{{userInfo.syS_FirstSelectCourse}}</el-descriptions-item>
					<el-descriptions-item label="选科组合">{{userInfo.syS_CourseGroup}}</el-descriptions-item>
					<el-descriptions-item
						label="区内高校投档总分">{{(userInfo.score)+(userInfo.scoreAddGX)}}</el-descriptions-item>
					<el-descriptions-item label="区内高校投档名次">{{userInfo.placeGX}}</el-descriptions-item>
				</el-descriptions>
				<div class="reportText">
					<p><span>提示：</span></p>
					<p><span>1、如学校专业组或专业数据不足3年，系统给出的概率会虚高，请自助分析或向经验丰富的老师咨询。比如学校专业组投档概率40%，专业录取概率却是95%，这种情况就是虚高；</span>
					</p>
					<p><span>2、点查看收藏夹，您可以看到您在系统一、系统二、系统三收藏过的学校专业组和专业；如果您对系统给出的分析结果不放心，您可以点“查看专业录取详情”，跳转到系统二，详细了解学校专业组和专业的录取情况。</span>
					</p>
				</div>
			</div>
			<div class="content">
				<div class="title" style="margin: 30px 0;">
					<img src="../../assets/title-line.jpg" />
					<h4>志愿模拟填报</h4>
				</div>
				<div class="innerBox" v-for="index in volunteerMax" :key="index">
					<div class="top">
						<span><span style="color: red;font-weight: bold;">{{index}}</span>志愿</span>
						<div v-if="exportList.subjectGroup[index-1]">
							<!-- 省 -->
							<div style="display: inline-block;">
								<span>
									所在省市：
									<span style="font-weight: bold;color: red;">
										{{exportList.provinceCity[index-1]}}
									</span>
								</span>
							</div>
							<span>专业录取规则：<span style="font-weight: bold;color: green;">{{}}</span></span>
						</div>
						<div v-else>
							<span>所在省市：</span>
							<span>所在城市：</span>
							<span>专业录取规则：</span>
						</div>
					</div>
					<div class="inputWrap">
						<div class="input" v-if="exportList.province">
							<span class="demonstration">学校省市：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
											</el-date-picker> -->
							<el-input v-model="exportList.province[index-1]" placeholder="省市" disabled
								size="medium"></el-input>
						</div>
						<div class="input" v-if="exportList.schoolName">
							<span class="demonstration">志愿院校：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
											</el-date-picker> -->
							<el-select v-model="exportList.schoolName[index-1]" placeholder="志愿院校" disabled></el-select>
						</div>
						<div class="input" v-if="exportList.schoolCode">
							<span class="demonstration">院校代码：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
											</el-date-picker> -->
							<el-input v-model="exportList.schoolCode[index-1]" placeholder="院校代码" disabled
								size="medium"></el-input>
							<!-- <el-select v-model="school" placeholder="院校代码">
												<el-option v-for="item in schoolList" :key="item" :label="item" :value="item">
												</el-option>
											</el-select> -->
						</div>
						<div class="input" v-if="exportList.subjectGroup">
							<span class="demonstration">院校专业组：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
											</el-date-picker> -->
							<el-input v-model="exportList.subjectGroup[index-1]" placeholder="专业组代码" disabled
								size="medium"></el-input>
						</div>
						<div
							v-if="exportList.subjectGroup && exportList.schoolSubjectGroupChance && exportList.schoolPlans">
							<!-- <span>再选科目：{{subjectGroupList[subjectGroupList.findIndex(u => {return u.subjectGroup==exportList.subjectGroup[index-1]})].ssrShort}}</span>
											&nbsp; -->
							<span>投档概率：<span
									style="color: red;">{{exportList.schoolSubjectGroupChance[index-1]}}</span></span>
							&nbsp;

							<span>招生计划数：{{exportList.schoolPlans[index-1]}}</span>
						</div>
						<div v-else>
							<span>投档概率：</span>
							<span>招生计划数：</span>
						</div>
					</div>
					<div class="subjectWarpper">
						<div class="selectSubject" v-if="exportList.subject">
							<div class="subjectItem" v-for="item in 6" :key="item">
								<h4 class="title">专业{{item}}</h4>
								<div class="input" v-if="exportList.subject[index-1]">
									<el-input v-model="exportList.subject[index-1][item-1]" placeholder="专业组代码" disabled
										size="medium"></el-input>
								</div>
							</div>
						</div>
						<div class="input" style="width: 200px;margin: auto;" v-if="exportList.adjustment">
							<div class="input">
								<el-input v-model="exportList.adjustment[index-1]" placeholder="是否服从专业调剂" disabled
									size="medium"></el-input>
							</div>
						</div>

						<!-- 部分服从 -->
						<div class="selectSubject" v-if="exportList.adjustment[index-1]=='服从部分专业调剂'">
							<div class="subjectItem" v-for="item in 10" :key="item">
								<h4 class="title">专业{{item+6}}</h4>
								<div class="input" v-if="exportList.subjectObey[index-1]">
									<el-input v-model="exportList.subjectObey[index-1][item-1]" placeholder="专业组代码"
										disabled size="medium"></el-input>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>


			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>留言</h4>
				</div>
				<p style="color: red;text-align: center;margin: 10px 0;">重要，必填！请您将自己的想法告知审核老师</p>
				<div class="input">
					<el-input type="textarea" placeholder="请输入留言" v-model="textarea" show-word-limit disabled
						style="font-weight: bold;"></el-input>
				</div>
			</div>
		</div>
		<div class="content-btn">
			<el-button type="primary" @click="exportPdf" :loading="exportLoading">导出</el-button>
			<el-button type="primary" @click="$router.go(-1)">返回</el-button>
		</div>
	</div>

</template>

<script>
	import * as categorys from '@/api/categorys'
	import {
		mapState,
	} from "vuex"
	import * as volunteers from '@/api/volunteers';
	import * as cityData from '@/components/cityData';
	import pdf from "@/components/pdf";
	import * as volunteerSchoolSubjectGroups from '@/api/volunteerSchoolSubjectGroups';
	export default {

		data() {
			return {
				oldTimes: [],
				jumpStatus: false,
				multipleSelection: [],
				checkStatus: '未审核',
				schoolClassList: [],
				subjectClassList: [],
				volunteerMax: 40, //最大志愿数
				volunteerMin: 1, //最小志愿数
				volunterrDialogVisible: false,
				volunterrList: [],
				volunterrJump: '',
				searchState: false,
				analysisData: {},
				none: '',
				exportList: {
					subject: [],
					subjectObey: [],
					adjustment: [],
					province: [],
					schoolName: [],
					schoolCode: [],
					subjectGroup: [],
					schoolSubjectGroupChance: [],
					provinceCity: [],
					schoolPlans: [],
				},
				exportLoading: false,
				exportDialogVisible: false,
				cellStyle: {
					'text-align': 'center',
				},
				headerStyle: {
					fontWeight: 'bold',
					color: '#303030',
					'text-align': 'center',
				},
				collectsList: [],
				detailList: [],
				detailsDialogVisible: false,
				collectDialogVisible: false,
				adjustmentList: [{
						label: '服从所有专业调剂',
						value: '服从所有专业调剂'
					},
					{
						label: '不服从专业调剂',
						value: '不服从专业调剂'
					},
				],
				subjectGroup: '',
				list: [],
				province: '',
				provincearr: [],
				schoolList: [],
				subjectList: [],
				subjectGroupList: [],
				textarea: '',
				subject: [],
				subjectObey: [],
				adjustment: '',
				volunteerId: '',
				volunterrIndex: 1,
				gategoryList: [],
				schoolCode: '',
				saveLoading: false,
				syS_VolunteerStatus: 0,
			}
		},
		computed: {
			...mapState(["userInfo", "systemData"]),
		},
		mounted() {
			this.analysisData = this.systemData4;
			//console.log(this.analysisData);
			this.provincearr = cityData.getAreaList();
			this.getGategorys();
			this.getVolunteerId();
		},

		methods: {
			getVolunteerId() {
				let listQuery = {
					syS_Years: this.analysisData.syS_Years,
					studentName: this.analysisData.studentName,
					mobileTel: this.analysisData.mobileTel,
					syS_CoachType: this.analysisData.syS_CoachType,
				}

				volunteers.memberGet(listQuery).then(res => {
					if (res.code == 200) {
						console.log(res);
						this.volunteerId = res.result.id;
						this.showExport();
						//console.log()
					}

				})
			},
			showExport() {
				for (let i = 0; i < this.volunteerMax; i++) {
					this.exportList.province.push('');
					this.exportList.provinceCity.push('');
					this.exportList.schoolName.push('');
					this.exportList.schoolCode.push('');
					this.exportList.subjectGroup.push('');
					this.exportList.schoolSubjectGroupChance.push('');
					this.exportList.schoolPlans.push('');
					this.exportList.subject.push(['', '', '', '', '', '']);
					this.exportList.subjectObey.push(['', '', '', '', '', '', '', '', '', '']);
					this.exportList.adjustment.push('');
				}
				//let tempList = this.volunterrList.filter(u => JSON.stringify(u) !== "{}");

				let listQuery = {
					limit: 40,
					page: 1,
					cardNo: this.userInfo.cardNo, // 卡号
					volunteerID: this.volunteerId, // 志愿Id
					syS_Times: '本科', // 批次
				}
				volunteerSchoolSubjectGroups.getVolunteerList2(listQuery).then(res => {
					console.log(res);
					res.data.map((item, index) => {
						console.log(item, index);
						// (item.v.levels-1)
						this.exportList.province.splice(index, 1, cityData.getAreaName(item.province));
						this.exportList.provinceCity.splice(index, 1, item.provinceCity);
						this.exportList.schoolName.splice(index, 1, item.schoolName);
						this.exportList.schoolCode.splice(index, 1, item.schoolCode);
						this.exportList.subjectGroup.splice(index, 1, item.subjectGroup);
						this.exportList.schoolSubjectGroupChance.splice(index, 1, item
							.schoolSubjectGroupChance);
						this.exportList.schoolPlans.splice(index, 1, item.schoolPlans);
						this.exportList.adjustment.splice(index, 1, item.syS_IfObey);
						item.volunteerSubjects.map((s, i) => {
							this.exportList.subject[index].splice(i, 1, s.subjectName);
						})

						item.volunteerSubjectsObey.map((s, i) => {
							this.exportList.subjectObey[index].splice(i, 1, s.subjectName);
						})

					})
					console.log(this.exportList);
					this.exportDialogVisible = true;

				})
			},
			exportPdf() {
				//this.exportLoading = true;
				// 获取要导出的Vue组件
				let exportContent = document.querySelector('.exportContent');
				//let innerBoxs = document.querySelectorAll('.innerBox');

				this.$nextTick(() => {
					//console.log(this.$refs.exportDialog.scrollTop);
					// 1、在生成截图前，先把滚动条置顶
					//this.$refs.exportDialog.$el.scrollTo(0,0);

					// 导出pdf
					//pdf('模拟填报志愿', exportContent, innerBoxs);
					//this.$print(exportContent);
					this.$printJS({
						printable: 'exportContent',
						type: 'html',
						documentTitle: "", // 标题
						style: "@page{size:auto;margin: 1cm ; size: }", // 去除页眉页脚
						targetStyles: ["*"],
					})
					setTimeout(() => {
						this.exportLoading = false;
					}, 1000)
				})
			},
			getGategorys() {
				//console.log(this.userInfo);
				let listQuery = {
					page: 1,
					limit: 9999
				};
				categorys.getList(listQuery).then(res => {
					this.gategoryList = res.data;
				})
			},
			getGategoryName(val, columnName) {

				var dicobject = this.gategoryList.find(t => t.typeId.toUpperCase() == columnName.toUpperCase() && t
					.dtValue == val)
				//var dicobject = this.gategoryList.find(t => { 
				//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) { 
				//    return t
				//  } 
				//});

				if (!dicobject) {
					return ''
				} else {
					return dicobject.name
				}

			},
		}
	}
</script>

<style scoped lang="scss">
	.exportVolunteer {
		.pageHeader {
			width: calc(100% - 10vw);
			height: 100px;
			line-height: 100px;
			background-color: #fff;
			padding: 0 5vw;
		}

		.content-btn {
			text-align: center;
			background-color: #fff;
			margin-bottom: 40px;

			.el-button {
				margin: 20px;
			}

			p {
				span {
					color: red;
				}
			}
		}

		.content {
			margin: 25px auto;
			padding: 20px;
			width: 80%;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			text-align: left;

			.title {
				margin-bottom: 20px;
				background-color: #4d6ffb;
				border-radius: 10px;

				h4 {
					text-align: left;
					color: #fff;
					font-size: 1.2vw;
					padding: 0.5vw 0vw 0.5vw 2.5vw;
				}

				img {
					float: left;
					margin-left: 1vw;
					margin-top: 0.7vw;
					height: 1.5vw;
				}
			}

			.inputWrap {
				margin: 10px auto;
				padding: 0 10px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				.input {
					margin: 0 10px;
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					white-space: nowrap;

					.demonstration {
						margin: 0 5px;
						font-weight: bold;
					}
				}

				// .input:nth-child(1),
				// .input:nth-child(3){
				// 	text-align: left;
				// }
				// .input:nth-child(2),
				// .input:nth-child(4){
				// 	text-align: right;
				// }
			}

			.innerBox {
				width: 90%;
				margin: 0 auto;

				.top {
					display: flex;
					font-weight: bold;
					margin: 10px 0;
					justify-content: space-around;
					white-space: nowrap;

					span {
						margin: 0 10px;
					}
				}
			}

			.selectSubject {
				display: flex;
				flex-wrap: wrap;

				.subjectItem {
					width: calc(33% - 40px);
					margin: 10px;
					padding: 10px;
					//border-radius:5px;
					border: 1px solid #ddd;

					.title {
						text-align: center;
						padding: 10px;
						color: #fff;
					}

					.input {
						margin: 0 auto;
						width: 80%;
					}

					p {
						display: flex;
						justify-content: center;
						font-weight: bold;
						margin: 10px 0;
					}
				}

			}

			.input {
				text-align: center;

			}


			.jumpVolunteer {
				display: flex;
				width: 80%;
				margin: 0 auto;
				align-items: center;
				justify-content: center;

				span {
					margin: 0 10px;
					white-space: nowrap;
				}
			}

			.tips {
				span {
					font-size: 0.8vw;
					font-weight: normal;
					color: #959595;
					margin: 0 2vw;

					i {
						color: #d30f11;
						margin-right: 0.5vw;
					}
				}
			}
		}

		.exportContent {
			.content {
				padding-bottom: 20px;

				.innerBox {
					margin: 50px auto;

					.inputWrap {

						.input {
							width: 30%;
							flex: unset;
						}
					}
				}
			}


		}
	}
</style>
import request from "@/utils/request";
export function saveMember(data) {
	return request({
		url: "/memberCheck/saveMember",
		method: 'post',
		data
	});
}
export function register(data) {
	return request({
		url: '/memberCheck/register',
		method: 'post',
		data
	})
}

export function getCode(mobileTel) {
	return request({
		url: '/memberCheck/memberVerification',
		method: 'post',
		params: {
			mobileTel
		}
	})
}

export function logOut(memberToken) {
	return request({
		url: '/memberCheck/logout',
		method: 'post',
		params: {
			memberToken
		}
	})
}

export function getInfo(memberToken) {
	return request({
		url: '/memberCheck/getMember',
		method: 'get',
		params: {
			memberToken
		}
	})
}

export function saveScore(data) {
  return request({
    url: '/members/SaveScore',
    method: 'post',
    data
  })
}

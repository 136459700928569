import request from '@/utils/request'

export function memberAdd(params) {
	return request({
		url: "/SubjectSelectSearchHistorys/MemberAdd",
		method: 'post',
		params
	});
}
export function getList(params) {
	return request({
		url: "/SubjectSelectSearchHistorys/MemberLoad",
		method: 'get',
		params
	});
}
export function memberClear(params) {
	return request({
		url: "/SubjectSelectSearchHistorys/MemberClear",
		method: 'get',
		params
	});
}
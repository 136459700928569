<template>
    <div class="activity-change">
        <h2>{{ getGategoryName(syS_ActType,'SYS_ActType') }}报名</h2>
        <div class="content">
            <el-form class="res-form" autoComplete="on" :model="resForm1" ref="resForm1" label-position="left"
                label-width="auto">
                <p style="font-weight:bold;padding-bottom: 15px;text-align: left;">搜索报名信息</p>
                <el-form-item prop="phone" label="手机号码">
                    <div class="inputBox">
                        <el-input name="phone" type="text" v-model="resForm1.phone" />
                    </div>
                </el-form-item>

                <el-form-item prop="number" label="报名编号">
                    <div class="inputBox">
                        <el-input name="number" type="text" v-model="resForm1.number" />
                    </div>
                </el-form-item>
                <el-button type="primary" style="width:100%;background:#0683a2;font-size: 20px;height: 50px;"
                    @click.native.prevent="search">
                    搜索
                </el-button>
            </el-form>
            <div class="searchList" v-if="showSearch">
                <el-form class="res-form" autoComplete="on" :model="resForm2" ref="resForm2" label-position="left"
                    label-width="auto" v-if="showSearch">
                    <p style="font-weight:bold;padding-bottom: 15px;text-align: left;">修改报名信息</p>
                    <el-form-item prop="school" label="孩子学校">
                        <div class="inputBox">
                            <el-input name="school" type="text" v-model="resForm2.school" />
                        </div>
                    </el-form-item>
                    <el-form-item prop="name" label="家长昵称">
                        <div class="inputBox">
                            <el-input name="name" type="text" v-model="resForm2.name" />
                        </div>
                    </el-form-item>
                    <el-button type="primary" style="width:100%;background:#0683a2;font-size: 20px;height: 50px;"
                        @click.native.prevent="submit">
                        保存
                    </el-button>
                </el-form>

                <p class="notFound" v-else style="color: red;text-align: center;font-size: 5.5vw;padding: 2vw 0;">
                    没有信息
                </p>
            </div>
        </div>

    </div>
</template>

<script>
import * as categorys from "@/api/categorys";
import * as avtivity from '@/api/activity'
import {
    mapState
} from "vuex"
export default {
    name: 'activity-change',
    data() {

        const validatePhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入手机号码'));
            } else {
                callback();
            }
        };
        const validateNumber = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入报名编号'));
            } else {
                callback();
            }
        };
        return {
            syS_ActType:'',
            gategoryList:[],
            showSearch: false,
            searchList: [],
            resForm1: {
                phone: '',
                number: '',
            },
            resForm2: {
                actId: '',
                school: '',
                name: '',
                phone: ''
            },
            resRules: {
                number: [{
                    required: true,
                    trigger: 'blur',
                    validator: validateNumber
                }],
                phone: [{
                    required: true,
                    trigger: 'blur',
                    validator: validatePhone
                }],
            },
        }
    },
    computed: {
        ...mapState(["activityActType",]),
    },
    mounted() {
        if(!this.activityActType){
            this.syS_ActType = sessionStorage.getItem('activityActType')
        }else{
            this.syS_ActType = this.activityActType
        }
    },
    created(){
        this.getGategorys();
    },
    methods: {
        search() {
            avtivity.memberGet(this.resForm1).then(res => {
                // console.log(res);
                if(res.code == 200){
                    this.resForm2 = res.result;
                    this.showSearch = true;
                }
                
            }).catch(err => {
                console.log(err);
                this.showSearch = false;
            })
        },
        submit() {
            avtivity.memberUpdate(this.resForm2).then(res => {
                this.$notify({
                    title: '成功',
                    message: '修改成功',
                    type: 'success',
                    duration: 2000
                })
            })
        },
        getGategorys() {
			let listQuery = {
				page: 1,
				limit: 9999,
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.gategoryList = res.data;
				//console.log(this.gategoryList);
				//for(let key in this.gategoryList){
				//    console.log(this.gategoryList[key])
				//}
				//console.log('getcategorys3')
			});
		},
		getGategoryName(val, columnName) {
			var dicobject = this.gategoryList.find(
				(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
			);
			//var dicobject = this.gategoryList.find(t => {
			//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
			//    return t
			//  }
			//});

			if (!dicobject) {
				return "";
			} else {
				return dicobject.name;
			}
		},
    }
}
</script>

<style scoped lang="scss">
.activity-change {
    h2 {
        padding-bottom: 4vw;
        padding-top: 4vw;
        background-color: #0683a2;
        font-size: 5vw;
        color: #fff;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 999;
    }

    .content {
        padding-top: 30vh;

        .res-form {
            display: inline-block;
            vertical-align: middle;
            padding: 20px;
            width: calc(100vw - 40px);
            border-radius: 5px;
            background-color: #fff;

            .inputBox {
                display: flex;

                .el-input {
                    max-width: 100%;
                }

                .tip {
                    flex: 1;
                    color: #ff0300;
                    margin-left: 5px;
                    white-space: nowrap;
                }
            }

            .codeItem {
                display: flex;

                .el-input {
                    margin-right: 15px;
                    font-size: 16px;
                }
            }

            // .el-from-item{
            // 	.el-input{
            // 		width: auto;
            // 	}
            // }
            .input {
                height: 50px;

            }
        }

        .searchList{
            margin-top: 20px
        }
    }

    .abut {
        display: block;
        width: 35vw;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        background-color: #0683a2;
        color: #fff;
        text-align: center;
        float: left;
        padding-bottom: 4vw;
        padding-top: 4vw;
        font-size: 5vw;
    }
}
</style>
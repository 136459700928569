<template>
  <div class="wrap" id="app-footer">
    <div class="map">
      <p>联系地址：南宁市青秀区东葛路1号广西锦华大酒店前楼二楼216室</p>
      <p>电话：0771—5679916 &nbsp;&nbsp;版权所有©:广西学士通教育投资有限公司</p>
      <p>
        网站备案号：<a
          href="https://beian.miit.gov.cn/"
          style="text-decoration: none; color: #fff"
          >桂ICP备16007468号-1</a
        >
        &nbsp;&nbsp;
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010302002989"
          style="text-decoration: none; position: relative"
          class="beian"
        >
          <img
            src="../assets/beian.jpg"
            style="position: absolute; top: 0; left: -18px"
          />
          <span style="margin-left: 10px"> 桂公网安备45010302002989号 </span>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "common-footer",
  data() {
    return {};
  },
};
</script>
<style>
.wrap {
  background: url(../assets/logo_bg.jpg) no-repeat;
}

.map {
  margin: 0 auto;
  width: 70%;
  min-height: 3vw;
  display: flex;
  flex-wrap: wrap;
  color: #fff;
  min-height: 70px;
  justify-content: center;
}

.map p {
  font-size: 1.2em;
  margin: 0.5vw;
  text-align: center;
  word-wrap: break-word;
}

.beian {
  margin: 0 5px;
  color: #fff;
  height: 20px;
}

.map p span,
a img {
  width: 10%;
}

/* 	.map p span:first-child {
		text-align: left;
	}


	.map p span:last-child {
		text-align: right;
	} */
</style>

import request from '@/utils/request'
//import request from './request.js'
export function getList(params) {
  return request({
    url: '/schoolSubjectGroups/getList',
    method: 'get',
    params
  })
}


export function getLoad(params) {
  return request({
    url: '/schoolSubjectGroups/load',
    method: 'get',
    params
  })
}

export function loadForRole(roleId) {
  return request({
    url: '/schoolSubjectGroups/loadForRole',
    method: 'get',
    params: { appId: '', firstId: roleId }
  })
}

export function add(data) {
  return request({
    url: '/schoolSubjectGroups/add',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/schoolSubjectGroups/update',
    method: 'post',
    data
  })
}

export function del(data) {
  return request({
    url: '/schoolSubjectGroups/delete',
    method: 'post',
    data
  })
}


export function getMembelList(params) {
  return request({
    url: '/schoolSubjectGroups/MemberLoadSchool',
    method: 'get',
    params
  })
}

export function getChance(params) {
  return request({
    url: '/schoolSubjectGroups/LoadChance',
    method: 'get',
    params
  })
}

export function getMyDif(params) {
  return request({
    url: '/schoolSubjectGroups/MyPlaceDif',
    method: 'get',
    params
  })
}

export function getSubjectGroupChance(params) {
  return request({
    url: '/schoolSubjectGroups/getSubjectGroupChance',
    method: 'get',
    params
  })
}

export function memberLoadSubjectGroup(params) {
  return request({
    url: '/SchoolSubjectGroups/MemberLoadSubjectGroup',
    method: 'get',
    params
  })
}

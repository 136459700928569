//import request from '../utils/request.js'
import request from '@/utils/request'
export function getList(params) {
  return request({
    url: '/volunteers/load',
    method: 'get',
    params
  })
}
export function getVolunteers(id) {
  return request({
    url: '/volunteers/getVolunteer',
    method: 'get',
    params: {id:id}
  })
}

export function send(id) {
  return request({
    url: '/volunteers/Send',
    method: 'get',
    params: {id:id}
  })
}

export function loadForRole(roleId) {
  return request({
    url: '/volunteers/loadForRole',
    method: 'get',
    params: { appId: '', firstId: roleId }
  })
}

export function add(data) {
  return request({
    url: '/volunteers/add',
    method: 'post',
    data
  })
}
export function addNew(data) {
  return request({
    url: '/volunteers/memberadd',
    method: 'post',
    data
  })
}

export function memberSaveList(data) {
  return request({
    url: '/volunteers/MemberSaveList',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/volunteers/update',
    method: 'post',
    data
  })
}


export function updateNew(data) {
  return request({
    url: '/volunteers/memberupdate',
    method: 'post',
    data
  })
}
export function del(data) {
  return request({
    url: '/volunteers/memberdelete',
    method: 'post',
    data
  })
}
export function memberGet(params) {
  return request({
    url: '/volunteers/MemberGet',
    method: 'get',
    params
  })
}

export function memberSaveNotes(data) {
  return request({
    url: '/Volunteers/MemberSaveNotes',
    method: 'post',
    data
  })
}

export function importFromCollect(params) {
  return request({
    url: '/Volunteers/ImportFromCollect',
    method: 'get',
    params
  })
}

export function exportExcel(params) {
  return request({
    url: '/Volunteers/excelexport',
    method: 'get',
    params
  })
}

<template>
    <div class="activity-index">
        <h2>{{ getGategoryName(syS_ActType,'SYS_ActType') }}报名</h2>
        <div class="content">
            <a class="abut" href="#/appadd">我要报名</a>
            <a class="abut" href="#/appedit" style="float:right;">我要修改</a>
        </div>
    </div>
</template>

<script>
import * as categorys from "@/api/categorys";
export default {
    name: 'activity-index',
    data() {
        return {
            syS_ActType:'001', // 活动类型
            gategoryList:[],
        }
    },
    created() {
        this.getGategorys();
    },
    mounted() {
        if(this.$route.query.syS_ActType){
            this.syS_ActType = this.$route.query.syS_ActType;
        }
        // console.log(this.$route.query.syS_ActType);
        this.$store.commit('setActivityActType',this.syS_ActType)
    },
    methods:{
        
		getGategorys() {
			let listQuery = {
				page: 1,
				limit: 9999,
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.gategoryList = res.data;
				//console.log(this.gategoryList);
				//for(let key in this.gategoryList){
				//    console.log(this.gategoryList[key])
				//}
				//console.log('getcategorys3')
			});
		},
		getGategoryName(val, columnName) {
			var dicobject = this.gategoryList.find(
				(t) =>
					t.typeId.toUpperCase() == columnName.toUpperCase() && t.dtValue == val
			);
			//var dicobject = this.gategoryList.find(t => {
			//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) {
			//    return t
			//  }
			//});

			if (!dicobject) {
				return "";
			} else {
				return dicobject.name;
			}
		},
    }
}
</script>

<style scoped lang="scss">
.activity-index {
    h2 {
        padding-bottom: 4vw;
        padding-top: 4vw;
        background-color: #0683a2;
        font-size: 5vw;
        color: #fff;
        position: fixed;
        top: 0;
        width: 100vw;
    }
    .content{
        padding-top: 30vh;
    }
    .abut {
        display: block;
        width: 35vw;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        background-color: #0683a2;
        color: #fff;
        text-align: center;
        float: left;
        padding-bottom: 4vw;
        padding-top: 4vw;
        font-size: 5vw;
        text-decoration: none;
    }
}
</style>
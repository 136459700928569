import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import Zhuce from '../views/zhuce.vue'
import ShowTree from '../views/showTree.vue'
import Home from '../views/home.vue'
import Teacher from '../views/teacher.vue'
import Teacher2 from '../views/teacher2.vue'
import Choose from '../views/xuanke/index'
import SchoolSearch from '../views/xuanke/schoolSearch'
import System1 from '../views/xuanke/system1'
import System2 from '../views/xuanke/system2/index'
import ComprehensiveEvaluation from '../views/xuanke/system2/comprehensiveEvaluation'
import Search from '../views/analysisSystem/search.vue'
import Search1 from '../views/analysisSystem/search1.vue'
import Search2 from '../views/analysisSystem/search2.vue'
import Search3 from '../views/analysisSystem/search3.vue'
import Search4 from '../views/analysisSystem/search4.vue'
import SearchTqp from '../views/analysisSystemTqp/search.vue'
import Search1Tqp from '../views/analysisSystemTqp/search1.vue'
import Search2Tqp from '../views/analysisSystemTqp/search2.vue'
import Search3Tqp from '../views/analysisSystemTqp/search3.vue'
import Introduce from '../views/introduce.vue'
import MbtiStuInfo from '../views/subjectSystem/mbtiStuInfo.vue'
//import SubjectStuInfo from '../views/xuanke/subjectStuInfo.vue'
import Collect from '../components/collect'
import AnalysisSystem1 from '../views/analysisSystem/analysisSystem1'
import AnalysisSystem2 from '../views/analysisSystem/analysisSystem2'
import AnalysisSystem3 from '../views/analysisSystem/analysisSystem3'
import AnalysisSystem4 from '../views/analysisSystem/analysisSystem4'
import AnalysisSystemTqp1 from '../views/analysisSystemTqp/analysisSystem1'
import AnalysisSystemTqp2 from '../views/analysisSystemTqp/analysisSystem2'
import AnalysisSystemTqp3 from '../views/analysisSystemTqp/analysisSystem3'
import ExportVolunteer from '../views/analysisSystem/exportVolunteer'
// 活动报名页面
import AppIndex from '../views/appActivity/index.vue'
import AppAdd from '../views/appActivity/add.vue'
import AppEdit from '../views/appActivity/edit.vue'

//import FinalPrint from '../views/finalPrint.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'Login',
		component: Login,
		meta: {
			title: '登录',
			footer_show: true,
		}
	},
	{
		path: '/appindex',
		name: 'AppIndex',
		component: AppIndex,
		meta: {
			notVer:true
		}
	},
	{
		path: '/appadd',
		name: 'AppAdd',
		component: AppAdd,
		meta: {
			notVer:true
		}
	},
	{
		path: '/appedit',
		name: 'AppEdit',
		component: AppEdit,
		meta: {
			notVer:true
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			footer_show: true,
		}
	},
	{
		path: '/home',
		name: 'home',
		component: Home,
		meta: {
			title: '首页',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/choose',
		name: 'choose',
		component: Choose,
		meta: {
			title: '选科查询',
			header_show: true,
			footer_show: true,

		}
	},
	{
		path: '/schoolSearch',
		name: 'schoolSearch',
		component: SchoolSearch,
		meta: {
			title: '院校专业查询',
			header_show: true,
			footer_show: true,

		}
	},
	{
		path: '/system1',
		name: 'system1',
		component: System1,
		meta: {
			title: '选科查询系统',
			header_show: true,
			footer_show: true,

		}
	},
	{
		path: '/system2',
		name: 'system2',
		component: System2,
		meta: {
			title: '选科评定系统',
			header_show: true,
			footer_show: true,

		}
	},
	{
		path: '/comprehensiveevaluation',
		name: 'ComprehensiveEvaluation',
		component: ComprehensiveEvaluation,
		meta: {
			title: '综合评定',
			header_show: true,
			footer_show: true,

		}
	},
	{
		path: '/search',
		name: 'search',
		component: Search,
		meta: {
			title: '志愿查询',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/search1',
		name: 'search1',
		component: Search1,
		meta: {
			title: '系统1选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/search2',
		name: 'search2',
		component: Search2,
		meta: {
			title: '系统2选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/search3',
		name: 'search3',
		component: Search3,
		meta: {
			title: '系统3选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/search4',
		name: 'search4',
		component: Search4,
		meta: {
			title: '系统4选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/searchTqp',
		name: 'searchTqp',
		component: SearchTqp,
		meta: {
			title: '志愿查询',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/search1Tqp',
		name: 'search1Tqp',
		component: Search1Tqp,
		meta: {
			title: '系统1选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/search2Tqp',
		name: 'search2Tqp',
		component: Search2Tqp,
		meta: {
			title: '系统2选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/search3Tqp',
		name: 'search3Tqp',
		component: Search3Tqp,
		meta: {
			title: '系统3选填',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/collect',
		name: 'collect',
		component: Collect,
		meta: {
			title: '收藏夹',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/analysisSystem1',
		name: 'analysisSystem1',
		component: AnalysisSystem1,
		meta: {
			title: '可报院校专业组投档概率查询',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/analysisSystem2',
		name: 'analysisSystem2',
		component: AnalysisSystem2,
		meta: {
			title: '单院校可报单专业组投档录取查询',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存

		}
	},
	{
		path: '/analysisSystem3',
		name: 'analysisSystem3',
		component: AnalysisSystem3,
		meta: {
			title: '专业反查',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存

		}
	},
	{
		path: '/analysisSystem4',
		name: 'analysisSystem4',
		component: AnalysisSystem4,
		meta: {
			title: '志愿模拟填报',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存

		}
	},
	{
		path: '/analysisSystemTqp1',
		name: 'analysisSystemTqp1',
		component: AnalysisSystemTqp1,
		meta: {
			title: '其他一类所有院校专业组投档概率查询',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存
		}
	},
	{
		path: '/analysisSystemTqp2',
		name: 'analysisSystemTqp2',
		component: AnalysisSystemTqp2,
		meta: {
			title: '其他一类招生院校查询',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存

		}
	},
	{
		path: '/analysisSystemTqp3',
		name: 'analysisSystemTqp3',
		component: AnalysisSystemTqp3,
		meta: {
			title: '本科提前批其他类志愿模拟填报',
			header_show: true,
			footer_show: true,
			keepAlive: true // 需要被缓存

		}
	},
	{
		path: '/exportVolunteer',
		name: 'exportVolunteer',
		component: ExportVolunteer,
		meta: {
			title: '志愿填报导出',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/teacher',
		name: 'teacher',
		component: Teacher,
		meta: {
			title: '专家讲堂',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/teacher2',
		name: 'teacher2',
		component: Teacher2,
		meta: {
			title: '查询分析系统讲解',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/introduce',
		name: 'introduce',
		component: Introduce,
		// children:[

		// ],
		meta: {
			title: '学业测评',
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/mbtiStuInfo',
		name: 'mbtiStuInfo',
		component: MbtiStuInfo,
		// children:[

		// ],
		meta: {
			title: '测评系统信息',
			header_show: true,
			footer_show: true,
		}
	},
	// {
	// 	path: '/subjectStuInfo',
	// 	name: 'subjectStuInfo',
	// 	component: SubjectStuInfo,
	// 	meta: {
	// 		title: '选科系统信息',
	// 		header_show: true,
	// 		footer_show: true,
	// 	}
	// },
	/** introduce 这这这这这这这这这这这这 */
	// {
	// 	path: '/finalprint',
	// 	name: 'finalprint',
	// 	component: FinalPrint,
	// 	// children:[

	// 	// ],
	// 	meta: {
	// 		title: '打印页面预览',
	// 		header_show:false,
	// 		footer_show:false,
	// 	}
	// },
	{
		path: '/major',
		name: '/major',
		component: () => import('../views/subjectSystem/major'),
		meta: {
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/character',
		name: '/character',
		component: () => import('../views/subjectSystem/character'),
		meta: {
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/preliminary',
		name: '/preliminary',
		component: () => import('../views/subjectSystem/preliminary'),
		meta: {
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/supplement',
		name: '/supplement',
		component: () => import('../views/subjectSystem/supplement'),
		meta: {
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/final',
		name: '/final',
		component: () => import('../views/subjectSystem/final'),
		meta: {
			header_show: true,
			footer_show: true,
		}
	},
	{
		path: '/mbti',
		name: 'mbti',
		component: () => import( /* webpackChunkName: "about" */ '../views/subjectSystem/mbti.vue'),
		meta: {
			title: 'MBTI测试'
		}
	},
	{
		path: '/schemeLibrary',
		name: 'schemeLibrary',
		component: () => import('../views/primaryScheme/schemeLibrary.vue'),
		meta: {
			title: '初选填报',
		},
	},
	{
		path: '/sVipschemeLibrary',
		name: 'sVipschemeLibrary',
		component: () => import('../views/primaryScheme/schemeLibrary.vue'),
		meta: {
			title: '志愿初选填报',
			frameShow: true,
		},
	},
	{
		path: '/primaryNew',
		name: 'primaryNew',
		component: () => import('../views/primaryScheme/newScheme.vue'),
		meta: {
			title: '新建初选填报',
			frameShow: true,
		},
	},
	{
		path: '/estimateScheme',
		name: 'estimateScheme',
		component: () => import('../views/estimateScheme/schemeLibrary.vue'),
		meta: {
			title: '估分填报',
			frameShow: true,
		},
	},
	{
		path: '/sVipestimateScheme',
		name: 'sVipestimateScheme',
		component: () => import('../views/estimateScheme/schemeLibrary.vue'),
		meta: {
			title: '志愿估分填报',
			frameShow: true,
		},
	},
	{
		path: '/estimateNew',
		name: 'estimateNew',
		component: () => import('../views/estimateScheme/newScheme.vue'),
		meta: {
			title: '新建估分填报',
			frameShow: true,
		},
	},
	{
		path: '/realizeScheme',
		name: 'realizeScheme',
		component: () => import('../views/realizeScheme/schemeLibrary.vue'),
		meta: {
			title: '知分填报',
			frameShow: true,
		},
	},
	{
		path: '/sViprealizeScheme',
		name: 'sViprealizeScheme',
		component: () => import('../views/realizeScheme/schemeLibrary.vue'),
		meta: {
			title: '志愿知分填报',
			frameShow: true,
		},
	},
	{
		path: '/realizeNew',
		name: 'realizeNew',
		component: () => import('../views/realizeScheme/newScheme.vue'),
		meta: {
			title: '新建知分填报',
			frameShow: true,
		},
	},
	{
		path: '/careerScheme',
		name: 'careerScheme',
		component: () => import('../views/careerPlanning/schemeLibrary.vue'),
		meta: {
			title: '专业填报',
			frameShow: true,
		},
	},
	{
		path: '/sVipcareerScheme',
		name: 'sVipcareerScheme',
		component: () => import('../views/careerPlanning/schemeLibrary.vue'),
		meta: {
			title: '志愿专业填报',
			frameShow: true,
		},
	},
	{
		path: '/careernewScheme',
		name: 'careernewScheme',
		component: () => import('../views/careerPlanning/newScheme.vue'),
		meta: {
			title: '新建专业填报',
			frameShow: true,
		},
	},
	{
		path: '/enteruniScheme',
		name: 'enteruniScheme',
		component: () => import('../views/enterUniversity/schemeLibrary.vue'),
		meta: {
			title: '升学填报',
			frameShow: true,
		},
	},
	{
		path: '/sVipenteruniScheme',
		name: 'sVipenteruniScheme',
		component: () => import('../views/enterUniversity/schemeLibrary.vue'),
		meta: {
			title: '志愿升学填报',
			frameShow: true,
		},
	},
	{
		path: '/enterScheme',
		name: 'enterScheme',
		component: () => import('../views/enterUniversity/newScheme.vue'),
		meta: {
			title: '新建升学填报',
			frameShow: true,
		},
	},
	{
		path: '/chaxun',
		name: 'chaxun',
		component: () => import( /* webpackChunkName: "about" */ '../views/subjectSystem/chaxun.vue'),
		meta: {
			frameShow: true,
			title: '专业查询'
		}
	},
	{
		path: '/xuanke',
		name: 'xuanke',
		component: () => import( /* webpackChunkName: "about" */ '../views/xuanke.vue'),
		meta: {
			frameShow: true,
			title: '选科'
		}
	},
	{
		path: '/unisearch',
		name: 'unisearch',
		component: () => import( /* webpackChunkName: "about" */ '../views/infoBase/uniSearch/uniSearch.vue'),
		meta: {
			frameShow: true,
			title: '院校信息查询'
		}
	},
	{
		path: '/majorsearch',
		name: 'majorsearch',
		component: () => import( /* webpackChunkName: "about" */ '../views/infoBase/majorSearch/majorSearch.vue'),
		meta: {
			frameShow: true,
			title: '专业信息查询'
		}
	},
	{
		path: '/zhuce',
		name: 'Zhuce',
		component: Zhuce,
		meta: {
			header_show: false,
			footer_show: true,
		}
	},
	{
		path: '/showTree',
		name: 'ShowTree',
		component: ShowTree
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
<template>
	<div :class="{'hidden':hidden}" class="pagination-container">
		<el-pagination ref="pagination" :background="background" :current-page.sync="currentPage" :page-size.sync="pageSize"
			:layout="layout" :total="total" v-bind="$attrs" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" />
		<el-button class="jumpBtn" size="small" @click="jumpPage">GO</el-button>
	</div>
</template>

<script>
	import {
		scrollTo
	} from '../api/scrollTo.js'

	export default {
		name: 'Pagination',
		props: {
			total: {
				required: true,
				type: Number
			},
			page: {
				type: Number,
				default: 1
			},
			limit: {
				type: Number,
				default: 20
			},
			pageSizes: {
				type: Number,
				default: 0
			},
			layout: {
				type: String,
				default: 'total,  prev, pager, next, jumper'
			},
			background: {
				type: Boolean,
				default: true
			},
			autoScroll: {
				type: Boolean,
				default: true
			},
			hidden: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			currentPage: {
				get() {
					return this.page
				},
				set(val) {
					this.$emit('update:page', val)
				}
			},
			pageSize: {
				get() {
					return this.limit
				},
				set(val) {
					this.$emit('update:limit', val)
				}
			}
		},
		methods: {
			jumpPage(){
				this.$emit('pagination', {
					page: this.currentPage,
					limit: this.pageSize
				})
				if (this.autoScroll) {
					scrollTo(0, 800)
				}
			},
			handleSizeChange(val) {
				this.$emit('pagination', {
					page: this.currentPage,
					limit: val
				})
				if (this.autoScroll) {
					scrollTo(0, 800)
				}
			},
			handleCurrentChange(val) {
				this.$emit('pagination', {
					page: val,
					limit: this.pageSize
				})
				if (this.autoScroll) {
					scrollTo(0, 800)
				}
			}
		}
	}
</script>

<style scoped>
	.pagination-container {
		background: #fff;
		padding-left: 15px;
		padding-right: 15px;
	}

	.pagination-container.hidden {
		display: none;
	}
	.el-pagination{
		display: inline-block;
	}
	.jumpBtn{
		position: absolute;
		margin-left: 5px;
		
	}
</style>
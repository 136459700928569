import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "../store/index";
import { getToken } from "../utils/auth";
// 创建axios实例
const service = axios.create({
  baseURL: "http://api.shenqi66.cn/api", // api的base_url
  timeout: 50000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    //console.log(getToken(),store.state.token);
    let token = getToken();
    if (token) {
      config.headers["memberToken"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
      //console.log(config.headers['memberToken']);
    }
    // if (store.state.token) {
    // 	config.headers['memberToken'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // 	//console.log(config.headers['memberToken']);
    // }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  },
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    //console.log(res);
    if (res.code !== 200) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        MessageBox.confirm(
          "登录已超时，可以【取消】继续留在该页面，或者【重新登录】",
          "超时提醒",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          },
        ).then(() => {
          store.dispatch("FedLogOut").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      } else if (res.code == 501) {
        return response.data;
      } else {
        Message({
          message: res.message || res.msg,
          type: "error",
          duration: 5 * 1000,
        });
      }
      return Promise.reject("error");
    } else {
      return response.data;
    }
  },
  (error) => {
    if (error.response && error.response.status == 400) {
      //调用远程服务时，前端类型和后端不匹配

      var message = "<strong>" + error.response.data.detail + "</strong><br>";
      //异常信息需要特殊处理一下
      var errors = Object.entries(error.response.data.errors).map(
        ([key, value]) => ({
          key,
          value,
        }),
      );
      errors.forEach((item) => {
        message += item.key + ":";

        item.value.forEach((dtl) => {
          message += " " + dtl;
        });

        message += "<br>";
      });
      Message({
        dangerouslyUseHTMLString: true,
        message: message,
        type: "error",
        duration: 10 * 1000,
      });
    }
    return Promise.reject(error);
  },
);

export default service;

<template>
    <div class="mbitStuInfo">
        <el-page-header class="pageHeader" @back="$router.go(-1)" content="">
        </el-page-header>
        <div class="content">
            <div class="title">
                <img src="../../assets/title-line.jpg" />
                <h4>测评系统信息填写</h4>
            </div>
            <div class="inputWrap">
                <div class="input">
                    <span class="demonstration">姓名：</span>
                    <el-input v-model="subjectData.studentName" placeholder="姓名" size="medium"></el-input>
                </div>
                <div class="input">
                    <span class="demonstration">手机号码：</span>
                    <el-input v-model="subjectData.mobileTel" placeholder="手机号码" size="medium"></el-input>
                </div>
            </div>
            <h4 style="color: red;text-align: center;margin: 20px 0 10px 0;">未报辅导的B卡用户，姓名处请输入8位数卡号</h4>
            <div style="margin: 10px auto;width: 185px;text-align: center;">
                <el-button type="primary" class="qx" @click="jumpSystem"
                    :disabled="!subjectData.studentName || !subjectData.mobileTel">进入
                </el-button>
            </div>
        </div>

    </div>
</template>

<script>
//import AuthSelect from '@/components/AuthSelect.vue'
export default {
    components: {
        //AuthSelect,
    },
    data() {
        return {
            subjectData: {},
        }
    },
    methods: {
        jumpSystem() {
            this.$store.commit('setSubjectData', this.subjectData);
            this.$router.push('introduce');

        },
    },

}
</script>

<style lang="scss">
.mbitStuInfo {
    .el-input__inner {
        font-weight: bold;
        color: #000;
    }

}
</style>
<style lang="scss" scoped>
.mbitStuInfo {
    ::-webkit-input-placeholder {
        font-weight: bold;
        color: #000;
    }

    :-moz-placeholder {
        font-weight: bold;
        color: #000;
    }

    ::-moz-placeholder {
        font-weight: bold;
        color: #000;
    }

    :-ms-input-placeholder {
        font-weight: bold;
        color: #000;
    }

    .pageHeader {
        width: calc(100% - 10vw);
        height: 100px;
        line-height: 100px;
        background-color: #fff;
        padding: 0 5vw;
    }

    .inputWrap {
        margin: 0 auto;
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;

        .input {
            margin: 0 10px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;

            .demonstration {
                font-weight: bold;
            }
        }

    }

    .content {
        margin: 50px auto;
        padding-bottom: 20px;
        width: 80%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        text-align: left;

        .title {
            margin-bottom: 20px;
            background-color: #4d6ffb;
            border-radius: 10px;

            h4 {
                text-align: left;
                color: #fff;
                font-size: 1.2vw;
                padding: 0.5vw 0vw 0.5vw 2.5vw;
            }

            img {
                float: left;
                margin-left: 1vw;
                margin-top: 0.7vw;
                height: 1.5vw;
            }
        }

        .inputBox {
            .select {
                display: inline-block;
                margin: 0 20px;
            }
        }

        .content-btn {
            text-align: center;

            .el-button {
                margin: 20px;
            }
        }


        .tips {
            span {
                font-size: 0.8vw;
                font-weight: normal;
                color: #959595;
                margin: 0 2vw;

                i {
                    color: #d30f11;
                    margin-right: 0.5vw;
                }
            }
        }
    }
}
</style>
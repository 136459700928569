<template>
	<el-container id="frame" :style="background">
		<!-- 头部栏 -->

		<el-header class="header">
			<img class="logo" src="../assets/logo.png">
			<el-dropdown @command="handleCommand">
				<img class="user" src="../assets/top_个人信息.png">
				<el-dropdown-menu slot="dropdown">
					<!-- 菜单单击后传入的值 -->
					<el-dropdown-item command="xueye">个人信息</el-dropdown-item>
					<el-dropdown-item command="logout">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>

		</el-header>
		<!-- 下方容器 -->
		<el-container>
			<!-- 侧边栏 -->
			<el-aside class="aside" style="display: none;">
				<p class="name">{{$store.state.stuInfo.studentName}}</p>
				<p class="menuTitle">导航</p>
				<el-menu active-text-color="#ff3225" :default-active="editableTabsValue" @select="handleSelect">
					<div v-for="(item,index) in menu" :key="index">
						<el-submenu :index="String(index)" v-if="item.children != undefined">
							<template slot="title">
								<i :class="item.icon"></i>
								<span>{{item.title}}</span>
							</template>
							<el-menu-item-group>
								<el-menu-item :index="menuItem.path" v-for="(menuItem,mindex) in item.children"
									:key="mindex">{{menuItem.title}}</el-menu-item>
							</el-menu-item-group>
						</el-submenu>
						<el-menu-item :index="item.path" v-else>
							<i :class="item.icon"></i>
							<span slot="title">{{item.title}}</span>
						</el-menu-item>
					</div>
				</el-menu>
			</el-aside>
			<!-- 右边内容 -->
			<el-main class="main">
				<!-- 头部标签栏 -->
				<div class="frameTabs" style="display:none;">
					<el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab"
						v-if="tabsMenu.length > 0" @tab-click="tabClick">
						<el-tab-pane :key="index" v-for="(item, index) in tabsMenu" :label="item.title"
							:name="item.name">
						</el-tab-pane>
					</el-tabs>
				</div>
				<!-- 面包屑标题 -->
				<!--<div class="breadCrumb">
					<img src="../assets/面包屑.png">
					<span>{{breadTitle}}</span>
				</div>-->
				<!-- 页面内容 -->
				<router-view />
			</el-main>

		</el-container>
	</el-container>
</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		logOut,
		getInfo
	} from '../api/member.js'
	import {
		getToken,
		removeToken
	} from '@/utils/auth'
	export default {
		name: 'Frame',
		components: {},
		data() {
			return {
				//backgroundImgUrl: require('../assets/home-bg.png'),
				// 标签栏双向绑定值
				//editableTabsValue: 'home',
				// 左侧菜单导航栏数据
				menu: [
					{
						icon: 'el-icon-document',
						title: '资料库',
						children: [{
								icon: '',
								title: '院校介绍等内容',
								path: 'unisearch'
							},
							{
								icon: '',
								title: '专业介绍',
								path: 'majorsearch'
							},
							{
								icon: '',
								title: '专业解析',
								path: 'zyjx'
							},
						],
						path: 'mbti'
					},
					{
						icon: 'el-icon-document-copy',
						title: '高考资讯',
						path: ''
					},
					{
						icon: 'el-icon-cpu',
						title: '科学选科系统',
						children: [{
								icon: '',
								title: '职业测评',
								path: 'mbti'
							},
							{
								icon: '',
								title: '专业测评报告（职业倾向和专业方向）',
								path: ''
							},
							{
								icon: '',
								title: '学业测评',
								path: ''
							},
							{
								icon: '',
								title: '学业测评报告（学科优势和专业方向）',
								path: ''
							},
							{
								icon: '',
								title: '选科大数据',
								path: ''
							},
							{
								icon: '',
								title: '选科',
								path: 'chaxun'
							},
							{
								icon: '',
								title: '查看选科解读',
								path: 'ckxk'
							},
						],
						path: 'report'
					},
					{
						icon: 'el-icon-monitor',
						title: '模拟填报',
						path: 'schemeLibrary'
					},
					{
						icon: 'el-icon-edit-outline',
						title: '志愿填报套餐服务',
						children: [{
								icon: '',
								title: '测评',
								path: 'cp'
							},
							{
								icon: '',
								title: '选专业',
								path: 'careerScheme'
							},
							{
								icon: '',
								title: '初选方案',
								path: 'schemeLibrary'
							},
							{
								icon: '',
								title: '估分方案',
								path: 'estimateScheme'
							},
							{
								icon: '',
								title: '知分方案',
								path: 'realizeScheme'
							},
						],
						path: 'xuanke'
					},
					{
						icon: 'el-icon-user',
						title: '志愿填报一对一服务',
						children: [{
								icon: '',
								title: '职业测评及专业测评报告',
								path: 'zycpbg'
							},
							{
								icon: '',
								title: '职业规划与专业选择方案',
								path: 'sVipcareerScheme'
							},
							{
								icon: '',
								title: '升学通道与院校选择方案',
								path: 'sVipenteruniScheme'
							},
							{
								icon: '',
								title: '志愿填报初步方案',
								path: 'sVipschemeLibrary'
							},
							{
								icon: '',
								title: '志愿填报估分方案',
								path: 'sVipestimateScheme'
							},
							{
								icon: '',
								title: '志愿填报知分方案',
								path: 'sViprealizeScheme'
							},
						],
						path: 'timetable'
					},
				],
				token: '',
				vipMenu: ['sVipcareerScheme', 'sVipenteruniScheme','sVipschemeLibrary', 'sVipestimateScheme', 'sViprealizeScheme']
			}
		},
		created() {
			//console.log(typeof(this.token));
			// this.token = getToken();
			// if (this.token) {
			// 	this.$store.commit('setToken', this.token);
			// 	this.getInfo();
			// 	//console.log(tempPath);
			// 	this.checkInfo(this.$route.path);
			// } else {
			// 	this.$router.push('login');
			// }
			this.verification();
		},
		methods: {
			verification(){
				console.log('start verification');
				var code=getToken();
				//console.log(code);
				if(code==undefined){
					Message({
						message: '未登录，请先登录！',
						type: 'error',
						duration: 5 * 1000
					})
					this.$router.push('/login');
					return;
				}
				getInfo(code).then(res => {
					//console.log(res);
					this.$store.commit("setStuinfo", res.result);
					this.$store.commit('setToken', this.token);
					this.checkInfo(this.$route.path);
				}).catch(()=>{
					Message({
						message: '登录失效，请重新登录',
						type: 'error',
						duration: 5 * 1000
					})
					this.$router.push('/login');
					//console.log(res);
				});
			},
			handleSelect(index) {
				this.checkInfo(index);
			},
			checkInfo(path) {
				if (this.$store.state.stuInfo.syS_MemberClass == '' || this.$store.state.stuInfo.syS_MemberClass == 0) {
					Message({
						message: '请先完善个人信息',
						type: 'info',
						duration: 5 * 1000
					})
					if (this.$route.path !== '/xueye') {
						this.$router.push('xueye').catch(err => {
							console.log(err)
						})
					}
				} else {
					let index = this.vipMenu.findIndex((item) => {
						return item == path;
					});
					if (index !== -1 && this.$store.state.stuInfo.syS_MemberClass != 2) {
						Message({
							message: '您的权限不足以使用该功能',
							type: 'info',
							duration: 5 * 1000
						})
						return;
					} else {
						if (this.$route.path !== path) {
							console.log(path);
							this.$router.push(path);
						}
					}

				}
				//this.$router.push(path);
			},
			//头像菜单单击
			handleCommand(command) {
				if (command == "xueye") {
					this.$router.push(command);
				} else {
					logOut(this.token).then(res => {
						if (res.result) {
							Message({
								message: res.message,
								type: 'success',
								duration: 5 * 1000
							})
							removeToken();
							window.localStorage.clear();
							this.$store.state.stuInfo={};
							console.log(this.$store.state.stuInfo);
							this.$router.push('login');
						} else {
							Message({
								message: res.message || res.errmsg,
								type: 'error',
								duration: 5 * 1000
							})
						}
					})

				}
			},
			// 删除标签栏
			removeTab(targetName) {
				let tabs = this.tabsMenu;
				let activeName = this.editableTabsValue;
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							let nextTab = tabs[index + 1] || tabs[index - 1];
							if (nextTab) {
								activeName = nextTab.name;
							}
						}
					});
				}
				this.editableTabsValue = activeName;
				this.$store.commit('layoutRemoveTab', targetName);
				//this.tabsMenu = tabs.filter(tab => tab.name !== targetName);
				this.$router.push(activeName);

			},
			// 点击标签栏回调
			tabClick(e) {
				if (this.$route.path != "/" + e.name) {
					this.checkInfo(e.name);
				}
			},
			addTab(tab) {
				this.$store.commit('layoutAddTab', {
					title: tab.title,
					name: tab.name,
					path: tab.path
				});
			}
		},
		watch: {
			// 当路由改变
			activeRoute(nv) {
				nv = nv.substr(1);
				if (nv == 'home') {
					this.backgroundImgUrl = require('../assets/home-bg.png');
				} else {
					this.backgroundImgUrl = require('../assets/bg.png');
				}
				//console.log(nv);
				this.editableTabsValue = nv;
				let flag = false;
				//console.log(this.tabsMenu);
				for (let i = 0; i < this.tabsMenu.length; i++) {
					if (nv == this.tabsMenu[i].name) {
						flag = true;
					}
				}
				//console.log(flag, obj.path);
				if (!flag) {
					let tab = {
						title: this.breadTitle,
						name: nv,
						path: nv
					}
					this.addTab(tab);
					// this.editableTabsValue = obj.path;
				}
			}
		},
		computed: {
			// 当页面改变标题自动改变
			breadTitle() {
				return this.$route.meta.title
			},
			// 动态获取路由状态
			activeRoute() {
				return this.$route.path
			},
			background: function() {
				return {
					backgroundImage: "url(" + this.backgroundImgUrl + ")",
					backgroundRepeat: 'no-repeat',
					backgroundSize: "cover",
					backgroundPosition: "center center"
				}
			},
			// 标签栏数据，点击后动态添加
			tabsMenu() {
				return this.$store.state.tabsMenu;
			},
		},
	}
</script>

<style scoped="scoped">
	#frame {
		background-size: contain;
		height: 100%;
	}

	.header .logo {
		height: 50px;
		padding: 5px;
		position: absolute;
		left: 1%;
	}
>>>.el-header{
	display: none;
}
	>>>.el-dropdown {
		display: inline-block;
		position: absolute;
		right: 8%;
	}

	.header .user {
		height: 25px;
		padding: 10px;
	}

	.el-container {
		height: 49vw !important;
	}

	.aside {

		background-color: white;
		box-shadow: 5px 5px 5px rgba(50, 50, 50, 0.1);
		overflow: hidden;
		position: relative;
	}

	.aside .name {
		padding: 30px 0;
		font-weight: bold;
	}


	.aside .el-menu {
		width: 100%;
		text-align: left;
		height: calc(100% - 170px);
		overflow-y: scroll;
		/* 隐藏滚动条，兼容IE、火狐 */
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	/* 兼容谷歌 */
	.aside .el-menu::-webkit-scrollbar {
		width: 0 !important
	}

	.el-main {
		padding: 0 !important;
	}

	.menuTitle {
		color: #a5a5a5;
	}

	.el-menu-item img {
		padding-right: 10px;
	}

	.el-menu-item.is-active {
		background-color: #fdf2df;
	}

	.el-menu-item:hover {
		background-color: #fdf2df;
		color: #ff3225;
	}

	>>>.el-tabs__item {
		border-radius: 5px;
		height: 30px;
		line-height: 30px;
		background-color: #fafcef;
	}

	>>>.frameTabs .el-tabs__item.is-active {
		background-color: #fdf2df;
		color: #303133;
	}

	>>>.frameTabs .el-tabs__item:hover {
		background-color: #fdf2df;
		color: #303133;
	}

	>>>.el-tabs--card>.el-tabs__header .el-tabs__nav {
		border: none;
		box-sizing: border-box;
	}

	>>>.el-tabs--card>.el-tabs__header {
		border: none;
	}

	.breadCrumb {
		color: white;
		text-align: left;
		background-color: rgba(0, 0, 0, 0.1);
		padding: 5px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.breadCrumb span {
		padding-left: 10px;
	}
</style>

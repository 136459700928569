import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VeHistogram from 'v-charts/lib/histogram.common' // 柱状
import VeRadar from 'v-charts/lib/radar.common' // 雷达
//import * as echarts from 'echarts/lib/echarts'
//import { GridComponent } from 'echarts/components';
import Echarts from 'echarts'
import Print from './components/exportPdf'
import printJS from 'print-js'
//echarts.use([GridComponent]);
//Vue.use(Echarts);
Vue.prototype.$echarts = Echarts;
Vue.component(VeHistogram.name, VeHistogram)
Vue.component(VeRadar.name, VeRadar)
Vue.use(ElementUI);
Vue.use(Print);//打印

Vue.prototype.$printJS = printJS

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
<template>
	<div id="analysisSystem4" style="background:#f5f5f5;">
		<el-page-header class="pageHeader"
			@back="volunterrDialogVisible ? volunterrDialogVisible = false : $router.go(-1)">
		</el-page-header>
		<componentsSystem :gategoryList="gategoryList" :subjectClassList="subjectClassList" :times="'本科'">
		</componentsSystem>
		<div class="warpper" v-if="!volunterrDialogVisible">
			<div class="content">
				<el-descriptions direction="vertical" :column="6" border style="width: 70%;margin: 0 auto;">
					<el-descriptions-item label="考生年份">{{ analysisData.syS_Years }}</el-descriptions-item>
					<el-descriptions-item label="考生姓名">{{ analysisData.studentName }}</el-descriptions-item>
					<el-descriptions-item label="联系手机">{{ analysisData.mobileTel }}</el-descriptions-item>
					<el-descriptions-item label="辅导类型">{{ getGategoryName(analysisData.syS_CoachType, 'syS_CoachType')
						}}</el-descriptions-item>
					<el-descriptions-item label="区外高校投档总分"><span style="color: red;">{{ (userInfo.score) +
				(userInfo.scoreAdd) }}</span></el-descriptions-item>
					<el-descriptions-item label="区外高校投档名次"><span style="color: red;">{{ userInfo.place
							}}</span></el-descriptions-item>

					<el-descriptions-item label="考生民族">{{ getGategoryName(userInfo.syS_Nation, 'SYS_Nation')
						}}</el-descriptions-item>
					<el-descriptions-item label="考生加分"><span style="color: red;">{{ userInfo.scoreAddGX
							}}</span></el-descriptions-item>
					<el-descriptions-item label="考生类别">{{ userInfo.syS_FirstSelectCourse }}</el-descriptions-item>
					<el-descriptions-item label="选科组合">{{ userInfo.syS_CourseGroup }}</el-descriptions-item>
					<el-descriptions-item label="区内高校投档总分"><span style="color: red;">{{ (userInfo.score) +
				(userInfo.scoreAddGX) }}</span></el-descriptions-item>
					<el-descriptions-item label="区内高校投档名次"><span style="color: red;">{{ userInfo.placeGX
							}}</span></el-descriptions-item>
				</el-descriptions>

			</div>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>志愿模拟填报</h4>
				</div>
				<el-button type="primary" @click="importCollect" class="importBtn">收藏夹导入生成模拟填报方案</el-button>
				<div class="reportText">
					<p><span>提示：</span></p>
					<p><span>1、如学校专业组或专业数据不足3年，系统给出的概率会虚高，请自助分析或向经验丰富的老师咨询。比如学校专业组投档概率40%，专业录取概率却是95%，这种情况就是虚高；</span>
					</p>
					<p><span>2、点查看收藏夹，您可以看到您在系统一、系统二、系统三收藏过的学校专业组和专业；如果您对系统给出的分析结果不放心，您可以点“查看专业录取详情”，跳转到系统二，详细了解学校专业组和专业的录取情况。</span>
					</p>
				</div>
				<div class="innerBox">
					<div class="top">
						<span><span style="color: red;font-weight: bold;">{{ volunterrIndex }}</span>志愿</span>
						<span>
							<el-link :underline="false" style="font-weight: bold;font-size: 1em;"
								@click="getCollectsList">
								查看收藏夹
							</el-link>
						</span>
						<span v-if="city">
							所在城市：<span style="color: red;">{{ city }}</span>
						</span>
						<span v-else>
							所在城市：
						</span>
						<div v-if="subjectGroupList.length > 0">
							<span>专业录取规则：
								<span style="font-weight: bold;" v-if="admissionrule" :style="admissionrule.indexOf('不认可加分') != -1 ||
				admissionrule.indexOf('专业清') != -1 ||
				admissionrule.indexOf('专业级差') != -1 ? { 'color': 'red' } : { 'color': 'green' }">
									{{ admissionrule }}
								</span>
							</span>
						</div>
						<div v-else>
							<span>专业录取规则：</span>
						</div>
						<span>
							<el-link type="danger" :underline="false" style="font-weight: bold;font-size: 1em;"
								@click="showSystem2">
								查看专业录取详情
							</el-link>
						</span>
					</div>
					<div class="inputWrap">
						<div class="input" style="min-width: 20%;">
							<span class="demonstration">学校省市：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
							</el-date-picker> -->
							<el-select v-model="province" @change="getSchoolList" clearable :disabled="checkStatusBack">
								<el-option v-for="item in provincearr" :key="item.code" :label="item.address"
									:value="item.code">
								</el-option>
							</el-select>
						</div>
						<div class="input" style="min-width: 25%;">
							<span class="demonstration">志愿院校：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
							</el-date-picker> -->
							<el-select v-model="schoolCode" :disabled="province == '' || checkStatusBack"
								@change="getSubjectGroups" clearable>
								<el-option v-for="item in schoolList" :key="item.id" :label="item.schoolName"
									:value="item.schoolCode">
								</el-option>
							</el-select>
						</div>
						<div class="input" style="min-width: 20%;">
							<span class="demonstration">院校代码：</span>
							<!-- <el-date-picker v-model="value1" type="year" placeholder="2022">
							</el-date-picker> -->
							<el-input v-model="schoolCode" disabled size="medium"></el-input>
							<!-- <el-select v-model="school" placeholder="院校代码">
								<el-option v-for="item in schoolList" :key="item" :label="item" :value="item">
								</el-option>
							</el-select> -->
						</div>
						<div class="input">
							<span class="demonstration">全国排名：</span>
							<span style="color: red;" v-if="subjectGroupList.length">
								{{ subjectGroupList[0].schoolRanking == 0 ? '-' : subjectGroupList[0].schoolRanking }}
							</span>
						</div>
						<div class="input">
							<span class="demonstration">院校实力评价：</span>
							<span v-if="subjectGroupList.length">
								<span v-for="item of subjectGroupList[0].diC_SchoolClass.split(',')" :key="item"
									style="font-weight: bold;">
									{{ getGategoryName(item, 'diC_SchoolClass') }}
								</span>
								<!-- {{ subjectGroupList[0].diC_SchoolClass }} -->
							</span>
						</div>
						<div class="input">
							<span class="demonstration">老高考招生批次：</span>
							<span
								style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-weight: bold;"
								v-if="subjectGroupList.length">
								<span v-for="item of subjectGroupList[0].diC_OldTimes.split(',')" :key="item">
									{{ getGategoryName(item, 'diC_OldTimes') }}
								</span>
							</span>
						</div>
						<div class="input" style="min-width: 20%;">
							<span class="demonstration">院校专业组：</span>
							<el-select v-model="subjectGroup" :disabled="schoolCode == '' || checkStatusBack"
								@change="getSchoolSubjects" clearable>
								<el-option v-for="item of subjectGroupList" :key="item.id" :label="item.subjectGroup"
									:value="item.subjectGroup">
								</el-option>
							</el-select>
						</div>
						<div v-if="subjectGroup" class="input">
							<span class="demonstration">再选科目：{{ subjectGroupList[subjectGroupList.findIndex(u => {
				return
				u.subjectGroup == subjectGroup
			})].ssrShort }}</span>
							&nbsp;
							<span class="demonstration">投档概率：<span style="color: red;">{{
				subjectGroupList[subjectGroupList.findIndex(u => {
					return
					u.subjectGroup == subjectGroup
				})].chance ? subjectGroupList[subjectGroupList.findIndex(u => {
					return u.subjectGroup ==
						subjectGroup
				})].chance : '0%' }}</span></span>
							&nbsp;

							<span class="demonstration">招生计划数：
								<span style="color: red;">
									{{ subjectGroupList[subjectGroupList.findIndex(u => {
				return
				u.subjectGroup == subjectGroup
			})].plans }}
								</span>
							</span>
						</div>
						<div v-else class="input">
							<span>投档概率：</span>
							<span>招生计划数：</span>
						</div>
					</div>

					<div class="selectSubject">
						<div class="subjectItem" v-for="item in subjectsMax" :key="item">
							<h4 class="title">专业{{ item }}</h4>
							<div class="input">
								<el-select v-model="subject[item - 1]" :disabled="subjectGroup == '' || checkStatusBack"
									clearable @clear="subjectCheck('clear')" @change="subjectCheck">
									<el-option v-for="item in subjectList" :key="item.id" :label="item.subjectName"
										:value="item.subjectCode" :style="{ color: item.disabled ? 'red' : '' }">
									</el-option>
								</el-select>
							</div>
							<div v-if="subject[item - 1]">
								<p>专业实力：
									{{ subjectList.find(u => { return u.subjectCode == subject[item - 1] })
				?
				getGategoryName(subjectList.find(u => {
					return
					u.subjectCode == subject[item - 1]
				}).diC_SubjectClass, 'DIC_SubjectClass') :
				"- -" }}
								</p>
								<p>
									<span>招生计划数：{{ subjectList.find(u => {
				return
				u.subjectCode == subject[item - 1]
			}) ? subjectList.find(u => {
				return
				u.subjectCode == subject[item - 1]
			}).plans : '' }}</span>
									&nbsp;
									<span>录取概率：<span style="color: red;">{{ subjectList.find(u => {
				return
				u.subjectCode == subject[item - 1]
			}) ? subjectList.find(u => {
				return
				u.subjectCode == subject[item - 1]
			}).chance : '' }}%</span></span>
								</p>
							</div>
						</div>
					</div>
					<div class="input" style="width: 200px;margin: auto;">
						<el-select v-model="adjustment" placeholder="是否服从专业调剂">
							<el-option :label="item.label" :value="item.value" v-for="item in adjustmentList"
								:key="item.value">
							</el-option>
						</el-select>
					</div>

					<div class="content-btn">
						<el-button type="danger" @click="back()">上一志愿</el-button>
						<el-button type="danger" @click="next()" v-if="volunterrIndex < volunteerMax"
							:disabled="clickNextStatus">下一志愿</el-button>
						<el-button type="danger" @click="save('save')"
							v-if="volunteerMax == volunterrIndex">保存志愿</el-button>
						<!-- <el-button type="danger" @click="save()" v-show="syS_VolunteerStatus==0">保存</el-button> -->
						<p v-if="syS_VolunteerStatus == 0 || syS_VolunteerStatus == ''" style="margin-bottom: 10px;">
							<span>点击上一志愿与下一志愿自动保存</span>
						</p>
					</div>

					<div class="jumpVolunteer">
						<span>共{{ volunteerMax }}个志愿</span>
						<span>当前第<span style="color: red;font-weight: bold;">{{ volunterrIndex }}</span>个志愿</span>
						<el-link type="danger" style="font-size: 1em;margin-left: 30px;" @click="showVolunterrList">
							查看修改志愿列表
						</el-link>
						<div class="jumpInput">
							<span>跳转第</span>
							<el-input v-model="volunterrJump" size="mini" max="40" type="number"></el-input>
							<span>志愿</span>
						</div>
						<el-button type="primary" @click="jump" size="mini">GO</el-button>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="title">
					<img src="../../assets/title-line.jpg" />
					<h4>留言</h4>
				</div>
				<p style="color: red;text-align: center;margin: 10px 0;">重要，必填！请您将自己的想法告知审核老师</p>
				<div class="input">
					<el-input class="textarea" type="textarea" placeholder="请输入留言" v-model="textarea" show-word-limit
						maxlength="1000" :rows="5" :disabled="checkStatusBack"></el-input>
				</div>
				<!-- <div v-if="syS_VolunteerStatus > 0" style="margin:10px 0;">
					<h4>审核状态：<span style="color:red">{{ checkStatus }}</span></h4>
					<h4>审核意见：<span>{{ checkNotes }}</span></h4>
				</div> -->
				<div class="content" style="margin: 10px auto;width: 80%;" v-if="syS_VolunteerStatus > 0">
					<h3 style="text-align: center;">审核记录</h3>
					<div class="approval" v-for="item in checkList" :key="item.id">
						<p>审核意见：{{ item.notes }}</p>
						<p>审核老师：{{ item.createUserName }}</p>
						<p>审核结果：{{ getGategoryName(item.syS_VolunteerStatus, 'SYS_VolunteerStatus') }}</p>
						<p>审核时间：{{ item.createTime }}</p>
					</div>
				</div>

				<div class="content-btn">
					<el-button type="primary" @click="showExport" v-loading.fullscreen.lock="exportOpen"
						element-loading-text="开始导出，请稍候">导出/预览</el-button>
					<el-button type="danger" @click="subimt()"
						v-if="!checkStatusBack && syS_CoachType != '999'">提交审核</el-button>
				</div>
			</div>
		</div>
		<div class="volunterrList" v-else>
			<el-table :data="volunterrList"
				style="width: calc(100% - 40px);margin: 0 20px;font-weight: bold;color: #303030;" border
				:header-cell-style="headerStyle" :cell-style="cellStyle" @selection-change="handleSelectionChange">
				<el-table-column type="selection" align="center" width="55"></el-table-column>
				<el-table-column label="操作" width="70px">
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="orderUp(scope.$index)">上移</el-link>
						<el-link type="primary" :underline="false" @click="orderDown(scope.$index)">下移</el-link>
					</template>
				</el-table-column>
				<el-table-column type="index" width="50" label="序号"></el-table-column>
				<el-table-column prop="provinceCity" label="省市" show-overflow-tooltip width="70px">
					<template slot-scope="scope">
						{{ scope.row.provinceCity ? scope.row.provinceCity : getAreaName(scope.row.province) }}
					</template>
				</el-table-column>
				<el-table-column prop="schoolName" label="志愿学校" show-overflow-tooltip min-width="150px">
					<template slot-scope="scope">
						<span @click="showSystem2(scope.row)" style="cursor: pointer;">{{ scope.row.schoolName }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="schoolCode" label="院校代码" show-overflow-tooltip width="70px">
					<template slot="header">
						<p>院校</p>
						<p>代码</p>
					</template>
				</el-table-column>
				<el-table-column prop="subjectGroup" label="专业组" show-overflow-tooltip width="70px">
					<template slot="header">
						<p>专业组</p>
						<p>代码</p>
					</template>
				</el-table-column>
				<el-table-column prop="schoolSubjectGroupChance" label="投档概率" width="70px">
					<template slot="header">
						<p>投档</p>
						<p>概率</p>
					</template>
					<template slot-scope="scope">
						<span style="color: red;">{{ scope.row.schoolSubjectGroupChance=='30%'?'建议等待征集':scope.row.schoolSubjectGroupChance }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="diC_OldTimes" label="老高考招生批次" width="100px">
					<template slot="header">
						<p>老高考</p>
						<p>招生批次</p>
					</template>
				</el-table-column>
				<el-table-column prop="diC_SchoolClass" label="院校实力评价" width="100px">
					<template slot="header">
						<p>院校</p>
						<p>实力评价</p>
					</template>
				</el-table-column>
				<el-table-column prop="syS_CreateType" label="办学性质" width="80px">
					<template slot="header">
						<p>办学</p>
						<p>性质</p>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="70px">
					<template slot-scope="scope">
						<el-link type="primary" :underline="false" @click="delRow(scope.row)">删除</el-link>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin:20px;text-align: left;">
				<el-popconfirm title="确定删除吗？" @confirm="delList">
					<el-button type="primary" size="medium" slot="reference">批量删除</el-button>
				</el-popconfirm>
				<span style="color: red;">（请点列表左侧的小方框，后勾选或取消要批量删除的志愿，最后再点批量删除）</span>
			</div>
			<div style="margin: 5x auto;">
				<el-button type="danger" @click="volunterrDialogVisible = false" size="medium">返回</el-button>
				<el-button type="primary" @click="saveVolunterrList" size="medium">保存修改</el-button>
			</div>
		</div>
		<el-dialog title="专业录取详情" :visible.sync="detailsDialogVisible">
			<div class="list" v-for="item in detailList" :key="item">
				<p v-if="item[0]">{{ item[0].subjectName }}</p>
				<el-table :data="item" style="width:calc(100% - 40px); margin:0 20px; font-weight:bold; color:#303030;"
					border :header-cell-style="headerStyle" :cell-style="cellStyle">
					<el-table-column prop="score" label="分数分布"></el-table-column>
					<el-table-column prop="person" label="录取人数"></el-table-column>
					<el-table-column prop="place" label="对应排名"></el-table-column>
				</el-table>
			</div>
		</el-dialog>

		<el-dialog title="导出预览" :visible.sync="exportDialogVisible" ref="exportDialog" width="90%">
			<!-- <div class="btns">
				<el-radio-group v-model="exporType">
					<el-radio-button label="pdf">PDF导出</el-radio-button>
					<el-radio-button label="excel">EXCEL导出</el-radio-button>
				</el-radio-group>
			</div> -->
			<div class="exportContent" id="exportContent" v-if="exporType == 'pdf'">
				<div class="content">
					<div>
						<el-descriptions direction="vertical" :column="6" border style="width: 80%;margin: 0 auto;">
							<el-descriptions-item label="考生年份">{{ analysisData.syS_Years }}</el-descriptions-item>
							<el-descriptions-item label="考生姓名">{{ analysisData.studentName }}</el-descriptions-item>
							<el-descriptions-item label="联系手机">{{ analysisData.mobileTel }}</el-descriptions-item>
							<el-descriptions-item label="辅导类型">{{ getGategoryName(analysisData.syS_CoachType,
				'syS_CoachType')
								}}</el-descriptions-item>
							<el-descriptions-item label="区外高校投档总分"><span style="color:red">{{ (userInfo.score) +
				(userInfo.scoreAdd)
									}}</span></el-descriptions-item>
							<el-descriptions-item label="区外高校投档名次"><span style="color:red">{{ userInfo.place
									}}</span></el-descriptions-item>

							<el-descriptions-item label="考生民族">{{ getGategoryName(userInfo.syS_Nation, 'SYS_Nation')
								}}</el-descriptions-item>
							<el-descriptions-item label="考生加分"><span style="color:red">{{ userInfo.scoreAddGX
									}}</span></el-descriptions-item>
							<el-descriptions-item label="考生类别">{{ userInfo.syS_FirstSelectCourse
								}}</el-descriptions-item>
							<el-descriptions-item label="选科组合">{{ userInfo.syS_CourseGroup }}</el-descriptions-item>
							<el-descriptions-item label="区内高校投档总分"><span style="color:red">{{ (userInfo.score) +
				(userInfo.scoreAddGX)
									}}</span></el-descriptions-item>
							<el-descriptions-item label="区内高校投档名次"><span style="color:red">{{ userInfo.placeGX
									}}</span></el-descriptions-item>
						</el-descriptions>
						<div class="reportText">
							<p><span>提示：</span></p>
							<p><span>1、如学校专业组或专业数据不足3年，系统给出的概率会虚高，请自助分析或向经验丰富的老师咨询。比如学校专业组投档概率40%，专业录取概率却是95%，这种情况就是虚高；</span>
							</p>
							<p><span>2、点查看收藏夹，您可以看到您在系统一、系统二、系统三收藏过的学校专业组和专业；如果您对系统给出的分析结果不放心，您可以点“查看专业录取详情”，跳转到系统二，详细了解学校专业组和专业的录取情况。</span>
							</p>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="title" style="margin: 30px 0;">
						<img src="../../assets/title-line.jpg" />
						<h4>志愿模拟填报</h4>
					</div>
					<div class="innerBox page-split pdf-group-item" v-for="index in exportCount" :key="index"
						style="page-break-after:always;">
						<div class="inputWrap">
							<div class="top">
								<span><span style="color: red;font-weight: bold;">{{ index }}</span>志愿</span>
								<span v-if="exportList.provinceCity[index - 1]">
									所在城市：<span style="color: red;">{{ exportList.provinceCity[index - 1] }}</span>
								</span>
								<span v-else>
									所在城市：
								</span>
								<div v-if="exportList.subjectGroup[index - 1]">
									<span>专业录取规则：
										<span style="font-weight: bold;" v-if="exportList.admissionrule[index - 1]"
											:style="exportList.admissionrule[index - 1].indexOf('不认可加分') != -1 ||
				exportList.admissionrule[index - 1].indexOf('专业清') != -1 ||
				exportList.admissionrule[index - 1].indexOf('专业级差') != -1 ? { 'color': 'red' } : { 'color': 'green' }">
											{{ exportList.admissionrule[index - 1] }}
										</span>
									</span>
								</div>
								<div v-else>
									<span>专业录取规则：</span>
								</div>
							</div>
							<div class="input" v-if="exportList.province" style="min-width: 20%;">
								<span class="demonstration">学校省市：</span>
								<el-input class="" v-model="exportList.province[index - 1]" disabled
									size="medium"></el-input>
							</div>
							<div class="input" v-if="exportList.schoolName" style="min-width: 20%;">
								<span class="demonstration">志愿院校：</span>
								<el-input class="" v-model="exportList.schoolName[index - 1]" disabled
									size="medium"></el-input>
							</div>
							<div class="input" v-if="exportList.schoolCode" style="min-width: 20%;">
								<span class="demonstration">院校代码：</span>
								<el-input class="" v-model="exportList.schoolCode[index - 1]" disabled
									size="medium"></el-input>
							</div>
							<div class="input">
								<span class="demonstration">全国排名：</span>
								<span style="color: red;" v-if="exportList.schoolRanking[index - 1]">
									{{ exportList.schoolRanking[index - 1] }}
								</span>
								<div class="input">
									<span class="demonstration">院校实力评价：</span>
									<span v-if="exportList.diC_SchoolClass[index - 1]">
										<span v-for="item of exportList.diC_SchoolClass[index - 1].split(',')"
											:key="item" style="font-weight: bold;">
											{{ getGategoryName(item, 'diC_SchoolClass') }}
										</span>
										<!-- {{ subjectGroupList[0].diC_SchoolClass }} -->
									</span>
								</div>
							</div>
							<div class="input">
								<span class="demonstration">老高考招生批次：</span>
								<span
									style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-weight: bold;"
									v-if="exportList.diC_OldTimes[index - 1]">
									<span v-for="item of exportList.diC_OldTimes[index - 1].split(',')" :key="item">
										{{ getGategoryName(item, 'diC_OldTimes') }}
									</span>
								</span>
							</div>
							<div class="input" v-if="exportList.subjectGroup" style="min-width: 20%;">
								<span class="demonstration">院校专业组：</span>
								<el-input v-model="exportList.subjectGroup[index - 1]" disabled
									size="medium"></el-input>
							</div>
							<div v-if="exportList.subjectGroup[index - 1]" class="input">
								<span class="demonstration">再选科目：{{ exportList.ssrShort[index - 1] }}</span>
								&nbsp;

								<div v-if="exportList.subjectGroup && exportList.schoolSubjectGroupChance && exportList.schoolPlans"
									class="input">
									<span class="demonstration">投档概率：<span style="color: red;">{{
				exportList.schoolSubjectGroupChance[index - 1]
			}}</span></span>
									&nbsp;
									<span class="demonstration">招生计划数：<span style="color: red;">{{
					exportList.schoolPlans[index - 1]
				}}</span></span>
								</div>
							</div>

							<div v-else class="input">
								<span class="demonstration">投档概率：</span>
								<span class="demonstration">招生计划数：</span>
							</div>
						</div>
						<div class="subjectWarpper">
							<div class="selectSubject" v-if="exportList.subject">
								<div class="subjectItem" v-for="item in subjectsMax" :key="item">
									<h4 class="title">专业{{ item }}</h4>
									<div class="input" v-if="exportList.subject[index - 1]">
										<el-input class="" v-model="exportList.subject[index - 1][item - 1]" disabled
											size="medium"></el-input>
									</div>
									<div v-if="exportList.diC_SubjectClass[index - 1]">
										<p>专业实力：
											{{
				exportList.diC_SubjectClass[index - 1][item - 1] ?
					exportList.diC_SubjectClass[index - 1][item - 1]
					:
					"- -" }}
										</p>
										<p>
											<span style="white-space: nowrap;">招生计划数：
												{{ exportList.plans[index - 1][item - 1] ? exportList.plans[index -
				1][item
				- 1] :
				"- -" }}</span>
											&nbsp;
											<span style="white-space: nowrap;">录取概率：<span style="color: red;">
													{{ exportList.chance[index - 1][item - 1] ? exportList.chance[index
				-
				1][item - 1]
				:
				"- -" }}%</span></span>
										</p>
									</div>
								</div>
							</div>
							<div class="input" style="width: 200px;margin: auto;" v-if="exportList.adjustment">
								<div class="input">
									<el-input class="" v-model="exportList.adjustment[index - 1]" disabled
										size="medium"></el-input>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content page-split pdf-group-item">
					<div class="title">
						<img src="../../assets/title-line.jpg" />
						<h4>留言</h4>
					</div>
					<p style="color: red;text-align: center;margin: 10px 0;" class="">重要，必填！请您将自己的想法告知审核老师
					</p>
					<div class="input" style="text-align: left;">
						<div class="textarea"
							style="font-weight: bold;font-size: 16px;text-align: left;text-indent: 2em;">
							{{ textarea }}
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="exportExcel" v-else>
				<el-table ref="excelTable" :data="exportList" border style="width: 100%" id="excelTable" :show-header="false">
					<el-table-column>
						<template slot-scope="scope">
							<p>{{scope.row.levels}}志愿</p>
						</template>
					</el-table-column>
				</el-table>
			</div> -->

			<div class="content-btn">
				<el-button type="primary" @click="exportPdf" :loading="exportLoading">导出PDF（推荐使用火狐或Edge浏览器）</el-button>
				<el-button type="primary" @click="exportExcel" :loading="exportLoading">导出EXCEL</el-button>
				<el-button type="primary" @click="exportDialogVisible = false">关闭</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import { PdfLoader } from "@/components/pdfLoader";
import componentsSystem from "@/components/componentsSystem";
//import AuthSelect from '@/components/AuthSelect.vue'
import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'
//import * as subjectcollects from '@/api/subjectcollects'
import * as categorys from '@/api/categorys'
import * as schoolSubject from '@/api/schoolsubjects'
import * as schoolGroups from '@/api/schoolsubjectgroups'
import * as cityData from '@/components/cityData';
import * as volunteers from '@/api/volunteers';
import * as volunteersCheck from '@/api/volunteersCheck';
import * as volunteerSchoolSubjectGroups from '@/api/volunteerSchoolSubjectGroups';
import {
	mapState,
} from "vuex"

export default {
	components: {
		//AuthSelect,
		componentsSystem,
		//Pagination
	},
	data() {
		return {
			checkList: [],
			exportOpen: false,
			provinceCity: '',
			city: '',
			admissionrule: '',
			delVolunteerIndex: -1,
			oldTimes: [],
			jumpStatus: false,
			multipleSelection: [],
			checkStatus: '待审',
			checkNotes: '',
			schoolClassList: [],
			subjectClassList: [],
			subjectsMax: 20, //专业数
			volunteerMax: 40, //最大志愿数
			volunteerMin: 1, //最小志愿数
			volunterrDialogVisible: false,
			volunterrList: [],
			volunterrJump: '',
			searchState: false,
			analysisData: {},
			none: '',
			exportList: {
				ssrShort: [],
				subject: [],
				subjectObey: [],
				adjustment: [],
				province: [],
				schoolName: [],
				schoolCode: [],
				subjectGroup: [],
				schoolSubjectGroupChance: [],
				provinceCity: [],
				schoolPlans: [],
				schoolRanking: [],
				admissionrule: [],
				diC_SchoolClass: [],
				diC_OldTimes: [],
				diC_SubjectClass: [],
				plans: [],
				chance: [],
			},
			exportLoading: false,
			exportDialogVisible: false,
			cellStyle: {
				'text-align': 'center',
			},
			headerStyle: {
				fontWeight: 'bold',
				color: '#303030',
				'text-align': 'center',
			},
			collectsList: [],
			detailList: [],
			detailsDialogVisible: false,
			collectDialogVisible: false,
			adjustmentList: [{
				label: '服从所有专业调剂',
				value: '服从所有专业调剂'
			},
			{
				label: '不服从专业调剂',
				value: '不服从专业调剂'
			},
			],
			subjectGroup: '',
			list: [],
			province: '',
			provincearr: [],
			schoolList: [],
			subjectList: [],
			subjectGroupList: [],
			textarea: '',
			subject: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
			subjectObey: [],
			adjustment: '',
			volunteerId: '',
			volunterrIndex: 1,
			gategoryList: [],
			schoolCode: '',
			saveLoading: false,
			syS_VolunteerStatus: -1,
			clickNextStatus: false,
			syS_CoachType: '',
			fromSystem2: false,
			exportCount: 0,
			exporType: 'pdf',
		}
	},
	computed: {
		...mapState(["userInfo", "systemData4"]),
		checkStatusBack() {
			return this.syS_VolunteerStatus == 1 || this.syS_VolunteerStatus == 3 || this.syS_VolunteerStatus == 5 || this.syS_VolunteerStatus == 7
		}
	},
	activated() {
		this.analysisData = this.systemData4;
		if (Object.keys(this.analysisData) == 0) {
			this.analysisData = JSON.parse(sessionStorage.getItem('analysisData4'));
		} else {
			window.addEventListener('beforeunload', () => {
				sessionStorage.setItem('analysisData4', JSON.stringify(this.analysisData));
			})
		}

		if (this.fromSystem2) {
			this.volunterrDialogVisible = true
			this.fromSystem2 = false;
		}

		//console.log(this.analysisData);
		this.provincearr = cityData.getAreaList();
		this.getGategorys();
		this.getSchoolClassList();

		this.getVolunteerId();
		this.getList();
		this.getCheckStatus();
		this.getVolunterrList();
	},
	beforeRouteLeave(to, from, next) {
		this.volunterrDialogVisible = false
		next();
	},
	methods: {
		exportExcel() {
			let query = {
				id: this.volunteerId
			}

			volunteers.exportExcel(query).then(res => {
				if (res.code == 200) {
					// let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
					// let objectUrl = URL.createObjectURL(blob);
					window.location.href = "http://api.xueshitongedu.cn/ExportFiles/" + res.result
					this.$message({
						message: '导出成功',
						type: 'success'
					})
				}
			});
		},
		// 导入收藏夹志愿
		importCollect() {
			this.$confirm('会覆盖原来导入生成的模拟填报方案（包括人工调整过的专业填报方案），是否继续？', '提示', {
				confirmButtonText: '继续',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				volunteers.importFromCollect({ volunteerId: this.volunteerId }).then(res => {
					console.log(res);
					if (res.code == 200) {
						this.jumpStatus = true;
						this.getList();
						this.$message({
							message: '导入成功',
							type: 'success'
						})
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				});
			});

		},
		showSystem2(row) {
			// console.log(row)
			let temp = {}
			let flag = false
			if (Object.keys(row).length > 0) {
				flag = true
			}
			if (!this.province || !this.schoolCode || !this.subjectGroup) {
				this.$message({
					message: '请选择完专业组后再查看',
					type: 'error'
				})
				return
			}
			if (flag) {
				temp = {
					syS_Years: this.analysisData.syS_Years,
					syS_Times: '本科',
					province: row.province,
					schoolCode: row.schoolCode,
					subjectGroup: row.subjectGroup
				}
			} else {
				temp = {
					syS_Years: this.analysisData.syS_Years,
					syS_Times: '本科',
					province: this.province,
					schoolCode: this.schoolCode,
					subjectGroup: this.subjectGroup
				}
			}
			this.fromSystem2 = true;
			this.$store.commit('setAnalysisData2', temp)
			this.$store.commit('setSearchStatus', true)
			// let url = this.$router.resolve({
			// 	path: '/analysisSystem2'
			// })
			// window.open(url.href, '_blank');
			this.save('unShow');
			setTimeout(() => {
				this.$router.push('/analysisSystem2')
			}, 200);
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		orderUp(index) {
			//console.log(index);
			if (index > 0) {
				this.volunterrList[index].levels = index;
				this.volunterrList[index - 1].levels = index + 1;
				let temp = Object.assign({}, this.volunterrList[index - 1])
				this.volunterrList.splice(index - 1, 1, this.volunterrList[index]);
				this.volunterrList.splice(index, 1, temp);
				//console.log(this.volunterrList);
				this.$message({
					message: '操作成功',
					type: 'success'
				})
			}
			// volunteerSchoolSubjectGroups.memberOrdersUp({
			// 	id: row.id
			// }).then(res => {
			// 	if (res.code == 200) {
			// 		this.$message({
			// 			message: '操作成功',
			// 			type: 'success'
			// 		})
			// 		this.getVolunteerList();
			// 	} else {
			// 		this.$message({
			// 			message: res.msg || res.error,
			// 			type: 'error'
			// 		})
			// 	}
			// })
		},
		orderDown(index) {
			//console.log(index);
			if (index < this.volunterrList.length) {
				this.volunterrList[index].levels = index + 2;
				this.volunterrList[index + 1].levels = index + 1;
				let temp = Object.assign({}, this.volunterrList[index + 1])
				this.volunterrList.splice(index + 1, 1, this.volunterrList[index]);
				this.volunterrList.splice(index, 1, temp);
				//console.log(this.volunterrList);
				this.$message({
					message: '操作成功',
					type: 'success'
				})
			}
			// volunteerSchoolSubjectGroups.memberOrdersDown({
			// 	id: row.id
			// }).then(res => {
			// 	if (res.code == 200) {
			// 		this.$message({
			// 			message: '操作成功',
			// 			type: 'success'
			// 		})
			// 		this.getVolunteerList();
			// 	} else {
			// 		this.$message({
			// 			message: res.msg || res.error,
			// 			type: 'error'
			// 		})
			// 	}
			// })
		},
		delList() {
			this.del(this.multipleSelection)
		},
		delRow(row) {
			let list = [];
			list.push(row);
			this.del(list);
		},
		del(rows) {
			if (this.checkStatusBack) {
				this.$message({
					message: '已提交审核，无法修改',
					type: 'error'
				})
				return;
			}
			rows.map(u => {
				let index = this.volunterrList.findIndex((item) => {
					return item.id == u.id
				})
				if (index == -1) {
					return
				}
				if (this.delVolunteerIndex == -1 && this.delVolunteerIndex < index) {
					this.delVolunteerIndex = index;
				}
				this.$message({
					message: '删除成功',
					type: 'success'
				})
				this.volunterrList.splice(index, 1, {});
			})

			// volunteerSchoolSubjectGroups.memberDelete(rows.map(u => u.id)).then(res => {
			// 	if (res.code == 200) {
			// 		this.$message({
			// 			message: '删除成功',
			// 			type: 'success'
			// 		})
			// 		this.getVolunteerList();
			// 	}
			// })
		},
		getSchoolClassList() {
			let listQuery = {
				page: 1,
				limit: 999,
				typeId: 'DIC_SchoolClass'
			};
			//console.log('getcategorys')
			categorys.getList(listQuery).then((res) => {
				this.schoolClassList = res.data;
				//console.log(this.schoolClassList);
			});
			listQuery.typeId = 'DIC_SubjectClass'
			categorys.getList(listQuery).then((res) => {
				this.subjectClassList = res.data;
				//console.log(this.schoolClassList);
			});
			listQuery.typeId = 'diC_OldTimes'
			categorys.getList(listQuery).then((res) => {
				res.data.map(u => {
					if (u.dtValue != 0) {
						this.oldTimes.push(u);
					}
				})
				//console.log(this.schoolClassList);
			});
		},
		saveVolunterrList() {
			if (this.checkStatusBack) {
				this.$message({
					message: '已提交审核，无法修改',
					type: 'error'
				})
				return;
			}
			let tempList = this.volunterrList.filter(u => JSON.stringify(u) !== "{}")
			//console.log(tempList);
			let query = {
				id: this.volunteerId,
				volunteerSchoolSubjectGroups: tempList
			}
			volunteers.memberSaveList(query).then(res => {
				if (res.code == 200) {
					this.$message({
						type: 'success',
						message: '保存成功'
					})
					//this.volunterrDialogVisible = false;
					// 如删除，则跳到删除志愿
					if (this.delVolunteerIndex != -1) {
						this.jumpStatus = true;
						this.volunterrIndex = this.delVolunteerIndex + 1;
						setTimeout(() => {
							this.getList();
						}, 100);
					}
				}
			})
		},
		getVolunterrList() {
			this.volunterrList = [];
			for (let i = 0; i < this.volunteerMax; i++) {
				this.volunterrList.push({});
			}
			let query = {
				limit: 40,
				page: 1,
				cardNo: this.userInfo.cardNo, // 卡号
				volunteerID: this.volunteerId, // 志愿Id
				syS_Times: '本科', // 批次

			}
			volunteerSchoolSubjectGroups.getVolunteerList(query).then(res => {
				//console.log(res);

				res.data.map(item => {
					item.v.syS_CreateType = this.getGategoryName(item.s.syS_CreateType,
						'SYS_CreateType');
					item.v.diC_OldTimes = item.s.diC_OldTimes;
					item.v.diC_SchoolClass = item.s.diC_SchoolClass;

					if (item.v.diC_OldTimes) {
						let temp = item.v.diC_OldTimes.split(',');
						let tempName = [];
						for (let i in temp) {
							temp[i] = this.oldTimes.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								tempName.push(temp[i].name)
							}
						}
						if (tempName.length == 0) {
							tempName = '-';
						}
						item.v.diC_OldTimes = tempName;
					}
					if (item.v.diC_SchoolClass) {
						let temp = item.v.diC_SchoolClass.split(',');
						let tempName = [];
						for (let i in temp) {
							temp[i] = this.schoolClassList.find(u => {
								return u.dtValue == temp[i];
							});
							if (temp[i]) {
								if (temp[i].name == '211工程大学' || temp[i].name == '985工程大学' || temp[i]
									.name == '一流学科大学' || temp[i].name == '一流大学A' || temp[i].name ==
									'一流大学B' || temp[i].name == '双非大学' || temp[i].name == '民办院校'
								) {

									tempName.push(temp[i].name)
								}
							}
						}
						if (tempName.length == 0) {
							tempName = '-';
						}
						item.v.diC_SchoolClass = tempName;
					}

					this.volunterrList.splice(item.v.levels - 1, 1, item.v);
				})

				//console.log(this.volunterrList);
			})
		},
		showVolunterrList() {
			this.delVolunteerIndex = -1;
			this.getVolunterrList();
			setTimeout(() => {
				this.volunterrDialogVisible = true;
			}, 500)

		},
		getAreaName(provice) {
			return cityData.getAreaName(provice)
		},
		// 导出word
		// handleExport() {
		// 	let data = {
		// 		list: this.list,
		// 		reportList: this.exportReportList,
		// 		scorePhysics: this.form.scorePhysics,
		// 		scoreHistory: this.form.scoreHistory,
		// 		scoreChemistry: this.form.scoreChemistry,
		// 		scoreBiology: this.form.scoreBiology,
		// 		scoreGeography: this.form.scoreGeography,
		// 		scorePolitics: this.form.scorePolitics,
		// 		checkList: this.checkList,
		// 		firstSelect: this.form.firstSelect || '',
		// 		secondSelect: this.form.secondSelect || '',
		// 		thirdSelect: this.form.thirdSelect || '',
		// 	}
		// 	exportWord("template.docx", data, "广西学士通教育高考选科报告.docx")
		// },
		showExport() {
			this.exportOpen = true;
			if (this.exportList.subject.length == 0) {
				this.exportList.ssrShort = [];
				this.exportList.province = [];
				this.exportList.provinceCity = [];
				this.exportList.schoolName = [];
				this.exportList.schoolCode = [];
				this.exportList.subjectGroup = [];
				this.exportList.schoolSubjectGroupChance = [];
				this.exportList.schoolPlans = [];
				this.exportList.adjustment = [];
				this.exportList.schoolRanking = [];
				this.exportList.admissionrule = [];
				this.exportList.diC_SchoolClass = [];
				this.exportList.diC_OldTimes = [];
				for (let i = 0; i < this.volunteerMax; i++) {
					this.exportList.subject.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
					this.exportList.subjectObey.push(['', '', '', '', '', '', '', '', '', '']);
					this.exportList.diC_SubjectClass.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
					this.exportList.plans.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
					this.exportList.chance.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
				}
			}
			//let tempList = this.volunterrList.filter(u => JSON.stringify(u) !== "{}");

			let listQuery = {
				limit: 40,
				page: 1,
				cardNo: this.userInfo.cardNo, // 卡号
				volunteerID: this.volunteerId, // 志愿Id
				syS_Times: '本科', // 批次
			}
			volunteerSchoolSubjectGroups.getVolunteerList(listQuery).then(res1 => {
				//console.log(res1);
				this.exportCount = res1.count;
				res1.data.map(item => {
					//console.log(item);
					// (item.levels-1)
					this.exportList.ssrShort.push(item.v.ssrShort);
					this.exportList.province.push(this.getAreaName(item.s.province));
					this.exportList.provinceCity.push(item.s.city);
					this.exportList.schoolName.push(item.s.schoolName);
					this.exportList.schoolCode.push(item.s.schoolCode);
					this.exportList.subjectGroup.push(item.v.subjectGroup);
					this.exportList.schoolSubjectGroupChance.push(item
						.v.schoolSubjectGroupChance);
					this.exportList.schoolPlans.push(item.v.schoolPlans);
					this.exportList.adjustment.push(item.v.syS_IfObey);

					this.exportList.schoolRanking.push(item.s.schoolRanking);
					this.exportList.admissionrule.push(item.s.admissionrule);
					this.exportList.diC_SchoolClass.push(item.s.diC_SchoolClass);
					this.exportList.diC_OldTimes.push(item.s.diC_OldTimes);
				})
				volunteerSchoolSubjectGroups.getVolunteerList2(listQuery).then(res2 => {
					console.log(res2);
					res2.data.map(item => {
						//console.log(item);
						item.volunteerSubjects.map((s, i) => {
							this.exportList.subject[item.levels - 1].splice(s.orders, 1, s.subjectName);
							// 处理专业实力
							if (s.diC_SubjectClass) {
								let tempSubjectClass = s.diC_SubjectClass.split(','), temp = [];
								tempSubjectClass.map(u => {
									temp.push(this.getGategoryName(u, 'DIC_SubjectClass'))
								})
								this.exportList.diC_SubjectClass[item.levels - 1].splice(s.orders, 1, temp.join(','));
							}

							this.exportList.plans[item.levels - 1].splice(s.orders, 1, s.subjectPlans);
							this.exportList.chance[item.levels - 1].splice(s.orders, 1, s.subjectChance);
						})

						item.volunteerSubjectsObey.map(s => {
							this.exportList.subjectObey[item.levels - 1].splice(s.orders, 1, s.subjectName);
						})

					})
					console.log(this.exportList);
					this.exportOpen = false;
					// this.exportPdf();
					this.exportDialogVisible = true;
					// setTimeout(() => {
					// 	this.exportDialogVisible = false;
					// }, 200);
				})
			})

		},
		exportPdf() {
			this.exportLoading = true;
			// 获取要导出的Vue组件
			let exportContent = document.querySelector('.exportContent');

			this.$nextTick(() => {
				// 导出pdf
				let pdf = new PdfLoader(exportContent, {
					fileName: '模拟填报志愿.pdf',
				})
				pdf.getPdf().then(async (res) => {
					this.exportLoading = false;
				}).catch((error) => {
					console.log(error)
				});
			})
		},
		// exportPdf() {
		// 	//this.$router.push('/exportVolunteer');
		// 	this.exportLoading = true;
		// 	this.$nextTick(() => {
		// 		// 导出pdf
		// 		this.$printJS({
		// 			maxWidth: 1920,
		// 			printable: 'exportContent',
		// 			type: 'html',
		// 			documentTitle: '', // 标题
		// 			style: `@page{size:auto;margin: 0cm 1cm 0cm 1cm;-webkit-print-color-adjust: exact;color-adjust: exact;}
		// 			body{zoom:60%;-webkit-print-color-adjust: exact;color-adjust: exact;}
		// 			`,
		// 			targetStyles: ['*']
		// 		})
		// 		this.exportLoading = false;
		// 	})
		// },
		//专业详细
		getDetailList() {
			this.detailList = [];
			//this.subjectName = row.subjectName;
			this.subject.map(u => {
				let subjectObj = this.subjectList.find(i => {
					return i.subjectCode == u
				});
				let query = {
					syS_Years: subjectObj.syS_Years,
					schoolCode: this.schoolCode,
					syS_FirstSelectCourse: this.userInfo.syS_FirstSelectCourse,
					syS_Times: '1',
					subjectName: subjectObj.subjectName
				}
				schoolSubject.getScoreDetails(query).then(res => {
					//console.log(res);
					this.detailList.push(res.result);
				})
			})

			this.detailsDialogVisible = true;
		},
		getCollectsList() {
			this.$store.commit("setCollectTimes", '本科')
			this.$router.push('/collect');
		},
		subimt() {
			let listQuery = {
				id: this.volunteerId,
				notes: this.textarea,
				// syS_VolunteerStatus: 1
			}
			volunteers.memberSaveNotes(listQuery).then(res => {
				if (res.code == 200) {

					this.$alert('提交成功，请等待老师审核方案。将在5秒后刷新', '即将刷新', {
						confirmButtonText: '直接刷新',
						callback: () => {
							this.$router.go(0)
						}
					});
					let time = 5;

					let timer = setInterval(() => {
						if (time == 0) {
							clearInterval(timer);
							this.$router.go(0)
						}
						time--
					}, 1000);

				}
			})
		},
		// 检查专业是否被选择，如已选，则改变颜色
		subjectCheck(type = '') {
			if (type == 'clear') {
				this.subjectList.map(u => {
					u.disabled = false;
				})
			}
			for (let i in this.subjectList) {
				if (this.subjectList[i].disabled) continue;
				for (let j in this.subject) {
					if (!this.subject[j]) continue;
					if (this.subject[j] == this.subjectList[i].subjectCode) {
						this.subjectList[i].disabled = true;
					}
				}
			}
		},
		getSchoolSubjects() {
			if (!this.subjectGroup) {
				return
			}
			this.setSubject();
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: '本科',
				schoolCode: this.schoolCode,
				subjectGroup: this.subjectGroup,
				limit: 999
			}
			this.admissionrule = this.subjectGroupList.find(u => {
				return u.subjectGroup == this.subjectGroup
			}).admissionrule
			//console.log(this.admissionrule)
			schoolSubject.loadChance(listQuery).then(res => {
				console.log(res);
				this.subjectList = res.data;
				// 判断哪些专业已被选择
				this.subjectCheck();
				//this.getList();
			})
		},
		getSubjectGroups() {
			if (!this.schoolCode) {
				return
			}
			this.subjectGroupList = [];
			this.setSubject();
			this.subjectGroup = '';
			this.admissionrule = '';
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: '本科',
				province: this.province,
				schoolCode: this.schoolCode
			}
			schoolGroups.memberLoadSubjectGroup(listQuery).then(res => {
				console.log(res);
				this.provinceCity = res.data[0].provinceCity; //获取省市

				if (this.provinceCity.includes('省')) {
					this.city = this.provinceCity.split('省')[1].split('市')[0] + '市';
				} else if (this.provinceCity.includes('市')) {
					this.city = this.provinceCity.split('市')[0] + '市';
				}

				this.subjectGroupList = res.data;
				//console.log(this.subjectGroupList);
			})
		},
		save(type) { //保存志愿
			if (this.checkStatusBack) {
				if (type == 'add') {
					this.volunterrIndex++;
				} else if (type == 'reduce') {
					this.volunterrIndex--;
				}
				this.getList();
				return;
			}
			if (this.volunterrIndex <= this.volunteerMax) {
				this.saveLoading = true;
				let subjectGroupObj = this.subjectGroupList.find(u => {
					return u.subjectGroup == this.subjectGroup
				});
				let schoolName = this.schoolList.find(u => {
					return u.schoolCode == this.schoolCode
				}).schoolName;
				let data = {
					cardNo: this.userInfo.cardNo,
					volunteerID: this.volunteerId,
					syS_Years: this.analysisData.syS_Years,
					syS_Times: '本科',
					levels: this.volunterrIndex,
					schoolCode: this.schoolCode,
					province: this.province,
					provinceCity: this.provinceCity,
					subjectGroup: this.subjectGroup,
					syS_IfObey: this.adjustment ? this.adjustment : '不服从专业调剂',
					syS_Admissionrule: this.getGategoryName(subjectGroupObj
						.admissionrule, 'SYS_Admissionrule') || '',
					schoolName: schoolName ? schoolName : '',
					schoolSubjectGroupChance: subjectGroupObj.chance || '',
					schoolPlans: subjectGroupObj.plans || '',
					ssrShort: subjectGroupObj.ssrShort || ''
				};
				console.log(this.subject);
				//专业
				data.volunteerSubjects = this.subject.map((u, index) => {
					let subjectObj = this.subjectList.find(i => {
						return i.subjectCode == u
					});
					if (subjectObj) {
						//console.log(subjectObj);
						let o = {
							cardNo: this.userInfo.cardNo,
							volunteerID: this.volunteerId,
							levels: this.volunterrIndex,
							subjectChance: subjectObj ? subjectObj.chance : '',
							subjectName: subjectObj ? subjectObj.subjectName : '',
							subjectPlans: subjectObj ? subjectObj.plans : '',
							orders: index,
							subjectCode: u,
							isObeyAdjust: false,
							diC_SubjectClass: subjectObj ? subjectObj.diC_SubjectClass : ''
						}
						return o;
					} else {
						return null
					}
				})
				//部分调剂专业
				// data.volunteerSubjectsObey = this.subjectObey.map((u, index) => {
				// 	let subjectObj = this.subjectList.find(i => {
				// 		return i.subjectCode == u
				// 	});
				// 	if (subjectObj) {
				// 		let o = {
				// 			cardNo: this.userInfo.cardNo,
				// 			volunteerID: this.volunteerId,
				// 			levels: this.volunterrIndex,
				// 			subjectChance: subjectObj ? subjectObj.chance : '',
				// 			subjectName: subjectObj ? subjectObj.subjectName : '',
				// 			subjectPlans: subjectObj ? subjectObj.plans : '',
				// 			orders: (index + 1),
				// 			subjectCode: u,
				// 			isObeyAdjust: false
				// 		}
				// 		return o;
				// 	} else {
				// 		return {}
				// 	}
				// })
				console.log(data);
				this.province = '';
				this.schoolCode = '';
				this.subjectGroup = '';
				this.setSubject();
				this.subjectObey = [];
				this.adjustment = '';
				this.provinceCity = '';
				this.subjectGroupList = [];

				volunteerSchoolSubjectGroups.memberSave(data).then(res => {
					//console.log(res);
					if (res.code == 200) {
						if (type !== 'unShow') {
							this.$message({
								message: '保存志愿' + this.volunterrIndex + '成功！',
								type: 'success',
								duration: 3 * 1000
							})
						}
						if (type == 'add') {
							this.volunterrIndex++;
						} else if (type == 'reduce') {
							this.volunterrIndex--;
						}
						this.getList();
					}
				})
			}
		},
		jump() {
			if (this.volunterrJump <= this.volunteerMax && this.volunterrJump >= this.volunteerMin) {
				if (this.subjectGroup == '') {
					this.volunterrIndex = this.volunterrJump;
					this.jumpStatus = true;
					this.getList();
				} else {
					this.save();
					setTimeout(() => {
						this.jumpStatus = true;
						this.volunterrIndex = this.volunterrJump;
						this.getList();
					}, 500);
				}
			} else {
				this.$message({
					type: 'error',
					message: '请输入正确的志愿数！'
				})
			}
		},
		back() { //上一步志愿
			if (this.volunterrIndex > this.volunteerMin) {
				//console.log(this.subject[0]);
				if (this.subjectGroup == '') {
					this.volunterrIndex--;
					this.saveLoading = true;
					this.getList();
				} else {
					this.save('reduce');
				}

			}
		},
		next() {
			if (this.volunterrIndex < this.volunteerMax) {
				if (this.subjectGroup == '') {
					// this.$message({
					// 	message: '当前志愿为空白，不可以进入下一志愿',
					// 	type: 'error',
					// })
					this.volunterrIndex++;
					this.saveLoading = true;
					this.getList();
				} else {
					this.save('add');
				}
			}
		},
		setSubject() {
			this.subject = [];
			for (let i = 0; i < this.subjectsMax; i++) {
				this.subject.push(null);
			}
		},
		getList() {
			this.clickNextStatus = true;
			let listQuery = {
				volunteerId: this.volunteerId,
				levels: Number(this.volunterrIndex),
			}
			//console.log(listQuery);
			if (this.syS_VolunteerStatus > 0 || this.jumpStatus || this.saveLoading) {
				this.jumpStatus = false;
				this.saveLoading = false;
				this.city = '';
				this.clickNextStatus = false;
				this.province = '';
				this.schoolCode = '';
				this.subjectGroup = '';
				this.setSubject();
				this.subjectObey = [];
				this.adjustment = '';
				this.provinceCity = '';
				this.subjectGroupList = [];
				this.subjectList = [];
				volunteerSchoolSubjectGroups.getList(listQuery).then(res => {
					console.log(res);

					if (res.code == 501) {
						return
					}

					this.provinceCity = res.result.provinceCity;
					//console.log(res);
					if (res.result.province) {
						this.province = res.result.province;
					}
					// if (this.schoolList.length == 0) {
					// 	this.getSchoolList();
					// }
					this.getSchoolList();
					setTimeout(() => {
						this.schoolCode = res.result.schoolCode;
						this.getSubjectGroups();
						setTimeout(() => {
							this.clickNextStatus = false;
							this.subjectGroup = res.result.subjectGroup;
							this.getSchoolSubjects();
							if (res.result.volunteerSubjects) {
								res.result.volunteerSubjects.map(item => {
									//console.log(item.subjectName);
									this.subject.splice(item.orders, 1, item.subjectCode);
								})
							} else {
								this.subject = [];
							}

							this.adjustment = res.result.syS_IfObey ? res.result.syS_IfObey :
								'不服从专业调剂';
						}, 500)

					}, 500)

					//console.log(this.subject);
				})
			}

		},
		getVolunteerId() {
			let listQuery = {
				syS_Times: '本科',
				syS_Years: this.analysisData.syS_Years,
				studentName: this.analysisData.studentName,
				mobileTel: this.analysisData.mobileTel,
			}

			volunteers.memberGet(listQuery).then(res => {
				if (res.code == 200) {
					//console.log(res);
					this.volunteerId = res.result.id;
					this.syS_CoachType = res.result.syS_CoachType;
					this.syS_VolunteerStatus = res.result.syS_VolunteerStatus;
					this.textarea = res.result.notes;
					//console.log()
					this.jumpStatus = true;
					this.getList();
					this.getCheckStatus();
					this.getVolunterrList();
				}

			})
		},
		getCheckStatus() {
			if (!this.volunteerId) {
				return
			}
			volunteersCheck.memberLoad({
				volunteerId: this.volunteerId
			}).then(res => {
				//console.log(res)
				this.checkList = res.data;
			})
		},
		// getList() {
		// 	let listQuery = {
		// 		volunteerId: this.volunteerId,
		// 		levels: this.volunterrIndex,
		// 	}
		// 	console.log(listQuery);
		// 	volunteerSchoolSubjectGroups.getList(listQuery).then(res => {
		// 		console.log(res);
		// 	})
		// },
		getSchoolList() {
			if (!this.province) {
				return
			}
			this.schoolCode = '';
			this.subjectGroup = '';
			this.setSubject();
			let listQuery = {
				syS_Years: this.analysisData.syS_Years,
				syS_Times: '本科',
				province: this.province,
				volunteerId: this.volunteerId,
				limit: 999
			}
			//console.log(listQuery);
			schoolGroups.getMembelList(listQuery).then(res => {
				//console.log(res);
				this.schoolList = res.data;
			})
		},
		getGategorys() {
			//console.log(this.userInfo);
			let listQuery = {
				page: 1,
				limit: 9999
			};
			categorys.getList(listQuery).then(res => {
				this.gategoryList = res.data;
			})
		},
		getGategoryName(val, columnName) {

			var dicobject = this.gategoryList.find(t => t.typeId.toUpperCase() == columnName.toUpperCase() && t
				.dtValue == val)
			//var dicobject = this.gategoryList.find(t => { 
			//  if (t.typeId.toUpperCase() === columnName.toUpperCase() ) { 
			//    return t
			//  } 
			//});

			if (!dicobject) {
				return ''
			} else {
				return dicobject.name
			}

		},
	}
}
</script>

<style lang="scss">
#analysisSystem4 {
	::-webkit-input-placeholder {
		font-weight: bold;
		color: #646464;
	}

	:-moz-placeholder {
		font-weight: bold;
		color: #646464;
	}

	::-moz-placeholder {
		font-weight: bold;
		color: #646464;
	}

	:-ms-input-placeholder {
		font-weight: bold;
		color: #646464;
	}

	.el-input.is-disabled .el-input__inner {
		font-weight: bold;
		color: #646464;
	}

	.el-descriptions .is-bordered .el-descriptions-item__cell {
		border: 1px solid #323232;
	}

	.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
		text-align: center;
	}

	.el-descriptions-item__label.is-bordered-label {
		font-weight: bold;
		color: #323232;
	}

	.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
		font-weight: bold;
	}

	.el-dialog {
		border-radius: 0.5vw;
	}

	.el-dialog__body {
		padding: 2vw;
		color: #606266;
		font-size: 1.1em;
		word-break: break-all;
	}

	.el-dialog__header {
		padding: 1vw 1vw;
		font-size: 1.4em;
		background: #4d6ffb;
		border-radius: 0.5vw;
	}

	.el-dialog__title {
		line-height: 24px;
		font-size: 1em;
		font-weight: bold;
		color: #ffffff;
	}

	.importBtn {
		margin: auto;
		min-width: 20%;
		font-weight: bold;
		font-size: 1em;
		background-color: #4d6ffb;
	}

	.importBtn:focus,
	.importBtn:hover {
		background: #66b1ff;
		border-color: #66b1ff;
		color: #FFF;
	}

	.el-select {
		width: 100%;
	}

	.el-input {
		input {
			font-weight: bold;
			color: #000;
		}
	}

	.el-dialog__headerbtn {
		font-size: 1.2em;
	}

	.el-dialog__headerbtn .el-dialog__close {
		color: #fff;
	}

	.el-textarea__inner {
		font-weight: bold;
		color: #000;
		font-size: 16px;
	}

	.jumpVolunteer {
		.jumpInput {
			display: flex;
			align-items: center;
			justify-content: space-evenly;

			.el-input {
				width: 15%;
				margin-right: 10px;
			}
		}
	}

	.el-checkbox__inner {
		border: 1px solid #a9a9a9;
	}
}
</style>

<style lang="scss" scoped>
#analysisSystem4 {
	min-height: 85vh;

	.volunterrList {
		padding: 20px 50px;

		.row {
			display: flex;
			justify-content: center;
		}

		.volunterrItem {
			margin: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.pageHeader {
		width: calc(100% - 10vw);
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		padding: 0 5vw;
	}

	.top {
		width: 100%;
		display: flex;
		justify-content: space-between;

		h3 {
			flex: 1;
			text-align: left;
		}

		button {
			flex: 1;
			border: none;
			outline: none;
			background: none;
			text-align: right;
			opacity: .8;
			font-size: 1em;
			cursor: pointer;
		}
	}



	.content {
		margin: 25px auto;
		padding: 20px;
		width: 80%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		text-align: left;

		.title {
			margin-bottom: 20px;
			background-color: #4d6ffb;
			color: #fff;
			border-radius: 10px;
			-webkit-print-color-adjust: exact;
			color-adjust: exact;

			// position: relative;
			h4 {
				text-align: left;
				color: #fff;
				font-size: 1.2vw;
				padding: 0.5vw 0vw 0.5vw 2.5vw;
			}

			img {
				float: left;
				margin-left: 1vw;
				margin-top: 0.7vw;
				height: 1.5vw;
			}
		}

		.inputWrap {
			margin: 0 auto;
			padding: 0 10px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.input {
				margin: 10px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				white-space: nowrap;

				.demonstration {
					margin: 0 5px;
					font-weight: bold;
				}
			}

			// .input:nth-child(1),
			// .input:nth-child(3){
			// 	text-align: left;
			// }
			// .input:nth-child(2),
			// .input:nth-child(4){
			// 	text-align: right;
			// }
		}

		.innerBox {
			width: 90%;
			margin: 0 auto;

			.top {
				display: flex;
				font-weight: bold;
				margin: 10px 0;
				justify-content: space-around;
				white-space: nowrap;

				span {
					margin: 0 10px;
				}
			}
		}

		.selectSubject {
			display: flex;
			flex-wrap: wrap;

			.subjectItem {
				width: calc(33% - 40px);
				margin: 10px;
				padding: 10px;
				//border-radius:5px;
				border: 1px solid #ddd;

				.title {
					text-align: center;
					padding: 10px;
					color: #fff;
				}

				.input {
					margin: 0 auto;
					width: 80%;
				}

				p {
					display: flex;
					justify-content: center;
					font-weight: bold;
					margin: 10px 0;
				}
			}

		}

		.input {
			text-align: center;

		}

		.content-btn {
			text-align: center;

			.el-button {
				margin: 20px;
			}

			p {
				span {
					color: red;
				}
			}
		}

		.jumpVolunteer {
			display: flex;
			width: 80%;
			margin: 0 auto;
			align-items: center;
			justify-content: center;

			span {
				margin: 0 10px;
				white-space: nowrap;
			}
		}

		.tips {
			span {
				font-size: 0.8vw;
				font-weight: normal;
				color: #959595;
				margin: 0 2vw;

				i {
					color: #d30f11;
					margin-right: 0.5vw;
				}
			}
		}
	}

	/*
	.exportContent {
		.content {
			padding-bottom: 20px;

			.innerBox {
				margin: 50px auto;

				.inputWrap {
					.input {
						margin: 10px 0;
						width: 30%;
						flex: unset;
					}
				}
			}
		}


	}
*/
	.reportText {
		width: 100%;
		// margin: 0 auto;

		p {
			font-size: 1.2em;
			margin: 5px 0;
			text-align: left;
		}

		span {
			color: red;
		}
	}
}
</style>
<template>
	<div id="home">
		<!-- <div style="width:calc(100% - 10vw);margin: 0 auto;text-align: left;padding: 0 5vw;position: absolute;top: 0;height: 60px;">
			<div class="logo">
				<img src="../assets/logo.png" />
				<h2>广西学士通教育</h2>
			</div>
			
			<h5 style="color: #ffe600;float:right;font-size: 1.2vw;margin:0.7vw 0;">始于2009 高度专业 高度敬业 为人师表</h5>
		</div> -->
		<div class="content">
			<div class="login-box">
				<div class="text">
					<h4>登录提示</h4>
				</div>
				<div class="login-box-content">
					<h4>
						学士卡
						<i style="color: #ff0000;font-style: normal;">{{ userInfo.cardNo }}</i>
						登录成功！
					</h4>
					<div class="btns" style="margin: 20px 0;">
						<div class="btn">
							<el-button type="danger" @click="openScoreDialog" size="mini">
								修改分数
							</el-button>
						</div>
						<div class="btn">
							<el-button type="danger" @click="loginout" size="mini">
								退出
							</el-button>
						</div>
					</div>

					<!-- <div class="ewm">
						<img src="../assets/login/erwm.png" style="width: 27%;margin-right: 0.5em;" />
						<span style="text-align: left;font-size: 1em;">手机微信扫描二维码关注公众号，点系统入口可同时使用手机版。</span>
					</div> -->
				</div>
			</div>

			<div class="box">
				<li>
					<div class="box_img">
						<img src="../assets/login/img5.jpg" />
					</div>
					<div class="box_title">
						<h4>
							<p style="white-space: nowrap;">本科提前批</p>
							<p style="white-space: nowrap;">志愿填报查询</p>
							<p style="white-space: nowrap;">分析系统</p>
						</h4>
						<p class="link">
							<img src="../assets/login/turn_in.png" />
							<el-link @click="register('searchTqp')" :underline="false" style="white-space: nowrap;">
								点击进入
							</el-link>
						</p>
					</div>
				</li>
				<li>
					<div class="box_img">
						<img src="../assets/login/img6.jpg" />
					</div>
					<div class="box_title">
						<h4>
							<p style="white-space: nowrap;">本科普通批</p>
							<p style="white-space: nowrap;">志愿填报查询</p>
							<p style="white-space: nowrap;">分析系统</p>
						</h4>
						<p class="link">
							<img src="../assets/login/turn_in.png" />
							<el-link @click="register('search')" :underline="false" style="white-space: nowrap;">
								点击进入
							</el-link>
						</p>
					</div>
				</li>


			</div>
		</div>
		<saveScoreDialog :visible="changeScoreVisible" @visible="getVisible" :userInfo="userInfo"></saveScoreDialog>
	</div>
</template>

<script>
import {
	removeToken
} from '../api/auth.js'

import {
	mapState,
	mapActions
} from "vuex"
import saveScoreDialog from '@/components/saveScoreDialog'
export default {
	components: {
		saveScoreDialog
	},
	data() {
		return {
			changeScoreVisible: false,
		}
	},
	created() {

	},
	computed: {
		...mapState(["userInfo",]),
	},
	methods: {
		...mapActions(["verification",]),
		toEdu() {
			window.location.href = 'http://xueshitongedu.cn/';
		},
		openScoreDialog() {
			this.verification();
			setTimeout(() => {
				this.changeScoreVisible = true;
			}, 200)
		},
		getVisible(val) {
			this.changeScoreVisible = val;
		},
		register(route) {
			this.$router.push(route)
			// if (this.userInfo.cardNo.substring(0, 3) == '999') {
			// 	this.$router.push(route)
			// }

			// if (this.userInfo.cardNo.substring(0, 3) == '808') {
			// 	if (route == 'mbtiStuInfo' || route == 'teacher') {
			// 		this.$message({
			// 			message: '您好，B卡用户才可以点击进入！A卡如想升级为B卡，请联系客服。',
			// 			type: 'error',
			// 			duration: 5000
			// 		})
			// 	} else {
			// 		this.$router.push(route)
			// 	}
			// }

		},
		loginout() {

			//location.reload()
			removeToken();
			localStorage.clear()
			window.sessionStorage.clear()
			this.$message({
				message: '退出成功',
				type: 'success',
				duration: 2000
			})
			this.$router.push('/login')
		},
	},

}
</script>

<style lang="scss" scoped>
// @media screen and (max-width: 1150px) {
// 	.leftImg {
// 		width: 450px !important;
// 	}
// }

// @media screen and (max-width: 1010px) {
// 	.leftImg {
// 		width: 380px !important;
// 	}
// }
$dark_gray: #D1DFE8;

#home {
	width: 100%;
	min-height: 900px;
	background: url(../assets/home/home_bg.jpg) no-repeat;
	background-color: #fff;
	background-position: 0 0;
	background-size: 100% 100%;
	text-align: center;
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.content {
		position: absolute;
		top: 20%;
		left: 16.5%;
		width: 70%;
		height: 60vh;
		display: flex;
		vertical-align: middle;

		.login-box {
			margin-right: 200px;
			width: 25%;
			text-align: left;

			.text {
				display: flex;
				text-align: left;
				line-height: 20px;
				border-bottom: 1px solid rgb(50, 50, 50);

				img {
					height: 20px;
				}

				h5 {
					//border-bottom: 1px solid rgb(50, 50, 50);
				}

			}

			.login-box-content {
				h4 {
					margin: 10px 0;
				}

				.ewm {
					margin-top: 8vw;
					display: flex;
				}

				.btns {
					display: flex;
					flex-direction: column;

					.btn {

						margin: 10px 0;

						.el-button {
							width: 100%;
						}
					}
				}
			}
		}

		.box {
			width: 70%;
			display: flex;
			flex-wrap: wrap;
			align-content: center;

			li {
				display: flex;
				height: 150px;
				width: 33%;
				margin: 15px;
				background: #f0d031;
				border-radius: 5px;
				padding: 5px;

				.box_img {
					width: 50%;
					height: calc(100% - 0.4vw);
					position: relative;

					img:first-child {
						width: 100%;
						height: calc(100% - 0.4vw);
						text-align: left;
					}

					// img:last-child{
					// 	height: 100%;
					// }
				}

				.box_title {
					flex: 1;
					height: 100%;
					position: relative;

					h4 {
						font-style: italic;
						color: #1f484d;
						text-shadow: 0.1vw 0.1vw #fff;
						text-align: left;
					}

					h5 {
						font-size: 18px;
						font-style: italic;
						color: #1f484d;
						text-shadow: 0.1vw 0.1vw #fff;
						position: absolute;
						bottom: 25px;
						right: 20px;
					}

					.link {
						img {
							height: 15px;
							width: unset;
							margin-right: 5px;
							margin-top: 0;
						}

						display: flex;
						position: absolute;
						bottom: 0;
						right: 0;
						align-items: center;
					}
				}
			}

			h4 {
				margin-bottom: 0vw;
				font-size: 1.2vw;
			}
		}

		> img {
			width: 568px;
			margin-right: 150px;
			vertical-align: middle;
		}

		.login-form {
			display: inline-block;
			width: 16vw;
			vertical-align: middle;
		}

		.el-button--primary {
			border: none;
		}
	}

	// @media screen and (max-width: 500px) {
	// 	.content {
	// 		flex-direction: column;

	// 		.login-box {
	// 			width: 100%;
	// 			margin: 0;
	// 			padding-bottom: 1vw;
	// 			position: relative;

	// 			.login-box-content {
	// 				display: none;
	// 			}

	// 			.text {
	// 				display: block;
	// 				height: 20px;
	// 				line-height: 20px;

	// 				img {
	// 					display: none;
	// 				}
	// 			}

	// 			.back {
	// 				position: absolute;
	// 				left: 0;
	// 				top: 45vh;
	// 			}
	// 		}

	// 		.box {
	// 			width: calc(100% - 10vw);
	// 			padding: 0 5vw;
	// 			flex-direction: column;

	// 			li {
	// 				width: 100%;
	// 				flex: 1;
	// 			}
	// 		}
	// 	}
	// }

	.svg-container {
		color: $dark_gray;
		vertical-align: middle;
		width: 33px;
		display: inline-block;
		font-size: 22px;

		&_login {
			font-size: 31px;
		}
	}

	.title {
		margin: 10px 0;
		margin-bottom: 40px;
		text-align: left;
		font-size: 26px;
		font-weight: bold;
		background-image: linear-gradient(to right, #fd7f0f, #ff0300);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}


	// .box li {
	// 	float: left;
	// 	background: #f0d031;
	// 	border-radius: 0.5vw;
	// 	padding: 0.4vw;
	// }

	// .box .box_img {
	// 	width: 8vw;
	// 	float: left;
	// }

	// .box h4 {
	// 	margin-bottom: 0vw;
	// 	font-size: 1.2vw;
	// }

	// .box li {
	// 	float: left;
	// 	background: #f0d031;
	// 	border-radius: 0.5vw;
	// 	padding: 0.4vw;
	// }

	// .box .box_img {
	// 	width: 8vw;
	// 	float: left;
	// }

	// .box h4 {
	// 	margin-bottom: 0vw;
	// 	font-size: 1.2vw;
	// }

	.map p {
		text-align: left;
		font-size: 0.8vw;
		margin-top: 0.5vw;
	}

	.el-header {
		display: none !important;
	}


}
</style>